import { useEffect, useState } from "react";
import { Team } from "msd-capacity-planning-model";

import { useAllTeams } from "./useAllTeams";

export default function useTeam(teamId: string) {
  const teams = useAllTeams();
  const [team, setTeam] = useState(undefined as Team | undefined);
  useEffect(() => {
    setTeam(teams[teamId]);
  }, [teamId, teams]);
  return team;
}
