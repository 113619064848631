import { Memento, MementoType } from "msd-capacity-planning-model";
import env from "../app/Environment";
import fetchService from "../utils/FetchService";

function getUrl(type: MementoType, id: string): string {
  return `${env.endpoint}/history/${type}/${id}`;
}

export class HistoryService {
  getItems(type: MementoType, id: string): Promise<Memento[]> {
    const url = getUrl(type, id);
    return fetchService.fetch(url).then((r) => r.json());
  }
}

const service = new HistoryService();
export default service;
