import { Grid, Paper } from "@material-ui/core";
import { useBudgetInAggregator } from "customers/useBudgetInAggregator";
import { CustomerAggregatorType } from "msd-capacity-planning-model";
import { CommentsPanelEditor } from "../../comments/CommentsPanelEditor";
import { CountCard } from "../../components/CountCard";
import { TeamBudgetTable } from "./TeamBudgetTable";

export function TeamCustomersDashboard({
  year,
  month,
  teamId,
}: {
  year: number;
  month: number;
  teamId: string;
}) {
  const capacity = useBudgetInAggregator(
    year,
    month,
    CustomerAggregatorType.TEAM,
    teamId
  );
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      spacing={5}
      style={{ marginTop: 16 }}
    >
      <Grid item xs={9}>
        <Paper>
          <TeamBudgetTable year={year} month={month} teamId={teamId} />
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          spacing={1}
        >
          <Grid item>
            <CountCard
              title="Customers"
              count={Object.keys(capacity.items).length}
            />
          </Grid>
          <Grid item>
            <CountCard
              title="Budget"
              count={Math.round(capacity.teamHours + capacity.opsHours)}
            />
          </Grid>
          <Grid item>
            <Paper style={{ marginTop: 32, marginBottom: 32 }}>
              <CommentsPanelEditor
                year={year}
                month={month}
                id={`customers@${teamId}`}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
