import {
  CustomerAggregatorType,
  CustomerHandle
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import useCustomersInAggregator from "./useCustomersInAggregator";

export interface CustomersInAggregator {
  [customerId: string]: CustomerHandle;
}

export default function useCustomersIdsInAggregator(
  year: number,
  month: number,
  type: CustomerAggregatorType,
  ids: string | string[]
): string[] {
  const customers = useCustomersInAggregator(year, month, type, ids);
  const [response, setResponse] = useState([] as string[]);

  useEffect(() => {
    setResponse(Object.keys(customers));
  }, [year, month, type, ids, customers]);

  return response;
}
