import { Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { DashboardBreadcrumbs } from "../DashboardBreadcrumbs";
import { useDashboardPeriod } from "../useDashboardPeriod";
import { EngineerDashboard } from "./EngineerDashboard";

export function EngineerDashboardPage() {
  const period = useDashboardPeriod();
  const params: { [key: string]: string } = useParams();

  return (
    <Box>
      <DashboardBreadcrumbs />
      <EngineerDashboard period={period} engineerId={params.engineer} />
    </Box>
  );
}
