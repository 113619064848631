import { SynchStatusState } from "../administration.synchstatus/SynchStatusTypes";
import { AdministratorsState } from "../administrators/AdministratorTypes";
import { AuthorizationState } from "../auth/AuthorizationTypes";
import { CommentsState } from "../comments/CommentTypes";
import { CustomersState } from "../customers/CustomerTypes";
import { EMsState } from "../ems/EMsTypes";
import { EngineersState } from "../engineers/EngineersTypes";
import { HarvestState } from "../harvest/HarvestTypes";
import { JiraState } from "../jira/JiraTypes";
import { ManagersState } from "../managers/ManagerTypes";
import { RegionsState } from "../regions/RegionTypes";
import { TeamsState } from "../teams/TeamsTypes";
import { WebSocketState } from "../websocket/WebSocketTypes";

export const INIT_APP = "app/init";
export const REPORT_APP_FEEDBACK = "app/feedback/report";
export const REMOVE_APP_FEEDBACK = "app/feedback/remove";

export interface InitAppAction {
  type: typeof INIT_APP;
}

export interface ReportFeedbackAction {
  type: typeof REPORT_APP_FEEDBACK;
  feedback: string;
  error?: Error;
}

export interface RemoveFeedbackAction {
  type: typeof REMOVE_APP_FEEDBACK;
}

export type AppFeedbackAction = ReportFeedbackAction | RemoveFeedbackAction;

export interface EndpointsState {
  backend: string;
}

export interface AppState {
  administrators: AdministratorsState;
  authorization: AuthorizationState;
  comments: CommentsState;
  customers: CustomersState;
  ems: EMsState;
  endpoints: EndpointsState;
  engineers: EngineersState;
  feedback: string[];
  harvest: HarvestState;
  initiated: boolean;
  jira: JiraState;
  managers: ManagersState;
  regions: RegionsState;
  synchStatus: SynchStatusState;
  teams: TeamsState;
  websocket: WebSocketState;
}
