import { useEffect, useState } from "react";
import { EngineerHandle } from "msd-capacity-planning-model";
import { useAllEngineers } from "./useAllEngineers";

export function useEngineer(id: string): EngineerHandle | undefined {
  const engineers = useAllEngineers();
  const [engineer, setEngineer] = useState(
    undefined as undefined | EngineerHandle
  );
  useEffect(() => {
    setEngineer(engineers[id]);
  }, [engineers, id]);
  return engineer;
}
