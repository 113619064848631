import { Box } from "@material-ui/core";
import useCustomersInAggregator from "customers/useCustomersInAggregator";
import { useDashboardPeriod } from "dashboards/useDashboardPeriod";
import { CustomerAggregatorType } from "msd-capacity-planning-model";
import { CustomersTable } from "../customers/CustomersTable";

export function EMCustomersTable({
  year,
  month,
  emId,
}: {
  year: number;
  month: number;
  emId: string;
}) {
  const period = useDashboardPeriod();
  const customers = useCustomersInAggregator(
    year,
    month,
    CustomerAggregatorType.EM,
    emId
  );
  return (
    <Box>
      <Box>
        <CustomersTable
          year={period.year}
          month={period.month}
          customers={customers}
          emColumn={false}
          inBudgetColumn={true}
          overBudgetColumn={true}
          retrospectiveNotesColumn={true}
          planningNotesColumn={true}
          linkPrefix={`/customers`}
        />
      </Box>
    </Box>
  );
}
