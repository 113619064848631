import { Container } from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import { CustomerDashboardPage } from "../customer/CustomerDashboardPage";
import { useDashboardPeriod } from "../useDashboardPeriod";
import { CustomersDashboard } from "./CustomersDashboard";

export function CustomersDashboardRoutePage() {
  const period = useDashboardPeriod();

  return (
    <Container maxWidth="xl">
      <Switch>
        <Route path="/dashboards/customers/items/:customer">
          <CustomerDashboardPage />
        </Route>
        <Route path="/dashboards/customers">
          <CustomersDashboard period={period} />
        </Route>
      </Switch>
    </Container>
  );
}
