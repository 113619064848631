import { Middleware } from "redux";
import { AppState, InitAppAction, INIT_APP } from "../redux/AppTypes";
import service from "./SynchStatusService";
import {
  FETCH_SYNCHSTATUS as FETCH,

  LoadSynchStatusAction as LoadAction, LOAD_SYNCHSTATUS as LOAD, SynchStatusAction as BaseAction
} from "./SynchStatusTypes";

const initMiddleware: Middleware<{}, AppState> =
  (store) => (next) => (action: InitAppAction) => {
    const result = next(action);
    if (action.type === INIT_APP) {
      store.dispatch({ type: FETCH });
    }
    return result;
  };

const fetchMiddleware: Middleware<{}, AppState> =
  (store) => (next) => async (action: BaseAction) => {
    const result = next(action);
    if (action.type === FETCH) {
      const statuses = await service.fetch();
      const load: LoadAction = {
        type: LOAD,
        statuses,
      };
      store.dispatch(load);
      return statuses;
    } else {
      return result;
    }
  };

const middlewares = [initMiddleware, fetchMiddleware];
export default middlewares;
