import { CustomerAggregatorType } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { StringCounter } from "../utils/ItemCounter";
import useCustomersInAggregator from "./useCustomersInAggregator";

export function useCustomerSkillsInTeam(
  year: number,
  month: number,
  teamId: string
): StringCounter {
  const customers = useCustomersInAggregator(
    year,
    month,
    CustomerAggregatorType.TEAM,
    teamId
  );
  const [skills, setSkills] = useState(new StringCounter());

  useEffect(() => {
    const newSkills = Object.values(customers).reduce((skills, customer) => {
      new Set(customer.skills || []).forEach((skill) => {
        if (!!skill) skills.count(skill);
      });
      return skills;
    }, new StringCounter());
    setSkills(newSkills);
  }, [year, month, teamId, customers]);

  return skills;
}
