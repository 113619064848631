import { Box, useTheme } from "@material-ui/core";
import { NoStyleLink } from "./NoStyleLink";

export function InlineCountCard({
  title,
  count,
  path,
  suffix = "",
}: {
  title: string;
  count: number;
  path?: string;
  suffix?: string;
}) {
  const theme = useTheme();
  const text = `${title}: ${count.toLocaleString()} ${suffix}`;
  return (
    <Box
      style={{
        padding: theme.spacing(),
      }}
      display="inline"
    >
      {path ? <NoStyleLink pathname={path} text={text} /> : text}
    </Box>
  );
}
