import { EMHandle } from "msd-capacity-planning-model";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AppState } from "../redux/AppTypes";

const stateSelector = (state: AppState) => state.ems.items;

export const useAllEMsSelector = createSelector(stateSelector, (items) => {
  return (Object.values(items) || []).reduce((map, em) => {
    map[em.id] = new EMHandle(em);
    return map;
  }, {} as { [emId: string]: EMHandle });
});

export function useAllEMs(): { [emId: string]: EMHandle } {
  const state = useSelector((state: AppState) => state);
  const ems = useAllEMsSelector(state);
  return ems;
}
