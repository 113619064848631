import { useTeamCapacityByAggregator } from "capacity/useTeamCapacityByAggregator";
import { DashboardPeriod } from "dashboards/DashboardPeriod";
import { useLeads } from "leads/useLeads";
import {
  CustomerAggregatorType,
  TeamCapacityAggregatorType
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { LabeledScatterDataPoint } from "../../components/LabeledScatterDataPoint";
import { ScatterChartCard } from "../../components/ScatterChartCard";
import { useBudgetByAggregator } from "../../customers/useBudgetByAggregator";

export function LeadUtilizationScatterChartCard({
  period,
}: {
  period: DashboardPeriod;
}) {
  const leads = useLeads();
  const capacity = useTeamCapacityByAggregator(
    period.year,
    period.month,
    TeamCapacityAggregatorType.LEAD
  );
  const budgets = useBudgetByAggregator(
    period.year,
    period.month,
    CustomerAggregatorType.LEAD
  );

  const [dataPoints, setDataPoints] = useState([] as LabeledScatterDataPoint[]);

  useEffect(() => {
    const allLeads = new Set(leads);
    const newDataPoints = Object.keys(budgets)
      .filter((leadId) => allLeads.has(leadId))
      .map((leadId) => {
        const x =
          (budgets[leadId]?.opsHours || 0) + (budgets[leadId]?.teamHours || 0);
        const y = capacity[leadId]?.allocatedHours || 0;
        const dataPoint: LabeledScatterDataPoint = {
          label: leadId,
          link: `/dashboards/leads/${leadId}`,
          x,
          y,
        };
        return dataPoint;
      });
    setDataPoints(newDataPoints);
  }, [leads, capacity, budgets]);

  return (
    <ScatterChartCard
      title="Lead allocations"
      xScaleTitle="Budget (hours)"
      yScaleTitle="Capacity (hours)"
      dataPoints={dataPoints}
      chartHeight={300}
    ></ScatterChartCard>
  );
}
