import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SearchIcon from "@material-ui/icons/Search";
import { HarvestUserLinkButton } from "harvest/HarvestUserLinkButton";
import { useHarvestUsers } from "harvest/useHarvestUsers";
import {
  EngineerHandle,
  EngineerWarningFlag
} from "msd-capacity-planning-model";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getHarvestInactiveUserURL } from "utils/harvestURLService";
import { AppState } from "../redux/AppTypes";
import { FETCH_ENGINEERS, REMOVE_ENGINEER } from "./EngineersTypes";
import { EngineerWarningFlagsIcon } from "./EngineerWarningFlagsIcon";
import useSortedEngineers from "./useSortedEngineers";

const now = new Date();

export function EngineersPanel() {
  const dispatch = useDispatch();
  const history = useHistory();
  const items = useSortedEngineers();
  const harvestUsers = useHarvestUsers();
  const teams = useSelector((state: AppState) => state.teams.items);
  const regions = useSelector((state: AppState) => state.regions.items);
  const [filter, setFilter] = useState("");
  const [offboarded, setOffboarded] = useState(false);
  const [filteredItems, setFilteredItems] = useState([] as EngineerHandle[]);
  const [warningFlags, setWarningFlags] = useState(
    {} as { [id: string]: Set<EngineerWarningFlag> }
  );

  useEffect(() => {
    const activeInHarvest = Object.values(harvestUsers).reduce((set, item) => {
      set.add(`${item.id}`);
      return set;
    }, new Set<string>());
    const newFlags = items.reduce((map, item) => {
      const harvestId = item.harvestId;
      const newWarningFlags = new Set(item.getWarningFlags().values());
      const itemOffboardDate = item.offboardDate;
      const offboardDate = new Date(
        itemOffboardDate?.year || now.getFullYear(),
        itemOffboardDate?.month || now.getMonth(),
        itemOffboardDate?.day || now.getDate() + 1
      );
      if (
        activeInHarvest.size > 0 &&
        harvestId &&
        !activeInHarvest.has(harvestId) &&
        offboardDate.getTime() > now.getTime()
      ) {
        newWarningFlags.add(EngineerWarningFlag.OFFBOARDED);
      }
      map[item.id] = newWarningFlags;
      return map;
    }, {} as { [id: string]: Set<EngineerWarningFlag> });
    setWarningFlags(newFlags);
  }, [items, harvestUsers]);

  useEffect(() => {
    let newFilteredItems = Array.from(items);

    if (!offboarded) {
      newFilteredItems = newFilteredItems.filter(
        (items) => items.isOffboarded() === false
      );
    }

    if (filter) {
      newFilteredItems = newFilteredItems.filter((item) => {
        const str = `${Array.from(item.getEmails()).join(" ")} ${
          item.firstName
        } ${item.lastName}`;
        return str.toLowerCase().indexOf(filter.toLowerCase()) > -1;
      });
    }
    newFilteredItems.sort((e1, e2) => {
      const offboarded1 = e1.isOffboarded(now) ? 1 : 0;
      const offboarded2 = e2.isOffboarded(now) ? 1 : 0;
      if (offboarded1 !== offboarded2) {
        return offboarded1 - offboarded2;
      }
      const flags1 = warningFlags[e1.id]?.size || 0;
      const flags2 = warningFlags[e2.id]?.size || 0;
      return flags2 - flags1 || e1.compare(e2);
    });
    setFilteredItems(newFilteredItems);
  }, [items, filter, offboarded, warningFlags]);

  return (
    <Box>
      <Grid>
        <Tooltip title="Add">
          <Button
            onClick={() => history.push("/administration/engineers/")}
            color="primary"
            variant="contained"
          >
            New engineer
          </Button>
        </Tooltip>
        <Tooltip title="Refresh">
          <IconButton onClick={() => dispatch({ type: FETCH_ENGINEERS })}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              value={offboarded}
              onChange={() => setOffboarded(!offboarded)}
            />
          }
          label="Offboarded"
        />

        <Tooltip title="Search">
          <TextField
            onChange={(e) => setFilter(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {" "}
                  <SearchIcon />{" "}
                </InputAdornment>
              ),
            }}
            style={{ left: 8 }}
          />
        </Tooltip>
      </Grid>

      <Grid container>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Team</TableCell>
                <TableCell>Region</TableCell>
                <TableCell>Role</TableCell>
                <TableCell align="right" style={{ maxWidth: 100 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems.map((item, index) => {
                const offboarded = item.isOffboarded(now);
                return (
                  <TableRow key={item.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={
                        offboarded ? { textDecorationLine: "line-through" } : {}
                      }
                    >
                      {index + 1}. {item.firstName} {item.lastName}
                    </TableCell>
                    <TableCell>
                      {item
                        .getTeamIds(now.getFullYear(), now.getMonth())
                        .filter((teamId) => !!teamId)
                        .map((teamId) => teams[teamId]?.name)
                        .join(", ")}
                    </TableCell>
                    <TableCell>{regions[item.region]?.name}</TableCell>
                    <TableCell>{item.role}</TableCell>
                    <TableCell align="right">
                      <EngineerWarningFlagsIcon id={item.id} />
                      <span>
                        {warningFlags[item.id]?.has(
                          EngineerWarningFlag.OFFBOARDED
                        ) ? (
                          <IconButton
                            onClick={() => {
                              const url = getHarvestInactiveUserURL();
                              window.open(url, "_blank");
                            }}
                          >
                            <ScheduleIcon />
                          </IconButton>
                        ) : (
                          <HarvestUserLinkButton user={item.harvestId} />
                        )}
                      </span>

                      <Tooltip title="Dashboard">
                        <IconButton disabled
                          onClick={() =>
                            history.push(`/dashboards/engineers/${item.id}`)
                          }
                        >
                          <DashboardIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() =>
                            history.push(`/administration/engineers/${item.id}`)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() =>
                            dispatch({ type: REMOVE_ENGINEER, id: item.id })
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
}
