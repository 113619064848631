import { useTeamCapacityByAggregator } from "capacity/useTeamCapacityByAggregator";
import {
  AggregatedBudget,
  AggregatedCapacity,
  AggregatedTeamUtilization, CustomerAggregatorType, Team,
  TeamCapacity,
  TeamCapacityAggregatorType
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useBudgetByAggregator } from "../customers/useBudgetByAggregator";

export interface TeamUtilizations {
  [teamId: string]: AggregatedTeamUtilization;
}

export function useTeamUtilizationByTeam(
  year: number,
  month: number,
  teams: { [teamId: string]: Team }
): TeamUtilizations {
  const budgets = useBudgetByAggregator(
    year,
    month,
    CustomerAggregatorType.TEAM
  );
  const capacities = useTeamCapacityByAggregator(
    year,
    month,
    TeamCapacityAggregatorType.TEAM
  );
  const [utilizations, setUtilizations] = useState({} as TeamUtilizations);

  useEffect(() => {
    const newUtilizations = Object.values(teams || {}).reduce(
      (response, team) => {
        response[team.id] = new AggregatedTeamUtilization(
          capacities[team.id] || new AggregatedCapacity<TeamCapacity>(),
          budgets[team.id] || new AggregatedBudget()
        );
        return response;
      },
      {} as TeamUtilizations
    );
    setUtilizations(newUtilizations);
  }, [year, month, teams, capacities, budgets]);

  return utilizations;
}
