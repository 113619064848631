import { useParams } from "react-router-dom";
import { TeamAggregatorType } from "teams/TeamAggregator";
import useTeamsInAggregator from "../../teams/useTeamsInAggregator";
import { TeamsDashboardRoutePage } from "../teams/TeamsDashboardRoutePage";
export function ManagerDashboardPage({ baseUrl }: { baseUrl: string }) {
  const params: { [key: string]: string } = useParams();
  const managerEmail = params.manager;
  const teams = useTeamsInAggregator(TeamAggregatorType.MANAGER, managerEmail);

  return <TeamsDashboardRoutePage baseUrl={baseUrl} teams={teams} />;
}
