import React, { useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Tooltip
} from '@material-ui/core'
import { ActorType, useJiraProjectPeople } from './useJiraProjectPeople'
import ClearIcon from '@material-ui/icons/Clear'
import CheckIcon from '@material-ui/icons/Check'
import service from './JiraResourcesService'
import RefreshIcon from '@material-ui/icons/Refresh'

export function JiraProjectPeople({ projectKey }: { projectKey: string }) {
  const [reloadCounter, setReloadCounter] = useState(0)
  const people = useJiraProjectPeople(projectKey, reloadCounter)
  const [updating, setUpdating] = useState(false)

  function onUpdate(): void {
    setUpdating(true)
    service.updatePeople(projectKey).finally(() => {
      setUpdating(false)
      setReloadCounter(reloadCounter + 1)
    })
  }
  return (
    <Box>
      People:{' '}
      <IconButton
        disabled={updating}
        onClick={() => setReloadCounter(reloadCounter + 1)}
        style={{ marginLeft: 8 }}
      >
        <RefreshIcon />
      </IconButton>
      {!!people.actors.find((p) => p.forbidden) ||
      !!people.roles.find((r) => r.incomplete) ? (
        <Button
          variant='text'
          color='primary'
          disabled={updating}
          onClick={() => onUpdate()}
        >
          Update{' '}
          {updating ? (
            <CircularProgress size={16} style={{ marginLeft: 8 }} />
          ) : null}
        </Button>
      ) : null}
      <List>
        {people.actors.map((actor, index) => {
          const roles = Array.from(actor.roles.values())
          const status = (
            <span style={{ marginRight: 8, verticalAlign: 'middle' }}>
              {actor.forbidden ? (
                <Tooltip title='Forbidden permission'>
                  <ClearIcon style={{ color: 'red' }} />
                </Tooltip>
              ) : (
                <Tooltip title='Allowed permission'>
                  <CheckIcon style={{ color: 'green' }} />
                </Tooltip>
              )}
            </span>
          )
          return (
            <ListItem key={`index=${index}`}>
              {status}
              {actor.type === ActorType.GROUP ? 'Group' : 'User'} "
              {actor.displayName}": {roles.map((r) => r.name).join(', ')}
            </ListItem>
          )
        })}
        {people.roles
          .filter((role) => role.incomplete)
          .map((role, index) => {
            const status = (
              <span style={{ marginRight: 8, verticalAlign: 'middle' }}>
                <Tooltip title='Missing permissions'>
                  <ClearIcon style={{ color: 'red' }} />
                </Tooltip>
              </span>
            )
            return (
              <ListItem key={`index=${index}`}>
                {status}
                Role: "{role.name}"
              </ListItem>
            )
          })}
      </List>
    </Box>
  )
}
