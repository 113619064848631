import React, { useState } from "react";

import {
  Box,
  Button,
  Typography,
  Modal,
  Checkbox
} from "@material-ui/core";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export function BasicModal(props: any) {
  const { agreeLabel, onAgree, desagreeLabel, body, title, confirmMessage } = props.modal;
  const { isVisible, setIsVisible } = props;
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleAgree = () => {
    if (onAgree && isChecked) {
      setIsVisible(false);
      setIsChecked(false);
      onAgree();
    }
  };

  const handleDesagree = () => {
    setIsVisible(false);
    setIsChecked(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div>
      <Modal
        open={isVisible}
        onClose={handleDesagree}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box>
            {title && (
              <>
                <Typography id="modal-modal-title" component="h4">
                  {title}
                </Typography>
                <br />
              </>
            )}
            {body && (
              <Typography id="modal-modal-title" component="h4">
                {body}
              </Typography>
            )}
            {confirmMessage && (
              <Typography id="modal-modal-title" component="p">
                <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
                {confirmMessage}
              </Typography>
            )}
          </Box>
          <br />
          <div>
            <Box display="flex" style={{ flexDirection: 'row' }}>

              <Button variant="outlined" onClick={handleDesagree}>
                {desagreeLabel || "Cancel"}
              </Button>

              {onAgree && (
                <Button
                  disabled={!isChecked}
                  variant="contained"
                  style={{ marginLeft: 'auto' }}
                  onClick={handleAgree}
                >
                  {agreeLabel || "OK"}
                </Button>
              )}
            </Box>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
