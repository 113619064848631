import { Card, CardContent, Grid, Typography, Box, Tooltip } from "@material-ui/core";
import { ReactNode } from "react";
import HelpIcon from "@material-ui/icons/Help";


export function DashboardCard({
  title,
  children,
  height,
  helpicon,
  helptext
}: {
  title?: string;
  height?: number;
  helpicon?: boolean;
  helptext?: string;
  children: {
    body: ReactNode;
    footer?: ReactNode;
  };
}) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          {title ? (
            <Grid item xs={12}>
              <Typography variant="h5">{title} 
              { helpicon ? (
                <Tooltip title={ helptext }>
                  <HelpIcon style={{ width: 25, color: "gray"}}/>
              </Tooltip> 
              ): null
              }
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Box style={{ height: height ? `${height}px` : undefined }}>
              {children.body}
            </Box>
          </Grid>
          {children?.footer ? (
            <Grid item xs={12}>
              {children?.footer}
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  );
}
