import { useEffect, useState } from "react";
import { useAllEMs } from "./useAllEMs";

export function useEMSkills() {
  const ems = useAllEMs();
  const [skills, setSkills] = useState([] as string[]);

  useEffect(() => {
    const newSkills = Object.values(ems).reduce((skills, em) => {
      (em.skills || []).forEach((skill) => skills.add(skill));
      return skills;
    }, new Set<string>());
    setSkills(Array.from(newSkills));
  }, [ems]);

  return skills;
}
