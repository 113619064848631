import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import { Team, TeamHandle } from "msd-capacity-planning-model";
import React, { useEffect, useState } from "react";
import { useAllTeams } from "./useAllTeams";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../redux/AppTypes";
import { FETCH_TEAMS, REMOVE_TEAM } from "./TeamsTypes";

export function TeamsPanel() {
  const dispatch = useDispatch();
  const history = useHistory();
  const now = new Date();
  const teams = useAllTeams();
  const [nameFilter, setNameFilter] = useState("");
  const [offboardedFilter, setOffboardedFilter] = useState(false);
  const [filteredTeams, setFilteredTeams] = useState([] as TeamHandle[]);

  useEffect(() => {
    let sortedTeams = Object.values(teams);
    if (nameFilter) {
      sortedTeams = sortedTeams.filter(
        (team) => team.name.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1
      );
    }

    if (!offboardedFilter) {
      sortedTeams = sortedTeams.filter(
        (team) => team.isOffboarded(now) === false
      );
    }

    sortedTeams.sort((t1, t2) => {
      const offboarded1 = t1.isOffboarded(now) ? 1 : 0;
      const offboarded2 = t2.isOffboarded(now) ? 1 : 0;
      if (offboarded1 !== offboarded2) {
        return offboarded1 - offboarded2;
      }
      return  t1.compare(t2);
    });
    setFilteredTeams(sortedTeams);
  }, [teams, nameFilter, offboardedFilter]);

  return (
    <Box>
      <Grid>
        <Tooltip title="Add">
          <Button
            onClick={() => history.push("/administration/teams/")}
            color="primary"
            variant="contained"
          >
            New team
          </Button>
        </Tooltip>
        <Tooltip title="Refresh">
          <IconButton onClick={() => dispatch({ type: FETCH_TEAMS })}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={offboardedFilter}
                onChange={() => setOffboardedFilter(!offboardedFilter)}
              />
            }
            label="Offboarded"
            style={{ marginRight: 16 }}
          />
        <Tooltip title="Search">
          <TextField
            onChange={(e) => setNameFilter(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {" "}
                  <SearchIcon />{" "}
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
      </Grid>

      <Grid container>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Lead Email</TableCell>
                <TableCell>Manager</TableCell>
                <TableCell>Type</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTeams.map((team, index) => {
                const offboarded = team.isOffboarded(now);
                return (
                <TableRow key={team.id}>
                  <TableCell 
                    component="th"
                    scope="row"
                    style={
                      offboarded ? { textDecorationLine: "line-through" } : {}
                    }
                  >
                    {index + 1}. {team.name}
                  </TableCell>
                  <TableCell>{team.lead}</TableCell>
                  <TableCell>{team.manager}</TableCell>
                  <TableCell>{(team.type || []).join(", ")}</TableCell>
                  <TableCell align="right">
                    <Tooltip title="Dashboard">
                      <IconButton
                        onClick={() =>
                          history.push(`/dashboards/teams/${team.id}`)
                        }
                      >
                        <DashboardIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <IconButton
                        onClick={() =>
                          history.push(`/administration/teams/${team.id}`)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() =>
                          dispatch({ type: REMOVE_TEAM, id: team.id })
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
}
