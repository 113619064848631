import { useEffect, useState } from "react";
import useAllCustomers from "../../customers/useAllCustomers";
import { useBurnedBudget } from "../../customers/useBurnedBudget";
import {
  SystemNotification,
  SystemNotificationSource
} from "../SystemNotificationTypes";

export function useCustomerOverBudgetNotifications(
  year: number,
  month: number
): SystemNotification[] {
  const customers = useAllCustomers();
  const budgets = useBurnedBudget(year, month);
  const [notifications, setNotifications] = useState(
    [] as SystemNotification[]
  );

  useEffect(() => {
    const newNotifications = Object.values(budgets.items)
      .filter(
        (budget) =>
          !!customers[budget.customerId] && budget.getConsumedOverBudget() > 0
      )
      .map((budget) => {
        const title = customers[budget.customerId]?.name;
        return {
          source: SystemNotificationSource.CUSTOMER,
          id: budget.customerId,
          title,
          link: `/dashboards/customers/items/${budget.customerId}`,
          description: `is ${budget
            .getConsumedOverBudget()
            .toLocaleString()}h (${budget
            .getConsumedOverBudgetPercent()
            .toLocaleString()}%) over budget`,
        };
      });
    setNotifications(newNotifications);
  }, [customers, budgets]);

  return notifications;
}
