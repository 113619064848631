import { useTheme } from "@material-ui/core/styles";
import { Chart } from "chart.js";
import { DashboardCard } from "components/DashboardCard";
import { useBurnedBudgetInAggregator } from "customers/useBurnedBudgetInAggregator";
import { DashboardPeriod } from "dashboards/DashboardPeriod";
import {
  CustomerAggregatorType,
  CustomerHandle
} from "msd-capacity-planning-model";
import { createRef, useEffect, useState } from "react";
import getCompletedMonthRatio from "utils/getCompletedMonthRatio";
import { getHarvestProjectReportURL } from "utils/harvestURLService";

export function CustomersBurnedBudgetBarChart({
  customers,
  period,
}: {
  customers: { [customerId: string]: CustomerHandle };
  period: DashboardPeriod;
}) {
  const chartRef = createRef<HTMLCanvasElement>();
  const [chart, setChart] = useState(null as null | Chart);
  const theme = useTheme();
  const [height, setHeight] = useState(300);
  const [annotation, setAnnotation] = useState({
    type: "line",
    xMin: 0,
    xMax: 0,
    borderColor: "rgb(255, 99, 132)",
    borderWidth: 2,
  });
  const [customerIds, setCustomerIds] = useState([] as string[]);
  const burnedBudget = useBurnedBudgetInAggregator(
    period.year,
    period.month,
    CustomerAggregatorType.CUSTOMER,
    customerIds
  );

  const [sortedCustomers, setSortedCustomers] = useState(
    [] as CustomerHandle[]
  );

  useEffect(() => {
    setSortedCustomers(
      Object.values(customers || {}).sort((e1, e2) => e1.compare(e2))
    );
    setCustomerIds(Object.keys(customers || {}));
  }, [customers]);

  useEffect(() => {
    if (chart) {
      return;
    }
    const ref = chartRef?.current as HTMLCanvasElement;
    const newChart = new Chart(ref, {
      type: "bar",
      data: { labels: [], datasets: [] },
      options: {
        indexAxis: "y",
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: true,
          },
        },
        plugins: {
          legend: { display: false },
          tooltip: { callbacks: { label: () => "" } },
          annotation: { annotations: {} },
        },
      },
    });
    setChart(newChart);
  }, []);

  useEffect(() => {
    const expectedRatio =
      getCompletedMonthRatio(period.year, period.month) * 100;
    setAnnotation({
      type: "line",
      xMin: expectedRatio,
      xMax: expectedRatio,
      borderColor: "rgb(255, 99, 132)",
      borderWidth: 2,
    });
  }, [period, chart]);

  useEffect(() => {
    if (!chart) {
      return;
    }
    const annotations =
      (chart?.options?.plugins?.annotation?.annotations as any) || {};
    annotations.expected = annotation;
    chart.update();
  }, [chart, annotation]);

  useEffect(() => {
    if (!chart) {
      return;
    }
    const items = Object.values(customers);
    setHeight(Math.max(300, items.length * 22 + 20));
    chart.data.labels = items.map((customer) => customer.name);
    chart.update();
  }, [chart, customers]);

  useEffect(() => {
    if (!chart) {
      return;
    }
    chart.data.datasets = [
      {
        data: sortedCustomers.map((item) => {
          if (!item.hasBudget(period.year, period.month)) {
            return undefined;
          }
          const customerBurnedBudget = burnedBudget.get(item.id);
          return customerBurnedBudget.getConsumedBudgetPercent();
        }),
        borderColor: "rgb(0, 96, 100, 0.5)",
        backgroundColor: "rgb(0, 96, 100, 0.5)",
      },
    ];
    const label: any = (x: any) => {
      const idx = x?.dataIndex;
      if (isNaN(idx)) {
        return "";
      }
      const customer = sortedCustomers[idx];
      const budget = burnedBudget?.get(customer.id);
      return budget
        ? `${budget.getConsumedBudget()} hours (${budget.getConsumedBudgetPercent()}%)`
        : "";
    };
    chart.options.plugins.tooltip.callbacks.label = label;
    chart.update();
  }, [theme, chart, period, sortedCustomers, burnedBudget]);

  useEffect(() => {
    if (!chart) {
      return;
    }
    chart.options.onClick = function (event, target) {
      if (target && target.length > 0) {
        const index = target[0].index;
        const harvestId = sortedCustomers[index].harvestProjectId;
        if (harvestId) {
          const url = getHarvestProjectReportURL(
            harvestId,
            period.year,
            period.month
          );
          window.open(url, "_blank");
        }
      }
    };
    chart.update();
  }, [chart, period, sortedCustomers]);

  return (
    <DashboardCard title="Burned budget" height={height}>
      {{
        body: <canvas ref={chartRef} />,
      }}
    </DashboardCard>
  );
}
