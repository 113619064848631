import { Engineer } from "msd-capacity-planning-model";
export const FETCH_ENGINEERS = "engineers/fetch";
export const LOAD_ENGINEERS = "engineers/load";
export const REMOVE_ENGINEER = "engineers/remove";
export const PERSIST_ENGINEER = "engineers/persist";
export const SET_ENGINEER_JIRA_ACCESS = "engineers/jira/access";
export const SET_ENGINEER_CONFLUENCE_ACCESS = "engineers/confluence/access";

export interface EngineersState {
  items: { [id: string]: Engineer };
  synching: boolean;
}

export interface FetchEngineersAction {
  type: typeof FETCH_ENGINEERS;
}

export interface LoadEngineersAction {
  type: typeof LOAD_ENGINEERS;
  items: Engineer[];
}

export interface RemoveEngineerAction {
  type: typeof REMOVE_ENGINEER;
  id: string;
}

export interface PersistEngineerAction {
  type: typeof PERSIST_ENGINEER;
  item: Engineer;
}

export interface SetEngineerJiraAccessAction {
  type: typeof SET_ENGINEER_JIRA_ACCESS;
  id: string;
  callback?: () => void;
}

export interface SetEngineerConfluenceAccessAction {
  type: typeof SET_ENGINEER_CONFLUENCE_ACCESS;
  id: string;
  callback?: () => void;
}

export type EngineerAction =
  | FetchEngineersAction
  | LoadEngineersAction
  | RemoveEngineerAction
  | PersistEngineerAction
  | SetEngineerJiraAccessAction
  | SetEngineerConfluenceAccessAction;
