import { Box, Typography, useTheme } from "@material-ui/core";
import { DashboardBreadcrumbs } from "dashboards/DashboardBreadcrumbs";
import useEM from "ems/useEM";
import { useParams } from "react-router-dom";
import { useDashboardPeriod } from "../useDashboardPeriod";
import { EMLeadCustomersTable } from "./EMLeadCustomersTable";

export function EMLeadCustomersDashboardPage() {
  const theme = useTheme();
  const period = useDashboardPeriod();
  const params: { [key: string]: string } = useParams();
  const em = useEM(params.emlead);

  return (
    <Box>
      <DashboardBreadcrumbs />
      <Box
        style={{
          marginTop: theme.spacing(),
          marginBottom: theme.spacing() * 2,
        }}
      >
        <Typography variant="h5">{em?.getDisplayName()} customers</Typography>
      </Box>
      <EMLeadCustomersTable
        year={period.year}
        month={period.month}
        emLeadId={params.emlead || ""}
      />
    </Box>
  );
}
