import { useTeamCapacityByAggregator } from "capacity/useTeamCapacityByAggregator";
import { LabeledScatterDataPoint } from "components/LabeledScatterDataPoint";
import { ScatterChartCard } from "components/ScatterChartCard";
import { useBudgetByAggregator } from "customers/useBudgetByAggregator";
import useCustomersInAggregator from "customers/useCustomersInAggregator";
import useEngineersInAggregator from "engineers/useEngineersInAggregator";
import {
  CustomerAggregatorType,
  EngineerAggregatorType,
  TeamCapacityAggregatorType
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useTeams } from "teams/useTeams";

export function SkillDeltaByTeamScatterChart({
  year,
  month,
  skill,
}: {
  year: number;
  month: number;
  skill: string;
}) {
  const [coordinates, setCoordinates] = useState(
    [] as LabeledScatterDataPoint[]
  );

  const teams = useTeams(year, month);
  const customers = useCustomersInAggregator(
    year,
    month,
    CustomerAggregatorType.SKILL,
    skill
  );
  const engineers = useEngineersInAggregator(
    year,
    month,
    EngineerAggregatorType.SKILL,
    skill
  );
  const budgets = useBudgetByAggregator(
    year,
    month,
    CustomerAggregatorType.TEAM,
    customers
  );
  const capacities = useTeamCapacityByAggregator(
    year,
    month,
    TeamCapacityAggregatorType.TEAM,
    engineers
  );
  const [yMin, setYMin] = useState(0);

  useEffect(() => {
    const newCoordinates = Object.values(teams)
      .map((team) => {
        const capacity = capacities[team.id]?.allocatedHours || 0;
        const budget = budgets[team.id]?.getTeamPlusOps() || 0;
        const x = budget;
        const y = Math.round(capacity - budget);
        return {
          label: team.name,
          x,
          y,
        };
      })
      .filter((coordinate) => coordinate.x !== 0 || coordinate.y !== 0);
    const newYmin = newCoordinates.reduce((min, c) => Math.min(min, c.y), 0);
    setYMin(newYmin);
    setCoordinates(newCoordinates);
  }, [year, month, teams, budgets, capacities]);

  return (
    <ScatterChartCard
      dataPoints={coordinates}
      title={`Skill delta by team`}
      xScaleTitle="Budget (hours)"
      yScaleTitle="Delta (capacity - budget)"
      chartHeight={300}
      xAnnotation={0}
      yMin={yMin}
    />
  );
}
