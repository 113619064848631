import React, { useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import RefreshIcon from '@material-ui/icons/Refresh'
import WarningIcon from '@material-ui/icons/Warning'
import service from './JiraResourcesService'
import { HarvestCodeState, useHarvestCode } from './useHarvestCode'

export function HarvestCode({
  projectKey,
  harvestId
}: {
  projectKey: string
  harvestId: string
}) {
  const [reloadCounter, setReloadCounter] = useState(0)
  const ruleState = useHarvestCode(projectKey, harvestId, reloadCounter)
  const [updateClicked, setUpdateClicked] = useState(false)
  const [updating, setUpdating] = useState(false)

  function onUpdate(): void {
    setUpdateClicked(true)
    setUpdating(true)
    service
      .putHarvestCode(projectKey, harvestId)
      .catch(() => {})
      .finally(() => {
        setUpdating(false)
        setReloadCounter(reloadCounter + 1)
      })
  }

  const status = (
    <span style={{ marginRight: 8, verticalAlign: 'middle' }}>
      {ruleState === HarvestCodeState.fetching ? (
        <CircularProgress size={16} style={{ marginLeft: 8 }} />
      ) : ruleState === HarvestCodeState.failed ? (
        <Tooltip title='Error: failed to fetch harvest code state'>
          <WarningIcon color='error' />
        </Tooltip>
      ) : ruleState === HarvestCodeState.correct ? (
        <Tooltip title='harvest code is correct'>
          <CheckIcon style={{ color: 'green' }} />
        </Tooltip>
      ) : ruleState === HarvestCodeState.incorrect ? (
        <Tooltip title='Hartvest code is incorrect'>
          <ClearIcon style={{ color: 'red' }} />
        </Tooltip>
      ) : null}
    </span>
  )

  return (
    <Box>
      {status}
      Harvest code:{' '}
      <IconButton
        disabled={updating || ruleState === HarvestCodeState.fetching}
        onClick={() => setReloadCounter(reloadCounter + 1)}
        style={{ marginLeft: 8 }}
      >
        <RefreshIcon />
      </IconButton>
      {ruleState === HarvestCodeState.incorrect ? (
        <span>
          <Button
            variant='text'
            color='primary'
            disabled={updateClicked}
            onClick={() => onUpdate()}
          >
            Update
            {updating ? (
              <CircularProgress size={16} style={{ marginLeft: 8 }} />
            ) : null}
          </Button>
        </span>
      ) : null}
    </Box>
  )
}
