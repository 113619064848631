import { Middleware } from "redux";
import {
  AppState,
  InitAppAction,
  INIT_APP,
  ReportFeedbackAction,
  REPORT_APP_FEEDBACK
} from "../redux/AppTypes";
import service from "./EngineerService";
import {
  EngineerAction as BaseAction,
  FETCH_ENGINEERS as FETCH,
  LoadEngineersAction as LoadAction,
  LOAD_ENGINEERS as LOAD,
  PERSIST_ENGINEER as PERSIST,
  REMOVE_ENGINEER as REMOVE,
  SET_ENGINEER_CONFLUENCE_ACCESS,
  SET_ENGINEER_JIRA_ACCESS
} from "./EngineersTypes";

const initMiddleware: Middleware<{}, AppState> =
  (store) => (next) => (action: InitAppAction) => {
    const result = next(action);
    if (action.type === INIT_APP) {
      store.dispatch({ type: FETCH });
    }
    return result;
  };

const fetchMiddleware: Middleware<{}, AppState> =
  (store) => (next) => async (action: BaseAction) => {
    const result = next(action);
    if (action.type === FETCH) {
      const items = await service.fetch();
      const load: LoadAction = {
        type: LOAD,
        items,
      };
      store.dispatch(load);
      return items;
    } else {
      return result;
    }
  };

const deleteMiddleware: Middleware<{}, AppState> =
  (store) => (next) => async (action: BaseAction) => {
    const result = next(action);
    if (action.type === REMOVE) {
      const items = await service.delete(action.id);
      const load: LoadAction = {
        type: LOAD,
        items: Object.values(items),
      };
      store.dispatch(load);
      return items;
    } else {
      return result;
    }
  };

const persistMiddleware: Middleware<{}, AppState> =
  (store) => (next) => async (action: BaseAction) => {
    const result = next(action);
    if (action.type === PERSIST) {
      const item = action.item;
      const items = await service.persist(item);
      const load: LoadAction = {
        type: LOAD,
        items: Object.values(items),
      };
      store.dispatch(load);
      return items;
    } else {
      return result;
    }
  };

const setEngineerJiraAccessMiddleware: Middleware<{}, AppState> =
  (store) => (next) => async (action: BaseAction) => {
    if (action.type === SET_ENGINEER_JIRA_ACCESS) {
      const id = action.id;
      await service
        .setJiraAccess(id)
        .then(() => {
          const action: ReportFeedbackAction = {
            type: REPORT_APP_FEEDBACK,
            feedback: "Jira access granted",
          };
          store.dispatch(action);
        })
        .catch(() => {})
        .finally(() => {
          if (action.callback) {
            action.callback();
          }
        }); // errors are reported by the fetch service
    }
    return next(action);
  };

const setEngineerConfluenceAccessMiddleware: Middleware<{}, AppState> =
  (store) => (next) => async (action: BaseAction) => {
    if (action.type === SET_ENGINEER_CONFLUENCE_ACCESS) {
      const id = action.id;
      await service
        .setConfluenceAccess(id)
        .then(() => {
          const action: ReportFeedbackAction = {
            type: REPORT_APP_FEEDBACK,
            feedback: "Confluence access granted",
          };
          store.dispatch(action);
        })
        .catch(() => {}); // errors are reported by the fetch service
    }
    return next(action);
  };

const middlewares = [
  initMiddleware,
  fetchMiddleware,
  deleteMiddleware,
  persistMiddleware,
  setEngineerJiraAccessMiddleware,
  setEngineerConfluenceAccessMiddleware,
];
export default middlewares;
