import { Chart } from "chart.js";
import { DashboardPeriod } from "dashboards/DashboardPeriod";
import { useTrendPeriods } from "dashboards/useTrendPeriods";
import React, { createRef, useEffect, useState } from "react";
import { DashboardCard } from "../../components/DashboardCard";
import { useEngineer } from "../../engineers/useEngineer";

export function EngineerTimeoffTrendCard({
  period,
  engineerId,
}: {
  period: DashboardPeriod;
  engineerId: string;
}) {
  const periods = useTrendPeriods(period);
  const engineer = useEngineer(engineerId);
  const [timeOff, setTimeOff] = useState([] as number[]);

  useEffect(() => {
    let newTimeOff = [] as number[];
    if (engineer) {
      newTimeOff = periods.map((period) =>
        engineer.getDaysOff(period.year, period.month)
      );
    }
    setTimeOff(newTimeOff);
  }, [periods, engineer]);

  const chartRef = createRef<HTMLCanvasElement>();
  const [chart, setChart] = useState(null as null | Chart);

  useEffect(() => {
    if (chart) {
      return;
    }
    const ref = chartRef?.current as HTMLCanvasElement;
    const newChart = new Chart(ref, {
      type: "line",
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: { precision: 0 },
          },
        },
      },
    });
    setChart(newChart);
  }, []);

  useEffect(() => {
    if (!chart) {
      return;
    }
    chart.data.labels = periods.map((period) =>
      new Date(period.year, period.month).toLocaleString("default", {
        month: "long",
      })
    );
    chart.data.datasets = [
      {
        data: timeOff,
        borderColor: "rgb(0, 96, 100, 0.5)",
        backgroundColor: "rgb(0, 96, 100, 0.5)",
        borderWidth: 2,
      },
    ];
    chart.update();
  }, [periods, chart, timeOff]);

  return (
    <DashboardCard title="Time off trend (days)" height={200}>
      {{ body: <canvas ref={chartRef} /> }}
    </DashboardCard>
  );
}
