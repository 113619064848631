import React from "react";
import { Button, Grid } from "@material-ui/core";
import { login } from "./Authorization";

import "./LoginPanel.css"

export function LoginPanel() {
  return (
    <>
      <div className="loginButtonLabel">
        <span >Login required</span>
      </div>
      <Button className="loginButton" size="large" onClick={() => login()}>Continue</Button>
    </>
  );
}
