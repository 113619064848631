import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import WarningIcon from "@material-ui/icons/Warning";
import { useSelector } from "react-redux";
import { AppState } from "redux/AppTypes";
import doc from "./statusPageDoc.json";

export function SynchStatusPage() {
  const status = useSelector((state: AppState) => state.synchStatus);

  return status.synching ? (
    <CircularProgress />
  ) : (
    <Box>
      <Typography variant="h5" style={{ marginTop: 8, marginBottom: 16 }}>
        Sync status
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Process</TableCell>
            <TableCell>Execution</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {status.statuses.map((s, idx) => {
            const date = new Date(s.date);
            return (
              <TableRow key={`row${idx}`}>
                <TableCell>
                  {s.success ? (
                    <CheckIcon style={{ color: "green" }} />
                  ) : (
                    <WarningIcon color="error" />
                  )}
                </TableCell>
                <TableCell>
                  {(s.id || "").replace(/Synch/g, "Sync")}
                  <br />
                  <Typography
                    variant="caption"
                    style={{ color: "gray", display: "block", maxWidth: 400 }}
                  >
                    {(doc as any)[s.id]}
                  </Typography>
                </TableCell>
                <TableCell>{date.toLocaleString()}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
}
