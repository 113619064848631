import { useOnboardedEngineers } from "engineers/useOnboardedEngineers";
import { useEffect, useState } from "react";
import { DAY_DATE_FORMAT } from "utils/dateFormats";
import {
  SystemNotification,
  SystemNotificationSource
} from "../SystemNotificationTypes";

export function useEngineersOnboardedNotifications(
  year: number,
  month: number
): SystemNotification[] {
  const engineers = useOnboardedEngineers(year, month);

  const [notifications, setNotifications] = useState(
    [] as SystemNotification[]
  );

  useEffect(() => {
    const newNotifications = engineers.map((engineer) => {
      const title =
        (engineer.firstName || "") + " " + (engineer.lastName || "");
      return {
        source: SystemNotificationSource.ENGINEER,
        id: engineer.id,
        title,
        link: `/dashboards/engineers/items/${engineer.id}`,
        description: engineer
          .getOnboardDate()
          ?.toLocaleString("en", DAY_DATE_FORMAT),
      };
    });
    setNotifications(newNotifications);
  }, [engineers]);

  return notifications;
}
