import React, { useState } from 'react'
import { Box, Button, CircularProgress, Tooltip } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import service from './JiraResourcesService'
import { useJiraBoardColumns } from './useJiraBoardColumns'

export function JiraBoardColumns({ projectKey }: { projectKey: string }) {
  const [reloadCounter, setReloadCounter] = useState(0)
  const columns = useJiraBoardColumns(projectKey, reloadCounter)
  const [updateClicked, setUpdateClicked] = useState(false)
  const [updating, setUpdating] = useState(false)

  function onUpdate(): void {
    setUpdateClicked(true)
    setUpdating(true)
    service.putBoardColumns(projectKey).finally(() => {
      setUpdating(false)
      setReloadCounter(reloadCounter + 1)
    })
  }

  const status = (
    <span style={{ marginRight: 8, verticalAlign: 'middle' }}>
      {typeof columns === 'undefined' ? (
        <CircularProgress size={16} style={{ marginLeft: 8 }} />
      ) : columns === null ? (
        <span>
          <Tooltip title='Missing board'>
            <ClearIcon style={{ color: 'red' }} />
          </Tooltip>
        </span>
      ) : columns?.expected ? (
        <Tooltip title='Standandard columns'>
          <CheckIcon style={{ color: 'green' }} />
        </Tooltip>
      ) : (
        <Tooltip title='Non standandard columns'>
          <ClearIcon style={{ color: 'red' }} />
        </Tooltip>
      )}
    </span>
  )

  return (
    <Box>
      {status}
      Board columns:{' '}
      {columns ? (
        <span>
          {columns?.columnConfig?.columns.map((c) => c.name).join(', ')}{' '}
          {columns?.expected ? null : (
            <span>
              <Button
                variant='text'
                color='primary'
                disabled={updateClicked}
                onClick={() => onUpdate()}
              >
                Update
                {updating ? (
                  <CircularProgress size={16} style={{ marginLeft: 8 }} />
                ) : null}
              </Button>
            </span>
          )}
        </span>
      ) : (
        ''
      )}
    </Box>
  )
}
