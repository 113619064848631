import { ListItemIcon, MenuItem } from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import { useState } from "react";
import { DataExportDialog } from "./DataExportDialog";

export function DataExportMenuItem() {
  const [open, setOpen] = useState(false);
  return <>
  <MenuItem onClick={() => { setOpen(true) }} style={{ position: "relative" }}>
    <ListItemIcon>
      <CloudDownload />
    </ListItemIcon>
    Export Data
    <DataExportDialog
      open={open}
      onClose={() => setOpen(false)}
    />
  </MenuItem>
</>
}