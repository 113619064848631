import React from "react";
import { Switch, Route } from "react-router-dom";
import { EMsPanel } from "./EMsPanel";
import { EMForm } from "./EMForm";

export function EMsPage() {
  return (
    <Switch>
      <Route path="/administration/ems/:id" strict={true}>
        <EMForm />
      </Route>
      <Route exact path="/administration/ems/" strict={true}>
        <EMForm />
      </Route>
      <Route path="/administration/ems">
        <EMsPanel />
      </Route>
    </Switch>
  );
}
