import { Team } from "msd-capacity-planning-model";
import env from "../app/Environment";
import fetchService from "../utils/FetchService";

export interface TeamService {
  fetch(): Promise<Team[]>;
  delete(id: string): Promise<Team[]>;
  persist(item: Team): Promise<Team[]>;
}

class HTTPTeamService implements TeamService {
  constructor(private endpoint: string) {}

  async fetch(): Promise<Team[]> {
    return fetchService
      .fetch(`${this.endpoint}/teams`)
      .then((r) => r.json())
      .catch(() => []);
  }

  async delete(id: string): Promise<Team[]> {
    return fetchService
      .fetch(`${this.endpoint}/teams/${id}`, {
        method: "DELETE",
      })
      .then(() => this.fetch());
  }

  async persist(item: Team): Promise<Team[]> {
    return fetchService
      .fetch(`${this.endpoint}/teams`, {
        method: "POST",
        body: JSON.stringify(item),
      })
      .then(() => this.fetch());
  }
}

const regionService: TeamService = new HTTPTeamService(env.endpoint);

export default regionService;
