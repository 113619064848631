import { CognitoUserSession } from "amazon-cognito-identity-js";

export const AUTH_SET_SESSION = "auth/setSession";
export const AUTH_SET_MYSELF = "auth/setMyself";
export const AUTH_INIT = "auth/init";
export const AUTH_SIGNOUT = "auth/signout";

export interface Myself {
  roles: string[];
}

export interface AuthorizationState {
  synching: boolean;
  accessToken: string;
  idToken: string;
  refreshToken: string;
  email: string;
  myself: Myself;
}

export interface InitAuthenticationAction {
  type: typeof AUTH_INIT;
}

export interface SetSessionAction {
  type: typeof AUTH_SET_SESSION;
  session?: CognitoUserSession;
}

export interface SetMyselfAction {
  type: typeof AUTH_SET_MYSELF;
  myself: Myself;
}

export interface SignoutAction {
  type: typeof AUTH_SIGNOUT;
}

export type AuthorizationAction =
  | InitAuthenticationAction
  | SetSessionAction
  | SetMyselfAction
  | SignoutAction;
