import { Middleware } from "redux";
import {
  AppState,
  InitAppAction,
  INIT_APP,
  ReportFeedbackAction,
  REPORT_APP_FEEDBACK
} from "../redux/AppTypes";
import service from "./EMService";
import {
  EMAction as BaseAction,
  FETCH_EMS as FETCH,
  LoadEMsAction as LoadAction,
  LOAD_EMS as LOAD,
  PERSIST_EM as PERSIST,
  REMOVE_EM as REMOVE,
  SET_EM_CONFLUENCE_ACCESS,
  SET_EM_JIRA_ACCESS
} from "./EMsTypes";

const initMiddleware: Middleware<{}, AppState> =
  (store) => (next) => (action: InitAppAction) => {
    const result = next(action);
    if (action.type === INIT_APP) {
      store.dispatch({ type: FETCH });
    }
    return result;
  };

const fetchMiddleware: Middleware<{}, AppState> =
  (store) => (next) => async (action: BaseAction) => {
    const result = next(action);
    if (action.type === FETCH) {
      const items = await service.fetch();
      items.sort((i1, i2) => {
        const i1Name = `${i1.firstName} ${i1.lastName}`;
        const i2Name = `${i2.firstName} ${i2.lastName}`;
        return i1Name.toLowerCase().localeCompare(i2Name.toLowerCase());
      });
      const load: LoadAction = {
        type: LOAD,
        items,
      };
      store.dispatch(load);
      return items;
    } else {
      return result;
    }
  };

const deleteMiddleware: Middleware<{}, AppState> =
  (store) => (next) => async (action: BaseAction) => {
    const result = next(action);
    if (action.type === REMOVE) {
      const items = await service.delete(action.id);
      const load: LoadAction = {
        type: LOAD,
        items: Object.values(items),
      };
      store.dispatch(load);
      return items;
    } else {
      return result;
    }
  };

const persistMiddleware: Middleware<{}, AppState> =
  (store) => (next) => async (action: BaseAction) => {
    const result = next(action);
    if (action.type === PERSIST) {
      const item = action.item;
      const items = await service.persist(item);
      const load: LoadAction = {
        type: LOAD,
        items: Object.values(items),
      };
      store.dispatch(load);
      return items;
    } else {
      return result;
    }
  };

const setEMJiraAccessMiddleware: Middleware<{}, AppState> =
  (store) => (next) => async (action: BaseAction) => {
    if (action.type === SET_EM_JIRA_ACCESS) {
      const id = action.id;
      await service
        .setJiraAccess(id)
        .then(() => {
          const action: ReportFeedbackAction = {
            type: REPORT_APP_FEEDBACK,
            feedback: "Jira access granted",
          };
          store.dispatch(action);
        })
        .catch(() => {}) // errors are reported by the fetch service
        .finally(() => {
          if (action.callback) {
            action.callback();
          }
        });
    }
    return next(action);
  };

const setEMConfluenceAccessMiddleware: Middleware<{}, AppState> =
  (store) => (next) => async (action: BaseAction) => {
    if (action.type === SET_EM_CONFLUENCE_ACCESS) {
      const id = action.id;
      await service
        .setConfluenceAccess(id)
        .then(() => {
          const action: ReportFeedbackAction = {
            type: REPORT_APP_FEEDBACK,
            feedback: "Confluence access granted",
          };
          store.dispatch(action);
        })
        .catch(() => {}) // errors are reported by the fetch service
        .finally(() => {
          if (action.callback) {
            action.callback();
          }
        });
    }
    return next(action);
  };

const middlewares = [
  initMiddleware,
  fetchMiddleware,
  deleteMiddleware,
  persistMiddleware,
  setEMJiraAccessMiddleware,
  setEMConfluenceAccessMiddleware,
];
export default middlewares;
