import { Grid } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { BudgetByRegionPieChart } from "./BudgetByRegionPieChart";
import { BudgetTrendByRegion } from "./BudgetTrendByRegionCard";
import { CapacityByRegionCard } from "./CapacityByRegionCard";
import { RegionsTable } from "./RegionsTable";
import { UtilizationByRegionScatterChartCard } from "./UtilizationByRegionScatterChart";

export function RegionsDashboard({
  year,
  month,
}: {
  year: number;
  month: number;
}) {
  const location = useLocation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <UtilizationByRegionScatterChartCard
          year={year}
          month={month}
          baseUrl={location.pathname}
        />
      </Grid>
      <Grid item xs={6}>
        <BudgetTrendByRegion
          year={year}
          month={month}
          baseUrl={location.pathname}
        />
      </Grid>
      <Grid item xs={6}>
        <BudgetByRegionPieChart year={year} month={month} />
      </Grid>
      <Grid item xs={6}>
        <CapacityByRegionCard year={year} month={month} />
      </Grid>
      <Grid item xs={12}>
        <RegionsTable year={year} month={month} />
      </Grid>
    </Grid>
  );
}
