import { EngineerHandle } from "msd-capacity-planning-model";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AppState } from "../redux/AppTypes";
import { useAllEngineersSelector } from "./useAllEngineers";

export const useSortedEngineersSelector = createSelector(
  useAllEngineersSelector,
  (items) => {
    const sortedItems = Object.values(items);
    sortedItems.sort((i1, i2) => i1.compare(i2));
    return sortedItems;
  }
);

export default function useSortedEngineers(): EngineerHandle[] {
  const state = useSelector((state: AppState) => state);
  return useSortedEngineersSelector(state);
}
