import { Box } from "@material-ui/core";
import { DashboardBreadcrumbs } from "../DashboardBreadcrumbs";
import { useDashboardPeriod } from "../useDashboardPeriod";
import { RegionsDashboard } from "./RegionsDashboard";

export function RegionsDashboardPage() {
  const { year, month } = useDashboardPeriod();
  return (
    <Box>
      <DashboardBreadcrumbs docs="/dashboards/regions.html"/>
      <RegionsDashboard year={year} month={month} />
    </Box>
  );
}
