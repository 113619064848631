import { useTheme } from "@material-ui/core";
import { LineChartCard } from "components/LineChartCard";
import { useBudgetByAggregator } from "customers/useBudgetByAggregator";
import { CustomerAggregatorType } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import useSortedRegions from "regions/useSortedRegions";
import { getColors } from "utils/colorGenerator";

const aggregatorType = CustomerAggregatorType.REGION;

export function BudgetTrendByRegion({
  year,
  month,
  baseUrl,
}: {
  year: number;
  month: number;
  baseUrl?: string;
}) {
  const [dates, setDates] = useState([] as Date[]);

  const regions = useSortedRegions();
  const theme = useTheme();
  const [colors, setColors] = useState([]);

  const budgets1 = useBudgetByAggregator(year, month - 2, aggregatorType);
  const budgets2 = useBudgetByAggregator(year, month - 1, aggregatorType);
  const budgets3 = useBudgetByAggregator(year, month, aggregatorType);
  const budgets4 = useBudgetByAggregator(year, month + 1, aggregatorType);
  const budgets5 = useBudgetByAggregator(year, month + 2, aggregatorType);

  useEffect(() => {
    const newDates = [] as Date[];
    for (let index = -2; index <= 2; index++) {
      newDates.push(new Date(year, month + index));
    }
    setDates(newDates);
  }, [year, month]);

  useEffect(() => {
    const newColors = getColors(theme, regions.length);
    setColors(newColors);
  }, [theme, regions]);

  return (
    <LineChartCard
      title="Budget trend"
      data={{
        labels: dates.map((date) => date.toISOString().substring(0, 7)),
        datasets: regions.map((region, index) => {
          const budgets = [budgets1, budgets2, budgets3, budgets4, budgets5];
          return {
            label: region?.name || "te",
            data: budgets.map(
              (budget, index) =>
                budget[region.id]?.teamHours + budget[region.id]?.opsHours ||
                index
            ),
            fill: false,
            borderColor: colors[index] || "white",
            backgroundColor: colors[index] || "white",
          };
        }),
      }}
    />
  );
}
