import { Box, FormControlLabel, Switch } from "@material-ui/core";
import { EMsTable } from "dashboards/ems/EMsTable";
import { useEMsByLead } from "ems/useEMsByLead";
import { useState } from "react";
import { useLocation } from "react-router-dom";

export function EMLeadEMsTable({
  year,
  month,
  emLeadId,
}: {
  year: number;
  month: number;
  emLeadId: string;
}) {
  const location = useLocation();
  const [directReportsFlag, setDirectReportsFlag] = useState(true);
  const ems = useEMsByLead();
  const recursiveEMs = useEMsByLead(true);
  return (
    <Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "right",
        }}
      >
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={directReportsFlag}
              onChange={(event) =>
                setDirectReportsFlag(event?.target?.checked === true)
              }
            />
          }
          label="Team"
          labelPlacement="start"
        />
      </Box>
      <EMsTable
        year={year}
        month={month}
        ems={
          directReportsFlag ? ems[emLeadId] || [] : recursiveEMs[emLeadId] || []
        }
        linkPrefix={`${location.pathname}/ems`}
      />
    </Box>
  );
}
