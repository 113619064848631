import { Card, CardContent, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

export function CountCard({
  title,
  count,
  path,
  suffix = "",
}: {
  title: string;
  count: number;
  path?: string;
  suffix?: string;
}) {
  return (
    <Card style={{ minWidth: "150px" }}>
      <CardContent
        style={{
          textAlign: "center",
          textDecoration: "none",
          color: "inherit",
        }}
        component={path ? Link : CardContent}
        to={path}
      >
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
        <Typography variant="h3" component="h3">
          {count.toLocaleString()}
          {suffix}
        </Typography>
      </CardContent>
    </Card>
  );
}
