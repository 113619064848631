import { useEffect, useState } from 'react'
import service from './JiraResourcesService'
import { JiraBoard } from './JiraBoardDTO'

export function useJiraProjectBoard(
  projectKey: string,
  reloadCounter = 0
): JiraBoard | null | undefined {
  const [board, setBoard] = useState(undefined as JiraBoard | null | undefined)

  useEffect(() => {
    service.getProjectBoard(projectKey).then((board) => setBoard(board))
  }, [projectKey, reloadCounter])

  return board
}
