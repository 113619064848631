import DateFnsUtils from "@date-io/date-fns";
import {dateReturn} from "utils/dateReturn";
import {
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell as DefaultTableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  withStyles
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import "date-fns";
import { EMCapacity } from "msd-capacity-planning-model";
import React, { useEffect, useState } from "react";

function nextMonth() {
  let next = new Date();
  if (next.getMonth() === 11) {
    next = new Date(next.getFullYear() + 1, 0, 1);
  } else {
    next = new Date(next.getFullYear(), next.getMonth() + 1, 1);
  }
  return next;
}

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(DefaultTableCell);

export function EMUtilizationForm(props: {
  defaultValue: EMCapacity[];
  onChange: (budget: EMCapacity[]) => void;
}) {
  const [items, setItems] = useState(
    JSON.parse(JSON.stringify(props.defaultValue)) as EMCapacity[]
  );

  useEffect(() => {
    setItems(JSON.parse(JSON.stringify(props.defaultValue)) as EMCapacity[]);
  }, [props.defaultValue]);

  function onChange(newItems: EMCapacity[]) {
    newItems.sort(
      (a, b) =>
        new Date(a.year, a.month, a.day).getTime() -
        new Date(b.year, b.month, b.day).getTime()
    );
    props.onChange(newItems);
    setItems(newItems);
  }

  function addItem(): void {
    const date = nextMonth();
    const newItem: EMCapacity = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: 1,
      percent: 75,
    };
    const newItems = JSON.parse(JSON.stringify(items));
    newItems.push(newItem);
    onChange(newItems);
  }

  function updateItem(index: number, item: EMCapacity): void {
    const newItems = JSON.parse(JSON.stringify(items));
    newItems[index] = item;
    onChange(newItems);
  }

  function deleteItem(index: number): void {
    const newDemand = JSON.parse(JSON.stringify(items));
    newDemand.splice(index, 1);
    onChange(newDemand);
  }
  const now = new Date();
  return (
    <Container>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>From</TableCell>
              <TableCell>Percentage*</TableCell>
              <TableCell align="right">
                <Tooltip title="New item">
                  <IconButton onClick={addItem}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => {
              const date = isNaN(item.year)
                ? new Date()
                : new Date(item.year, item.month || 0, item.day || 1);  
              const frozen = now.getTime() > date.getTime();
              return (
              <TableRow key={`item-${index}`}>
                <TableCell>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      format="MM/dd/yyyy"
                      value={date}
                      disabled={frozen}
                      onChange={(newDate) => {
                        const newItem: EMCapacity = JSON.parse(
                          JSON.stringify(item)
                        );
                        console.log(JSON.stringify(newItem))
                        newItem.year = dateReturn(newDate, 'year')
                        newItem.month = dateReturn(newDate, 'month')
                        newItem.day = dateReturn(newDate, 'day')
                        updateItem(index, newItem);
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </TableCell>
                <TableCell>
                  <TextField
                    value={items[index].percent || 0}
                    onChange={(e) => {
                      item.percent = parseInt(e.target.value);
                      updateItem(index, item);
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Delete">
                    <IconButton onClick={() => deleteItem(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
              );
                  })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
