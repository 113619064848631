import { EM } from "msd-capacity-planning-model";
export const FETCH_EMS = "ems/fetch";
export const LOAD_EMS = "ems/load";
export const REMOVE_EM = "ems/remove";
export const PERSIST_EM = "ems/persist";
export const SET_EM_JIRA_ACCESS = "ems/jira/access";
export const SET_EM_CONFLUENCE_ACCESS = "ems/confluence/access";

export interface EMsState {
  items: { [id: string]: EM };
  synching: boolean;
}

export interface FetchEMsAction {
  type: typeof FETCH_EMS;
}

export interface LoadEMsAction {
  type: typeof LOAD_EMS;
  items: EM[];
}

export interface RemoveEMAction {
  type: typeof REMOVE_EM;
  id: string;
}

export interface PersistEMAction {
  type: typeof PERSIST_EM;
  item: EM;
}

export interface SetEngineerJiraAccessAction {
  type: typeof SET_EM_JIRA_ACCESS;
  id: string;
  callback?: () => void;
}

export interface SetEngineerConfluenceAccessAction {
  type: typeof SET_EM_CONFLUENCE_ACCESS;
  id: string;
  callback?: () => void;
}

export type EMAction =
  | FetchEMsAction
  | LoadEMsAction
  | RemoveEMAction
  | PersistEMAction
  | SetEngineerJiraAccessAction
  | SetEngineerConfluenceAccessAction;
