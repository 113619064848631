export const FETCH_COMMENTS = "comments/fetch";
export const DO_FETCH_COMMENTS = "comments/do_fetch";
export const LOAD_COMMENTS = "comments/load";
export const REMOVE_COMMENT = "comments/remove";
export const PERSIST_COMMENT = "comments/persist";

export interface Comment {
  timestamp: number;
  author: string;
  message: string;
}

export interface MonthlyComments {
  [id: string]: Comment[];
}

export interface CommentsState {
  synching: { [year: string]: { [month: string]: boolean } };
  items: {
    [year: string]: {
      [month: string]: MonthlyComments;
    };
  };
}

export interface FetchCommentsAction {
  type: typeof FETCH_COMMENTS;
  year: number;
  month: number;
}

export interface DoFetchCommentsAction {
  type: typeof DO_FETCH_COMMENTS;
  year: number;
  month: number;
}

export interface LoadCommentsAction {
  type: typeof LOAD_COMMENTS;
  year: number;
  month: number;
  items: { [id: string]: Comment[] };
}

export interface RemoveCommentAction {
  type: typeof REMOVE_COMMENT;
  year: number;
  month: number;
  id: string;
  index: number;
}

export interface PersistCommentAction {
  type: typeof PERSIST_COMMENT;
  year: number;
  month: number;
  id: string;
  item: Comment;
}

export type CommentAction =
  | FetchCommentsAction
  | DoFetchCommentsAction
  | LoadCommentsAction
  | RemoveCommentAction
  | PersistCommentAction;
