import { SynchStatus } from "msd-capacity-planning-model";

export const FETCH_SYNCHSTATUS = "synchstatus/fetch";
export const LOAD_SYNCHSTATUS = "synchstatus/load";

export interface SynchStatusState {
  statuses: SynchStatus[];
  synching: boolean;
}

export interface FetchSynchStatusAction {
  type: typeof FETCH_SYNCHSTATUS;
}

export interface LoadSynchStatusAction {
  type: typeof LOAD_SYNCHSTATUS;
  statuses: SynchStatus[];
}

export type SynchStatusAction = FetchSynchStatusAction | LoadSynchStatusAction;
