import Button, { ButtonProps }  from '@material-ui/core/Button';
import { CustomButton } from './CustomButton';

import { Link } from "react-router-dom";

export function NoStyleLink({
  text,
  pathname,
}: {
  text: string;
  pathname: string;
}) { 
  return (
    <CustomButton 
      component={Link}
      variant='outlined'
      to={{ pathname: pathname }}
      style={{ textDecoration: "inherit", color: "inherit" }}
    >
      {text}
    </CustomButton>
  );
}
