import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import jiraResourcesService, {
  JiraProjectSchemeType
} from './JiraResourcesService'

const CREATION_STEPS = 2

enum MessageType {
  INFO,
  ERROR
}
interface Message {
  text: string
  type: MessageType
}

export function JiraProjectCreationBar({
  projectKey,
  projectName,
  emEmail,
  emLabel,
  harvestId,
  onCreated
}: {
  projectKey: string
  projectName: string
  emEmail: string
  emLabel: string
  harvestId: string
  onCreated: () => void
}) {
  const [creable, setCreable] = useState(false)
  const [createClicked, setCreateClicked] = useState(false)
  const [creating, setCreating] = useState(false)
  const [messages, setMessages] = useState([] as Message[])

  function onCreate(): void {
    setCreateClicked(true)
    setCreating(true)

    const newMessages = [] as Message[]

    function addMessage(message: Message): void {
      newMessages.push(message)
      setMessages(newMessages.concat([]))
    }

    const create = () =>
      jiraResourcesService
        .createProject({ projectKey, projectName, harvestId })
        .then(() =>
          addMessage({
            type: MessageType.INFO,
            text: `Created project '${projectKey}'`
          })
        )
        .then(() =>
          Promise.all([
            jiraResourcesService
              .putHarvestCode(projectKey, harvestId)
              .then(() =>
                addMessage({
                  type: MessageType.INFO,
                  text: `Set harvest code`
                })
              ),
            jiraResourcesService.putProjectLead(projectKey, emEmail).then(() =>
              addMessage({
                type: MessageType.INFO,
                text: `Set project lead '${emEmail}'`
              })
            ),
            jiraResourcesService
              .updateScheme(projectKey, JiraProjectSchemeType.ISSUE_TYPE)
              .then(() =>
                addMessage({
                  type: MessageType.INFO,
                  text: `Set issue type scheme`
                })
              ),
            jiraResourcesService
              .updateScheme(projectKey, JiraProjectSchemeType.ISSUE_SCREEN_TYPE)
              .then(() =>
                addMessage({
                  type: MessageType.INFO,
                  text: `Set issue screen type scheme`
                })
              ),
            jiraResourcesService
              .updateScheme(projectKey, JiraProjectSchemeType.NOTIFICATION)
              .then(() =>
                addMessage({
                  type: MessageType.INFO,
                  text: `Set notification scheme`
                })
              ),
            jiraResourcesService
              .updateScheme(projectKey, JiraProjectSchemeType.PERMISSION)
              .then(() =>
                addMessage({
                  type: MessageType.INFO,
                  text: `Set permission scheme`
                })
              ),
            jiraResourcesService
              .updateScheme(projectKey, JiraProjectSchemeType.WORKFLOW)
              .then(() =>
                addMessage({
                  type: MessageType.INFO,
                  text: `Set workflow scheme`
                })
              ),
            jiraResourcesService
              .updatePeople(projectKey)
              .then(() =>
                addMessage({
                  type: MessageType.INFO,
                  text: `Set project people`
                })
              )
              .then(() => {
                return jiraResourcesService
                  .putOnboardingTasks(projectKey, emEmail, emLabel)
                  .then(() => {
                    addMessage({
                      type: MessageType.INFO,
                      text: `Created onboarding tasks`
                    })
                  })
              }),
            jiraResourcesService.putProjectFilter(projectKey).then(async () => {
              addMessage({
                type: MessageType.INFO,
                text: `Added board filter`
              })
              await jiraResourcesService.putProjectBoard(projectKey)
              addMessage({
                type: MessageType.INFO,
                text: `Added scrum board`
              })
              return Promise.all([
                jiraResourcesService
                  .putBoardAdministrator(projectKey)
                  .then(() =>
                    addMessage({
                      type: MessageType.INFO,
                      text: `Set scrum board administrator`
                    })
                  ),
                jiraResourcesService.putBoardColumns(projectKey).then(() =>
                  addMessage({
                    type: MessageType.INFO,
                    text: `Set scrum board columns`
                  })
                ),
                jiraResourcesService.putBoardSwimlane(projectKey).then(() =>
                  addMessage({
                    type: MessageType.INFO,
                    text: `Set scrum board columns`
                  })
                )
              ])
            })
          ])
        )
        .catch((error) => {
          // TODO: improve UX
          console.log(error)
        })

    const done = () =>
      newPromise().then(() =>
        addMessage({ type: MessageType.INFO, text: 'Done' })
      )

    create()
      .then(() => done())
      .then(() => onCreated())
      .finally(() => setCreating(false))
  }

  function newPromise(): Promise<any> {
    return new Promise((resolve) => setTimeout(resolve, 3000))
  }

  useEffect(() => {
    setCreable(!!projectKey && !!emEmail && !!emLabel)
  }, [projectKey, emEmail, emLabel])

  return (
    <Box style={{ marginTop: 16 }}>
      <Box>
        <Button
          variant='contained'
          color='primary'
          disabled={!creable || createClicked}
          onClick={() => onCreate()}
        >
          Create {creating ? <CircularProgress size={16} /> : null}
        </Button>
      </Box>
      {creating ? (
        <Box style={{ marginTop: 16 }}>
          <LinearProgress
            variant='determinate'
            value={Math.round((messages.length / CREATION_STEPS) * 100)}
          />
        </Box>
      ) : null}
      <Box>
        <List>
          {messages.map((m, index) => {
            return (
              <ListItem key={`item${index}`}>
                <ListItemIcon>
                  {m.type === MessageType.INFO ? (
                    <CheckIcon style={{ color: 'green' }} />
                  ) : (
                    <ClearIcon style={{ color: 'red' }} />
                  )}
                </ListItemIcon>
                {m.text}
              </ListItem>
            )
          })}
        </List>
      </Box>
    </Box>
  )
}
