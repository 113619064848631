import { Middleware } from "redux";
import { AppState, INIT_APP } from "../redux/AppTypes";
import { currentSession, signOut } from "./Authorization";
import authorizationService from "./AuthorizationService";
import {
  AUTH_INIT,
  AUTH_SET_MYSELF,
  AUTH_SET_SESSION,
  AUTH_SIGNOUT,
  InitAuthenticationAction,
  SetMyselfAction,
  SetSessionAction,
  SignoutAction
} from "./AuthorizationTypes";

const initAuthMiddleware: Middleware<{}, AppState> =
  (store) => (next) => (action: InitAuthenticationAction) => {
    if (action.type === AUTH_INIT) {
      currentSession()
        .catch((error) => {
          store.dispatch({ type: AUTH_SET_SESSION });
        })
        .then((session) => {
          store.dispatch({ type: AUTH_SET_SESSION, session });
        });
    }
    return next(action);
  };

const setMyselfMiddleware: Middleware<{}, AppState> =
  (store) => (next) => (action: SetSessionAction) => {
    const result = next(action);
    if (action.type === AUTH_SET_SESSION && action.session) {
      authorizationService
        .getMyself()
        .then((myself) => {
          const setMyselfAction: SetMyselfAction = {
            type: AUTH_SET_MYSELF,
            myself,
          };
          store.dispatch(setMyselfAction);
        })
        .catch(() => {
          store.dispatch({ type: INIT_APP });
        });
    } else if (action.type === AUTH_SET_SESSION) {
      store.dispatch({ type: INIT_APP });
    }
    return result;
  };

const initAuthorizedAppMiddleware: Middleware<{}, AppState> =
  (store) => (next) => (action: SetMyselfAction) => {
    const result = next(action);
    if (action.type === AUTH_SET_MYSELF) {
      store.dispatch({ type: INIT_APP });
    }
    return result;
  };

const signoutMiddleware: Middleware<{}, AppState> =
  (store) => (next) => (action: SignoutAction) => {
    if (action.type === AUTH_SIGNOUT) {
      signOut();
    }
    return next(action);
  };

const middlewares = [
  initAuthMiddleware,
  setMyselfMiddleware,
  initAuthorizedAppMiddleware,
  signoutMiddleware,
];
export default middlewares;
