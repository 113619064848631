import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { useTeamCapacityByAggregator } from "capacity/useTeamCapacityByAggregator";
import { DashboardPeriod } from "dashboards/DashboardPeriod";
import {
  CustomerAggregatorType,
  TeamCapacityAggregatorType
} from "msd-capacity-planning-model";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { useEffect, useState, useMemo } from "react";
import { NoStyleLink } from "components/NoStyleLink";

import { TeamAggregatorType } from "teams/TeamAggregator";
import { useBudgetByAggregator } from "../../customers/useBudgetByAggregator";
import { useManagers } from "../../managers/useManagers";
import { useTeamsByAggregator } from "../../teams/useTeamsByAggregator";

export function ManagersTable({ period }: { period: DashboardPeriod }) {
  const managerEmails = useManagers();
  const budgets = useBudgetByAggregator(
    period.year,
    period.month,
    CustomerAggregatorType.MANAGER
  );
  const capacity = useTeamCapacityByAggregator(
    period.year,
    period.month,
    TeamCapacityAggregatorType.MANAGER
  );
  const teams = useTeamsByAggregator(TeamAggregatorType.MANAGER);

  const managerNames = useMemo(() => Array.from(new Set(Object.values(managerEmails).map((managerEmail,index) => (managerEmail)))) ,[managerEmails])


  const columns: any = [ 
    {
      name: "Manager",
      options: {
        filter: true,
        filterOptions:{
          names: managerNames,
          logic(manager: any, filters: any) {
            let textString = manager.props.text;
            return !filters.includes(textString);} 
        },
        sortCompare: (order: string) => {
          return (obj1: { data: { props: { text: string; }; }; }, obj2: { data: { props: { text: string; }; }; }) => {
            return obj1.data.props.text.localeCompare(obj2.data.props.text) * (order === 'asc' ? 1 : -1);
          };
        },
      }
    },
    {
      name: "Teams",
      options: {
        filter: true
      }
    },
    {
      name: "Customers",
      options: {
        filter: true
      }
    },
    {
      name: "Capacity",
      options: {
        filter: true
      }
    },
    {
      name: "Budget",
      options: {
        filter: true
      }
    }
  ]


  const data = useMemo(() => managerEmails.map((managerEmail,index) => (
     (
      [

        <NoStyleLink
        pathname={`/dashboards/managers/${managerEmail}`}
        text={managerEmail}
        />,
        (teams[managerEmail]||[]).length,
        Object.values(budgets[managerEmail]?.items || {}).length,
        Math.round(capacity[managerEmail]?.allocatedHours).toLocaleString(),
        Math.round(budgets[managerEmail]?.teamHours + budgets[managerEmail]?.opsHours).toLocaleString()
      ]
     )
     )),[managerEmails])


  return (
    <Box>
    <MUIDataTable
       title=""
       columns={columns}
       data={data}
       options={
        {
          filterType: 'multiselect',

          "draggableColumns":{"enabled":true},
          "pagination":false,
          "selectableRows":"none",
          onDownload: (buildHead, buildBody, columns, data) => {
            return "\uFEFF" + buildHead(columns) + buildBody(
             data.map((it: { index: any; data: { id: string | number; }[]; }) => 
             (
              { index: it.index, 
               data: [
                 data[it.index].data[0].props.text,
                 data[it.index].data[1],
                 data[it.index].data[2],
                 data[it.index].data[3],
                 data[it.index].data[4]
               ] 
             })
             )
             );
            },
       }
       }
     
       /> 
    </Box>  
  );
}