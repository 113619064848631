import { Box } from "@material-ui/core";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { useTeams } from "teams/useTeams";
import { CustomersDashboardRoutePage } from "./customers/CustomersDashboardRoutePage";
import { EMLeadsDashboardRoutePage } from "./emleads/EMLeadsDashboardRoutePage";
import { EMsDashboardRoutePage } from "./ems/EMsDashboardRoutePage";
import { useSortedEMs } from "ems/useSortedEMs";
import { useDashboardPeriod } from "./useDashboardPeriod";
import { EngineersDashboardRoutePage } from "./engineers/EngineersDashboardRoutePage";
import { LeadsDashboardRoutePage } from "./leads/LeadsDashboardRoutePage";
import { ManagersDashboardRoutePage } from "./managers/ManagersDashboardRoutePage";
import { OverviewDashboardPage } from "./overview/OverviewDashboardPage";
import { RegionsDashboardRoutePage } from "./regions/RegionsDashboardRoutePage";
import { SkillsDashboardRoutePage } from "./skills/SkillsDashboardRoutePage";
import { TeamsDashboardRoutePage } from "./teams/TeamsDashboardRoutePage";

export function DashboardsRoutePage() {

  const period = useDashboardPeriod();
  const teams = useTeams(period.year, period.month);
  return (
    <Box>
      <Switch>
        <Route path="/dashboards/customers">
          <CustomersDashboardRoutePage />
        </Route>
        <Route path="/dashboards/teams">

          <TeamsDashboardRoutePage baseUrl="/dashboards/teams" teams={teams}/>
        </Route>
        <Route path="/dashboards/ems">
          <EMsDashboardRoutePage />
        </Route>
        <Route path="/dashboards/emleads">
          <EMLeadsDashboardRoutePage />
        </Route>
        <Route path="/dashboards/engineers">
          <EngineersDashboardRoutePage baseUrl="/dashboards/engineers" />
        </Route>
        <Route path="/dashboards/leads">
          <LeadsDashboardRoutePage />
        </Route>
        <Route path="/dashboards/managers">
          <ManagersDashboardRoutePage />
        </Route>
        <Route path="/dashboards/regions">
          <RegionsDashboardRoutePage baseUrl="/dashboards/regions" />
        </Route>
        <Route path="/dashboards/skills">
          <SkillsDashboardRoutePage baseUrl="/dashboards/skills" />
        </Route>
        <Route path="/dashboards">
          <OverviewDashboardPage />
        </Route>
      </Switch>
    </Box>
  );
}
