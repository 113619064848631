import env from "../app/Environment";
import fetchService from "../utils/FetchService";
import memoizeUntil from "../utils/memoizeUntil";

export class DocService {
  constructor(private endpoint: string) {}

  setCookies = memoizeUntil(
    async () => {
      const cookies = await fetchService
        .fetch(`${this.endpoint}/signed/docs`)
        .then((res) => res.json() as Promise<{ [key: string]: string }>);
      Object.keys(cookies).forEach((key) => {
        document.cookie = `${key}=${cookies[key]}; path=/docs; Secure`;
      });
    },
    60000 * 60 // 1 hour
  );

  public async getDocs(path: string): Promise<any> {
    if (!path.startsWith("/")) {
      throw new Error("illegal path");
    }
    await this.setCookies();
    window.open(`/docs${path}`);
  }
}

export const docsService = new DocService(env.endpoint);
