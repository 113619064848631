import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useState } from "react";

export function DeleteButton({
  title,
  text = "Are you sure you want to delete this item?",
  onClick,
}: {
  title: string;
  text?: string;
  onClick: () => void;
}) {
  const [open, setOpen] = useState(false);

  return (
    <span>
      <IconButton title={title} onClick={() => setOpen(true)}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setOpen(false);
              onClick();
            }}
            autoFocus
            color="primary"
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
