import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@material-ui/core";
import { Size as TableSize } from "@material-ui/core/Table";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useEngineerCapacityByAggregator } from "capacity/useEngineerCapacityByAggregator";
import useComments from "comments/useComments";
import { EngineerLinkBar } from "dashboards/engineer/EngineerLinkBar";
import {
  Capacity,
  Engineer,
  EngineerAggregatorType,
  EngineerHandle
} from "msd-capacity-planning-model";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export function EngineerCapacityTable({
  year,
  month,
  engineers,
  linkPrefix = "",
  notes = false,
  actualHours = true,
  size = "medium",
}: {
  year: number;
  month: number;
  engineers: { [engineerId: string]: EngineerHandle };
  linkPrefix?: string;
  notes?: boolean;
  actualHours?: boolean;
  size?: TableSize;
}) {
  const location = useLocation();
  const comments = useComments(year, month);
  const capacities = useEngineerCapacityByAggregator(
    year,
    month,
    EngineerAggregatorType.ALL,
    engineers
  );
  const [anchorEl, setAnchorEl] = useState(null as any);
  const [menuItem, setMenuItem] = useState(null as Engineer | null);

  return (
    <Box>
      <Paper>
        <TableContainer>
          <Table size={size}>
            <TableHead>
              <TableRow>
                <TableCell>Engineer</TableCell>
                <TableCell>Hours</TableCell>
                <TableCell>PTO</TableCell>
                <TableCell>Weekdays</TableCell>
                <TableCell>Holidays</TableCell>
                <TableCell>Region</TableCell>
                {actualHours ? <TableCell>Actual hours</TableCell> : null}
                {notes ? <TableCell>Notes</TableCell> : null}
                <TableCell
                  style={size === "small" ? {} : { minWidth: "150px" }}
                >
                  {" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(Object.values(capacities)?.[0]?.getItems() || []).map(
                (item, index) => {
                  const capacity = item as Capacity;
                  const engineer = item.engineer;
                  return (
                    <TableRow key={engineer.id}>
                      <TableCell>
                        <Link
                          to={{
                            pathname: `${location.pathname}${linkPrefix}/${engineer.id}`,
                          }}
                          style={{
                            textDecoration: "inherit",
                            color: "inherit",
                          }}
                        >
                          {index + 1}. {engineer.firstName} {engineer.lastName}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <span>{Math.round(item.getUtilizationHours())} </span>
                      </TableCell>
                      <TableCell>{engineer.getDaysOff(year, month)}</TableCell>
                      <TableCell>{capacity.getWeekdays()}</TableCell>
                      <TableCell>{capacity.getHolidays()}</TableCell>
                      <TableCell>{capacity.region?.name || ""} </TableCell>
                      {actualHours ? (
                        <TableCell>
                          {Math.round(
                            engineer.getHarvestHours(year, month).total
                          )}{" "}
                        </TableCell>
                      ) : null}
                      {notes ? (
                        <TableCell>
                          {(comments[engineer.id] || []).map((cmt, index) => {
                            return (
                              <Box key={`key${index}`}>
                                <Tooltip title={cmt.author}>
                                  <span>
                                    {">"} {cmt.message || " "}
                                  </span>
                                </Tooltip>
                              </Box>
                            );
                          })}
                        </TableCell>
                      ) : null}
                      <TableCell>
                        {size === "small" ? (
                          <IconButton
                            size="small"
                            onClick={(event: any) => {
                              setMenuItem(engineer);
                              setAnchorEl(event.currentTarget);
                            }}
                          >
                            <MoreVertIcon fontSize="small" />
                          </IconButton>
                        ) : (
                          <EngineerLinkBar
                            year={year}
                            month={month}
                            engineer={engineer}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem>
            <EngineerLinkBar
              year={year}
              month={month}
              engineer={menuItem as Engineer}
            />
          </MenuItem>
        </Menu>
      </Paper>
    </Box>
  );
}
