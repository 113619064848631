import { Button } from "@material-ui/core";
import { useState } from "react";
import { useDispatch } from "react-redux";

export function DispatchActionButton({
  title,
  action,
  id,
  disabled = false,
}: {
  title: string;
  action: string;
  id: string;
  disabled?: boolean;
}) {
  const dispatch = useDispatch();

  const [running, setRunning] = useState(false);

  function onClick(id: string): void {
    setRunning(true);
    dispatch({
      type: action,
      id,
      callback: () => {
        setRunning(false);
      },
    });
  }

  return (
    <Button
      disabled={disabled || running}
      color="primary"
      onClick={() => onClick(id)}
    >
      {title}
    </Button>
  );
}
