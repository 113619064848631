import { useEffect, useState } from 'react'
import service from './JiraResourcesService'

export function useHost(): string {
  const [host, setHost] = useState('')

  useEffect(() => {
    service.getHost().then((newHost) => setHost(newHost))
  }, [])

  return host
}
