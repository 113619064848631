export interface Environment {
  endpoint: string;
  websocketEndpoint: string;
  region: string;
  userPoolId: string;
  userPoolClientId: string;
  userPoolDomain: string;
  redirectSignIn: string;
  redirectSignOut: string;
}

const env: Environment = {
  endpoint: process.env.REACT_APP_ENDPOINT_BACKEND as string,
  websocketEndpoint: process.env.REACT_APP_WEBSOCKET_ENDPOINT_BACKEND as string,
  region: process.env.REACT_APP_REGION as string,
  userPoolId: process.env.REACT_APP_USER_POOL_ID as string,
  userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID as string,
  userPoolDomain: process.env.REACT_APP_USER_POOL_DOMAIN as string,
  redirectSignIn: process.env.REACT_APP_USER_POOL_REDIRECT_SIGNIN as string,
  redirectSignOut: process.env.REACT_APP_USER_POOL_REDIRECT_SIGNOUT as string,
};
export default env;
