import {
  CustomerAggregatorType,
  CustomerHandle
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useCustomerAggregator } from "./useCustomerAggregator";
import { useSortedCustomers } from "./useSortedCustomers";

export interface CustomersByAggregator {
  [id: string]: CustomerHandle[];
}

export default function useCustomersByAggregator(
  year: number,
  month: number,
  type: CustomerAggregatorType
): CustomersByAggregator {
  const customers = useSortedCustomers(year, month);
  const [response, setResponse] = useState({} as CustomersByAggregator);
  const aggregator = useCustomerAggregator(year, month);

  useEffect(() => {
    const newResponse = customers.reduce((response, customer) => {
      const ids = aggregator.get(customer, type);
      ids.forEach((id) => {
        response[id] = response[id] || [];
        response[id].push(customer);
      });
      return response;
    }, {} as CustomersByAggregator);
    setResponse(newResponse);
  }, [year, month, type, aggregator, customers]);

  return response;
}
