import { Box } from "@material-ui/core";
import useCustomersInAggregator from "customers/useCustomersInAggregator";
import { CustomersTable } from "dashboards/customers/CustomersTable";
import { DashboardBreadcrumbs } from "dashboards/DashboardBreadcrumbs";
import { useDashboardPeriod } from "dashboards/useDashboardPeriod";
import { CustomerAggregatorType } from "msd-capacity-planning-model";
import { useParams } from "react-router-dom";

export function SkillCustomersDashboardPage({ baseUrl }: { baseUrl: string }) {
  const { year, month } = useDashboardPeriod();
  const params: { [key: string]: string } = useParams();
  const customers = useCustomersInAggregator(
    year,
    month,
    CustomerAggregatorType.SKILL,
    params.skill || ""
  );
  return (
    <Box>
      <DashboardBreadcrumbs />
      <CustomersTable year={year} month={month} customers={customers} />
    </Box>
  );
}
