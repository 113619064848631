import { Box, Grid, Paper } from "@material-ui/core";
import { DashboardBreadcrumbs } from "dashboards/DashboardBreadcrumbs";
import { CustomersNotificationsCard } from "../../notifications/customer/CustomersNotificationCard";
import { useDashboardPeriod } from "../useDashboardPeriod";
import { EMLeadsTable } from "./EMLeadsTable";

export function EMLeadsDashboardPage() {
  const { year, month } = useDashboardPeriod();

  return (
    <Box>
      <DashboardBreadcrumbs />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs={8}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item xs={12}>
              <Paper>
                <EMLeadsTable year={year} month={month} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomersNotificationsCard year={year} month={month} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
