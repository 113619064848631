import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Toolbar,
  Tooltip,
  Typography
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { AggregatedBurnedBudgetRatio } from "customers/BurnedBudgetTypes";
import { useBurnedBudgetInAggregator } from "customers/useBurnedBudgetInAggregator";
import {
  CustomerAggregatorType, CustomerHandle
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";

function BurnedBudgetCardImpl({ ratio }: { ratio: AggregatedBurnedBudgetRatio }) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Tooltip title="Data Source: Capacity Planner" placement="top-start">
              <Typography
                variant="h5"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                Burned budget
                {!ratio.isOK() ? (
                  <Tooltip
                    title={`Expected: ${ratio
                      .getExpectedHours()
                      .toLocaleString()} (${ratio
                      .getExpectedHoursPercent()
                      .toLocaleString()}%)`}
                  >
                    <WarningIcon
                      color="secondary"
                      style={{ marginLeft: "4px" }}
                    />
                  </Tooltip>
                ) : null}
              </Typography>
            </Tooltip>
            <Typography variant="caption" component="p">
              Burned hours / budget
            </Typography>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <Box position="relative" display="inline-flex">
              <CircularProgress
                variant="determinate"
                color="primary"
                value={ratio.getBurnedHoursInBudgetPercent()}
                size={64}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                >
                  {ratio.getBurnedHoursInBudgetPercent().toLocaleString()}%
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <Typography variant="caption">
              {Math.round(ratio.getBurnedHoursInBudget()).toLocaleString()} (
              {ratio.getBurnedHoursInBudgetPercent().toLocaleString()}
              %)
              <br />
              In Budget
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <Typography variant="caption">
              {Math.round(ratio.getBurnedHoursOverBudget()).toLocaleString()} (
              {ratio.getBurnedHoursOverBudgetPercent().toLocaleString()}
              %)
              <br />
              Over budget
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <Typography variant="caption">
              {Math.round(ratio.total).toLocaleString()} (100%)
              <br />
              Budget
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export function BurnedBudgetCard({
  year,
  month,
  customers,
}: {
  year: number;
  month: number;
  customers: { [customerId: string]: CustomerHandle };
}) {
  const [customerIds, setCustomerIds] = useState([] as string[]);
  const burnedBudget = useBurnedBudgetInAggregator(
    year,
    month,
    CustomerAggregatorType.CUSTOMER,
    customerIds
  );

  useEffect(() => {
    setCustomerIds(Object.keys(customers));
  }, [year, month, customers]);

  return <BurnedBudgetCardImpl ratio={burnedBudget.getRatio()} />;
}

export function TeamBurnedBudgetCard({
  year,
  month,
  teamId,
}: {
  year: number;
  month: number;
  teamId: string;
}) {
  const burnedBudget = useBurnedBudgetInAggregator(
    year,
    month,
    CustomerAggregatorType.TEAM,
    teamId
  );
  return <BurnedBudgetCardImpl ratio={burnedBudget.getRatio()} />;
}

export function CustomerBurnedBudgetCard({
  year,
  month,
  customerId,
}: {
  year: number;
  month: number;
  customerId: string;
}) {
  const burnedBudget = useBurnedBudgetInAggregator(
    year,
    month,
    CustomerAggregatorType.CUSTOMER,
    customerId
  );
  return <BurnedBudgetCardImpl ratio={burnedBudget.getRatio()} />;
}
