import {
  AppFeedbackAction,
  REMOVE_APP_FEEDBACK,
  REPORT_APP_FEEDBACK
} from "./AppTypes";

const initialState = [] as string[];

export default function feedbackReducer(
  state = initialState,
  action: AppFeedbackAction
): string[] {
  switch (action.type) {
    case REPORT_APP_FEEDBACK:
      if (action.feedback) {
        return state.concat([action.feedback]);
      } else {
        return state;
      }
    case REMOVE_APP_FEEDBACK:
      return state.length > 0 ? state.slice(1) : [];
    default:
      return state;
  }
}
