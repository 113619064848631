import { Container } from "@material-ui/core";
import { EMDashboardRoutePage } from "dashboards/em/EMDashboardRoutePage";
import { Route, Switch } from "react-router-dom";
import { EMsDashboardOverviewPage } from "./EMsDashboardOverviewPage";
import { EMDeltaDashboardPage } from "./EMDeltaDashboardPage";
import { EMDelta1850DashboardPage } from "./EMDelta1850DashboardPage";
import { TeamHandle } from "msd-capacity-planning-model";
import { useDashboardPeriod } from "dashboards/useDashboardPeriod";
import { useTeams } from "teams/useTeams";

export function EMsDashboardRoutePage({
}) {
  const baseUrl = "/dashboards/ems";
  const period = useDashboardPeriod();
  const teams = useTeams(period.year, period.month)

  return (
    <Switch>
        <Route path={`${baseUrl}/delta`} exact={true}>
          <EMDeltaDashboardPage 
            teams={teams}
            linkPrefix="/dashboards/ems"
            routelinkPrefix="/dashboards/regions"
          />
        </Route>
        <Route path={`${baseUrl}/delta1850`} exact={true}>

          <EMDelta1850DashboardPage
            teams={teams}
            linkPrefix="/dashboards/ems"
            routelinkPrefix="/dashboards/regions"
          />
        </Route>
      <Route path={`${baseUrl}/:em`}>
        <Container maxWidth="xl">
          <EMDashboardRoutePage baseUrl={baseUrl} />
        </Container>
      </Route>
      <Route path={baseUrl}>
        <EMsDashboardOverviewPage />
      </Route>
    </Switch>
  );
}
