import {
  AggregatedCapacity,
  Capacity,
  EngineerHandle
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useCapacities } from "./useCapacities";

export default function useEngineersCapacity(
  year: number,
  month: number,
  engineers: { [engineerId: string]: EngineerHandle }
): AggregatedCapacity<Capacity> {
  const [months, setMonths] = useState([] as { year: number; month: number }[]);
  const capacities = useCapacities(months);
  const [capacity, setCapacity] = useState(new AggregatedCapacity<Capacity>());

  useEffect(() => {
    setMonths([{ year, month }]);
  }, [year, month]);

  useEffect(() => {
    const ids = new Set(Object.keys(engineers));
    const items = capacities.find((x) => true)?.items || {};
    const newCapacity = Object.keys(items)
      .filter((engineerId) => ids.has(engineerId))
      .reduce((all, engineerId) => {
        all.add(items[engineerId]);
        return all;
      }, new AggregatedCapacity<Capacity>());
    setCapacity(newCapacity);
  }, [months, capacities, engineers]);

  return capacity;
}
