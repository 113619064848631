import useBudget from "customers/useBudget";
import { useEffect, useState } from "react";
import useAllCustomers from "../../customers/useAllCustomers";
import {
  SystemNotification,
  SystemNotificationSource
} from "../SystemNotificationTypes";

export function useCustomersOnboardedNotifications(
  year: number,
  month: number
): SystemNotification[] {
  const customers = useAllCustomers();
  const previous = useBudget(year, month - 1);
  const current = useBudget(year, month);

  const [notifications, setNotifications] = useState(
    [] as SystemNotification[]
  );

  useEffect(() => {
    const newNotifications = Object.keys(current.items)
      .filter((customerId) => !previous.items[customerId])
      .map((customerId) => {
        const title = customers[customerId]?.name || "";
        return {
          source: SystemNotificationSource.CUSTOMER,
          id: customerId,
          title,
          link: `/dashboards/customers/items/${customerId}`,
          description: "",
        };
      });
    setNotifications(newNotifications);
  }, [customers, current, previous]);

  return notifications;
}
