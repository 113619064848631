import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem
} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import React, { useEffect, useState } from 'react'
import { JiraProjectCreationBar } from './JiraProjectCreationBar'
import { JiraProjectDTO } from './JiraProjectDTO'
import { useHost } from './useHost'
import { useProject } from './useProject'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

enum JiraProjectLoaderStatus {
  LOADING,
  FOUND,
  NOT_FOUND
}

export function JiraProject({
  projectKey,
  projectName,
  emEmail,
  emLabel,
  harvestId,
  onLoad
}: {
  projectKey: string
  projectName: string
  emEmail: string
  emLabel: string
  harvestId: string
  onLoad: (project?: JiraProjectDTO) => any
}) {
  const host = useHost()
  const [projectLoadCounter, setProjectLoadCounter] = useState(0)
  const loader = useProject(projectKey, projectLoadCounter)
  const [status, setStatus] = useState(JiraProjectLoaderStatus.LOADING)

  useEffect(() => {
    onLoad(loader.project)
    let newStatus = JiraProjectLoaderStatus.LOADING
    if (!loader.loading) {
      if (loader.project) {
        newStatus = JiraProjectLoaderStatus.FOUND
      } else {
        newStatus = JiraProjectLoaderStatus.NOT_FOUND
      }
    }
    setStatus(newStatus)
  }, [loader])

  return (
    <Box style={{ textAlign: 'left' }}>
      <Box>
        Project:{' '}
        <a
          href={`https://${host}/browse/${projectKey}`}
          target='_blank'
          rel='noreferrer noopener'
          style={{ textDecoration: 'inherit', color: 'inherit' }}
        >
          {projectKey}{' '}
        </a>
        {status === JiraProjectLoaderStatus.LOADING ? (
          <CircularProgress size={16} />
        ) : null}
        {status === JiraProjectLoaderStatus.FOUND
          ? `(${loader.project?.name})`
          : null}
        {status === JiraProjectLoaderStatus.FOUND ? (
          <IconButton
            onClick={() => {
              setProjectLoadCounter(projectLoadCounter + 1)
            }}
            style={{ marginLeft: 8 }}
          >
            <RefreshIcon />
          </IconButton>
        ) : null}
      </Box>

      {status === JiraProjectLoaderStatus.FOUND ? (
        <Box
          style={{
            marginLeft: 16,
            backgroundColor: '#ffffe9',
            padding: 16,
            borderRadius: 25
          }}
        >
          Manual tasks after project creation
          <List>
            <ListItem>
              <a
                href={`https://${host}//plugins/servlet/project-config/${projectKey}`}
                target='_blank'
                rel='noreferrer noopener'
                style={{ textDecoration: 'inherit', color: 'gray' }}
              >
                <ArrowForwardIcon />
              </a>
              Set jira project logo and category
            </ListItem>
          </List>
        </Box>
      ) : null}

      {status === JiraProjectLoaderStatus.NOT_FOUND ? (
        <Box>
          {projectKey && emEmail && emLabel ? (
            <JiraProjectCreationBar
              projectKey={projectKey}
              projectName={projectName}
              emEmail={emEmail}
              emLabel={emLabel}
              harvestId={harvestId}
              onCreated={() => setProjectLoadCounter(projectLoadCounter + 1)}
            />
          ) : null}
        </Box>
      ) : null}
    </Box>
  )
}
