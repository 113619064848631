import { useAllEMs }  from "ems/useAllEMs";
import { CustomerAggregator } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useTeams} from "teams/useTeams";

export function useCustomerAggregator(
  year: number,
  month: number
): CustomerAggregator {
  const teams = useTeams(year, month);
  const ems = useAllEMs();
  const [aggregator, setAggregator] = useState(
    new CustomerAggregator(year, month, {}, {})
  );

  useEffect(() => {
    const aggregator = new CustomerAggregator(year, month, teams, ems);
    setAggregator(aggregator);
  }, [year, month, teams, ems]);

  return aggregator;
}
