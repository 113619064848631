import React from "react";
import { Switch, Route } from "react-router-dom";
import { EngineersPanel } from "./EngineersPanel";
import { EngineerForm } from "./EngineerForm";

export function EngineersPage() {
  return (
    <Switch>
      <Route path="/administration/engineers/:id" strict={true}>
        <EngineerForm />
      </Route>
      <Route exact path="/administration/engineers/" strict={true}>
        <EngineerForm />
      </Route>
      <Route path="/administration/engineers">
        <EngineersPanel />
      </Route>
    </Switch>
  );
}
