export enum SystemNotificationSource {
  TEAM,
  CUSTOMER,
  ENGINEER,
  SYNCH,
}

export enum SystemNotificationType {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

export interface SystemNotification {
  source: SystemNotificationSource;
  type?: SystemNotificationType;
  id: string;
  title: string;
  description: string;
  link?: string;
}
