import { useEngineerCapacityByAggregator } from "capacity/useEngineerCapacityByAggregator";
import { LabeledScatterDataPoint } from "components/LabeledScatterDataPoint";
import { ScatterChartCard } from "components/ScatterChartCard";
import { useBudgetByAggregator } from "customers/useBudgetByAggregator";
import useCustomersInAggregator from "customers/useCustomersInAggregator";
import useEngineersInAggregator from "engineers/useEngineersInAggregator";
import {
  CustomerAggregatorType,
  EngineerAggregatorType
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import useRegions from "regions/useRegions";

export function SkillDeltaByRegionScatterChart({
  year,
  month,
  skill,
}: {
  year: number;
  month: number;
  skill: string;
}) {
  const [coordinates, setCoordinates] = useState(
    [] as LabeledScatterDataPoint[]
  );

  const regions = useRegions();
  const customers = useCustomersInAggregator(
    year,
    month,
    CustomerAggregatorType.SKILL,
    skill
  );
  const budgets = useBudgetByAggregator(
    year,
    month,
    CustomerAggregatorType.REGION,
    customers
  );
  const engineers = useEngineersInAggregator(
    year,
    month,
    EngineerAggregatorType.SKILL,
    skill
  );
  const capacities = useEngineerCapacityByAggregator(
    year,
    month,
    EngineerAggregatorType.REGION,
    engineers
  );
  const [yMin, setYMin] = useState(0);

  useEffect(() => {
    const newCoordinates = Object.values(regions)
      .map((region) => {
        const capacity = capacities[region.id]?.allocatedHours || 0;
        const budget = budgets[region.id]?.getTeamPlusOps() || 0;
        const x = budget;
        const y = Math.round(capacity - budget);
        return {
          label: region.name,
          x,
          y,
        };
      })
      .filter((coordinate) => coordinate.x !== 0 || coordinate.y !== 0);
    const newYmin = newCoordinates.reduce((min, c) => Math.min(min, c.y), 0);
    setYMin(newYmin);
    setCoordinates(newCoordinates);
  }, [year, month, regions, budgets, capacities]);

  return (
    <ScatterChartCard
      dataPoints={coordinates}
      title={`Skill delta by region: ${(skill || "").toUpperCase()}`}
      xScaleTitle="Budget (hours)"
      yScaleTitle="Delta (capacity - budget)"
      chartHeight={300}
      xAnnotation={0}
      yMin={yMin}
    />
  );
}
