import { TeamHandle } from "msd-capacity-planning-model";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { createSelector } from "reselect";
import { AppState } from "../redux/AppTypes";
import { useAllTeamsSelector } from "./useAllTeams";
import { useTeams }  from "./useTeams";


export const useSortedTeamsSelector = createSelector(
  useAllTeamsSelector,
  (items) => {
    const sortedItems = Object.values(items);
    sortedItems.sort((i1, i2) => i1.compare(i2));
    return sortedItems;
  }
);

export default function useSortedTeams(year: number, month: number): TeamHandle[] {
  const state = useSelector((state: AppState) => state);
  const teams = useTeams(year, month);
  const sorted = useSortedTeamsSelector(state);
  const [filtered, setFiltered] = useState([] as TeamHandle[]);

  useEffect(() => {
    const active = new Set(Object.keys(teams));
    setFiltered(sorted.filter((item) => active.has(item.id)));
  }, [teams, sorted]);

  return filtered;
}