import { AppState } from "../redux/AppTypes";
import { EngineerHandle } from "msd-capacity-planning-model";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

const engineersSelector = (state: AppState) => state.engineers.items;

export const useAllEngineersSelector = createSelector(
  engineersSelector,
  (items) => {
    const values = Object.values(items) || [];
    return values.reduce((map, e) => {
      map[e.id] = new EngineerHandle(e);
      return map;
    }, {} as { [id: string]: EngineerHandle });
  }
);

export function useAllEngineers(): { [id: string]: EngineerHandle } {
  const state = useSelector((state: AppState) => state);
  return useAllEngineersSelector(state);
}
