import { useParams } from "react-router-dom";
import { TeamAggregatorType } from "teams/TeamAggregator";
import useTeamsInAggregator from "../../teams/useTeamsInAggregator";
import { TeamsDashboardRoutePage } from "../teams/TeamsDashboardRoutePage";

export function LeadDashboardPage({ baseUrl }: { baseUrl: string }) {
  const params: { [key: string]: string } = useParams();
  const lead = params.lead;
  const teams = useTeamsInAggregator(TeamAggregatorType.LEAD, lead);

  return <TeamsDashboardRoutePage baseUrl={baseUrl} teams={teams} />;
}
