import { Box } from "@material-ui/core";
import useCustomers from "customers/useCustomers";
import { DashboardBreadcrumbs } from "dashboards/DashboardBreadcrumbs";
import { useDashboardPeriod } from "dashboards/useDashboardPeriod";
import { useEngineers } from "engineers/useEngineers";
import { SkillsDashboard } from "./SkillsDashboard";

export function SkillsDashboardPage({ baseUrl }: { baseUrl: string }) {
  const { year, month } = useDashboardPeriod();
  const customers = useCustomers(year, month);
  const engineers = useEngineers(year, month);
  return (
    <Box>
      <DashboardBreadcrumbs docs="/dashboards/skills.html"/>
      <SkillsDashboard
        year={year}
        month={month}
        customers={customers.items}
        engineers={engineers}
        baseUrl={baseUrl}
      />
    </Box>
  );
}
