import { Button, Container, Grid, makeStyles } from "@material-ui/core";
import useCapacity from "capacity/useCapacity";
import { InlineCountCard } from "components/InlineCountCard";
import { useEMLeads } from "ems/useEMLeads";
import { useEngineers } from "engineers/useEngineers";
import { EngineersNotificationsCard } from "notifications/engineer/EngineersNotificationCard";
import { SynchNotificationsCard } from "notifications/synch/SynchNotificationsCard";
import useRegions from "regions/useRegions";
import useSkills from "skills/useSkills";
import { CountCard } from "../../components/CountCard";
import useCustomers from "../../customers/useCustomers";
import { useEMs } from "../../ems/useEMs";
import { useLeads } from "../../leads/useLeads";
import { useManagers } from "../../managers/useManagers";
import { CustomersNotificationsCard } from "../../notifications/customer/CustomersNotificationCard";
import { TeamsNotificationsCard } from "../../notifications/team/TeamsNotificationsCard";
import { useTeams } from "../../teams/useTeams";
import { CustomersTrendsCard } from "../customers/CustomersTrendCard";
import { DashboardBreadcrumbs } from "../DashboardBreadcrumbs";
import { EngineersTrendsCard } from "../engineers/EngineersTrendCard";
import { CapacityByRegionCard } from "../regions/CapacityByRegionCard";
import { useDashboardPeriod } from "../useDashboardPeriod";
import { AllCustomersBurnedBudgetScatterChartCard } from "../utilization/BurnedBudgetByCustomerScatterChartCard";
import { BurnedBudgetCard } from "../utilization/BurnedBudgetCard";
import { CapacityByRoleCard } from "../utilization/CapacityByRoleCard";
import { HarvestBudgetTrend } from "../utilization/HarvestBudgetTrend";
import { UtilizationRatioCard } from "../utilization/UtilizationRatioCard";
import { UtilizationTrendsCard } from "../utilization/UtilizationTrendCard";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export function OverviewDashboardPage() {
  const classes = useStyles();
  const period = useDashboardPeriod();
  const previousPeriod = useDashboardPeriod(-1);
  const regions = useRegions();
  const teams = useTeams(period.year, period.month);
  const ems = useEMs(period.year, period.month);
  const emLeads = useEMLeads(period.year, period.month);
  const capacities = useCapacity(period.year, period.month);
  const customers = useCustomers(period.year, period.month);
  const engineers = useEngineers(period.year, period.month);
  const managers = useManagers();
  const leads = useLeads();
  const skills = useSkills();

  return (
    <Container maxWidth="xl">
      <DashboardBreadcrumbs docs="/dashboards/overview.html" />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
        className={classes.root}
      >          
          <Grid item xs={3}>
            <CountCard
              title="Teams"
              count={Object.keys(teams).length}
              path="/dashboards/teams"
            />
          </Grid>          
        <Grid item xs={3}>
          <CountCard
            title="Engineers"
            count={Object.keys(capacities.items).length}
            path="/dashboards/engineers"
          />
        </Grid>
        <Grid item xs={3}>
          <CountCard
            title="Customers"
            count={customers.getAll().length}
            path="/dashboards/customers"
          />
        </Grid>
        <Grid item xs={3}>
          <CountCard
            title="EMS"
            count={Object.values(ems).length}
            path="/dashboards/ems"
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={2}
        className={classes.root}
      >
        <Grid item xs={2} style={{ textAlign: "center" }}>
          <InlineCountCard
            title="Managers"
            count={managers.length}
            path="/dashboards/managers"
          />{" "}
        </Grid>
        <Grid item xs={2} style={{ textAlign: "center" }}>
          <InlineCountCard
            title="EM Leads"
            count={Object.keys(emLeads).length}
            path="/dashboards/emleads"
          />
        </Grid>
        <Grid item xs={2} style={{ textAlign: "center" }}>
          <InlineCountCard
            title="Leads"
            count={leads.length}
            path="/dashboards/leads"
          />
        </Grid>
        <Grid item xs={2} style={{ textAlign: "center" }}>
          <InlineCountCard
            title="Regions"
            count={Object.values(regions).length}
            path="/dashboards/regions"
          />{" "}
        </Grid>
        <Grid item xs={2} style={{ textAlign: "center" }}>
          <InlineCountCard
            title="Skills"
            count={skills.length}
            path="/dashboards/skills"
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
        className={classes.root}
      >
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item xs={9}>
              <Grid container spacing={2}>
                <Grid item xs={6} style={{ minWidth: 400 }}>
                  <BurnedBudgetCard
                    year={period.year}
                    month={period.month}
                    customers={customers.items}
                  />
                </Grid>
                <Grid item xs={6} style={{ minWidth: 400 }}>
                  <UtilizationRatioCard
                    year={period.year}
                    month={period.month}
                    customers={customers.items || {}}
                    engineers={engineers || {}}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AllCustomersBurnedBudgetScatterChartCard
                    year={period.year}
                    month={period.month}
                    linkPrefix="/dashboards/customers/items"
                  />
                </Grid>

                <Grid item xs={6}>
                  <HarvestBudgetTrend period={previousPeriod} height={200} />
                </Grid>

                <Grid item xs={6}>
                  <UtilizationTrendsCard period={period} height={200} />
                </Grid>

                <Grid item xs={6} style={{ minWidth: 400 }}>
                  <CustomersTrendsCard period={period} height={200} />
                </Grid>

                <Grid item xs={6} style={{ minWidth: 400 }}>
                  <EngineersTrendsCard period={period} height={200} />
                </Grid>

                <Grid item xs={6} style={{ minWidth: 400 }}>
                  <CapacityByRoleCard period={period} />
                </Grid>

                <Grid item xs={6} style={{ minWidth: 400 }}>
                  <CapacityByRegionCard
                    year={period.year}
                    month={period.month}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SynchNotificationsCard />
                  <TeamsNotificationsCard
                    year={period.year}
                    month={period.month}
                    teams={teams}
                  />
                  <CustomersNotificationsCard
                    year={period.year}
                    month={period.month}
                  />
                  <EngineersNotificationsCard
                    year={period.year}
                    month={period.month}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
