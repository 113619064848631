import { FormControl, FormHelperText, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Controller, UseFormMethods } from "react-hook-form";
import useProductTypes from "../teams/useProductTypes";

export function ProductTypeChips({
  name,
  defaultValue,
  form,
  onChange,
}: {
  name: string;
  defaultValue?: string[];
  form: UseFormMethods<Record<string, any>>;
  onChange?: (value: string[]) => void;
}) {
  const all = useProductTypes();

  return (
    <Controller
      name={name}
      control={form.control}
      defaultValue={defaultValue || []}
      render={(field) => (
        <FormControl fullWidth>
          <Autocomplete
            multiple
            options={all}
            freeSolo
            value={field.value || ""}
            onChange={(_, value: string[]) => {
              form.setValue(name, value);
              if (onChange) {
                onChange(value || []);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} variant="standard" label="Product types" />
            )}
          />
          <FormHelperText>{form.errors[name]?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
