import { Breadcrumbs, Grid } from "@material-ui/core";
import { DocsDrawer } from "docs/DocsDrawer";
import { useAllEMs } from "ems/useAllEMs";
import { useEffect, useState } from "react";
import { Link, useLocation, useParams, useRouteMatch } from "react-router-dom";
import useRegions from "regions/useRegions";
import useAllCustomers from "../customers/useAllCustomers";
import { useAllEngineers } from "../engineers/useAllEngineers";
import { useAllTeams } from "../teams/useAllTeams";
import { DashboardPeriodNavigator } from "./DashboardPeriodNavigator";

class PathIndex {
  manager = -1;
  em = -1;
  emlead = -1;
  team = -1;
  customer = -1;
  engineer = -1;
  region = -1;
  get(segment: string): number {
    const x: any = this;
    return x[segment] || -1;
  }
}

interface DashboardBreadcrumbAlias {
  [value: string]: string;
}

interface DashboardBreadcrumbItem {
  to: string;
  label: string;
}

function capitalize(word: string) {
  if (word.length < 2) return word;
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

const EMPTY_EXCLUDED: string[] = [];

const ALIAS: DashboardBreadcrumbAlias = {
  dashboards: "Overview",
  emleads: "EM leads",
};
const EXCLUDED = ["items"];

export function DashboardBreadcrumbs({
  excluded = EMPTY_EXCLUDED,
  docs,
}: {
  excluded?: string[];
  docs?: string;
}) {
  const route = useRouteMatch();
  const location = useLocation();
  const params: { [key: string]: string } = useParams();
  const teams = useAllTeams();
  const customers = useAllCustomers();
  const engineers = useAllEngineers();
  const ems = useAllEMs();
  const regions = useRegions();
  const [items, setItems] = useState([] as DashboardBreadcrumbItem[]);

  useEffect(() => {
    const knownSegments = new Set(Object.keys(new PathIndex()));
    const pathIndex = route.path
      .split("/")
      .reduce((result: any, item, index) => {
        const segmentKey: string = item.startsWith(":")
          ? item.substring(1)
          : "";
        if (knownSegments.has(segmentKey)) {
          result[segmentKey] = index;
        }
        return result;
      }, new PathIndex());
    const allExcluded = new Set(EXCLUDED.concat(excluded));
    const allAlias = { ...ALIAS };
    const newItems = location.pathname
      .split("/")
      .map((item, index, array) => {
        const value = item ? `${item}` : "";
        const to = allExcluded.has(value)
          ? "#"
          : `/${array.slice(1, index + 1).join("/")}`;
        let label = allAlias[value] || capitalize(value);
        if (index === pathIndex.team) {
          label = teams[params.team]?.name || "";
        } else if (index === pathIndex.customer) {
          label = customers[params.customer]?.name || "";
        } else if (index === pathIndex.em) {
          const emId = params.em;
          label = emId == 'delta' ? "Delta" : String(ems[emId]?.getDisplayName() || "")
        } else if (index === pathIndex.emlead) {
          const emId = params.emlead;
          label = `${ems[emId]?.getDisplayName()}`;
        } else if (index === pathIndex.region) {
          const regionId = params.region;
          label = `${regions[regionId]?.name || " "}`;
        } else if (index === pathIndex.engineer) {
          const engineerId = params.engineer;
          label = `${engineers[engineerId]?.firstName || " "} ${
            engineers[engineerId]?.lastName || " "
          }`;
        }
        return { to, label };
      })
      .filter((i, index) => index > 0);
    setItems(newItems);
    // FIXME: too many executions
  }, [
    route,
    location,
    params,
    teams,
    customers,
    engineers,
    ems,
    regions,
    excluded,
  ]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <Breadcrumbs>
          {items.map((item, index) => (
            <Link
              key={`${index}`}
              to={item.to}
              style={
                item.to === "#"
                  ? {
                      textDecoration: "none",
                      color: "inherit",
                      cursor: "inherit",
                    }
                  : { color: "#3f51b5" }
              }
            >
              {item.label}
            </Link>
          ))}
        </Breadcrumbs>
      </Grid>
      <Grid item>
        <DashboardPeriodNavigator />
        {docs ? <DocsDrawer path={docs} /> : null}
      </Grid>
    </Grid>
  );
}
