import { Box } from "@material-ui/core";
import { SystemNotificationsAccordion } from "notifications/SystemNotificationsAccordion";
import { SystemNotificationType } from "notifications/SystemNotificationTypes";
import { useFilteredNotifications } from "notifications/useFilteredNotifications";
import { useCustomerBudgetOutOfSynchNotifications } from "./useCustomerBudgetOutOfSynchNotifications";
import { useCustomerOverBudgetNotifications } from "./useCustomerOverBudgetNotifications";
import { useCustomersOffboardedNotifications } from "./useCustomersOffboardedNotifications";
import { useCustomersOnboardedNotifications } from "./useCustomersOnboardedNotifications";
import { useCustomersWithNoEMNotifications } from "./useCustomerWithNoEMNotifications";
import { useCustomersWithNoTeamNotifications } from "./useCustomerWithNoTeamNotifications";

const EMPTY: string[] = [];

export function CustomersNotificationsCard({
  year,
  month,
  customerIds = EMPTY,
}: {
  year: number;
  month: number;
  customerIds?: string[];
}) {
  const noEm = useCustomersWithNoEMNotifications(year, month);
  const filteredNoEm = useFilteredNotifications(noEm, customerIds);
  const noTeam = useCustomersWithNoTeamNotifications(year, month);
  const overBudget = useCustomerOverBudgetNotifications(year, month);
  const filteredOverBudget = useFilteredNotifications(overBudget, customerIds);
  const onboarded = useCustomersOnboardedNotifications(year, month);
  const filteredOnboarded = useFilteredNotifications(onboarded, customerIds);
  const offboarded = useCustomersOffboardedNotifications(year, month);
  const filteredOffboarded = useFilteredNotifications(offboarded, customerIds);
  const budgetOutOfSynch = useCustomerBudgetOutOfSynchNotifications(
    year,
    month
  );
  const filteredBudgetOutOfSynch = useFilteredNotifications(
    budgetOutOfSynch,
    customerIds
  );
  return (
    <Box>
      <SystemNotificationsAccordion
        title={`customers with no team: ${noTeam.length}`}
        notifications={noTeam}
        type={SystemNotificationType.ERROR}
      />
      <SystemNotificationsAccordion
        title={`customers with no EM: ${filteredNoEm.length}`}
        notifications={filteredNoEm}
        type={SystemNotificationType.ERROR}
      />
      <SystemNotificationsAccordion
        title={`budget out of sync: ${filteredBudgetOutOfSynch.length}`}
        notifications={filteredBudgetOutOfSynch}
        type={SystemNotificationType.ERROR}
      />
      <SystemNotificationsAccordion
        title={`customers overbudget: ${filteredOverBudget.length}`}
        notifications={filteredOverBudget}
        type={SystemNotificationType.WARNING}
      />
      <SystemNotificationsAccordion
        title={`customers onboarded: ${filteredOnboarded.length}`}
        notifications={filteredOnboarded}
        type={SystemNotificationType.INFO}
      />
      <SystemNotificationsAccordion
        title={`customers offboarded: ${filteredOffboarded.length}`}
        notifications={filteredOffboarded}
        type={SystemNotificationType.INFO}
      />
    </Box>
  );
}
