import { useTheme } from "@material-ui/core";
import { Chart } from "chart.js";
import { createRef, useEffect, useState } from "react";
import { DashboardCard } from "../../components/DashboardCard";
import { useCustomerSkillsInTeam } from "../../customers/useCustomerSkillsInTeam";
import { useEngineerSkillsInTeam } from "../../engineers/useEngineerSkillsInTeam";
import { getColors } from "../../utils/colorGenerator";

export function TeamTopSkillsCard({
  year,
  month,
  teamId,
}: {
  year: number;
  month: number;
  teamId: string;
}) {
  const chartRef = createRef<HTMLCanvasElement>();
  const [chart, setChart] = useState(null as null | Chart);
  const theme = useTheme();
  const customerSkills = useCustomerSkillsInTeam(year, month, teamId);
  const engineerSkills = useEngineerSkillsInTeam(year, month, teamId);

  useEffect(() => {
    if (chart) {
      return;
    }
    const ref = chartRef?.current as HTMLCanvasElement;
    const newChart = new Chart(ref, {
      type: "bar",
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "bottom",
          },
        },
      },
    });
    setChart(newChart);
  }, []);

  useEffect(() => {
    if (!chart) {
      return;
    }
    const topCustomerSkills = customerSkills.getSlice(5);
    const topEngineerSkills = engineerSkills.getSlice(5);
    const allSkills = Array.from(
      new Set(
        ([] as string[]).concat(
          topCustomerSkills.map((i) => i.name),
          topEngineerSkills.map((i) => i.name)
        )
      )
    );
    chart.data.labels = allSkills;
    const backgroundColours = getColors(theme, 2);
    chart.data.datasets = [
      {
        label: "Customer",
        data: allSkills.map((i) => customerSkills.getCount(i)),
        borderColor: backgroundColours[0],
        backgroundColor: backgroundColours[0],
      },
      {
        label: "Engineer",
        data: allSkills.map((i) => engineerSkills.getCount(i)),
        borderColor: backgroundColours[1],
        backgroundColor: backgroundColours[1],
      },
    ];
    chart.update();
  }, [year, month, theme, chart, customerSkills, engineerSkills]);

  return (
    <DashboardCard title="Top skills" height={300}>
      {{ body: <canvas ref={chartRef} /> }}
    </DashboardCard>
  );
}
