import { CustomerWarningFlag } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import useAllCustomers from "./useAllCustomers";
import useOffboardedCustomers from "./useOffboardedCustomers";

// FIXME use reselect
export function useCustomerWarningFlags(year: number, month: number) {
  const customers = useAllCustomers();
  const offboarded = useOffboardedCustomers();
  const [flags, setFlags] = useState(
    {} as { [customerId: string]: Set<CustomerWarningFlag> }
  );

  useEffect(() => {
    // checks that it's offboarded at the end of the month
    const offboardDate = new Date(year, month + 1);
    const newFlags = Object.values(customers).reduce((all, c) => {
      const warnings = c.getWarnings(year, month);
      const flags = new Set(warnings.values());
      if (!!offboarded[c.id] && !c.isOffboarded(offboardDate)) {
        flags.add(CustomerWarningFlag.OFFBOARDED_IN_JIRA);
      }
      all[c.id] = flags;
      return all;
    }, {} as { [customerId: string]: Set<CustomerWarningFlag> });
    setFlags(newFlags);
  }, [year, month, customers, offboarded]);

  return flags;
}
