import processEnv, { Environment } from "../app/Environment";
import fetchService from "../utils/FetchService";

export interface S3DataFile {
  key: string;
  signedUrl: string;
}

export class DataService {

  constructor(private env: Environment = processEnv) { }

  async getDataFile(): Promise<S3DataFile> {
    return fetchService
      .fetch(`${this.env.endpoint}/data`, { method: "POST" })
      .then((r) => r.json())
      .then(r => r as S3DataFile);
  }
}

const data = new DataService();
export default data;