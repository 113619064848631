import { useEffect, useState } from "react";
import { CustomerHandle } from "msd-capacity-planning-model";
import useAllCustomers from "./useAllCustomers";

export default function useCustomer(
  customerId: string
): CustomerHandle | undefined {
  const customers = useAllCustomers();
  const [customer, setCustomer] = useState(
    undefined as CustomerHandle | undefined
  );
  useEffect(() => {
    setCustomer(customers[customerId]);
  }, [customerId, customers]);
  return customer;
}
