import { useJiraProjects } from "jira/useJiraProjects";
import { CustomerHandle } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import useAllCustomers from "./useAllCustomers";

// customers that are active in this system and are offboarded in Jira
export default function useOffboardedCustomers(): {
  [id: string]: CustomerHandle;
} {
  const customers = useAllCustomers();
  const jiraProjects = useJiraProjects();
  const [offboarded, setOffboarded] = useState(
    {} as { [id: string]: CustomerHandle }
  );

  useEffect(() => {
    const offboardedKeys = new Set(
      jiraProjects
        .filter((p) => (p.name || "").toLocaleLowerCase().startsWith("z-"))
        .map((p) => p.key)
    );
    const newOffboarded = Object.values(customers).reduce((all, c) => {
      if (offboardedKeys.has(c.jiraProjectKey)) {
        all[c.id] = c;
      }
      return all;
    }, {} as { [id: string]: CustomerHandle });

    setOffboarded(newOffboarded);
  }, [customers, jiraProjects]);

  return offboarded;
}
