import { useEffect, useState } from 'react'
import { JiraBoardAdministratorDTO } from './JiraBoardAdministratorDTO'
import service from './JiraResourcesService'

export function useJiraBoardAdmininstrator(
  projectKey: string,
  reloadCounter: number
): JiraBoardAdministratorDTO | null | undefined {
  const [administrator, setAdministrator] = useState(
    undefined as JiraBoardAdministratorDTO | null | undefined
  )

  useEffect(() => {
    setAdministrator(undefined)
    service
      .getBoardAdministrator(projectKey)
      .then((response) => setAdministrator(response))
      .catch(() => setAdministrator(null))
  }, [projectKey, reloadCounter])

  return administrator
}
