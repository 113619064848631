import { useBurnedBudgetByAggregator } from "customers/useBurnedBudgetByAggregator";
import { CustomerAggregatorType, Team } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { LabeledScatterDataPoint } from "../../components/LabeledScatterDataPoint";
import { ScatterChartCard } from "../../components/ScatterChartCard";
import getCompletedMonthRatio from "../../utils/getCompletedMonthRatio";

export function BurnedBudgetByTeamScatterChartCard({
  year,
  month,
  teams,
  title,
  linkPrefix,
}: {
  year: number;
  month: number;
  teams: { [teamId: string]: Team };
  title?: string;
  linkPrefix?: string;
}) {
  const budgets = useBurnedBudgetByAggregator(
    year,
    month,
    CustomerAggregatorType.TEAM
  );
  const [xAnnotation, setXAnnotation] = useState(0);
  const [coordinates, setCoordinates] = useState(
    [] as LabeledScatterDataPoint[]
  );

  useEffect(() => {
    const newXAnnotation = getCompletedMonthRatio(year, month) * 100;
    setXAnnotation(newXAnnotation);
  }, [year, month]);

  useEffect(() => {
    const newCoordinates = Object.values(teams)
      .map((team) => {
        const consumed = budgets[team.id]?.consumedInBudget || 0;
        const total = budgets[team.id]?.total || 0;
        return {
          label: team.name,
          x: total,
          y: (consumed / total) * 100,
          link: linkPrefix ? `${linkPrefix}/${team.id}` : undefined,
        };
      })
      .filter((coordinate) => coordinate.x > 0);
    setCoordinates(newCoordinates);
  }, [linkPrefix, teams, budgets]);

  return (
    <ScatterChartCard
      dataPoints={coordinates}
      title={title || "Burned budget"}
      xScaleTitle="Budget (hours)"
      yScaleTitle="In budget hours/ total budget (%)"
      tooltipSuffix="%"
      xAnnotation={xAnnotation}
      chartHeight={300}
    ></ScatterChartCard>
  );
}
