import { useEffect, useState } from "react";
import { DashboardPeriod } from "./DashboardPeriod";

export function useTrendPeriods(
  period: DashboardPeriod,
  from = -1,
  to = 1
): DashboardPeriod[] {
  const [periods, setPeriods] = useState([] as DashboardPeriod[]);

  useEffect(() => {
    const limitedFrom = Math.max(Math.min(0, from), -5);
    const limitedTo = Math.min(Math.max(0, to), 5);
    const newPeriods = [];
    for (let delta = limitedFrom; delta <= limitedTo; delta++) {
      newPeriods.push({ year: period.year, month: period.month + delta });
    }
    setPeriods(newPeriods);
  }, [period, from, to]);

  return periods;
}
