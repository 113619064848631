import {
  Box,
  CircularProgress
} from "@material-ui/core";
import { Error } from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import { Alert } from "@material-ui/lab";
import React, { Component, ErrorInfo, Fragment, ReactNode } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AppState } from "redux/AppTypes";
import env from "../app/Environment";
import fetchService from "../utils/FetchService";

interface Props extends RouteComponentProps {
  children?: ReactNode;
}

interface State {
  persisted?: boolean;
  error?: {
    user: string;
    pathname?: string;
    errorInfo: ErrorInfo;
  };
}

class ErrorBoundaryComponent extends Component<Props, State> {
  public state: State = {};

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const props = this.props as any;
    const body = {
      user: props.user,
      pathname: `${props.location.pathname}`,
      errorInfo,
      error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
    };
    this.setState({
      error: body,
    });
    fetchService
      .fetch(`${env.endpoint}/errors`, {
        method: "POST",
        body: JSON.stringify(body),
      })
      .then(() => {
        this.setState({
          ...this.state,
          persisted: true,
        });
      })
      .catch(() => {
        this.setState({
          ...this.state,
          persisted: false,
        });
      });
  }

  public render() {
    if (this.state.error) {
      return (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box style={{ width: 400, margin: 32, textAlign: "center" }}>
            <Alert severity="error">
              <Box>
                Sorry.. there was an error. <br /> We are saving the details to
                resolve it ASAP
              </Box>
              <Box style={{ marginTop: 8 }}>
                {typeof this.state.persisted === "undefined" ? (
                  <CircularProgress
                    variant="indeterminate"
                    size={16}
                    style={{ marginLeft: 8 }}
                  />
                ) : null}
                {this.state.persisted === true ? (
                  <Fragment>
                    <Check style={{ color: "green", marginRight: 8 }} /> Saved
                  </Fragment>
                ) : null}
                {this.state.persisted === false ? (
                  <Fragment>
                    <Error style={{ color: "red", marginRight: 8 }} /> Failed to
                    save
                  </Fragment>
                ) : null}
              </Box>
            </Alert>
          </Box>
        </Box>
      );
    } else {
      return this.props.children;
    }
  }
}

function mapStateToProps(state: AppState, ownProps: State): any {
  return { user: state?.authorization?.email };
}

export const ErrorBoundary = withRouter(
  connect(mapStateToProps)(ErrorBoundaryComponent)
);
