import { HarvestProject, HarvestUser } from "msd-capacity-planning-model";
import env from "../app/Environment";
import fetchService from "../utils/FetchService";
import { HarvestBudget, HarvestTeamTimeReport } from "./HarvestTypes";

interface ReportTimeProjectEntry {
  project: number;
  hours: number;
  billableHours: number;
  budgetedHours: number;
}

interface ReportUserTimeEntry {
  id: string;
  email: string;
  totalHours: number;
}

export class HarvestService {
  private cache: Promise<HarvestProject[]> | undefined = undefined;

  constructor(public endpoint: string) {}

  fetchProjects(): Promise<HarvestProject[]> {
    if (!this.cache) {
      this.cache = fetchService
        .fetch(`${this.endpoint}/harvest/projects`, {})
        .then((r) => r.json())
        .catch(() => []);
    }
    return this.cache;
  }

  fetchUsers(): Promise<HarvestUser[]> {
    return fetchService
      .fetch(`${this.endpoint}/harvest/users`, {})
      .then((r) => r.json());
  }

  private fetchReportTimeProjects(
    year: number,
    month: number
  ): Promise<ReportTimeProjectEntry[]> {
    return fetchService
      .fetch(
        `${this.endpoint}/harvest/reports/time/projects?year=${year}&month=${month}`
      )
      .then((r) => r.json())
      .catch(() => []);
  }

  private fetchReportTeamTime(
    year: number,
    month: number
  ): Promise<ReportUserTimeEntry[]> {
    return fetchService
      .fetch(
        `${this.endpoint}/harvest/reports/time/teams?year=${year}&month=${month}`
      )
      .then((r) => r.json());
  }

  async fetchBudget(year: number, month: number): Promise<HarvestBudget> {
    const response = Promise.all([
      this.fetchProjects(),
      this.fetchReportTimeProjects(year, month),
    ]).then(([projects, report]) => {
      const projectsMap = projects.reduce((map, p) => {
        const key = `${p.project}`;
        map.set(key, p);
        return map;
      }, new Map<string, HarvestProject>());

      return report.reduce((budgets, r) => {
        const key = `${r.project}`;
        const project = projectsMap.get(key);
        budgets.items[key] = {
          harvestProjectId: key,
          billable: r.billableHours,
          consumed: r.hours || 0,
          total: project?.budget || 0,
        };
        return budgets;
      }, new HarvestBudget());
    });
    return response;
  }

  async fetchTeamHours(
    year: number,
    month: number
  ): Promise<HarvestTeamTimeReport> {
    const response = this.fetchReportTeamTime(year, month).then(
      (entries: ReportUserTimeEntry[]) => {
        return entries.reduce((all, entry) => {
          const email = (entry.email || "").toLowerCase();
          all[email] = {
            id: entry.id,
            email,
            hours: entry.totalHours,
          };
          return all;
        }, {} as HarvestTeamTimeReport);
      }
    );
    return response;
  }
}

const service = new HarvestService(env.endpoint);

export default service;
