import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AppState } from "../redux/AppTypes";
import { CustomerHandle } from "msd-capacity-planning-model";

const stateSelector = (state: AppState) => state.customers.items;

export const useAllCustomersSelector = createSelector(stateSelector, (items) => {
  const values = Object.values(items) || [];
  return values.reduce((map, e) => {
    map[e.id] = new CustomerHandle(e);
    return map;
  }, {} as { [id: string]: CustomerHandle });
});

export default function useAllCustomers(): { [id: string]: CustomerHandle } {
  const state = useSelector((state: AppState) => state);
  return useAllCustomersSelector(state);
}
