import React from "react";
import { Switch, Route } from "react-router-dom";
import { TeamsPanel } from "./TeamsPanel";
import { TeamForm } from "./TeamForm";

export function TeamsPage() {
  return (
    <Switch>
      <Route path="/administration/teams/:id" strict={true}>
        <TeamForm />
      </Route>
      <Route path="/administration/teams/" strict={true}>
        <TeamForm />
      </Route>
      <Route path="/administration/teams">
        <TeamsPanel />
      </Route>
    </Switch>
  );
}
