import { Box, IconButton, Typography } from "@material-ui/core";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";
import { useEffect, useState } from "react";

export function MonthNavigator({
  year,
  month,
  onChange = (year, month) => {},
}: {
  year: number;
  month: number;
  onChange?: (year: number, month: number) => void;
}) {
  const [selectedYear, setSelectedYear] = useState(year);
  const [selectedMonth, setSelectedMonth] = useState(month);
  // flag to prevent external updates once a change occured
  const [changed, setChanged] = useState(false);

  function updateMonth(newYear: number, newMonth: number): void {
    setChanged(true);
    const newDate = new Date(newYear, newMonth);
    setSelectedYear(newYear);
    setSelectedMonth(newMonth);
    onChange(newDate.getFullYear(), newDate.getMonth());
  }

  useEffect(() => {
    if (!changed) {
      setSelectedYear(year);
      setSelectedMonth(month);
    }
  }, [changed, year, month]);

  return (
    <Box style={{ display: "inline" }}>
      <IconButton onClick={() => updateMonth(selectedYear, selectedMonth - 1)}>
        <ArrowLeft />
      </IconButton>
      <Typography variant="caption">
        {new Date(selectedYear, selectedMonth).toLocaleString("default", {
          month: "long",
          year: "numeric",
        })}
      </Typography>
      <IconButton onClick={() => updateMonth(selectedYear, selectedMonth + 1)}>
        <ArrowRight />
      </IconButton>
    </Box>
  );
}
