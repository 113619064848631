import { Box } from "@material-ui/core";
import useCustomersInAggregator from "customers/useCustomersInAggregator";
import { CustomerAggregatorType } from "msd-capacity-planning-model";
import React from "react";
import { CustomersTable } from "../customers/CustomersTable";

export function TeamBudgetTable({
  year,
  month,
  teamId,
  linkPrefix = "",
}: {
  year: number;
  month: number;
  teamId: string;
  linkPrefix?: string;
}) {
  const customers = useCustomersInAggregator(
    year,
    month,
    CustomerAggregatorType.TEAM,
    teamId
  );
  return (
    <Box>
      <CustomersTable
        year={year}
        month={month}
        customers={customers}
        linkPrefix={`${linkPrefix}`}
      />
    </Box>
  );
}
