import { Box, Grid } from "@material-ui/core";
import { useBurnedBudget } from "customers/useBurnedBudget";
import { DashboardPeriod } from "dashboards/DashboardPeriod";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { CountCard } from "../../components/CountCard";
import { CustomerFilter } from "../../components/CustomerFilter";
import useBudget from "../../customers/useBudget";
import useCustomers, {
  AggregatedCustomers
} from "../../customers/useCustomers";
import { CustomersNotificationsCard } from "../../notifications/customer/CustomersNotificationCard";
import { DashboardBreadcrumbs } from "../DashboardBreadcrumbs";
import {
  BurnedBudgetScatterChartCard
} from "../utilization/BurnedBudgetByCustomerScatterChartCard";
import { BurnedBudgetCard } from "../utilization/BurnedBudgetCard";
import { CustomersTable } from "./CustomersTable";

export function CustomersDashboard({ period }: { period: DashboardPeriod }) {
  const location = useLocation();
  const customers = useCustomers(period.year, period.month);

  const [filteredCustomers, setFilteredCustomers] = useState(
    new AggregatedCustomers()
  );
  const onCustomersChange = useCallback((customers: AggregatedCustomers) => {
    setFilteredCustomers(customers);
  }, []);
  const budget = useBudget(period.year, period.month, filteredCustomers.items);
  const burnedBudget = useBurnedBudget(period.year, period.month, filteredCustomers.items);

  return (
    <Box>
      <DashboardBreadcrumbs />
      <CustomerFilter
        year={period.year}
        month={period.month}
        customers={customers}
        onChange={onCustomersChange}
      />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item xs={9}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <BurnedBudgetScatterChartCard
                year={period.year}
                month={period.month}
                burnedBudget={burnedBudget}
                linkPrefix={`${location.pathname}/items`}
              />
            </Grid>

            <Grid item xs={12} style={{ minWidth: 400 }}>
              <CustomersTable
                year={period.year}
                month={period.month}
                customers={filteredCustomers.items}
                emColumn={false}
                inBudgetColumn={true}
                overBudgetColumn={true}
                budgetColumn={true}
                linkPrefix={`/items`}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <BurnedBudgetCard
                year={period.year}
                month={period.month}
                customers={filteredCustomers.items}
              />
            </Grid>
            <Grid item xs={6}>
              <CountCard
                title="Customers"
                count={filteredCustomers.getAll().length}
                path="/dashboards/customers"
              />
            </Grid>
            <Grid item xs={6}>
              <CountCard
                title="Budget"
                count={Math.round(
                  (budget.opsHours || 0) + (budget.teamHours || 0)
                )}
                path="/dashboards/customers"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomersNotificationsCard
                year={period.year}
                month={period.month}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
