import { Box } from "@material-ui/core";
import { DashboardBreadcrumbs } from "dashboards/DashboardBreadcrumbs";
import { useDashboardPeriod } from "dashboards/useDashboardPeriod";
import { useParams } from "react-router-dom";
import { TeamRetrospectiveDashboard } from "./TeamRetrospectiveDashboard";

export function TeamRetrospectiveDashboardPage() {
  const { year, month } = useDashboardPeriod();
  const params: { [key: string]: string } = useParams();

  return (
    <Box>
      <DashboardBreadcrumbs />
      <TeamRetrospectiveDashboard
        teamId={params.team}
        year={year}
        month={month}
      />
    </Box>
  );
}
