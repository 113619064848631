import {
  DO_FETCH_HARVEST_BUDGET,
  DO_FETCH_HARVEST_USERS,
  HarvestAction,
  HarvestState,
  LOAD_HARVEST_BUDGET,
  LOAD_HARVEST_PROJECTS,
  LOAD_HARVEST_USERS
} from "./HarvestTypes";

const initialState: HarvestState = {
  synching: {},
  projects: [],
  users: {
    synching: false,
    items: [],
  },
  budgets: {},
};

export default function harvestReducer(
  state = initialState,
  action: HarvestAction
): HarvestState {
  switch (action.type) {
    case LOAD_HARVEST_PROJECTS: {
      return {
        ...state,
        projects: action.projects,
      };
    }
    case DO_FETCH_HARVEST_USERS: {
      return {
        ...state,
        users: {
          synching: true,
          items: state.users.items,
        },
      };
    }
    case LOAD_HARVEST_USERS: {
      return {
        ...state,
        users: {
          synching: false,
          items: action.items,
        },
      };
    }
    case DO_FETCH_HARVEST_BUDGET: {
      const currentValue = state.synching[`${action.year}-${action.month}`];
      if (currentValue !== true) {
        const newState = { ...state };
        newState.synching[`${action.year}-${action.month}`] = true;
        return newState;
      } else {
        return state;
      }
    }
    case LOAD_HARVEST_BUDGET: {
      const newState = { ...state };
      delete newState.synching[`${action.year}-${action.month}`];
      // update only if budget changed
      if (newState?.budgets?.[action.year]?.[action.month] !== action.budget) {
        newState.budgets = { ...state.budgets };
        if (newState.budgets[`${action.year}`]) {
          const year = newState.budgets[`${action.year}`];
          newState.budgets[`${action.year}`] = { ...year };
        } else {
          newState.budgets[action.year] = {};
        }
        newState.budgets[action.year][action.month] = action.budget;
      }
      return newState;
    }
    default:
      return state;
  }
}
