import { useTheme } from "@material-ui/core";
import useEngineersCapacity from "capacity/useEngineersCapacity";
import { PieChartCard } from "components/PieChartCard";
import {
  AggregatedCapacity,
  AggregatedCapacityItem,
  EngineerHandle
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { getColors } from "utils/colorGenerator";

export function EngineersByRoleCard({
  year,
  month,
  engineers,
}: {
  year: number;
  month: number;
  engineers: { [engineerId: string]: EngineerHandle };
}) {
  const theme = useTheme();
  const capacities = useEngineersCapacity(year, month, engineers);
  const [data, setData] = useState({
    datasets: [],
  } as any);

  useEffect(() => {
    const capacityByRole = capacities.getItems().reduce((map, item) => {
      const name = item.engineer.role;
      if (!map.has(name)) {
        map.set(name, new AggregatedCapacity<AggregatedCapacityItem>());
      }
      map.get(name)!.add(item);
      return map;
    }, new Map<string, AggregatedCapacity<AggregatedCapacityItem>>());
    const backgroundColor = getColors(theme, capacityByRole.size);
    const data = Array.from(capacityByRole.values()).map(
      (c) => c.allocatedHours
    );
    const newData = {
      labels: Array.from(capacityByRole.keys()).map(
        (region) =>
          region +
          " (" +
          capacityByRole.get(region)?.allocatedHours.toLocaleString() +
          ")"
      ),
      datasets: [{ data, backgroundColor }],
    };
    setData(newData);
  }, [theme, capacities]);

  return <PieChartCard title="Capacity by role" data={data} />;
}
