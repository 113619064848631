import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { NoStyleLink } from "components/NoStyleLink";
import { withStyles } from "@material-ui/styles";
import { RegionSelect } from "components/RegionSelect";
import { TeamsTable } from "dashboards/teams/TeamsTable";
import { CustomerAggregatorType, EMHandle } from "msd-capacity-planning-model";
import { useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import useRegions from "regions/useRegions";
import { useBudgetByAggregator } from "../../customers/useBudgetByAggregator";
import { EMLeadCustomersDashboardPage } from "dashboards/emlead/EMLeadCustomersDashboardPage";

const NumberCell = withStyles((theme) => ({
  root: {
    textAlign: "right",
  },
}))((props) => <TableCell {...props} />);

export function EMsTable({
  year,
  month,
  ems,
  linkPrefix,
}: {
  year: number;
  month: number;
  ems: EMHandle[];
  linkPrefix?: string;
}) {
  const location = useLocation();
  const budgets = useBudgetByAggregator(year, month, CustomerAggregatorType.EM);
  const projectedBudgets = useBudgetByAggregator(
    year,
    month + 1,
    CustomerAggregatorType.EM
  );
  const regions = useRegions();
  const form = useForm();
  const [regionId, setRegionId] = useState("");
  const [filteredEMs, setFilteredEMs] = useState([] as EMHandle[]);

  useEffect(() => {
    const newEMs = ems.filter((em) => !regionId || em.region === regionId);
    setFilteredEMs(newEMs);
  }, [ems, regionId]);

  // we set all the filter options separately for each column and do it in a memo so we don't recalculate this constantly...
  const emNames = useMemo(() => Object.values(ems).map(em =>  em.firstName + " " + em.lastName), [ems]);
  const capableHoursItems = useMemo(() => Array.from(new Set(Object.values(ems).map(em => {
    const capacity = em.getCapacityHandle().getCapacity(year,month,regions[em.region])
    return Math.round(capacity.countWorkingHours())
  }))), [ems]);
  const assignedHoursItems = useMemo(() => Array.from(new Set(Object.values(ems).map(em => Math.round(budgets[em.id]?.emHours) || 0 ))), [ems, budgets]);
  const availableHoursItems = useMemo(() => Array.from(new Set(Object.values(ems).map(em => {
    const capacity = em.getCapacityHandle().getCapacity(year,month,regions[em.region])
    return Math.round(Math.max(0, Math.round(capacity.countWorkingHours()) - (Math.round(budgets[em.id]?.emHours) || 0)))
  }))), [ems, budgets, regions]);
  const activeCustomersItems = useMemo(() => Array.from(new Set(Object.values(ems).map(em => Object.keys(budgets[em.id]?.items || {}).length))), [ems, budgets])
  const contractHoursItems = useMemo(() => Array.from(new Set(Object.values(ems).map(em => Math.round(budgets[em.id]?.getContractHours() || 0)))), [ems, budgets])
  const weekdayPtoItems = useMemo(() => Array.from(new Set(Object.values(ems).map(em => {
    const capacity = em.getCapacityHandle().getCapacity(year,month,regions[em.region])
    return capacity.countWeekDayTimeOff()
  }))), [ems, regions]);
  const workingDaysItems = useMemo(() => Array.from(new Set(Object.values(ems).map(em => {
    const capacity = em.getCapacityHandle().getCapacity(year,month,regions[em.region])
    return capacity.countWorkingDays()
  }))), [ems, regions]);
  const utilizationItems = useMemo(() => Array.from(new Set(Object.values(ems).map(em => {
    const capacity = em.getCapacityHandle().getCapacity(year,month,regions[em.region])
    const textString = capacity.periods.map((period: { partialCapacity: any; }, idx2: any) => {
      const p = period.partialCapacity;
      const from = new Date(
        p.year || 0,
        p.month || 0,
        p.day || 1
      );
      return (p.percent || 0) +  "%"
    }
    )
    if (textString.length > 0 ){
      return textString[0]
    } else {
      return "NULL"
    }
    }))), [ems, regions]);
  const projectedCapableHoursItems = useMemo(() => Array.from(new Set(Object.values(ems).map(em => {
    const capacity = em.getCapacityHandle().getCapacity(year,month+1,regions[em.region])
    return Math.round(capacity.countWorkingHours())
  }))), [ems]);
  const projectedAssignedHoursItems = useMemo(() => Array.from(new Set(Object.values(ems).map(em => Math.round(projectedBudgets[em.id]?.emHours || 0)))), [ems, projectedBudgets]);
  const projectedAvailableHoursItems = useMemo(() => Array.from(new Set(Object.values(ems).map(em => {
    const capacity = em.getCapacityHandle().getCapacity(year,month + 1 ,regions[em.region])
    return Math.round(Math.max(0, Math.round(capacity.countWorkingHours()) - (Math.round(projectedBudgets[em.id]?.emHours) || 0)))
  }))), [ems, projectedBudgets, regions]);

  const capableHours = (em: any) => {   
    const capacity = em
    .getCapacityHandle()
    .getCapacity(year, month, regions[em.region])
    const value = Math.round(capacity.countWorkingHours())
    return value
    

  }


  const columns: any = [ 
    {
      name: "EM",
      options: {
      filter: true,
      setCellProps: () => ({ style: { padding: 5 }}),
      setCellHeaderProps: () => ({ style: { padding: 5 }}),
      filterOptions:{
        names: emNames,
        logic(em: { firstName: string; lastName: string; }, filters: any) {
          let textString = em.firstName + " " + em.lastName;
          return !filters.includes(textString);} 

      },
      sortCompare: (order: string) => {
        return (obj1: { data: { compare: (arg0: any) => number; }; }, obj2: { data: any; }) => {
          return obj1.data.compare(obj2.data) * (order === 'asc' ? 1 : -1);
        };
      },
      customBodyRender: (em: EMHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
        return <NoStyleLink
               pathname={`${linkPrefix}/${em.id}${location.search}`}
               text={em.firstName+" "+em.lastName}
               />
      },      
    }
    },
    {
      name: "Capable hours",
      options: {
        filter: true,
        filterOptions:{
          names:  capableHoursItems,
          logic(em:any, filters: any) {
            // working around a bug where if capacity would be 0, em shows up as 0, unsure why
            if (typeof(em) != 'number') {
              let capacity = em?.getCapacityHandle().getCapacity(year,month,regions[em?.region]);
              let textString = Math.round(capacity.countWorkingHours());
              return !filters.includes(textString); 
            } else {
              let textString = 0;
              return !filters.includes(textString)
            }
          }
        },
        setCellProps: () => ({ style: { padding: 5 }}),
        setCellHeaderProps: () => ({ style: { padding: 5 }}),
        sortCompare: (order: string) => {
          return (obj1: any, obj2: any ) => {
            const capacity1 = obj1.data.getCapacityHandle().getCapacity(year,month,regions[obj1.data.region])
            const capacity2 = obj2.data.getCapacityHandle().getCapacity(year,month,regions[obj2.data.region])

            let val1 = Math.round(capacity1.countWorkingHours());
            let val2 = Math.round(capacity2.countWorkingHours());
            return (val1 - val2) * (order === 'desc' ? 1 : -1);
          };
      },
        customBodyRender: (em: EMHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
          return capableHours(em)
        }, 
      }
    },
    {
      name: "Assigned hours",
      options: {
        filter: true,
        filterOptions:{
          names:  assignedHoursItems,
          logic(em:any, filters: any) {
              let textString = Math.round(budgets[em.id]?.emHours) || 0;;
              return !filters.includes(textString); 
          }
        },
        setCellProps: () => ({ style: { padding: 5 }}),
        setCellHeaderProps: () => ({ style: { padding: 5 }}),
        sortCompare: (order: string) => {
          return (obj1: any, obj2: any ) => {
            let val1 = Math.round(budgets[obj1.data.id]?.emHours) || 0;
            let val2 = Math.round(budgets[obj2.data.id]?.emHours) || 0;;
            return (val1 - val2) * (order === 'desc' ? 1 : -1);
          };
      },
        customBodyRender: (em: EMHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
          return Math.round(budgets[em.id]?.emHours) || 0;
        }, 
      }
    },
    {
      name: "Available hours",
      options: {
        filter: true,
        filterOptions:{
          names:  availableHoursItems,
          logic(em:any, filters: any) {
            // working around a bug where if capacity would be 0, em shows up as 0, unsure why
            if (typeof(em) != 'number') {
              let capacity = em.getCapacityHandle().getCapacity(year,month,regions[em?.region]);
              let textString = Math.round(Math.max(0, Math.round(capacity.countWorkingHours()) - (Math.round(budgets[em.id]?.emHours) || 0)));
              return !filters.includes(textString); 
            } else {
              let textString = 0;
              return !filters.includes(textString)
            }
          }
        },
        setCellProps: () => ({ style: { padding: 5 }}),
        setCellHeaderProps: () => ({ style: { padding: 5 }}),
        sortCompare: (order: string) => {
          return (obj1: any, obj2: any ) => {
            let val1 = Math.round(Math.max(0, Math.round(obj1.data.getCapacityHandle().getCapacity(year,month,regions[obj1.data.region]).countWorkingHours()) - (Math.round(budgets[obj1.data.id]?.emHours) || 0)));
            let val2 = Math.round(Math.max(0, Math.round(obj2.data.getCapacityHandle().getCapacity(year,month,regions[obj2.data.region]).countWorkingHours()) - (Math.round(budgets[obj2.data.id]?.emHours) || 0)));
            return (val1 - val2) * (order === 'desc' ? 1 : -1);
          };
      },
        customBodyRender: (em: EMHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
          const capacity = em
                .getCapacityHandle()
                .getCapacity(year, month, regions[em.region]);
          return Math.round(Math.max(0, Math.round(capacity.countWorkingHours()) - (Math.round(budgets[em.id]?.emHours) || 0)));
        }, 
      }
    },
    {
      name: "Active customers",
      options: {
        filter: true,
        filterOptions:{
          names:  activeCustomersItems,
          logic(em:any, filters: any) {
              let textString = Object.keys(budgets[em.id]?.items || {}).length;
              return !filters.includes(textString); 
          }
        },
        setCellProps: () => ({ style: { padding: 5 }}),
        setCellHeaderProps: () => ({ style: { padding: 5 }}),
        sortCompare: (order: string) => {
          return (obj1: any, obj2: any ) => {
            let val1 = Object.keys(budgets[obj1.data.id]?.items || {}).length;
            let val2 = Object.keys(budgets[obj2.data.id]?.items || {}).length;
            return (val1 - val2) * (order === 'desc' ? 1 : -1);
          };
      },
        customBodyRender: (em: EMHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
          return Object.keys(budgets[em.id]?.items || {}).length;
        }, 
      }
    },
    {
      name: "Contract hours",
      options: {
        filter: true,
        filterOptions:{
          names:  contractHoursItems,
          logic(em:any, filters: any) {
              let textString = Math.round(budgets[em.id]?.getContractHours() || 0);
              return !filters.includes(textString); 
          }
        },
        setCellProps: () => ({ style: { padding: 5 }}),
        setCellHeaderProps: () => ({ style: { padding: 5 }}),
        sortCompare: (order: string) => {
          return (obj1: any, obj2: any ) => {
            let val1 = Math.round(budgets[obj1.data.id]?.getContractHours() || 0);
            let val2 = Math.round(budgets[obj2.data.id]?.getContractHours() || 0);
            return (val1 - val2) * (order === 'desc' ? 1 : -1);
          };
      },
        customBodyRender: (em: EMHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
          return Math.round(budgets[em.id]?.getContractHours() || 0);
        }, 
      }
    },
    {
      name: "Weekday PTO",
      options: {
        filter: true,
        filterOptions:{
          names:  weekdayPtoItems,
          logic(em:any, filters: any) {
            if (typeof(em) != 'number') {
              let capacity = em.getCapacityHandle().getCapacity(year,month,regions[em?.region]);
              let textString = capacity.countWeekDayTimeOff();
              return !filters.includes(textString); 
            } else {
              let textString = 0;
              return !filters.includes(textString) 
          }}
        },
        setCellProps: () => ({ style: { padding: 5 }}),
        setCellHeaderProps: () => ({ style: { padding: 5 }}),
        sortCompare: (order: string) => {
          return (obj1: any, obj2: any ) => {
            const capacity1 = obj1.data.getCapacityHandle().getCapacity(year,month,regions[obj1.data.region])
            const capacity2 = obj2.data.getCapacityHandle().getCapacity(year,month,regions[obj2.data.region])

            let val1 = capacity1.countWeekDayTimeOff();
            let val2 = capacity2.countWeekDayTimeOff();
            return (val1 - val2) * (order === 'desc' ? 1 : -1);
          };
      },
        customBodyRender: (em: EMHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
          const capacity = em
                .getCapacityHandle()
                .getCapacity(year, month, regions[em.region]);
          return capacity.countWeekDayTimeOff();
        }, 
      }
    },
    {
      name: "Working days",
      options: {
        filter: true,
        filterOptions:{
          names:  workingDaysItems,
          logic(em:any, filters: any) {
            if (typeof(em) != 'number') {
              let capacity = em.getCapacityHandle().getCapacity(year,month,regions[em?.region]);
              let textString = capacity.countWorkingDays();
              return !filters.includes(textString); 
            } else {
              let textString = 0;
              return !filters.includes(textString) 
          }}
        },
        setCellProps: () => ({ style: { padding: 5 }}),
        setCellHeaderProps: () => ({ style: { padding: 5 }}),
        sortCompare: (order: string) => {
          return (obj1: any, obj2: any ) => {
            const capacity1 = obj1.data.getCapacityHandle().getCapacity(year,month,regions[obj1.data.region])
            const capacity2 = obj2.data.getCapacityHandle().getCapacity(year,month,regions[obj2.data.region])

            let val1 = capacity1.countWorkingDays();
            let val2 = capacity2.countWorkingDays();
            return (val1 - val2) * (order === 'desc' ? 1 : -1);
          };
      },
        customBodyRender: (em: EMHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
          const capacity = em
                .getCapacityHandle()
                .getCapacity(year, month, regions[em.region]);
          return <Tooltip
          title={`${capacity.countWeekDayHolidays()} weekday holidays `}
        >
          <span>{capacity.countWorkingDays()}</span>
        </Tooltip>;
        }, 
      }
    },
    {
      name: "Utilization",
      options: {
        filter: true,
        filterOptions:{
          names:  utilizationItems,
          logic(em:any, filters: any) {
            if (typeof(em) != 'number') {
              let capacity = em.getCapacityHandle().getCapacity(year,month,regions[em?.region]);
              let textString = capacity.periods.map((period: { partialCapacity: any; }, idx2: any) => {
                const p = period.partialCapacity;
                const from = new Date(
                  p.year || 0,
                  p.month || 0,
                  p.day || 1
                );
                return (p.percent || 0) +  "%"
              });
              if (textString.length > 0) {
                return !filters.includes(textString[0]); 
              } else if (textString.length == 0) {
              if (!filters.includes("NULL")) {
                return true
              }
              else {
                return false
              }
            }
            
          }}
        },
        setCellProps: () => ({ style: { padding: 5 }}),
        setCellHeaderProps: () => ({ style: { padding: 5 }}),
        sortCompare: (order: string) => {
          return (obj1: any, obj2: any ) => {
            const capacity1 = obj1.data.getCapacityHandle().getCapacity(year,month,regions[obj1.data.region])
            const capacity2 = obj2.data.getCapacityHandle().getCapacity(year,month,regions[obj2.data.region])

            let val1 = capacity1.periods.map((period: { partialCapacity: any; }, idx2: any) => {
              const p = period.partialCapacity;
              return p.percent || 0
            })
            let val2 = capacity2.periods.map((period: { partialCapacity: any; }, idx2: any) => {
              const p = period.partialCapacity;
              return p.percent || 0
            })
            return (val1 - val2) * (order === 'desc' ? 1 : -1);
          };
      },
        customBodyRender: (em: EMHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
          const capacity = em
                .getCapacityHandle()
                .getCapacity(year, month, regions[em.region]);
          return capacity.periods.map((period, idx2) => {
            const p = period.partialCapacity;
            const from = new Date(
              p.year || 0,
              p.month || 0,
              p.day || 1
            );
            return (
              <Tooltip
                key={`tt${idx2}`}
                title={`from ` + from.toISOString().substring(0, 10)}
              >
                <span>
                  {p.percent || 0} %
                  <br />
                </span>
              </Tooltip>
            );
          });
        }, 
      }
    },
    {
      name: "Projected capable hours",
      options: {
        filter: true,
        filterOptions:{
          names:  projectedCapableHoursItems,
          logic(em:any, filters: any) {
            // working around a bug where if capacity would be 0, em shows up as 0, unsure why
            if (typeof(em) != 'number') {
              let capacity = em?.getCapacityHandle().getCapacity(year,month + 1,regions[em?.region]);
              let textString = Math.round(capacity.countWorkingHours());
              return !filters.includes(textString); 
            } else {
              let textString = 0;
              return !filters.includes(textString)
            }
          }
        },
        setCellProps: () => ({ style: { padding: 5 }}),
        setCellHeaderProps: () => ({ style: { padding: 5 }}),
        sortCompare: (order: string) => {
          return (obj1: any, obj2: any ) => {
            const projectedCapacity1 = obj1.data.getCapacityHandle().getCapacity(year,month + 1 ,regions[obj1.data.region])
            const projectedCapacity2 = obj2.data.getCapacityHandle().getCapacity(year,month + 1,regions[obj2.data.region])

            let val1 = projectedCapacity1.countWorkingHours();
            let val2 = projectedCapacity2.countWorkingHours();
            return (val1 - val2) * (order === 'desc' ? 1 : -1);
          };
      },

        customBodyRender: (em: EMHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
          const projectedCapacity = em
                .getCapacityHandle()
                .getCapacity(year, month + 1, regions[em.region]);
          return Math.round(projectedCapacity.countWorkingHours());
        }, 
      }
    },
    {
      name: "Projected assigned hours",
      options: {
        filter: true,
        filterOptions:{
          names:  projectedAssignedHoursItems,
          logic(em:any, filters: any) {
              let textString = Math.round(projectedBudgets[em.id]?.emHours || 0);
              return !filters.includes(textString); 
          }
        },
        setCellProps: () => ({ style: { padding: 5 }}),
        setCellHeaderProps: () => ({ style: { padding: 5 }}),

        sortCompare: (order: string) => {
          return (obj1: any, obj2: any ) => {
            const projectedBudget1 = projectedBudgets[obj1.data.id];
            const projectedBudget2 = projectedBudgets[obj2.data.id];

            let val1 = projectedBudget1?.emHours || 0;
            let val2 = projectedBudget2?.emHours || 0;
            return (val1 - val2) * (order === 'desc' ? 1 : -1);
          };
      },

        customBodyRender: (em: EMHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
          const projectedBudget = projectedBudgets[em.id];
          return Math.round(projectedBudget?.emHours || 0);
        }, 
      }
    },
    {
      name: "Projected available hours",
      options: {
        filter: true,
        filterOptions:{
          names:  projectedAvailableHoursItems,
          logic(em:any, filters: any) {
            // working around a bug where if capacity would be 0, em shows up as 0, unsure why
            if (typeof(em) != 'number') {
              let capacity = em.getCapacityHandle().getCapacity(year,month + 1,regions[em?.region]);
              let textString = Math.round(Math.max(0, Math.round(capacity.countWorkingHours()) - (Math.round(projectedBudgets[em.id]?.emHours) || 0)));
              return !filters.includes(textString); 
            } else {
              let textString = 0;
              return !filters.includes(textString)
            }
          }
        },

        setCellProps: () => ({ style: { padding: 5 }}),
        setCellHeaderProps: () => ({ style: { padding: 5 }}),

        sortCompare: (order: string) => {
          return (obj1: any, obj2: any ) => {
            const projectedBudget1 = projectedBudgets[obj1.data.id];
            const projectedBudget2 = projectedBudgets[obj2.data.id];

            const projectedBudgetEMHours1  = Math.round(projectedBudget1?.emHours || 0)
            const projectedBudgetEMHours2  = Math.round(projectedBudget2?.emHours || 0)

            const projectedCapacity1 = obj1.data.getCapacityHandle().getCapacity(year,month + 1 ,regions[obj1.data.region])
            const projectedCapacity2 = obj2.data.getCapacityHandle().getCapacity(year,month + 1,regions[obj2.data.region])
            
            const projectedWorkingHours1 = Math.round(projectedCapacity1.countWorkingHours())
            const projectedWorkingHours2 = Math.round(projectedCapacity2.countWorkingHours())

            let val1 = Math.round(Math.max(0, projectedWorkingHours1 - projectedBudgetEMHours1))
            let val2 = Math.round(Math.max(0, projectedWorkingHours2 - projectedBudgetEMHours2))

            return (val1 - val2) * (order === 'desc' ? 1 : -1);
          };
      },

        customBodyRender: (em: EMHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
          const projectedBudget = projectedBudgets[em.id];
          const projectedBudgetEMHours  = Math.round(projectedBudget?.emHours || 0)
          const projectedCapacity = em
                .getCapacityHandle()
                .getCapacity(year, month + 1, regions[em.region]);
          const projectedWorkingHours = Math.round(projectedCapacity.countWorkingHours())
          return Math.round(
            Math.max(
              0,
              projectedWorkingHours - projectedBudgetEMHours
            )
          );
        }, 
      }
    },
    {
      name: "Region",
      options: {
        filter: true,
        setCellProps: () => ({ style: { padding: 5 }}),
        setCellHeaderProps: () => ({ style: { padding: 5 }}),
        sortCompare: (order: string) => {
          return (obj1: { data: { region: string | number; }; }, obj2: { data: { region: string | number; }; } ) => {
            let val1 = regions[obj1.data.region]?.code;
            let val2 = regions[obj2.data.region]?.code;
            return (val1.localeCompare(val2)) * (order === 'desc' ? 1 : -1);
          };
      },
        customBodyRender: (em: EMHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
          return regions[em.region]?.code
        }, 
      }
    },
];

  const data = useMemo(() => Object.values(filteredEMs).sort((c1, c2) => c1.compare(c2)).map(c => Array(columns.length).fill(c)), [filteredEMs]);

  return (
    <Box>
    <MUIDataTable
       title=""
       columns={columns}
       data={data}
       options={
         {
         filterType: 'multiselect',

         onDownload: (buildHead, buildBody, columns, data) => {
          const capableHoursDownload = (em: any) => {   
            const capacity = em
            .getCapacityHandle()
            .getCapacity(year, month, regions[data[0].region])
            return Math.round(capacity.countWorkingHours())
          }

          const availableHoursDownload = (em:any) => {
            const capacity = em
                .getCapacityHandle()
                .getCapacity(year, month, regions[em.region]);
          return Math.round(Math.max(0, Math.round(capacity.countWorkingHours()) - (Math.round(budgets[em.id]?.emHours) || 0)));
          }
          const weekDayPTODownload = (em:any) =>{
            const capacity = em
                  .getCapacityHandle()
                  .getCapacity(year, month, regions[em.region]);
            return capacity.countWeekDayTimeOff();
          }

          const workingDaysDownload = (em:any) => {
            const capacity = em
                  .getCapacityHandle()
                  .getCapacity(year, month, regions[em.region]);
            return capacity.countWorkingDays();
          }

          const utilizationDownload = (em: any) => {
            const capacity = em
                  .getCapacityHandle()
                  .getCapacity(year, month, regions[em.region]);
            return capacity.periods.map((period: { partialCapacity: any; }, idx2: any) => {
              const p = period.partialCapacity;
              const from = new Date(
                p.year || 0,
                p.month || 0,
                p.day || 1
              );
              return (p.percent  || 0)  + "%"
      });
          }
          const projectedCapableHoursDownload = (em: any) => {
                const projectedCapacity = em
                .getCapacityHandle()
                .getCapacity(year, month + 1, regions[em.region]);
          return Math.round(projectedCapacity.countWorkingHours());
          }

          const projectedAssignedHoursDownload = (em: any) => {
            const projectedBudget = projectedBudgets[em.id];
            return Math.round(projectedBudget?.emHours || 0);
          }

          const projectedAvailableHoursDownload = (em: any) => {
            const projectedBudget = projectedBudgets[em.id];
            const projectedBudgetEMHours  = Math.round(projectedBudget?.emHours || 0)
            const projectedCapacity = em
                  .getCapacityHandle()
                  .getCapacity(year, month + 1, regions[em.region]);
            const projectedWorkingHours = Math.round(projectedCapacity.countWorkingHours())
            return Math.round(
              Math.max(
                0,
                projectedWorkingHours - projectedBudgetEMHours
              )
            );
          }

          const rowInfo: any = data.map((it: { index: any; data: any; }) => 
          ({ index: it.index, 
            data: [    
              it.data[0]?.firstName + " " + it.data[0].lastName,            // em column                                              
              capableHoursDownload(it.data[0]),                             // Capable hours                   
              Math.round(budgets[it.data[0].id]?.emHours) || 0,             // assigned hours
              availableHoursDownload(it.data[0]),                           // available hours
              Object.keys(budgets[it.data[0].id]?.items || {}).length,      // active customers
              Math.round(budgets[it.data[0].id]?.getContractHours() || 0),  // contract hours
              weekDayPTODownload(it.data[0]),                               // weekday pto
              workingDaysDownload(it.data[0]),                              // working days
              utilizationDownload(it.data[0]),                              // utilization
              projectedCapableHoursDownload(it.data[0]),                    // projected capable hours
              projectedAssignedHoursDownload(it.data[0]),                   // projectedassignedhours
              projectedAvailableHoursDownload(it.data[0]),                  // projectedavailablehours
              regions[it.data[0].region]?.code                              // region
              
            ] 
          })
          )




          return "\uFEFF" + buildHead(columns) + buildBody(rowInfo);
       }, 
         "draggableColumns":{"enabled":true},
         "pagination":false,
         "selectableRows":"none",
      
       }
       }
         /> 
 </Box>
  );
}
