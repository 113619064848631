import { Team } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useTeamUtilizationByTeam } from "../../utilization/useTeamUtilizationByTeam";
import {
  SystemNotification,
  SystemNotificationSource
} from "../SystemNotificationTypes";

export function useOverallocatedTeamNotifications(
  year: number,
  month: number,
  teams: { [teamId: string]: Team }
): SystemNotification[] {
  const utilizations = useTeamUtilizationByTeam(year, month, teams);
  const [items, setItems] = useState([] as SystemNotification[]);

  useEffect(() => {
    const newItems: SystemNotification[] = Object.values(teams || {})
      .filter((team) => {
        const utilization = utilizations[team.id];
        return (
          !!utilization &&
          utilization.getCapacity() > 0 &&
          utilization.isOverallocated()
        );
      })
      .map((team) => {
        const utilization = utilizations[team.id];
        return {
          source: SystemNotificationSource.TEAM,
          id: team.id,
          title: team.name,
          link: `/dashboards/teams/${team.id}`,
          description: `is overallocated (${Math.round(
            utilization.getUtilization()
          ).toLocaleString()}%)`,
        };
      });
    setItems(newItems);
  }, [year, month, teams, utilizations]);

  return items;
}
