import {
  Container,
  Table,
  TableBody,
  TableCell as DefaultTableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  withStyles
} from "@material-ui/core";
import "date-fns";
import { StaffingCalendarTimeOff } from "msd-capacity-planning-model";
import React from "react";
import { MONTH_DATE_FORMAT } from "utils/dateFormats";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(DefaultTableCell);

export function EngineerStaffingCalendarForm({
  timeOff,
}: {
  timeOff: StaffingCalendarTimeOff;
}) {
  return (
    <Container>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Month</TableCell>
              <TableCell>Days</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(timeOff || {}).map((yearMonth, index) => {
              const parts = yearMonth.split("-");
              const year = parseInt(parts[0]);
              const month = parseInt(parts[1]);
              const date = new Date(year, month);
              return (
                <TableRow key={`item-${index}`}>
                  <TableCell>
                    {date.toLocaleString("en", MONTH_DATE_FORMAT)}
                  </TableCell>
                  <TableCell>
                    <Tooltip title={JSON.stringify(timeOff[yearMonth])}>
                      <span>{timeOff[yearMonth].length}</span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
