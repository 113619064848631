import { AggregatedCapacity, Capacity } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useCapacities } from "./useCapacities";

export default function useCapacity(
  year: number,
  month: number
): AggregatedCapacity<Capacity> {
  const [months, setMonths] = useState([] as { year: number; month: number }[]);
  const capacities = useCapacities(months);
  useEffect(() => {
    setMonths([{ year, month }]);
  }, [year, month]);
  return capacities.length > 0 ? capacities[0] : new AggregatedCapacity();
}
