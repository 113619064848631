import { Box } from "@material-ui/core";
import { EMSelect } from "components/EMSelect";
import { ManagerSelect } from "components/ManagerSelect";
import { ProductTypeChips } from "components/ProductTypeChips";
import { RegionSelect } from "components/RegionSelect";
import { SkillsChips } from "components/SkillsChips";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTeams } from "teams/useTeams";
import { AggregatedCustomers } from "../customers/useCustomers";

export function CustomerFilter({
  year,
  month,
  customers,
  onChange,
}: {
  year: number;
  month: number;
  customers: AggregatedCustomers;
  onChange: (customers: AggregatedCustomers) => void;
}) {
  const teams = useTeams(year, month);
  const form = useForm();
  const [em, setEM] = useState(undefined as string | undefined);
  const [manager, setManager] = useState(undefined as string | undefined);
  const [productTypes, setProductTypes] = useState([] as string[]);
  const [region, setRegion] = useState(undefined as string | undefined);
  const [skills, setSkills] = useState([] as string[]);
  const [filteredCustomers, setFilteredCustomers] = useState(
    new AggregatedCustomers()
  );

  useEffect(() => {
    onChange(filteredCustomers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredCustomers]);

  useEffect(() => {
    async function run() {
      const newCustomers = customers.getAll().filter((customer) => {
        const team = teams[customer.getTeam(year, month)];
        if (em && customer.engagementManager !== em) {
          return false;
        }
        if (manager && team?.manager !== manager) {
          return false;
        }
        if (
          productTypes.length > 0 &&
          _.intersection(customer.productTypes || [], productTypes).length < 1
        ) {
          return false;
        }
        if (region && customer.region !== region) {
          return false;
        }
        if (
          skills.length > 0 &&
          _.intersection(customer.skills || [], skills).length < 1
        ) {
          return false;
        }
        return true;
      });
      const newFilteredCustomers = new AggregatedCustomers();
      newFilteredCustomers.addAll(newCustomers);
      setFilteredCustomers(newFilteredCustomers);
    }
    run();
  }, [
    year,
    month,
    teams,
    customers,
    manager,
    em,
    region,
    productTypes,
    skills,
  ]);

  return (
    <Box style={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
      <Box
        style={{
          display: "inline",
          minWidth: 150,
          marginBottom: 8,
        }}
      >
        <ProductTypeChips
          name="productTypes"
          form={form}
          onChange={(types) => setProductTypes(types)}
        />
      </Box>
      <Box
        style={{
          display: "inline",
          minWidth: 150,
          marginLeft: 8,
          marginBottom: 8,
        }}
      >
        <SkillsChips
          name="skills"
          form={form}
          onChange={(skills) => setSkills(skills)}
        />
      </Box>
      <Box style={{ display: "inline", width: 150, marginLeft: 8 }}>
        <RegionSelect
          name="region"
          form={form}
          onChange={(region) => setRegion(region)}
        />
      </Box>
      <Box
        style={{
          display: "inline",
          width: 150,
          marginLeft: 8,
          marginBottom: 8,
        }}
      >
        <EMSelect name="em" form={form} onChange={(em) => setEM(em)} />
      </Box>
      <Box
        style={{
          display: "inline",
          width: 150,
          marginLeft: 8,
          marginBottom: 8,
        }}
      >
        <ManagerSelect
          name="manager"
          form={form}
          onChange={(manager) => setManager(manager)}
        />
      </Box>
    </Box>
  );
}
