import { CustomerDashboardPage } from "dashboards/customer/CustomerDashboardPage";
import { Route, Switch } from "react-router-dom";
import { RegionCustomersPage } from "./RegionCustomersPage";
import { RegionDashboardPage } from "./RegionDashboardPage";
import { RegionEngineersPage } from "./RegionEngineersPage";

export function RegionDashboardRoutePage({ baseUrl }: { baseUrl: string }) {
  return (
    <Switch>
      <Route path={`${baseUrl}/:region/engineers`}>
        <RegionEngineersPage />
      </Route>
      <Route path={`${baseUrl}/:region/customers/:customer`}>
        <CustomerDashboardPage />
      </Route>
      <Route path={`${baseUrl}/:region/customers`}>
        <RegionCustomersPage />
      </Route>
      <Route path={`${baseUrl}/:region`}>
        <RegionDashboardPage />
      </Route>
    </Switch>
  );
}
