import { InitAppAction, INIT_APP } from "./AppTypes";

const initialState = false;

export default function initReducer(
  state = initialState,
  action: InitAppAction
): boolean {
  switch (action.type) {
    case INIT_APP:
      return true;
    default:
      return state;
  }
}
