import { Box } from "@material-ui/core";
import { Team } from "msd-capacity-planning-model";
import { DashboardBreadcrumbs } from "../DashboardBreadcrumbs";
import { useDashboardPeriod } from "../useDashboardPeriod";
import { TeamsDashboard } from "./TeamsDashboard";

export function TeamsOverviewDashboardPage({
  teams,
}: {
  teams: { [teamId: string]: Team };
}) {
  const { year, month } = useDashboardPeriod();
  return (
    <Box>
      <DashboardBreadcrumbs />
      <TeamsDashboard year={year} month={month} teams={teams} />
    </Box>
  );
}
