import { TeamHandle } from "msd-capacity-planning-model";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AppState } from "../redux/AppTypes";

const stateSelector = (state: AppState) => state.teams.items;

export const useAllTeamsSelector = createSelector(stateSelector, (items) => {
  return (Object.values(items) || []).reduce((map, team) => {
    map[team.id] = new TeamHandle(team);
    return map;
  }, {} as { [teamId: string]: TeamHandle });
});

export function useAllTeams(): { [emId: string]: TeamHandle } {
  const state = useSelector((state: AppState) => state);
  const ems = useAllTeamsSelector(state);
  return ems;
}