import { Box, Divider } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { AdministratorsPage } from "administrators/AdministratorsPage";
import { useAdministratorRoles } from "auth/useAdministratorRoles";
import { DocsDrawer } from "docs/DocsDrawer";
import React from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { SynchStatusPage } from "../administration.synchstatus/SynchStatusPage";
import { CustomersPage } from "../customers/CustomersPage";
import { EMsPage } from "../ems/EMsPage";
import { EngineersPage } from "../engineers/EngineersPage";
import { RegionsPage } from "../regions/RegionsPage";
import { TeamsPage } from "../teams/TeamsPage";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 3,
  },
  drawerContainer: {
    overflow: "auto",
  },
  drawerLink: {
    textDecoration: "inherit",
    color: "inherit",
  },
  content: {
    flexGrow: 1,
  },
}));

export function AdministrationPage() {
  const classes = useStyles();
  const roles = useAdministratorRoles();

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            {roles.has("superadmin") ? (
              <Link
                to="/administration/administrators"
                className={classes.drawerLink}
              >
                <ListItem>
                  <ListItemText primary="Administrators" />
                </ListItem>
              </Link>
            ) : null}
            <Link to="/administration/customers" className={classes.drawerLink}>
              <ListItem>
                <ListItemText primary="Customers" />
              </ListItem>
            </Link>
            <Link to="/administration/ems" className={classes.drawerLink}>
              <ListItem>
                <ListItemText primary="EMs" />
              </ListItem>
            </Link>
            <Link to="/administration/engineers" className={classes.drawerLink}>
              <ListItem>
                <ListItemText primary="Engineers" />
              </ListItem>
            </Link>
            <Link to="/administration/regions" className={classes.drawerLink}>
              <ListItem>
                <ListItemText primary="Regions" />
              </ListItem>
            </Link>
            <Link to="/administration/teams" className={classes.drawerLink}>
              <ListItem>
                <ListItemText primary="Teams" />
              </ListItem>
            </Link>
            <Divider />
            <Link
              to="/administration/synchstatus"
              className={classes.drawerLink}
            >
              <ListItem>
                <ListItemText primary="Sync Status" />
              </ListItem>
            </Link>
          </List>
          <Divider />
          <List>
            <ListItem>
              <ListItemText primary="How to" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Grant access" />
              <DocsDrawer path="/usage/access.html" />
            </ListItem>
          </List>
        </div>
      </Drawer>
      <Box maxWidth="lg" style={{ marginLeft: 8, minWidth: "1024px" }}>
        <Switch>
          <Route path="/administration/administrators">
            <AdministratorsPage />
          </Route>
          <Route path="/administration/customers">
            <CustomersPage />
          </Route>
          <Route path="/administration/teams">
            <TeamsPage />
          </Route>
          <Route path="/administration/ems">
            <EMsPage />
          </Route>
          <Route path="/administration/engineers">
            <EngineersPage />
          </Route>
          <Route path="/administration/regions">
            <RegionsPage />
          </Route>
          <Route path="/administration/synchstatus">
            <SynchStatusPage />
          </Route>
          <Route path="/administration">
            <Redirect to="/administration/customers" />
          </Route>
        </Switch>
      </Box>
    </div>
  );
}
