import { Administrator } from "msd-capacity-planning-model";
import {
  AdministratorAction, AdministratorsState, FETCH_ADMINISTRATORS, LOAD_ADMINISTRATORS, PERSIST_ADMINISTRATOR, REMOVE_ADMINISTRATOR
} from "./AdministratorTypes";
const initialState: AdministratorsState = {
  items: {},
  synching: false,
};

export default function administratorsReducer(
  state = initialState,
  action: AdministratorAction
): AdministratorsState {
  switch (action.type) {
    case FETCH_ADMINISTRATORS:
      return {
        ...state,
        synching: true,
      };
    case REMOVE_ADMINISTRATOR:
      return {
        ...state,
        synching: true,
      };
    case PERSIST_ADMINISTRATOR:
      return {
        ...state,
        synching: true,
      };

    case LOAD_ADMINISTRATORS:
      return {
        ...state,
        items: action.items.reduce((all: { [id: string]: Administrator }, team) => {
          all[team.id] = team;
          return all;
        }, {}),
        synching: false,
      };
    default:
      return state;
  }
}
