import { useEffect, useState } from 'react'
import service from './JiraResourcesService'
import { JiraProjectDTO } from './JiraProjectDTO'

export interface JiraProjectDTOLoader {
  loading: boolean
  project?: JiraProjectDTO
}

export function useProject(
  projectKey: string,
  loadCounter: number
): JiraProjectDTOLoader {
  const [value, setValue] = useState({ loading: true } as JiraProjectDTOLoader)

  useEffect(() => {
    if (!projectKey) {
      setValue({ loading: false })
    }
    setValue({ loading: true })
    service
      .getProject(projectKey)
      .then((project) => {
        setValue({ loading: false, project })
      })
      .catch((error) => {
        setValue({ loading: false })
        console.log(error)
      })
  }, [projectKey, loadCounter])

  return value
}
