import {
  LOAD_CUSTOMERS,
  CustomersState,
  CustomerAction,
  FETCH_CUSTOMERS,
  REMOVE_CUSTOMER,
  PERSIST_CUSTOMER,
} from "./CustomerTypes";
import { Customer } from "msd-capacity-planning-model";
const initialState: CustomersState = {
  items: {},
  synching: true,
};

export default function customersReducer(
  state = initialState,
  action: CustomerAction
): CustomersState {
  switch (action.type) {
    case FETCH_CUSTOMERS:
      return {
        ...state,
        synching: true,
      };
    case REMOVE_CUSTOMER:
      return {
        ...state,
        synching: true,
      };
    case PERSIST_CUSTOMER:
      return {
        ...state,
        synching: true,
      };

    case LOAD_CUSTOMERS:
      return {
        ...state,
        items: action.items.reduce((all: { [id: string]: Customer }, team) => {
          all[team.id] = team;
          return all;
        }, {}),
        synching: false,
      };
    default:
      return state;
  }
}
