import { Team } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { SystemNotification } from "../SystemNotificationTypes";
import { useOverallocatedTeamNotifications } from "./useOverallocatedTeamNotifications";

export function useTeamsNotifications(
  year: number,
  month: number,
  teams: { [teamId: string]: Team }
) {
  const overallocated = useOverallocatedTeamNotifications(year, month, teams);
  const [items, setItems] = useState([] as SystemNotification[]);
  useEffect(() => {
    setItems(overallocated);
  }, [year, month, overallocated]);
  return items;
}
