import { useEngineerAggregator } from "engineers/useEngineerAggregator";
import {
  AggregatedCapacity,
  AggregatedCapacityItem,
  Engineer,
  EngineerAggregatorType
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import useCapacity from "./useCapacity";

export interface AggregatedCapacityByRegion {
  [groupId: string]: AggregatedCapacity<AggregatedCapacityItem>;
}

export function useEngineerCapacityByAggregator(
  year: number,
  month: number,
  group: EngineerAggregatorType,
  filter?: { [engineerId: string]: Engineer }
): AggregatedCapacityByRegion {
  const capacities = useCapacity(year, month);
  const aggregator = useEngineerAggregator(year, month);
  const [response, setResponse] = useState({} as AggregatedCapacityByRegion);

  useEffect(() => {
    const newResponse = Object.values(capacities.items)
      .filter((c) => !filter || filter[c.engineer.id])
      .reduce((all, capacity) => {
        aggregator.get(capacity.engineer, group).forEach((id) => {
          all[id] = all[id] || new AggregatedCapacity<AggregatedCapacityItem>();
          all[id].add(capacity);
        });
        return all;
      }, {} as AggregatedCapacityByRegion);
    setResponse(newResponse);
  }, [year, month, group, aggregator, capacities, filter]);

  return response;
}
