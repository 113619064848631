import { EMHandle } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../redux/AppTypes";
import { useAllEMsSelector } from "./useAllEMs";

export function useEMs(
  year: number,
  month: number
): { [emId: string]: EMHandle } {
  const state = useSelector((state: AppState) => state);
  const ems = useAllEMsSelector(state);
  const [filtered, setFiltered] = useState({} as { [emId: string]: EMHandle });

  useEffect(() => {
    const date = new Date(year, month + 1, 1);
    const newEms = Object.values(ems).reduce((acc, em) => {
      if (!em.isOffboarded(date)) {
        acc[em.id] = em;
      }
      return acc;
    }, {} as { [emId: string]: EMHandle });
    setFiltered(newEms);
  }, [year, month, ems]);

  return filtered;
}
