import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AppState } from "../redux/AppTypes";

const myselfSelector = (state: AppState) => state.authorization?.myself;

export const useAdministratorRolesSelector = createSelector(
  myselfSelector,
  (myself) => new Set(myself?.roles || [])
);

export function useAdministratorRoles(): Set<string> {
  const state = useSelector((state: AppState) => state);
  return useAdministratorRolesSelector(state);
}
