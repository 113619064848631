import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import HistoryIcon from "@material-ui/icons/History";
import flatten from "flat";
import {
  Identifiable,
  Memento,
  MementoType
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import service from "./HistoryService";

function getValue(value: any): string {
  if (typeof value === "undefined") {
    return "";
  } else if (Array.isArray(value) && value.length < 1) {
    return "";
  } else if (typeof value === "object" && Object.keys(value).length < 1) {
    return "";
  } else {
    return JSON.stringify(value);
  }
}

export function HistoryPanel({
  type,
  target,
}: {
  type: MementoType;
  target: Identifiable;
}) {
  const [loading, setLoading] = useState(false);
  const [diffIndex, setDiffIndex] = useState(-1);
  const [diff, setDiff] = useState([] as string[]);
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([] as Memento[]);

  useEffect(() => {
    if (open && type && target.id) {
      setLoading(true);
      service
        .getItems(type, target.id)
        .then((newItems) => setItems(newItems))
        .finally(() => setLoading(false));
    } else {
      setItems([]);
    }
  }, [open, type, target]);

  useEffect(() => {
    if (items.length > 1 && diffIndex >= 0) {
      const memento1: { [key: string]: string } = flatten(target);
      const memento2: { [key: string]: string } = flatten(
        items[diffIndex].state || {}
      );
      const fields = Array.from(
        new Set(Object.keys(memento1).concat(Object.keys(memento2)))
      ).sort();
      const newDiff = fields
        .filter((field) => field !== "date")
        .reduce((diffs, field) => {
          const value1 = getValue(memento1[field]);
          const value2 = getValue(memento2[field]);
          if (value1 !== value2) {
            diffs.push(`${field}: ${value1} > ${value2}`);
          }
          return diffs;
        }, []);
      setDiff(newDiff);
    } else {
      setDiff([]);
    }
  }, [target, items, diffIndex]);

  return (
    <span>
      <IconButton onClick={() => setOpen(true)}>
        <HistoryIcon />
      </IconButton>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        anchor="bottom"
        style={{ textAlign: "center" }}
      >
        <Box
          style={{
            paddingTop: 16,
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Paper style={{ width: 1024, maxHeight: 320, overflowY: "auto" }}>
            {loading ? (
              <CircularProgress size={32} />
            ) : (
              <Box>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Author</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Diff</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map((item, index) => {
                      return (
                        <TableRow key={`index${index}`}>
                          <TableCell>{item.author}</TableCell>
                          <TableCell>
                            {new Date(item.date).toLocaleString()}
                          </TableCell>
                          <TableCell>
                            {diffIndex === index ? (
                              <Box>
                                {diff.map((d) => (
                                  <Box>{d}</Box>
                                ))}
                              </Box>
                            ) : (
                              <Button onClick={() => setDiffIndex(index)}>
                                View
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            )}
          </Paper>
        </Box>
      </Drawer>
    </span>
  );
}
