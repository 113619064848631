import { useOffboardedEngineers } from "engineers/useOffboardedEngineers";
import { useEffect, useState } from "react";
import { DAY_DATE_FORMAT } from "utils/dateFormats";
import {
  SystemNotification,
  SystemNotificationSource
} from "../SystemNotificationTypes";

export function useEngineersOffboardedNotifications(
  year: number,
  month: number
): SystemNotification[] {
  const engineers = useOffboardedEngineers(year, month);
  const [notifications, setNotifications] = useState(
    [] as SystemNotification[]
  );

  useEffect(() => {
    const newNotifications = engineers.map((engineer) => {
      const engineerId = engineer.id;
      const title = engineer.getDisplayName();
      return {
        source: SystemNotificationSource.ENGINEER,
        id: engineerId,
        title,
        link: `/administration/engineers/${engineerId}`,
        description: engineer
          .getOffboardDate()
          ?.toLocaleString("en", DAY_DATE_FORMAT),
      };
    });
    setNotifications(newNotifications);
  }, [year, month, engineers]);

  return notifications;
}
