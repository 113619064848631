import {
  Box,
  FormControlLabel, Switch
} from "@material-ui/core";
import useCustomersInAggregator from "customers/useCustomersInAggregator";
import { CustomersTable } from "dashboards/customers/CustomersTable";
import { CustomerAggregatorType } from "msd-capacity-planning-model";
import { useState } from "react";

export function EMLeadCustomersTable({
  year,
  month,
  emLeadId,
}: {
  year: number;
  month: number;
  emLeadId: string;
}) {
  const [directCustomerFlag, setDirectCustomerFlag] = useState(true);
  const customers = useCustomersInAggregator(
    year,
    month,
    CustomerAggregatorType.EM_LEAD,
    emLeadId
  );
  const directCustomers = useCustomersInAggregator(
    year,
    month,
    CustomerAggregatorType.EM,
    emLeadId
  );

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "right",
        }}
      >
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={directCustomerFlag}
              onChange={(event) =>
                setDirectCustomerFlag(event?.target?.checked === true)
              }
            />
          }
          label="Direct customers"
          labelPlacement="start"
        />
      </Box>

      <CustomersTable
        year={year}
        month={month}
        retrospectiveNotesColumn={true}
        planningNotesColumn={true}
        customers={directCustomerFlag ? directCustomers || {} : customers || {}}
        linkPrefix={`/customers`}
      />
    </Box>
  );
}
