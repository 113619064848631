import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { DashboardPeriod } from "dashboards/DashboardPeriod";
import { Customer } from "msd-capacity-planning-model";
import { CommentsPanelEditor } from "../../comments/CommentsPanelEditor";
import useCustomer from "../../customers/useCustomer";
import { CustomerBurnedBudgetCard } from "../utilization/BurnedBudgetCard";
import { CustomerHarvestBudgetTrend } from "../utilization/HarvestBudgetTrend";
import { CustomerLinkBar } from "./CustomerLinkBar";
import { CustomerOverviewCard } from "./CustomerOverviewCard";

export function CustomerDashboard({
  period,
  customerId,
}: {
  period: DashboardPeriod;
  customerId: string;
}) {
  const {year, month} = period;
  const customer = useCustomer(customerId);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">{customer?.name}</Typography>
          <CustomerLinkBar
            year={year}
            month={month}
            customer={customer as Customer}
            comments={false}
          />
        </Box>
      </Grid>
      <Grid item xs={9}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={6}>
            <CustomerOverviewCard
              year={year}
              month={month}
              customerId={customerId}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomerBurnedBudgetCard
              year={year}
              month={month}
              customerId={customerId}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomerHarvestBudgetTrend
              period={period}
              harvestProjectId={customer?.harvestProjectId || ""}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Paper>
              <CommentsPanelEditor
                year={year}
                month={month}
                id={customer?.id || ""}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
