import { useTheme } from "@material-ui/core";
import { PieChartCard } from "components/PieChartCard";
import { AggregatedBudget } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { getColors } from "utils/colorGenerator";

export function TeamBudgetDetailsCard({
  budget,
}: {
  budget: AggregatedBudget;
}) {
  const theme = useTheme();
  const [data, setData] = useState({
    datasets: [],
  } as any);

  useEffect(() => {
    const b = budget;
    const data = [b.teamHours, b.opsHours, b.bankHours, b.emHours];
    const labels = ["Team", "Ops", "Bank", "EM"];
    const backgroundColor = getColors(theme, data.length);
    const newData = {
      labels: labels.map(
        (label, idx) => `${label} (${Math.round(data[idx] || 0)})`
      ),
      datasets: [{ data: data.map((d) => Math.round(d)), backgroundColor }],
    };
    setData(newData);
  }, [theme, budget]);

  return <PieChartCard title="Budget hours" data={data} />;
}
