import { Box } from "@material-ui/core";
import { DashboardBreadcrumbs } from "../DashboardBreadcrumbs";
import { useDashboardPeriod } from "../useDashboardPeriod";
import { EngineersDashboard } from "./EngineersDashboard";

export function EngineersDashboardPage() {
  const period = useDashboardPeriod();

  return (
    <Box>
      <DashboardBreadcrumbs />
      <EngineersDashboard period={period}/>
    </Box>
  );
}
