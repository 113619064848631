import { EngineersRolesTable } from "dashboards/engineers/EngineersRolesTable";
import useEngineersInAggregator from "engineers/useEngineersInAggregator";
import { EngineerAggregatorType } from "msd-capacity-planning-model";

export function TeamCompositionTable({
  year,
  month,
  teamId,
}: {
  year: number;
  month: number;
  teamId: string;
}) {
  const engineers = useEngineersInAggregator(
    year,
    month,
    EngineerAggregatorType.TEAM,
    teamId
  );
  return <EngineersRolesTable engineers={engineers} />;
}
