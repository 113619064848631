import { Box } from "@material-ui/core";
import { Customer } from "msd-capacity-planning-model";
import { CommentsPanel } from "./CommentsPanel";

export function CustomerCommentsPanel({
  year,
  month,
  customer,
  hideAuthor = false,
}: {
  year: number;
  month: number;
  customer: Customer;
  hideAuthor?: boolean;
}) {
  return (
    <Box>
      <CommentsPanel
        year={year}
        month={month}
        id={`${customer.id}`}
        hideAuthor={hideAuthor}
      />
    </Box>
  );
}
