import { Team } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { SystemNotification } from "../SystemNotificationTypes";
import { useTeamsNotifications } from "./useTeamsNotifications";

export function useTeamNotifications(
  year: number,
  month: number,
  team: Team | undefined
): SystemNotification[] {
  const [teams, setTeams] = useState({} as { [teamId: string]: Team });
  const notifications = useTeamsNotifications(year, month, teams);

  useEffect(() => {
    setTeams(team ? { [team.id]: team } : {});
  }, [year, month, team]);

  return notifications;
}
