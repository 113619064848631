import { Grid, Paper, Typography } from "@material-ui/core";
import { useBudgetInAggregator } from "customers/useBudgetInAggregator";
import useCustomersInAggregator from "customers/useCustomersInAggregator";
import { DashboardPeriod } from "dashboards/DashboardPeriod";
import { EMEditButton } from "ems/EMEditButton";
import useEM from "ems/useEM";
import { HarvestUserLinkButton } from "harvest/HarvestUserLinkButton";
import { CustomerAggregatorType } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CountCard } from "../../components/CountCard";
import { CustomersNotificationsCard } from "../../notifications/customer/CustomersNotificationCard";
import { CustomersBurnedBudgetBarChart } from "../customers/CustomersBurnedBudgetBarChart";
import { CustomersBurnedBudgetScatterChartCard } from "../utilization/BurnedBudgetByCustomerScatterChartCard";
import { EMHarvestBudgetTrend } from "../utilization/HarvestBudgetTrend";
import { EMCustomersTable } from "./EMCustomersTable";

export function EMDashboard({
  period,
  emId,
}: {
  period: DashboardPeriod;
  emId: string;
}) {
  const { year, month } = period;
  const location = useLocation();
  const budget = useBudgetInAggregator(
    year,
    month,
    CustomerAggregatorType.EM,
    emId
  );
  const customers = useCustomersInAggregator(
    year,
    month,
    CustomerAggregatorType.EM,
    emId
  );
  const [customerIds, setCustomerIds] = useState(["none"] as string[]);
  useEffect(
    () => setCustomerIds(Object.values(customers).map((c) => c.id)),
    [customers]
  );
  const em = useEM(emId);
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography variant="h4">
          {em?.firstName} {em?.lastName}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={6}>
            <CustomersBurnedBudgetScatterChartCard
              year={year}
              month={month}
              customerIds={customerIds}
              linkPrefix={`${location.pathname}/customers`}
            />
          </Grid>
          <Grid item xs={6}>
            <EMHarvestBudgetTrend period={period} emId={emId} />
          </Grid>
          <Grid item xs={12}>
            <CustomersBurnedBudgetBarChart
              customers={customers}
              period={period}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={3}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <EMEditButton id={emId} />
            <HarvestUserLinkButton
              user={em?.harvestId}
              year={year}
              month={month}
            />
          </Grid>
          <Grid item xs={9}>
            <CountCard
              title="Customers"
              count={Object.values(customers).length}
            />
          </Grid>
          <Grid item xs={9}>
            <CountCard
              title="Budget"
              count={Math.round(budget.opsHours + budget.teamHours)}
            />
          </Grid>
          <Grid item xs={9}>
            <CountCard title="EM Hours" count={budget.emHours} />
          </Grid>
          <Grid item xs={12}>
            <CustomersNotificationsCard
              year={year}
              month={month}
              customerIds={customerIds}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <EMCustomersTable year={year} month={month} emId={emId}/>
        </Paper>
      </Grid>
    </Grid>
  );
}
