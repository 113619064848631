import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/AppTypes";
import { PersistCommentAction, PERSIST_COMMENT } from "./CommentTypes";

export function AddCommentInput({
  year,
  month,
  id,
  onSubmit,
}: {
  year: number;
  month: number;
  id: string;
  onSubmit?: () => void;
}) {
  const dispatch = useDispatch();
  const author = useSelector((state: AppState) => state.authorization.email);
  const [newMessage, setNewMessage] = useState("");

  function addComment(): void {
    if (newMessage?.length < 1) return;
    const action: PersistCommentAction = {
      type: PERSIST_COMMENT,
      year,
      month,
      id,
      item: {
        timestamp: new Date().getTime(),
        author,
        message: newMessage,
      },
    };
    setNewMessage("");
    dispatch(action);
    onSubmit?.();
  }

  return (
    <TextField
      size="small"
      label="Add..."
      fullWidth
      value={newMessage}
      onChange={(e) => setNewMessage(e.target.value as string)}
      onKeyPress={(ev) => {
        if (ev.key === "Enter") {
          ev.preventDefault();
          addComment();
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="Send" onClick={() => addComment()}>
              <SendIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    ></TextField>
  );
}
