import { Chart } from "chart.js";
import { useBudgets } from "customers/useBudgets";
import { DashboardPeriod } from "dashboards/DashboardPeriod";
import { useTrendPeriods } from "dashboards/useTrendPeriods";
import { createRef, useEffect, useState } from "react";
import { DashboardCard } from "../../components/DashboardCard";
import getMonthName from "../../utils/getMonthName";

export function CustomersTrendsCard({
  period,
  height,
}: {
  period: DashboardPeriod;
  height?: number;
}) {
  const periods = useTrendPeriods(period, -3, 3);
  const budgets = useBudgets(periods);

  const chartRef = createRef<HTMLCanvasElement>();
  const [chart, setChart] = useState(null as null | Chart);

  useEffect(() => {
    if (chart) {
      return;
    }
    const ref = chartRef?.current as HTMLCanvasElement;
    const newChart = new Chart(ref, {
      type: "line",
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: { precision: 0 },
            beginAtZero: false,
          },
        },
      },
    });
    setChart(newChart);
  }, []);

  useEffect(() => {
    if (!chart) {
      return;
    }
    chart.data.labels = periods.map((period) =>
      getMonthName(new Date(period.year, period.month))
    );
    chart.data.datasets = [
      {
        data: budgets.map((b) => Object.keys(b.items).length),
        borderColor: "rgb(0, 96, 100, 0.5)",
        backgroundColor: "rgb(0, 96, 100, 0.5)",
        borderWidth: 2,
      },
    ];
    chart.update();
  }, [chart, periods, budgets]);

  return (
    <DashboardCard title="Customers trend" height={height}>
      {{ body: <canvas ref={chartRef} /> }}
    </DashboardCard>
  );
}
