import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Tooltip,
  Typography
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import useEngineersCapacity from "capacity/useEngineersCapacity";
import useCustomersBudget from "customers/useCustomersBudget";
import {
  CustomerHandle,
  EngineerHandle,
  Team
} from "msd-capacity-planning-model";
import { useTeamUtilization } from "../../utilization/useTeamUtilization";

function UtilizationRatioCardImpl({
  budget,
  capacity,
}: {
  budget: number;
  capacity: number;
}) {
  const delta = capacity - budget;
  const ratio = capacity > 0 ? Math.round((budget / capacity) * 100) : 0;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Typography
              variant="h5"
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              Utilization ratio{" "}
              {delta < 0 ? (
                <Tooltip title={`Allocated budget exceeds available capacity`}>
                  <WarningIcon color="error" style={{ verticalAlign: "" }} />
                </Tooltip>
              ) : null}
            </Typography>
            <Typography variant="caption" component="p">
              Budget / Capacity
            </Typography>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <Box position="relative" display="inline-flex">
              <CircularProgress
                variant="determinate"
                color="primary"
                value={Math.min(ratio, 100)}
                size={64}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                >{`${ratio}%`}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <Typography variant="caption">
              {Math.round(capacity).toLocaleString()}
              <br />
              Capacity
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <Typography variant="caption">
              {Math.round(budget).toLocaleString()}
              <br />
              Budget
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <Typography variant="caption">
              {Math.round(delta).toLocaleString()}
              <br />
              Delta
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export function UtilizationRatioCard({
  year,
  month,
  customers,
  engineers,
}: {
  year: number;
  month: number;
  customers: { [customerId: string]: CustomerHandle };
  engineers: { [engineerId: string]: EngineerHandle };
}) {
  const budget = useCustomersBudget(year, month, customers);
  const capacity = useEngineersCapacity(year, month, engineers);

  return UtilizationRatioCardImpl({
    budget: budget.teamHours + budget.opsHours,
    capacity: capacity.allocatedHours,
  });
}

export function TeamUtilizationRatioCard({
  year,
  month,
  team,
}: {
  year: number;
  month: number;
  team: Team;
}) {
  const utilization = useTeamUtilization(year, month, team);
  return UtilizationRatioCardImpl({
    budget: utilization.getBudget(),
    capacity: utilization.getCapacity(),
  });
}
