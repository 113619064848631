import { JiraProject, JiraProjectCategory } from "msd-capacity-planning-model";
export const FETCH_JIRA = "jira/fetch";
export const DO_FETCH_JIRA = "jira/do_fetch";
export const LOAD_JIRA = "jira/load";

export interface FetchJiraAction {
  type: typeof FETCH_JIRA;
}

export interface DoFetchJiraAction {
  type: typeof DO_FETCH_JIRA;
}

export interface LoadJiraAction {
  type: typeof LOAD_JIRA;
  projects: JiraProject[];
  projectCategories: JiraProjectCategory[];
}

export interface JiraState {
  synching: boolean;
  projects: JiraProject[];
  projectCategories: JiraProjectCategory[];
}

export type JiraAction = FetchJiraAction | DoFetchJiraAction | LoadJiraAction;
