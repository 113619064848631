import { Box, Grid } from "@material-ui/core";
import { DashboardBreadcrumbs } from "dashboards/DashboardBreadcrumbs";
import { EngineersTable } from "dashboards/engineers/EngineersTable";
import { useDashboardPeriod } from "dashboards/useDashboardPeriod";
import useEngineersInAggregator from "engineers/useEngineersInAggregator";
import { EngineerAggregatorType } from "msd-capacity-planning-model";
import { useParams } from "react-router-dom";

export function RegionEngineersPage() {
  const { year, month } = useDashboardPeriod();
  const params: { [key: string]: string } = useParams();
  const engineers = useEngineersInAggregator(
    year,
    month,
    EngineerAggregatorType.REGION,
    params.region || ""
  );

  return (
    <Box>
      <DashboardBreadcrumbs />
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12}>
          <EngineersTable
            year={year}
            month={month}
            engineers={engineers}
            linkPrefix="/engineers"
            initialRowsPerPage={100}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
