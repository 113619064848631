import { Route, Switch } from "react-router-dom";
import { EMCustomerDashboardPage } from "../em/EMCustomerDashboardPage";
import { EMDashboardPage } from "../em/EMDashboardPage";

export function EMDashboardRoutePage({ baseUrl }: { baseUrl: string }) {
  return (
    <Switch>
      <Route path={`${baseUrl}/:em/customers/:customer`}>
        <EMCustomerDashboardPage />
      </Route>
      <Route path={`${baseUrl}/:em`}>
        <EMDashboardPage />
      </Route>
    </Switch>
  );
}
