import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Controller, UseFormMethods } from "react-hook-form";

export function CustomTextField({
  name,
  label,
  defaultValue,
  form,
  linkBaseUrl,
  disabled = false,
  helperText,
}: {
  label: string;
  name: string;
  defaultValue?: string | number | undefined;
  form: UseFormMethods<Record<string, any>>;
  linkBaseUrl?: string;
  disabled?: boolean;
  helperText?: string;
}) {

  return (
    <Controller
      name={name}
      control={form.control}
      defaultValue={defaultValue}
      render={(field) => (
        <FormControl fullWidth disabled={disabled}>
          <TextField
            autoComplete="off"
            name={name}
            label={label}
            defaultValue={defaultValue}
            error={!!form.errors[name]}
            onChange={(event) => {
              form.control.setValue(name, event?.target?.value);
            }}
            InputProps={{
              endAdornment: linkBaseUrl ? (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      window.open(
                        `${linkBaseUrl}${form.getValues(name)}`,
                        "_blank"
                      )
                    }
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </InputAdornment>
              ) : undefined,
            }}
            helperText={(helperText || "") + (form.errors[name]?.message || "")}
          />
        </FormControl>
      )}
    />
  );
}
