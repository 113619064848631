import DateFnsUtils from "@date-io/date-fns";
import { FormControl } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { Control, Controller } from "react-hook-form";
import { isNumber } from "util";

export function CustomDatePicker({
  year,
  month,
  day,
  control,
  record,
  label,
}: {
  year?: number;
  month?: number;
  day?: number;
  control: Control<Record<string, any>>;
  record: string;
  label?: string;
}) {
  const defaultValue =
    !isNumber(year) || !isNumber(month) || !isNumber(day)
      ? null
      : new Date(year, month, day);
  return (
    <Controller
      name={record}
      control={control}
      defaultValue={defaultValue}
      render={(field) => (
        <FormControl fullWidth>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              format="yyyy/MM/dd"
              value={field.value}
              label={label}
              onChange={(newDate) => control.setValue(record, newDate)}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      )}
    />
  );
}
