import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../redux/AppTypes";

export interface SynchingState {
  any: boolean;
  administrators: boolean;
  comments: boolean;
  customers: boolean;
  ems: boolean;
  engineers: boolean;
  harvest: boolean;
  jira: boolean;
  managers: boolean;
  regions: boolean;
  teams: boolean;
}

export function getSynchingState(state: AppState): SynchingState {
  const administrators = state.administrators.synching;
  const comments = Object.keys(state.comments.synching).length > 0;
  const customers = state.customers.synching;
  const ems = state.ems.synching;
  const engineers = state.engineers.synching;
  const harvest =
    Object.keys(state.harvest.synching).length > 0 ||
    state.harvest.users.synching;
  const jira = state.jira.synching;
  const managers = state.managers.synching;
  const regions = state.regions.synching;
  const teams = state.teams.synching;

  return {
    any:
      administrators ||
      comments ||
      customers ||
      ems ||
      engineers ||
      harvest ||
      jira ||
      managers ||
      regions ||
      teams,
    administrators,
    comments,
    customers,
    ems,
    engineers,
    harvest,
    jira,
    managers,
    regions,
    teams,
  };
}

export function useSynching(): SynchingState {
  const state = useSelector((state: AppState) => state);

  const [synching, setSynching] = useState({
    any: true,
    administrators: false,
    comments: true,
    customers: true,
    ems: true,
    engineers: true,
    harvest: true,
    jira: false,
    regions: true,
    teams: true,
  } as SynchingState);

  useEffect(() => {
    const newSynching = getSynchingState(state);
    setSynching(newSynching);
  }, [state]);

  return synching;
}
