import { Team } from "msd-capacity-planning-model";

export enum TeamAggregatorType {
  TEAM,
  MANAGER,
  LEAD,
}

export class TeamAggregator {
  private aggregators = new Map<TeamAggregatorType, (team: Team) => string>();

  constructor() {
    this.aggregators.set(
      TeamAggregatorType.TEAM,
      (team: Team) => team.id || ""
    );
    this.aggregators.set(
      TeamAggregatorType.MANAGER,
      (team: Team) => team.manager || ""
    );
    this.aggregators.set(
      TeamAggregatorType.LEAD,
      (team: Team) => team.lead || ""
    );
  }

  get(team: Team, type: TeamAggregatorType): string {
    return this.aggregators.get(type)(team);
  }
}

const aggregator = new TeamAggregator();

export function useTeamAggregator(): TeamAggregator {
  return aggregator;
}
