import { Box } from "@material-ui/core";
import { useFilteredNotifications } from "notifications/useFilteredNotifications";
import { useCustomersOffboardedNotifications } from "../../notifications/customer/useCustomersOffboardedNotifications";


const EMPTY: string[] = [];

export function EMCustomersOffboarded({
  year,
  month,
  customerIds = EMPTY,
}: {
  year: number;
  month: number;
  customerIds?: string[];
}) {
  const offboarded = useCustomersOffboardedNotifications(year, month);
  const filteredOffboarded = useFilteredNotifications(offboarded, customerIds);


  return (
    <Box sx={{textAlign: 'right'}}>{filteredOffboarded.length} Offboardings</Box>
  );
}
