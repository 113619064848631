import { Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { DashboardBreadcrumbs } from "../DashboardBreadcrumbs";
import { useDashboardPeriod } from "../useDashboardPeriod";
import { CustomerDashboard } from "./CustomerDashboard";

export function CustomerDashboardPage() {
  const period = useDashboardPeriod();
  const params: { [key: string]: string } = useParams();
  return (
    <Box>
      <DashboardBreadcrumbs />
      <CustomerDashboard period={period} customerId={params.customer} />
    </Box>
  );
}
