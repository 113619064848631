import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { useTeamCapacityByAggregator } from "capacity/useTeamCapacityByAggregator";
import { DashboardPeriod } from "dashboards/DashboardPeriod";
import { useLeads } from "leads/useLeads";
import {
  CustomerAggregatorType,
  TeamCapacityAggregatorType
} from "msd-capacity-planning-model";
import { NoStyleLink } from "components/NoStyleLink";
import { TeamAggregatorType } from "teams/TeamAggregator";
import { useTeamsByAggregator } from "teams/useTeamsByAggregator";
import { useBudgetByAggregator } from "../../customers/useBudgetByAggregator";

export function LeadsTable({ period }: { period: DashboardPeriod }) {
  const leads = useLeads();
  const teams = useTeamsByAggregator(TeamAggregatorType.LEAD);
  const capacities = useTeamCapacityByAggregator(
    period.year,
    period.month,
    TeamCapacityAggregatorType.LEAD
  );
  const budgets = useBudgetByAggregator(
    period.year,
    period.month,
    CustomerAggregatorType.LEAD
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Lead</TableCell>
            <TableCell>Teams</TableCell>
            <TableCell>Capacity</TableCell>
            <TableCell>Budget</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leads.map((leadName, index) => (
            <TableRow key={`lead-${leadName}`}>
              <TableCell>
                <NoStyleLink
               pathname={`/dashboards/leads/${leadName}`}
               text={leadName}
               />
              </TableCell>
              <TableCell>{(teams[leadName] || []).length}</TableCell>
              <TableCell>
                {Math.round(
                  capacities[leadName]?.allocatedHours || 0
                ).toLocaleString()}
              </TableCell>
              <TableCell>
                {Math.round(
                  (budgets[leadName]?.teamHours || 0) +
                    (budgets[leadName]?.opsHours || 0)
                ).toLocaleString()}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
