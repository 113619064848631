import React, { useEffect, useState } from 'react'
import { Box, List, ListItem } from '@material-ui/core'
import { JiraProject } from './JiraProject'
import { JiraProjectLead } from './JiraProjectLead'
import { JiraProjectScheme } from './JiraProjectScheme'
import { JiraProjectSchemeType } from './JiraResourcesService'
import { JiraProjectDTO } from './JiraProjectDTO'
import { JiraProjectPeople } from './JiraProjectPeople'
import { JiraBoardFilter } from './JiraBoardFilter'
import { JiraBoard } from './JiraBoard'
import { JiraBoardAdministrator } from './JiraBoardAministrator'
import { JiraBoardColumns } from './JiraBoardColumns'
import { JiraBoardSwimlane } from './JiraBoardSwimlane'
import { JiraOnboardingTasks } from './JiraOnboardingTasks'
import { HarvestCode } from './HarvestCode'
import { Warning as WarningIcon } from '@material-ui/icons'

export function JiraResources({
  projectKey,
  projectName,
  emEmail,
  emLabel,
  harvestId
}: {
  projectKey: string
  projectName: string
  emEmail: string
  emLabel: string
  harvestId: string
}) {
  const [currentProjectKey, setCurrentProjectKey] = useState(
    projectKey as string
  )
  const [project, setProject] = useState(
    undefined as JiraProjectDTO | undefined
  )

  useEffect(() => {
    setCurrentProjectKey(projectKey)
  }, [projectKey])

  return currentProjectKey ? (
    <Box style={{ width: '100%' }}>
      {currentProjectKey ? null : (
        <Box>
          <WarningIcon color='error' /> Jira project key is missing
        </Box>
      )}
      {emEmail ? null : (
        <Box>
          <WarningIcon color='error' /> EM email is missing
        </Box>
      )}
      {emLabel ? null : (
        <Box>
          <WarningIcon color='error' /> EM jira label is missing
        </Box>
      )}
      {harvestId ? null : (
        <Box>
          <WarningIcon color='error' /> Harvest code is missing
        </Box>
      )}
      <JiraProject
        projectKey={projectKey}
        projectName={projectName}
        onLoad={(project) => setProject(project)}
        emEmail={emEmail}
        emLabel={emLabel}
        harvestId={harvestId}
      />
      {project ? (
        <List>
          <ListItem>
            <HarvestCode projectKey={projectKey} harvestId={harvestId} />
          </ListItem>
          <ListItem>
            <JiraProjectLead projectKey={projectKey} leadEmail={emEmail} />
          </ListItem>
          <ListItem>
            <Box>
              <JiraProjectScheme
                projectKey={projectKey}
                scheme={JiraProjectSchemeType.ISSUE_TYPE}
              />
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <JiraProjectScheme
                projectKey={projectKey}
                scheme={JiraProjectSchemeType.ISSUE_SCREEN_TYPE}
              />
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <JiraProjectScheme
                projectKey={projectKey}
                scheme={JiraProjectSchemeType.NOTIFICATION}
              />
            </Box>
          </ListItem>

          <ListItem>
            <Box>
              <JiraProjectScheme
                projectKey={projectKey}
                scheme={JiraProjectSchemeType.PERMISSION}
              />
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <JiraProjectScheme
                projectKey={projectKey}
                scheme={JiraProjectSchemeType.WORKFLOW}
              />
            </Box>
          </ListItem>
          <ListItem>
            <JiraProjectPeople projectKey={projectKey} />
          </ListItem>
          <ListItem>
            <JiraBoardFilter projectKey={projectKey} />
          </ListItem>
          <ListItem>
            <JiraBoard projectKey={projectKey} />
          </ListItem>
          <ListItem>
            <JiraBoardAdministrator projectKey={projectKey} />
          </ListItem>
          <ListItem>
            <JiraBoardColumns projectKey={projectKey} />
          </ListItem>
          <ListItem>
            <JiraBoardSwimlane projectKey={projectKey} />
          </ListItem>
          <ListItem>
            <JiraOnboardingTasks
              projectKey={projectKey}
              emEmail={emEmail}
              emLabel={emLabel}
            />
          </ListItem>
          {/*
          * Disabled because now tickets are created from the capacity planner
          * it's configurable in the customer form
          *
          <ListItem>
            <JiraAutomationComm projectKey={projectKey} emLabel={emLabel} />
          </ListItem>
          <ListItem>
            <JiraAutomationQBR projectKey={projectKey} emLabel={emLabel} />
          </ListItem> */}
        </List>
      ) : null}
    </Box>
  ) : null
}
