import { Administrator } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "redux/AppTypes";

export function useSortedAministrators() {
  const admins = useSelector((state: AppState) => state.administrators.items);
  const [sorted, setSorted] = useState([] as Administrator[]);
  useEffect(() => {
    const adminArray = Object.values(admins);
    adminArray.sort((i1, i2) =>
      i1.id.toLowerCase().localeCompare(i2.id.toLowerCase())
    );
    setSorted(adminArray);
  }, [admins]);
  return sorted;
}
