import { RegionHandle } from "msd-capacity-planning-model";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AppState } from "../redux/AppTypes";

const regionsSelector = (state: AppState) => state.regions.items;

export const useSortedRegionsSelector = createSelector(
  regionsSelector,
  (items) => {
    const sortedItems = Object.values(items).map((r) => new RegionHandle(r));
    sortedItems.sort((i1, i2) => i1.compare(i2));
    return sortedItems;
  }
);

export default function useSortedRegions(): RegionHandle[] {
  const state = useSelector((state: AppState) => state);
  return useSortedRegionsSelector(state);
}
