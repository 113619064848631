import { Badge, Drawer, IconButton, Tooltip } from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CommentIcon from "@material-ui/icons/Comment";
import EditIcon from "@material-ui/icons/Edit";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { Customer } from "msd-capacity-planning-model";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { CommentsPanelEditor } from "../../comments/CommentsPanelEditor";
import useComments from "../../comments/useComments";
import { getHarvestProjectReportURL } from "../../utils/harvestURLService";
import { getJiraProjectURL } from "../../utils/jiraURLService";

export function CustomerLinkBar({
  year,
  month,
  customer,
  comments: commentButton = true,
  notesKey = "",
}: {
  year: number;
  month: number;
  customer: Customer;
  comments?: boolean;
  notesKey?: string;
}) {
  const history = useHistory();
  const comments = useComments(year, month);
  const [commentId, setCommentId] = useState(undefined as string | undefined);

  function onComment(e: any) {
    const id = e?.currentTarget?.dataset?.id;
    setCommentId(`${notesKey}${id || ""}`);
  }

  function onEdit(e: any) {
    const path = e?.currentTarget?.dataset?.path;
    if (path) {
      history.push(path);
    }
  }

  function onOpen(e: any) {
    const url = e?.currentTarget?.dataset?.url;
    if (url) {
      window.open(url, "_blank");
    }
  }

  return (
    <span>
      <IconButton
        data-path={`/administration/customers/${customer?.id}`}
        onClick={onEdit}
      >
        <EditIcon />
      </IconButton>
      <Tooltip title="Jira">
        <IconButton
          data-url={getJiraProjectURL(customer?.jiraProjectKey)}
          onClick={onOpen}
        >
          <AssignmentIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Harvest">
        <IconButton
          data-url={getHarvestProjectReportURL(
            customer?.harvestProjectId,
            year,
            month
          )}
          onClick={onOpen}
        >
          <ScheduleIcon />
        </IconButton>
      </Tooltip>
      {commentButton ? (
        <IconButton data-id={customer?.id} onClick={onComment}>
          <Badge
            color="primary"
            badgeContent={
              comments[`${notesKey}${customer?.id || ""}`]?.length || 0
            }
          >
            <CommentIcon />
          </Badge>
        </IconButton>
      ) : null}
      <Drawer
        anchor="right"
        open={!!commentId && commentId !== notesKey}
        onClose={onComment}
        style={{ width: 400 }}
      >
        <CommentsPanelEditor
          year={year}
          month={month}
          id={commentId || ""}
        ></CommentsPanelEditor>
      </Drawer>
    </span>
  );
}
