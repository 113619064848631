import {
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import { useHistory } from "react-router-dom";
import { useEngineer } from "../../engineers/useEngineer";
import { useEngineerRegion } from "../../engineers/useEngineerRegion";
import { useEngineerSkills } from "../../engineers/useEngineerSkills";

export function EngineerOverviewCard({ engineerId }: { engineerId: string }) {
  const history = useHistory();
  const engineer = useEngineer(engineerId);
  const skills = useEngineerSkills(engineerId);
  const region = useEngineerRegion(engineerId);
  return (
    <Card style={{ minWidth: 200 }}>
      <CardContent>
        <List>
          <ListItem>Name: {engineer?.getDisplayName()}</ListItem>
          <ListItem>Role: {engineer?.role}</ListItem>
          <ListItem>Region: {region?.name}</ListItem>
          <ListItem>
            Skills:
            {skills.map((skill, index) => (
              <Chip key={`${index}`} label={skill} style={{ margin: "8px" }} />
            ))}
          </ListItem>
        </List>
      </CardContent>
      <Divider></Divider>
      <CardActions>
        <IconButton
          onClick={() =>
            history.push(`/administration/engineers/${engineerId}`)
          }
        >
          <EditIcon></EditIcon>
        </IconButton>
      </CardActions>
    </Card>
  );
}
