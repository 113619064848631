import { useAllEMsSelector } from "ems/useAllEMs";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AppState } from "../redux/AppTypes";
import { useAdministratorRolesSelector } from "./useAdministratorRoles";

const emailSelector = (state: AppState) => state.authorization?.email;

export const useIsJiraAdminSelector = createSelector(
  useAdministratorRolesSelector,
  emailSelector,
  useAllEMsSelector,
  (roles, email, ems) => {
    if (roles.has("jira")) {
      return true;
    }
    if (Object.values(ems).find((em) => em.getRackspaceEmail() === email)) {
      return true;
    }
    return false;
  }
);

export function useIsJiraAdmin(): boolean {
  const isJiraAdmin = useSelector(useIsJiraAdminSelector);
  return isJiraAdmin;
}
