import { ManagersState } from "./ManagerTypes";
import { LOAD_TEAMS, TeamAction } from "../teams/TeamsTypes";

const initialState: ManagersState = {
  items: {},
  synching: true,
};

export default function managersReducer(
  state = initialState,
  action: TeamAction
): ManagersState {
  switch (action.type) {
    case LOAD_TEAMS: {
      const items = action.items
        .map((t) => t.manager)
        .sort()
        .reduce((all, managerEmail) => {
          all[managerEmail] = true;
          return all;
        }, {} as { [email: string]: boolean });
      return {
        ...state,
        items,
        synching: false,
      };
    }
    default:
      return state;
  }
}
