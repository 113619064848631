import { Container } from "@material-ui/core";
import { RegionDashboardRoutePage } from "dashboards/region/RegionDashboardRoutePage";
import { Route, Switch } from "react-router-dom";
import { RegionsDashboardPage } from "./RegionsDashboardPage";

export function RegionsDashboardRoutePage({ baseUrl }: { baseUrl: string }) {
  return (
    <Container maxWidth="xl">
      <Switch>
        <Route path={`${baseUrl}/:region`}>
          <RegionDashboardRoutePage baseUrl={baseUrl} />
        </Route>
        <Route path={`${baseUrl}`}>
          <RegionsDashboardPage />
        </Route>
      </Switch>
    </Container>
  );
}
