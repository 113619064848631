import { useEffect, useState } from 'react'
import service from './JiraResourcesService'
import { JiraFilter } from './JiraBoardFilterDTO'

export function useJiraProjectFilter(
  projectKey: string,
  reloadCounter: number = 0
): JiraFilter | null | undefined {
  const [filter, setFilter] = useState(
    undefined as JiraFilter | null | undefined
  )

  useEffect(() => {
    service.getProjectFilter(projectKey).then((filter) => {
      if (filter) {
        setFilter(filter)
      } else {
        setFilter(null)
      }
    })
  }, [projectKey, reloadCounter])

  return filter
}
