import { Middleware } from "redux";
import { AppState, InitAppAction, INIT_APP } from "../redux/AppTypes";
import {
  RegionAction as BaseAction,
  FETCH_REGIONS as FETCH,
  LOAD_REGIONS as LOAD,
  LoadRegionsAction as LoadAction,
  REMOVE_REGION as REMOVE,
  PERSIST_REGION as PERSIST,
} from "./RegionTypes";
import service from "./RegionService";

const initMiddleware: Middleware<{}, AppState> = (store) => (next) => (
  action: InitAppAction
) => {
  const result = next(action);
  if (action.type === INIT_APP) {
    store.dispatch({ type: FETCH });
  }
  return result;
};

const fetchMiddleware: Middleware<{}, AppState> = (store) => (next) => async (
  action: BaseAction
) => {
  const result = next(action);
  if (action.type === FETCH) {
    const items = await service.fetch();
    const load: LoadAction = {
      type: LOAD,
      items,
    };
    store.dispatch(load);
    return items;
  } else {
    return result;
  }
};

const deleteMiddleware: Middleware<{}, AppState> = (store) => (next) => async (
  action: BaseAction
) => {
  const result = next(action);
  if (action.type === REMOVE) {
    await service.delete(action.id);
    store.dispatch({
      type: FETCH,
    });
  } else {
    return result;
  }
};

const persistMiddleware: Middleware<{}, AppState> = (store) => (next) => async (
  action: BaseAction
) => {
  const result = next(action);
  if (action.type === PERSIST) {
    const item = action.item;
    const items = await service.persist(item);
    const load: LoadAction = {
      type: LOAD,
      items: Object.values(items),
    };
    store.dispatch(load);
    return items;
  } else {
    return result;
  }
};

const middlewares = [
  initMiddleware,
  fetchMiddleware,
  deleteMiddleware,
  persistMiddleware,
];
export default middlewares;
