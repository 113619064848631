import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/AppTypes";
import {
  FetchCommentsAction,
  FETCH_COMMENTS,
  MonthlyComments
} from "./CommentTypes";

export default function useComments(
  year: number,
  month: number
): MonthlyComments {
  const dispatch = useDispatch();
  const all = useSelector((state: AppState) => state.comments);
  const [comments, setComments] = useState({} as MonthlyComments);

  useEffect(() => {
    const action: FetchCommentsAction = { type: FETCH_COMMENTS, year, month };
    dispatch(action);
  }, [year, month, dispatch]);

  useEffect(() => {
    const date = new Date(year, month);
    const newComments =
      all.items[`${date.getFullYear()}`]?.[`${date.getMonth()}`] || {};
    setComments(newComments);
  }, [year, month, all]);

  return comments;
}
