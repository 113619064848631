import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/AppTypes";
import {
  FetchCommentsAction,
  FETCH_COMMENTS,
  PersistCommentAction,
  PERSIST_COMMENT,
  RemoveCommentAction,
  REMOVE_COMMENT
} from "./CommentTypes";

export function CommentsPanelEditor({
  year,
  month,
  id,
}: {
  year: number;
  month: number;
  id: string;
}) {
  const dispatch = useDispatch();
  const author = useSelector((state: AppState) => state.authorization.email);
  const [newMessage, setNewMessage] = useState("");

  const comments = useSelector(
    (state: AppState) =>
      state.comments.items[`${year}`]?.[`${month}`]?.[`${id}`] || []
  );
  const synching = useSelector(
    (state: AppState) => state.comments.synching[`${year}`]?.[`${month}`]
  );

  useEffect(() => {
    const action: FetchCommentsAction = { type: FETCH_COMMENTS, year, month };
    dispatch(action);
  }, []);

  function addComment(): void {
    if (newMessage?.length < 1) return;
    const action: PersistCommentAction = {
      type: PERSIST_COMMENT,
      year,
      month,
      id,
      item: {
        timestamp: new Date().getTime(),
        author,
        message: newMessage,
      },
    };
    setNewMessage("");
    dispatch(action);
  }

  function deleteComment(index: number): void {
    const action: RemoveCommentAction = {
      type: REMOVE_COMMENT,
      year,
      month,
      id,
      index,
    };
    dispatch(action);
  }

  return (
    <Box style={{ padding: 16, minHeight: "200px" }}>
      <Typography
        style={{
          fontWeight: 500,
          lineHeight: "1.5rem",
          fontSize: "0.875rem",
          letterSpacing: "0.01071em",
          marginBottom: 16,
        }}
      >
        Comments
      </Typography>
      <TextField
        label="Add..."
        variant="outlined"
        fullWidth
        style={{ marginBottom: 16 }}
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value as string)}
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            ev.preventDefault();
            addComment();
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Send"
                onClick={() => addComment()}
                disabled={synching === true}
              >
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      ></TextField>
      <Box>
        {comments.map((comment, index) => (
          <Tooltip
            title={new Date(comment?.timestamp || Date.now()).toLocaleString()}
            aria-label="add"
            key={`${index}`}
          >
            <Box style={{ marginBottom: 16 }}>
              <Box style={{ width: 260 }}>
                <Typography>{comment.message}</Typography>
              </Box>
              <Box>
                <Typography variant="body2">
                  <span style={{ color: "gray" }}>{comment.author}</span>{" "}
                  {comment.author === author ? (
                    <IconButton
                      size="small"
                      onClick={() => deleteComment(index)}
                      disabled={synching === true}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  ) : null}
                </Typography>
              </Box>
            </Box>
          </Tooltip>
        ))}
      </Box>
    </Box>
  );
}
