import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { useTeamCapacities } from "capacity/useTeamCapacities";
import { Chart } from "chart.js";
import { DashboardPeriod } from "dashboards/DashboardPeriod";
import { useTrendPeriods } from "dashboards/useTrendPeriods";
import { createRef, useEffect, useState } from "react";
import { DashboardCard } from "../../components/DashboardCard";

class CapacityItem {
  weekdays = 0; // weekday hours
  holidays = 0; // holiday hours
  timeOff = 0;  // timeoff hours
  nonWorkingHours = 0;
}

export function TeamTimeOffForecastCard({
  period,
  teamId,
}: {
  period: DashboardPeriod;
  teamId: string;
}) {
  const chartRef = createRef<HTMLCanvasElement>();
  const [chart, setChart] = useState(null as null | Chart);
  const [highTimeOffWarnings, setHighTimeOffWarnings] = useState([] as Date[]);
  const periods = useTrendPeriods(period, 0, 2);
  const capacities = useTeamCapacities(periods, teamId);
  const [months, setMonths] = useState([] as Date[]);

  useEffect(() => {
    const newMonths = periods.map((p) => new Date(p.year, p.month));
    setMonths(newMonths);
  }, [periods]);

  useEffect(() => {
    if (chart) {
      return;
    }
    const ref = chartRef?.current as HTMLCanvasElement;
    const newChart = new Chart(ref, {
      type: "bar",
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "bottom",
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            title: { display: true, text: "time off / weekdays (%)" },
            stacked: true,
          },
        },
      },
    });
    setChart(newChart);
  }, []);

  useEffect(() => {
    if (!chart || capacities.length !== months.length) {
      return;
    }
    const samples = capacities
      .map((c) => c.getItems())
      .map((capacity) =>
        capacity.reduce((item, capacity) => {
          const holidays = capacity.getHolidaysHours();
          const timeOff = capacity.getTimeOffHours();
          item.weekdays += capacity.getWeekdaysHours();
          item.holidays += holidays;
          item.timeOff += timeOff;
          item.nonWorkingHours += holidays + timeOff;
          return item;
        }, new CapacityItem())
      );

    // high time off
    setHighTimeOffWarnings(
      samples.reduce((all, sample, index) => {
        if (sample.nonWorkingHours / sample.weekdays >= 0.1) {
          all.push(months[index]);
        }
        return all;
      }, [] as Date[])
    );

    chart.data.labels = months.map((m, index) => {
      return (
        m.toLocaleString("default", { month: "long" }) +
        ` (${Math.round(
          samples[index].nonWorkingHours
        ).toLocaleString()} hours)`
      );
    });
    chart.data.datasets = [
      {
        label: "Holidays",
        data: samples.map((sample) =>
          Math.round((sample.holidays / sample.weekdays) * 100)
        ),
        borderColor: "rgb(183, 28, 28, 0.5)",
        backgroundColor: "rgb(183, 28, 28, 0.5)",
      },
      {
        label: "Time off",
        data: samples.map((sample) =>
          Math.round((sample.timeOff / sample.weekdays) * 100)
        ),
        borderColor: "rgb(0, 96, 100, 0.5)",
        backgroundColor: "rgb(0, 96, 100, 0.5)",
      },
    ];
    chart.update();
  }, [chart, months, capacities]);

  return (
    <DashboardCard title="Time off forecast" height={300}>
      {{
        body: <canvas ref={chartRef} />,
        footer:
          highTimeOffWarnings.length > 0 ? (
            <List>
              {highTimeOffWarnings.map((month, index) => (
                <ListItem key={`${index}`}>
                  <ListItemIcon>
                    <WarningIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText
                    secondary={`${month.toLocaleString("default", {
                      month: "long",
                    })}: non working hours are higher than 10%`}
                  />
                </ListItem>
              ))}
            </List>
          ) : undefined,
      }}
    </DashboardCard>
  );
}
