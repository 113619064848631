import { Middleware } from "redux";
import { AppState } from "../redux/AppTypes";
import service from "./AdministratorService";
import {
  AdministratorAction as BaseAction,
  FETCH_ADMINISTRATORS as FETCH,
  LoadAdministratorsAction as LoadAction,
  LOAD_ADMINISTRATORS as LOAD,
  PERSIST_ADMINISTRATOR as PERSIST,
  REMOVE_ADMINISTRATOR as REMOVE
} from "./AdministratorTypes";

const fetchMiddleware: Middleware<{}, AppState> =
  (store) => (next) => async (action: BaseAction) => {
    const result = next(action);
    if (action.type === FETCH) {
      const items = await service.fetch();
      items.sort((i1, i2) =>
        i1.id.toLowerCase().localeCompare(i2.id.toLowerCase())
      );
      const load: LoadAction = {
        type: LOAD,
        items,
      };
      store.dispatch(load);
      return items;
    } else {
      return result;
    }
  };

const deleteMiddleware: Middleware<{}, AppState> =
  (store) => (next) => async (action: BaseAction) => {
    const result = next(action);
    if (action.type === REMOVE) {
      const items = await service.delete(action.id);
      const load: LoadAction = {
        type: LOAD,
        items: Object.values(items),
      };
      store.dispatch(load);
      return items;
    } else {
      return result;
    }
  };

const persistMiddleware: Middleware<{}, AppState> =
  (store) => (next) => async (action: BaseAction) => {
    const result = next(action);
    if (action.type === PERSIST) {
      const item = action.item;
      const items = await service.persist(item);
      const load: LoadAction = {
        type: LOAD,
        items: Object.values(items),
      };
      store.dispatch(load);
      return items;
    } else {
      return result;
    }
  };

const middlewares = [fetchMiddleware, deleteMiddleware, persistMiddleware];
export default middlewares;
