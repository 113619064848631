import { useEffect, useState } from "react";
import useAllCustomers from "../../customers/useAllCustomers";
import {
  SystemNotification,
  SystemNotificationSource
} from "../SystemNotificationTypes";

export function useCustomersWithNoEMNotifications(
  year: number,
  month: number
): SystemNotification[] {
  const customers = useAllCustomers();

  const [notifications, setNotifications] = useState(
    [] as SystemNotification[]
  );

  useEffect(() => {
    const now = new Date(year, month);
    const newNotifications = Object.values(customers)
      .filter((c) => !c.isOffboarded(now) && c.hasNoEMFlag(year, month))
      .map((c) => {
        return {
          source: SystemNotificationSource.CUSTOMER,
          id: c.id,
          title: c.name,
          link: `/administration/customers/${c.id}`,
          description: "EM is undefined",
        };
      });
    setNotifications(newNotifications);
  }, [year, month, customers]);

  return notifications;
}
