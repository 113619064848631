import { Grid } from "@material-ui/core";
import { useEngineerCapacityByAggregator } from "capacity/useEngineerCapacityByAggregator";
import { CountCard } from "components/CountCard";
import { useBudgetByAggregator } from "customers/useBudgetByAggregator";
import useCustomersInAggregator from "customers/useCustomersInAggregator";
import { UtilizationTable } from "dashboards/utilization/UtilizationTable";
import useEngineersInAggregator from "engineers/useEngineersInAggregator";
import {
  CustomerAggregatorType,
  CustomerHandle,
  EngineerAggregatorType,
  EngineerHandle
} from "msd-capacity-planning-model";
import { useLocation } from "react-router-dom";
import { SkillCapacityByRegionPieChart } from "./SkillCapacityByRegionPieChart";
import { SkillCapacityByTeamPieChart } from "./SkillCapacityByTeamPieChart";
import { SkillDeltaByRegionScatterChart } from "./SkillDeltaByRegionScatterChart";
import { SkillDeltaByTeamScatterChart } from "./SkillDeltaByTeamScatterChart";

export function SkillDashboard({
  year,
  month,
  skill,
  customers,
  engineers,
  baseUrl,
}: {
  year: number;
  month: number;
  skill: string;
  customers: { [customerId: string]: CustomerHandle };
  engineers: { [engineerId: string]: EngineerHandle };
  baseUrl: string;
}) {
  const location = useLocation();
  const customersWithSkill = useCustomersInAggregator(
    year,
    month,
    CustomerAggregatorType.SKILL,
    skill
  );
  const budgets = useBudgetByAggregator(
    year,
    month,
    CustomerAggregatorType.ALL,
    customersWithSkill
  );
  const engineersWithSkill = useEngineersInAggregator(
    year,
    month,
    EngineerAggregatorType.SKILL,
    skill
  );
  const capacities = useEngineerCapacityByAggregator(
    year,
    month,
    EngineerAggregatorType.ALL,
    engineersWithSkill
  );

  const budget = Math.round(Object.values(budgets)?.[0]?.getTeamPlusOps() || 0);
  const capacity = Object.values(capacities)?.[0]?.allocatedHours || 0;

  return (
    <Grid container spacing={2} justifyContent="space-between">
      <Grid item xs={2}>
        <CountCard
          title="Customers"
          count={Object.values(customersWithSkill).length}
          path={`${location.pathname}/customers`}
        />
      </Grid>
      <Grid item xs={2}>
        <CountCard title="Budget" count={budget} />
      </Grid>
      <Grid item xs={2}>
        <CountCard
          title="Engineers"
          count={Object.values(engineersWithSkill).length}
          path={`${location.pathname}/engineers`}
        />
      </Grid>
      <Grid item xs={2}>
        <CountCard title="Capacity" count={capacity} />
      </Grid>
      <Grid item xs={2}>
        <CountCard title="Delta" count={capacity - budget} />
      </Grid>

      <Grid item xs={4}>
        <SkillCapacityByTeamPieChart year={year} month={month} skill={skill} />
      </Grid>
      <Grid item xs={8}>
        <SkillDeltaByTeamScatterChart year={year} month={month} skill={skill} />
      </Grid>
      <Grid item xs={4}>
        <SkillCapacityByRegionPieChart
          year={year}
          month={month}
          skill={skill}
        />
      </Grid>
      <Grid item xs={8}>
        <SkillDeltaByRegionScatterChart
          year={year}
          month={month}
          skill={skill}
        />
      </Grid>
      <Grid item xs={12}>
        <UtilizationTable
          year={year}
          month={month}
          customers={customersWithSkill}
          engineers={engineersWithSkill}
          linkPrefix={baseUrl}
        />
      </Grid>
    </Grid>
  );
}
