import { JiraState, JiraAction, DO_FETCH_JIRA, LOAD_JIRA } from "./JiraTypes";

const initialState: JiraState = {
  synching: false,
  projects: [],
  projectCategories: [],
};

export default function jiraReducer(
  state = initialState,
  action: JiraAction
): JiraState {
  switch (action.type) {
    case DO_FETCH_JIRA: {
      return {
        ...state,
        synching: true,
      };
    }
    case LOAD_JIRA: {
      return {
        synching: false,
        projects: action.projects,
        projectCategories: action.projectCategories,
      };
    }
    default:
      return state;
  }
}
