import { useSelector } from "react-redux";
import { AppState } from "redux/AppTypes";
import { createSelector } from "reselect";

const stateSelector = (state: AppState) => state.teams.items;

const useLeadsSelector = createSelector(stateSelector, (items) => {
  const leads = Object.values(items).reduce((all, team) => {
    all.add(team.lead);
    return all;
  }, new Set<string>());
  const response = Array.from(leads);
  response.sort();
  return response;
});

export function useLeads(): string[] {
  const state = useSelector((state: AppState) => state);
  return useLeadsSelector(state);
}
