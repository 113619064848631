import { Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { DashboardBreadcrumbs } from "../DashboardBreadcrumbs";
import { useDashboardPeriod } from "../useDashboardPeriod";
import { TeamEngineersDashboard } from "./TeamEngineersDashboard";

export function TeamEngineersDashboardPage() {
  const period = useDashboardPeriod();
  const params: { [key: string]: string } = useParams();
  const teamId = params.team;
  return (
    <Box>
      <DashboardBreadcrumbs />
      <TeamEngineersDashboard
        year={period.year}
        month={period.month}
        teamId={teamId}
      />
    </Box>
  );
}
