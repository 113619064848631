import { Middleware } from "redux";
import { AppState } from "../redux/AppTypes";
import {
  DoFetchJiraAction,
  DO_FETCH_JIRA,
  FetchJiraAction,
  FETCH_JIRA,
  LoadJiraAction,
  LOAD_JIRA,
} from "./JiraTypes";
import service from "./JiraService";

let fetched = false;

// FIXME: add force reload
const fetchJiraMiddleware: Middleware<{}, AppState> = (store) => (
  next
) => async (action: FetchJiraAction) => {
  next(action);
  if (action.type === FETCH_JIRA) {
    if (!fetched) {
      fetched = true;
      store.dispatch({ type: DO_FETCH_JIRA });
    }
  }
};

const doFetchJiraMiddleware: Middleware<{}, AppState> = (store) => (
  next
) => async (action: DoFetchJiraAction) => {
  next(action);
  if (action.type === DO_FETCH_JIRA) {
    const [projects, projectCategories] = await Promise.all([
      service.fetchProjects(),
      service.fetchProjectCategories(),
    ]);
    const action: LoadJiraAction = {
      type: LOAD_JIRA,
      projects,
      projectCategories,
    };
    store.dispatch(action);
  }
};

const middlewares = [fetchJiraMiddleware, doFetchJiraMiddleware];
export default middlewares;
