import { Grid, Paper } from "@material-ui/core";
import { DashboardPeriod } from "dashboards/DashboardPeriod";
import { CommentsPanelEditor } from "../../comments/CommentsPanelEditor";
import { EngineerCapacityTrendCard } from "./EngineerCapacityTrendCard";
import { EngineerOverviewCard } from "./EngineerOverviewCard";
import { EngineerTimeoffTrendCard } from "./EngineerTimeoffTrendCard";

export function EngineerDashboard({
  period,
  engineerId,
}: {
  period: DashboardPeriod;
  engineerId: string;
}) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      spacing={2}
    >
      <Grid item xs={4}>
        <EngineerOverviewCard engineerId={engineerId} />
      </Grid>
      <Grid item xs={4}>
        <EngineerCapacityTrendCard period={period} engineerId={engineerId} />
      </Grid>
      <Grid item xs={4}>
        <EngineerTimeoffTrendCard period={period} engineerId={engineerId} />
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={1}
        >
          <Grid item xs={9}>
            {/** TODO: add */}
          </Grid>
          <Grid item xs={3}>
            <Paper>
              <CommentsPanelEditor
                year={period.year}
                month={period.month}
                id={engineerId}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
