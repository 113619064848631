import { Box } from "@material-ui/core";
import { DashboardBreadcrumbs } from "dashboards/DashboardBreadcrumbs";
import { useDashboardPeriod } from "dashboards/useDashboardPeriod";
import { useParams } from "react-router-dom";
import { TeamPlanningDashboard } from "./TeamPlanningDashboard";

export function TeamPlanningDashboardPage() {
  const { year, month } = useDashboardPeriod();
  const params: { [key: string]: string } = useParams();

  return (
    <Box>
      <DashboardBreadcrumbs />
      <TeamPlanningDashboard teamId={params.team} year={year} month={month} />
    </Box>
  );
}
