import { IconButton, Tooltip } from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { getHarvestUserReportURL } from "../utils/harvestURLService";

const now = new Date();

export function HarvestUserLinkButton({
  user,
  year = now.getFullYear(),
  month = now.getMonth(),
}: {
  user: string;
  year?: number;
  month?: number;
}) {
  return user ? (
    <Tooltip title="Harvest">
      <IconButton
        onClick={() => {
          // FIXME: add harvest id to engineers
          const url = getHarvestUserReportURL(user, year, month);
          window.open(url, "_blank");
        }}
      >
        <ScheduleIcon />
      </IconButton>
    </Tooltip>
  ) : null;
}
