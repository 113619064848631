import { Grid } from "@material-ui/core";
import { CustomerHandle, EngineerHandle } from "msd-capacity-planning-model";
import { SkillMatchingTable } from "./SkillMatchingTable";
import { TopSkillsBarChart } from "./TopSkillsBarChart";

export function SkillsDashboard({
  year,
  month,
  customers,
  engineers,
  baseUrl,
}: {
  year: number;
  month: number;
  customers: { [customerId: string]: CustomerHandle };
  engineers: { [engineerId: string]: EngineerHandle };
  baseUrl: string;
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <TopSkillsBarChart
          year={year}
          month={month}
          customers={customers}
          engineers={engineers}
          max={10}
        />
      </Grid>
      <Grid item xs={9}>
        <SkillMatchingTable
          year={year}
          month={month}
          customers={customers}
          engineers={engineers}
        />
      </Grid>
    </Grid>
  );
}
