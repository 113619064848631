import { Middleware } from "redux";
import { AppState, InitAppAction, INIT_APP } from "../redux/AppTypes";
import service from "./HarvestService";
import {
  DoFetchHarvestBudget,
  DoFetchHarvestUsers,
  DO_FETCH_HARVEST_BUDGET,
  DO_FETCH_HARVEST_USERS,
  FetchHarvestBudget,
  FetchHarvestUsers,
  FETCH_HARVEST_BUDGET,
  FETCH_HARVEST_USERS,
  LoadHarvestBudget,

  LoadHarvestUsers,
  LOAD_HARVEST_BUDGET,
  LOAD_HARVEST_PROJECTS,

  LOAD_HARVEST_USERS
} from "./HarvestTypes";

const budgetCache = new Set<string>();
const teamHoursCache = new Set<string>();
let fetchedUsers = false;

const initMiddleware: Middleware<{}, AppState> =
  (store) => (next) => (action: InitAppAction) => {
    next(action);
    if (action.type === INIT_APP) {
      const year = new Date().getFullYear();
      const month = new Date().getMonth();
      const action: FetchHarvestBudget = {
        type: FETCH_HARVEST_BUDGET,
        year,
        month,
      };
      store.dispatch(action);
    }
  };

const fetchProjectsMiddleware: Middleware<{}, AppState> =
  (store) => (next) => async (action: InitAppAction) => {
    next(action);
    if (action.type === INIT_APP) {
      const projects = await service.fetchProjects();
      store.dispatch({
        type: LOAD_HARVEST_PROJECTS,
        projects,
      });
    }
  };

const doFetchBudgetMiddleware: Middleware<{}, AppState> =
  (store) => (next) => async (action: DoFetchHarvestBudget) => {
    next(action);
    if (action.type === DO_FETCH_HARVEST_BUDGET) {
      const budget = await service.fetchBudget(action.year, action.month);
      const load: LoadHarvestBudget = {
        type: LOAD_HARVEST_BUDGET,
        year: action.year,
        month: action.month,
        budget,
      };
      store.dispatch(load);
    }
  };

// TODO add force reload
const fetchBudgetMiddleware: Middleware<{}, AppState> =
  (store) => (next) => async (action: FetchHarvestBudget) => {
    next(action);
    if (action.type === FETCH_HARVEST_BUDGET) {
      const now = new Date();
      const requestedDate = new Date(action.year, action.month);
      // ignore future budgets
      if (requestedDate.getTime() > now.getTime()) {
        return;
      }
      const key = `${action.year}-${action.month}`;
      if (!budgetCache.has(key)) {
        budgetCache.add(key);
        const load: DoFetchHarvestBudget = {
          type: DO_FETCH_HARVEST_BUDGET,
          year: action.year,
          month: action.month,
        };
        store.dispatch(load);
      }
    }
  };

const fetchHarvestUsersMiddleware: Middleware<{}, AppState> =
  (store) => (next) => async (action: FetchHarvestUsers) => {
    next(action);
    if (action.type === FETCH_HARVEST_USERS && !fetchedUsers) {
      fetchedUsers = true;
      const doFetch: DoFetchHarvestUsers = {
        type: DO_FETCH_HARVEST_USERS,
      };
      store.dispatch(doFetch);
    }
  };

const doFetchUsersMiddleware: Middleware<{}, AppState> =
  (store) => (next) => async (action: DoFetchHarvestUsers) => {
    next(action);
    if (action.type === DO_FETCH_HARVEST_USERS) {
      const users = await service.fetchUsers();
      const load: LoadHarvestUsers = {
        type: LOAD_HARVEST_USERS,
        items: users,
      };
      store.dispatch(load);
    }
  };

const middlewares = [
  doFetchBudgetMiddleware,
  doFetchUsersMiddleware,
  fetchBudgetMiddleware,
  fetchHarvestUsersMiddleware,
  fetchProjectsMiddleware,
  initMiddleware,
];
export default middlewares;
