import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import { SystemNotificationList } from "./SystemNotificationsList";
import {
  SystemNotification,
  SystemNotificationType
} from "./SystemNotificationTypes";

const useStyles = makeStyles((theme) => ({
  root: {
    "&$disabled": {
      backgroundColor: "white",
    },
    '&:before': {
      display: 'none',
    },
    boxShadow: "none",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  disabled: {
    backgroundColor: "white",
  },
}));

const ICONS = {
  [SystemNotificationType.INFO]: (
    <InfoIcon fontSize="small" style={{ marginRight: 8, color: "lightgray" }} />
  ),
  [SystemNotificationType.WARNING]: (
    <WarningIcon fontSize="small" style={{ marginRight: 8, color: "orange" }} />
  ),
  [SystemNotificationType.ERROR]: (
    <ErrorIcon fontSize="small" style={{ marginRight: 8, color: "red" }} />
  ),
};

export function SystemNotificationsAccordion({
  title,
  notifications,
  dense = true,
  type = SystemNotificationType.WARNING,
}: {
  title: string;
  notifications: SystemNotification[];
  dense?: boolean;
  type?: SystemNotificationType;
}) {
  const classes = useStyles();

  return (
    <Accordion
      disabled={notifications.length < 1}
      classes={{ root: classes.root, disabled: classes.disabled }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {notifications.length > 0
          ? ICONS[type]
          : ICONS[SystemNotificationType.INFO]}
        <Typography className={classes.heading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SystemNotificationList notifications={notifications} dense={dense} />
      </AccordionDetails>
    </Accordion>
  );
}
