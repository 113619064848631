import { Container } from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import { LeadDashboardPage } from "./LeadDashboardPage";
import { LeadsDashboardPage } from "./LeadsDashboardPage";

export function LeadsDashboardRoutePage() {
  return (
    <Container maxWidth="xl">
      <Switch>
        <Route path="/dashboards/leads/:lead">
          <LeadDashboardPage baseUrl="/dashboards/leads/:lead" />
        </Route>
        <Route path="/dashboards/leads">
          <LeadsDashboardPage />
        </Route>
      </Switch>
    </Container>
  );
}
