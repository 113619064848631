import { Box } from "@material-ui/core";
import { DashboardBreadcrumbs } from "dashboards/DashboardBreadcrumbs";
import { useParams } from "react-router-dom";
import { useDashboardPeriod } from "../useDashboardPeriod";
import { EMDashboard } from "./EMDashboard";

export function EMDashboardPage() {
  const period = useDashboardPeriod();
  const params: { [key: string]: string } = useParams();
  const emId = params.em;

  return (
    <Box>
      <DashboardBreadcrumbs />
      <EMDashboard period={period} emId={emId} />
    </Box>
  );
}
