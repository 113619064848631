import {
  FETCH_SYNCHSTATUS,
  LOAD_SYNCHSTATUS,
  SynchStatusAction,
  SynchStatusState
} from "./SynchStatusTypes";

const initialState: SynchStatusState = {
  statuses: [],
  synching: true,
};

export default function synchStatusReducer(
  state = initialState,
  action: SynchStatusAction
): SynchStatusState {
  switch (action.type) {
    case FETCH_SYNCHSTATUS:
      return {
        statuses: [],
        synching: true,
      };
    case LOAD_SYNCHSTATUS:
      return {
        synching: false,
        statuses: action.statuses,
      };
    default:
      return state;
  }
}
