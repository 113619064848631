import { Theme } from "@material-ui/core";
const TONES = ["light", "main", "dark"];

export function getColors(theme: Theme, count: number) {
  const palette = theme.palette;
  const available = [
    palette.primary,
    palette.secondary,
    palette.error,
    palette.warning,
    palette.info,
    palette.success,
  ];
  const colors = [] as string[];
  let toneIndex = 0;
  while (colors.length < count) {
    if (toneIndex >= TONES.length) {
      toneIndex = 0;
    }
    const toneName = TONES[toneIndex];
    for (
      let colorIndex = 0;
      colorIndex < available.length && colors.length < count;
      colorIndex++
    ) {
      const color = available[colorIndex];
      const tone = (color as any)[toneName] as string;
      colors.push(tone);
    }
    toneIndex++;
  }
  return colors;
}

export function mapValueToColor(value: number) {
  const min = -100;
  const max = 100;

  if (value > 100) {
    value = 100;
  } else if (value < -100) {
    value = -100;
  }

  const normalizedValue = (value - min) / (max - min);

  let red, green, blue;

  if (normalizedValue < 0.5) {
    red = 255;
    green = Math.round(510 * normalizedValue);
    if (green > 255) (
      green = 255
    )
    blue = 0;
  } else {
    red = Math.round(510 * (1 - normalizedValue));
    if (red > 255) (
      red = 255
    )
    green = 255;
    blue = 0;
  }

  const redHex = red.toString(16).padStart(2, '0');
  const greenHex = green.toString(16).padStart(2, '0');
  const blueHex = blue.toString(16).padStart(2, '0');
  const alphaHex = '80'; // 0.5 opacity

  return `#${redHex}${greenHex}${blueHex}${alphaHex}`;
}