import {
  CommentAction,
  CommentsState,
  DO_FETCH_COMMENTS,
  LOAD_COMMENTS,
  PERSIST_COMMENT,
  REMOVE_COMMENT,
} from "./CommentTypes";
import _ from "lodash";

const initialState: CommentsState = {
  synching: {},
  items: {},
};

export default function commentsReducer(
  state = initialState,
  action: CommentAction
): CommentsState {
  switch (action.type) {
    case DO_FETCH_COMMENTS: {
      const year = action.year;
      const month = action.month;
      const newState = { ...state };
      _.defaultsDeep(newState.synching, { [year]: { [month]: false } });
      newState.synching[`${year}`][`${month}`] = true;
      return newState;
    }
    case LOAD_COMMENTS: {
      const year = action.year;
      const month = action.month;
      const newState = { ...state };
      delete newState.synching[`${year}`]?.[`${month}`];
      if (Object.keys(newState.synching[`${year}`] || {}).length < 1) {
        delete newState.synching[`${year}`];
      }
      _.defaultsDeep(newState.items, { [year]: { [month]: {} } });
      newState.items[`${year}`][`${month}`] = action.items;
      return newState;
    }
    case REMOVE_COMMENT: {
      const year = action.year;
      const month = action.month;
      const newState = { ...state };
      _.defaultsDeep(newState.synching, { [year]: { [month]: false } });
      newState.synching[`${year}`][`${month}`] = true;
      return newState;
    }
    case PERSIST_COMMENT: {
      const year = action.year;
      const month = action.month;
      const newState = { ...state };
      _.defaultsDeep(newState.synching, { [year]: { [month]: false } });
      newState.synching[`${year}`][`${month}`] = true;
      return newState;
    }
    default:
      return state;
  }
}
