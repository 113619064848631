import { useEffect, useState } from "react";
import { useEngineer } from "./useEngineer";

export function useEngineerSkills(id: string): string[] {
  const engineer = useEngineer(id);
  const [skills, setSkills] = useState([] as string[]);
  useEffect(() => {
    setSkills(engineer?.skills || []);
  }, [engineer]);
  return skills;
}
