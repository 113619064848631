import { EngineerAggregatorType, EngineerHandle } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useEngineerAggregator } from "./useEngineerAggregator";
import useEngineerByAggregator from "./useEngineerByAggregator";

export interface EngineersInAggregator {
  [engineerId: string]: EngineerHandle;
}

export default function useEngineersInAggregator(
  year: number,
  month: number,
  type: EngineerAggregatorType,
  ids: string | string[]
): EngineersInAggregator {
  const engineersByAggregator = useEngineerByAggregator(year, month, type);
  const [response, setResponse] = useState({} as EngineersInAggregator);
  const aggregator = useEngineerAggregator(year, month);

  useEffect(() => {
    const newResponse = {} as EngineersInAggregator;
    const idsArray = Array.isArray(ids) ? ids : [ids];
    idsArray.forEach((id) => {
      (engineersByAggregator[id] || []).forEach((engineer) => {
        newResponse[engineer.id] = engineer;
      });
    });
    setResponse(newResponse);
  }, [year, month, type, ids, aggregator, engineersByAggregator]);

  return response;
}
