import React, { useState } from 'react'
import { useJiraProjectLead } from './useJiraProjectLead'
import { Box, Button, CircularProgress, Tooltip } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import service from './JiraResourcesService'

export function JiraProjectLead({
  projectKey,
  leadEmail
}: {
  projectKey: string
  leadEmail: string
}) {
  const [reloadCounter, setReloadCounter] = useState(0)
  const lead = useJiraProjectLead(projectKey, leadEmail, reloadCounter)
  const [updateClicked, setUpdateClicked] = useState(false)
  const [updating, setUpdating] = useState(false)

  function onUpdate(): void {
    setUpdateClicked(true)
    setUpdating(true)
    service.putProjectLead(projectKey, leadEmail).finally(() => {
      setUpdating(false)
      setReloadCounter(reloadCounter + 1)
    })
  }

  const status =
    lead?.expected === true ? (
      <Tooltip title='Expected lead'>
        <CheckIcon style={{ color: 'green', marginLeft: 8 }} />
      </Tooltip>
    ) : (
      <Tooltip title='Unexpected lead'>
        <ClearIcon style={{ color: 'red', marginLeft: 8 }} />
      </Tooltip>
    )

  return (
    <Box>
      <span style={{ marginRight: 8, verticalAlign: 'middle' }}>
        {typeof lead === 'undefined' ? (
          <CircularProgress size={16} style={{ marginLeft: 8 }} />
        ) : (
          status
        )}
      </span>
      Project Lead: {lead?.displayName}
      {lead?.expected === false ? (
        <span>
          <Button
            variant='text'
            color='primary'
            disabled={updateClicked}
            onClick={() => onUpdate()}
          >
            Update
            {updating ? (
              <CircularProgress size={16} style={{ marginLeft: 8 }} />
            ) : null}
          </Button>
        </span>
      ) : (
        ''
      )}
    </Box>
  )
}
