import { Chart } from "chart.js";
import { EngineerHandle } from "msd-capacity-planning-model";
import { createRef, useEffect, useState } from "react";
import { DashboardCard } from "../../components/DashboardCard";
import getCompletedMonthRatio from "../../utils/getCompletedMonthRatio";
import { getHarvestUserReportURL } from "../../utils/harvestURLService";
import HelpIcon from "@material-ui/icons/Help";

export function HarvestEngineerHoursBarChartCard({
  year,
  month,
  engineers,
}: {
  year: number;
  month: number;
  engineers: { [engineerId: string]: EngineerHandle };
}) {
  const chartRef = createRef<HTMLCanvasElement>();
  const [chart, setChart] = useState(null as null | Chart<"bar">);
  const [height, setHeight] = useState(300);
  const [annotation, setAnnotation] = useState({
    type: "line",
    xMin: 0,
    xMax: 0,
    borderColor: "rgb(255, 99, 132)",
    borderWidth: 2,
  });
  const [sortedEngineers, setSortedEngineers] = useState(
    [] as EngineerHandle[]
  );

  useEffect(() => {
    setSortedEngineers(
      Object.values(engineers || {}).sort((e1, e2) => e1.compare(e2))
    );
  }, [engineers]);

  useEffect(() => {
    const expectedRatio = getCompletedMonthRatio(year, month) * 160;
    setAnnotation({
      type: "line",
      xMin: expectedRatio,
      xMax: expectedRatio,
      borderColor: "rgb(255, 99, 132)",
      borderWidth: 2,
    });
  }, [year, month, chart]);

  useEffect(() => {
    if (chart) {
      return;
    }
    const ref = chartRef?.current as HTMLCanvasElement;
    const newChart = new Chart(ref, {
      type: "bar",
      data: { labels: [], datasets: [] },
      options: {
        indexAxis: "y",
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          annotation: {
            annotations: {},
          },
        },
      },
    });
    setChart(newChart);
  }, []);

  useEffect(() => {
    if (!chart) {
      return;
    }
    const annotations =
      (chart?.options?.plugins?.annotation?.annotations as any) || {};
    annotations.expected = annotation;
    chart.update();
  }, [chart, annotation]);

  useEffect(() => {
    if (!chart) {
      return;
    }
    setHeight(Math.max(300, sortedEngineers.length * 22 + 20));
    chart.data.labels = sortedEngineers.map(
      (engineer) => `${engineer.firstName} ${engineer.lastName}`
    );
    chart.update();
  }, [chart, sortedEngineers]);

  useEffect(() => {
    if (!chart) {
      return;
    }
    chart.options.onClick = function (event, target) {
      if (target && target.length > 0) {
        const index = target[0].index;
        const harvestId = sortedEngineers[index].harvestId;
        if (harvestId) {
          const url = getHarvestUserReportURL(harvestId, year, month);
          window.open(url, "_blank");
        }
      }
    };
    chart.update();
  }, [chart, year, month, sortedEngineers]);

  useEffect(() => {
    if (!chart) {
      return;
    }
    chart.data.datasets = [
      {
        data: sortedEngineers.map( (e) => e.getHarvestHours(year, month).total || 0 ),
        borderColor: "rgb(0, 96, 100, 0.5)",
        backgroundColor: "rgb(0, 96, 100, 0.5)",
      },
    ];
    chart.update();
  }, [year, month, chartRef, chart, sortedEngineers]);

  return (
    <DashboardCard title="Engineer time tracking" height={height} helpicon={true}
    helptext="Information displayed here may be up to one hour delayed, and does not include running timers."> 
      {{ body: <canvas ref={chartRef} /> }}
    </DashboardCard>
  );
}
