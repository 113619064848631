import {
  EMHandle,
  getEMsByLead,
  getEMSubordinatesByLead
} from "msd-capacity-planning-model";
import { useSelector } from "react-redux";
import { AppState } from "redux/AppTypes";
import { createSelector } from "reselect";
import { useAllEMsSelector } from "./useAllEMs";

export const useEMByLeadRecusivelySelector = createSelector(
  [useAllEMsSelector],
  (ems) => {
    return getEMSubordinatesByLead(ems);
  }
);

export const useEMByLeadSelector = createSelector(
  [useAllEMsSelector],
  (ems) => {
    return getEMsByLead(Object.values(ems));
  }
);

export function useEMsByLead(recursive: boolean = false): {
  [lead: string]: EMHandle[];
} {
  const state = useSelector((state: AppState) => state);
  const emsByLead = useEMByLeadSelector(state);
  const recursiveEmsByLead = useEMByLeadRecusivelySelector(state);
  return recursive ? recursiveEmsByLead : emsByLead;
}
