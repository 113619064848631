import { SynchStatus } from "msd-capacity-planning-model";
import env from "../app/Environment";
import fetchService from "../utils/FetchService";

class HTTPSynchStatusService {
  constructor(private endpoint: string) {}

  async fetch(): Promise<SynchStatus[]> {
    return fetchService
      .fetch(`${this.endpoint}/synch/status`, {})
      .then((r) => r.json())
      .catch(() => []);
  }
}

const regionService: HTTPSynchStatusService = new HTTPSynchStatusService(
  env.endpoint
);

export default regionService;
