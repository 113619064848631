import { Container } from "@material-ui/core";
import { ManagerDashboardPage } from "dashboards/managers/ManagerDashboardPage";
import { Route, Switch } from "react-router-dom";
import { ManagersDashboardPage } from "../managers/ManagersDashboardPage";

export function ManagersDashboardRoutePage() {
  return (
    <Container maxWidth="xl">
      <Switch>
        <Route path="/dashboards/managers/:manager">
          <ManagerDashboardPage baseUrl="/dashboards/managers/:manager" />
        </Route>
        <Route path="/dashboards/managers">
          <ManagersDashboardPage />
        </Route>
      </Switch>
    </Container>
  );
}
