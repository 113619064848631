import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { NoStyleLink } from "components/NoStyleLink";
import { useBudgetByAggregator } from "customers/useBudgetByAggregator";
import useCustomersByAggregator from "customers/useCustomersByAggregator";
import { useEMsByLead } from "ems/useEMsByLead";
import { useSortedEMLeads } from "ems/useSortedEMLeads";
import { CustomerAggregatorType } from "msd-capacity-planning-model";

export function EMLeadsTable({ year, month }: { year: number; month: number }) {
  const emLeads = useSortedEMLeads(year, month);
  const customersByEMLead = useCustomersByAggregator(
    year,
    month,
    CustomerAggregatorType.EM_LEAD
  );
  const budgetsByEMLead = useBudgetByAggregator(
    year,
    month,
    CustomerAggregatorType.EM_LEAD
  );
  const emsByLead = useEMsByLead();
  const recursiveEmsByLead = useEMsByLead(true);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>EM Lead</TableCell>
            <TableCell>
              Direct
              <br />
              reports
            </TableCell>
            <TableCell>EMs</TableCell>
            <TableCell>Customers</TableCell>
            <TableCell>Budget</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(emLeads).map((emLead, index) => {
            return (
              <TableRow key={`lead-${index}`}>
                <TableCell>
                  <NoStyleLink
                    pathname={`/dashboards/emleads/${emLead.id}`}
                    text={`${index + 1}. ${emLead.getDisplayName()}`}
                  />
                </TableCell>
                <TableCell>{emsByLead[emLead.id]?.length || 0}</TableCell>
                <TableCell>
                  {recursiveEmsByLead[emLead.id]?.length || 0}
                </TableCell>
                <TableCell>
                  {customersByEMLead[emLead.id]?.length || 0}
                </TableCell>
                <TableCell>
                  {Math.round(
                    budgetsByEMLead[emLead.id]?.getTeamPlusOps() || 0
                  ).toLocaleString()}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
