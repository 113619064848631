import { Box, Tab, Tabs } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import useTeamCapacityInAggregator from "capacity/useTeamCapacityInAggregator";
import { useBudgetInAggregator } from "customers/useBudgetInAggregator";
import {
  CustomerAggregatorType,
  TeamCapacityAggregatorType
} from "msd-capacity-planning-model";
import React, { useState } from "react";
import { TeamBudgetTable } from "./TeamBudgetTable";
import { TeamCapacityTable } from "./TeamCapacityTable";

export function TeamUtilizationTable({
  year,
  month,
  teamId,
  linkPrefix,
}: {
  year: number;
  month: number;
  teamId: string;
  linkPrefix: string;
}) {
  const [tab, setTab] = useState("customers");
  const capacity = useTeamCapacityInAggregator(
    year,
    month,
    TeamCapacityAggregatorType.TEAM,
    teamId
  );
  const budget = useBudgetInAggregator(
    year,
    month,
    CustomerAggregatorType.TEAM,
    teamId
  );

  return (
    <Box style={{ minHeight: "400px" }}>
      <Box style={{ marginBottom: "16px" }}>
        <TabContext value={tab}>
          <Tabs
            value={tab}
            onChange={(event, newValue) => setTab(`${newValue}`)}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label={`Budget (${Math.round(
                budget.opsHours + budget.teamHours
              ).toLocaleString()} hours)`}
              value="customers"
            />
            <Tab
              label={`Engineers (${Math.round(
                capacity.allocatedHours
              ).toLocaleString()} hours)`}
              value="engineers"
            />
          </Tabs>

          <TabPanel value={"customers"}>
            <TeamBudgetTable
              year={year}
              month={month}
              teamId={teamId}
              linkPrefix={linkPrefix}
            />
          </TabPanel>
          <TabPanel value={"engineers"}>
            <TeamCapacityTable
              year={year}
              month={month}
              teamId={teamId}
              size="small"
              linkPrefix="/engineers"
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}
