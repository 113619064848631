import { Region } from "msd-capacity-planning-model";

export const FETCH_REGIONS = "regions/fetch";
export const LOAD_REGIONS = "regions/load";
export const REMOVE_REGION = "regions/remove";
export const PERSIST_REGION = "regions/persist";

export interface RegionsState {
  items: { [id: string]: Region };
  synching: boolean;
}

export interface FetchRegionsAction {
  type: typeof FETCH_REGIONS;
}

export interface LoadRegionsAction {
  type: typeof LOAD_REGIONS;
  items: Region[];
}

export interface RemoveRegionAction {
  type: typeof REMOVE_REGION;
  id: string;
}

export interface PersistRegionAction {
  type: typeof PERSIST_REGION;
  item: Region;
}

export type RegionAction =
  | FetchRegionsAction
  | LoadRegionsAction
  | RemoveRegionAction
  | PersistRegionAction;
