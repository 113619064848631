import { Box } from "@material-ui/core";
import { SystemNotificationsAccordion } from "notifications/SystemNotificationsAccordion";
import { SystemNotificationType } from "notifications/SystemNotificationTypes";
import React from "react";
import useSynchNotifications from "./useTeamNotifications";

export function SynchNotificationsCard() {
  const notifications = useSynchNotifications();
  return notifications.length > 0 ? (
    <Box>
      <SystemNotificationsAccordion
        title={`Sync errors: ${notifications.length}`}
        notifications={notifications}
        type={SystemNotificationType.ERROR}
      />
    </Box>
  ) : null;
}
