import { Box, Button, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { AppState } from "redux/AppTypes";

export function WebSocketStatusPanel() {
  const alive = useSelector((state: AppState) => state.websocket.alive);
  return alive ? null : (
    <Box style={{ textAlign: "center", backgroundColor: "yellow", padding: 8 }}>
      <Typography color="error" variant="body1">
        Connection lost ...{" "}
        <Button
          onClick={() => window.location.reload()}
          color="primary"
          variant="contained"
        >
          Reload
        </Button>
      </Typography>
    </Box>
  );
}
