import { Box, CircularProgress, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import service, { S3DataFile } from "./DataService";


export function DataExportPanel() {
  const [dataFile, setDataFile] = useState(undefined as S3DataFile | undefined)

  useEffect(() => {
    if (!dataFile) {
      service.getDataFile().then(newData => setDataFile(newData));
    }
  }, [])

  return <Box>
    {dataFile ?
      <Box>
        <a href={dataFile.signedUrl} target="_blank" download>{dataFile.key}</a><br />
      </Box> :
      <Box>
        <Box><CircularProgress size={16} /> Exporting data ...</Box>
        <Box><Typography variant="caption" style={{ color: "gray" }}>Wait a few seconds until the file is generated</Typography> </Box>
      </Box>}
  </Box>
}