import { Route, Switch } from "react-router-dom";
import { CustomerDashboardPage } from "../customer/CustomerDashboardPage";
import { EngineerDashboardPage } from "../engineer/EngineerDashboardPage";
import { SkillCustomersDashboardPage } from "./SkillCustomersDashboardPage";
import { SkillDashboardPage } from "./SkillDashboardPage";
import { SkillEngineersDashboardPage } from "./SkillEngineersDashboardPage";

export function SkillDashboardRoutePage({ baseUrl }: { baseUrl: string }) {
  return (
    <Switch>
      <Route path={`${baseUrl}/:skill/customers/:customer`}>
        <CustomerDashboardPage />
      </Route>
      <Route path={`${baseUrl}/:skill/customers`}>
        <SkillCustomersDashboardPage baseUrl={baseUrl} />
      </Route>
      <Route path={`${baseUrl}/:skill/engineers/:engineer`}>
        <EngineerDashboardPage />
      </Route>
      <Route path={`${baseUrl}/:skill/engineers`}>
        <SkillEngineersDashboardPage baseUrl={baseUrl} />
      </Route>
      <Route path={`${baseUrl}/:skill`}>
        <SkillDashboardPage baseUrl={baseUrl} />
      </Route>
    </Switch>
  );
}
