import { EngineerAggregatorType } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { StringCounter } from "../utils/ItemCounter";
import useEngineersInAggregator from "./useEngineersInAggregator";

export function useEngineerSkillsInTeam(
  year: number,
  month: number,
  teamId: string
): StringCounter {
  const engineers = useEngineersInAggregator(
    year,
    month,
    EngineerAggregatorType.TEAM,
    teamId
  );
  const [skills, setSkills] = useState(new StringCounter());

  useEffect(() => {
    const newSkills = Object.values(engineers).reduce((skills, engineer) => {
      new Set(engineer.skills || []).forEach((skill) => {
        if (!!skill) skills.count(skill);
      });
      return skills;
    }, new StringCounter());
    setSkills(newSkills);
  }, [year, month, teamId, engineers]);

  return skills;
}
