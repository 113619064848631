import { JiraProject, JiraProjectCategory } from "msd-capacity-planning-model";
import env from "../app/Environment";
import fetchService from "../utils/FetchService";

export class JiraService {
  constructor(public endpoint: string) {}

  fetchProjects(): Promise<JiraProject[]> {
    return fetchService
      .fetch(`${this.endpoint}/jira/projects`)
      .then((r) => r.json());
  }

  fetchProjectCategories(): Promise<JiraProjectCategory[]> {
    return fetchService
      .fetch(`${this.endpoint}/jira/projectcategories`)
      .then((r) => r.json());
  }
}

const service = new JiraService(env.endpoint);

export default service;
