import {
  Box,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@material-ui/core";
import { Size as TableSize } from "@material-ui/core/Table";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import useTeamCapacityInAggregator from "capacity/useTeamCapacityInAggregator";
import { CommentsPanel } from "comments/CommentsPanel";
import { EngineerLinkBar } from "dashboards/engineer/EngineerLinkBar";
import { EngineerWarningFlagsIcon } from "engineers/EngineerWarningFlagsIcon";
import {
  Engineer,
  TeamCapacityAggregatorType
} from "msd-capacity-planning-model";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { DAY_DATE_FORMAT } from "../../utils/dateFormats";

class CapacityTotals {
  hours = 0;
  actualHours = 0;
}

const useStyles = makeStyles(() => ({
  totalsRow: {
    "& > .MuiTableCell-body": { fontWeight: "bold" },
  },
}));

export function TeamCapacityTable({
  year,
  month,
  teamId,
  linkPrefix = "",
  notes = false,
  notesKey = "",
  size = "medium",
  actualHours = true,
}: {
  year: number;
  month: number;
  teamId: string;
  linkPrefix?: string;
  notes?: boolean;
  notesKey?: string;
  size?: TableSize;
  actualHours?: boolean;
}) {
  const classes = useStyles();
  const location = useLocation();
  const teamCapacity = useTeamCapacityInAggregator(
    year,
    month,
    TeamCapacityAggregatorType.TEAM,
    teamId
  );
  const [anchorEl, setAnchorEl] = useState(null as any);
  const [menuItem, setMenuItem] = useState(null as Engineer | null);
  const [totals, setTotals] = useState(new CapacityTotals());

  useEffect(() => {
    const totals = teamCapacity.getItems().reduce((totals, teamCapacity) => {
      const engineer = teamCapacity.engineer;
      totals.hours += teamCapacity.getUtilizationHours();
      totals.actualHours += engineer.getHarvestHours(year, month).total;
      return totals;
    }, new CapacityTotals());
    setTotals(totals);
  }, [year, month, teamCapacity]);

  return (
    <Box>
      <Paper>
        <TableContainer>
          <Table size={size}>
            <TableHead>
              <TableRow>
                <TableCell>Engineer</TableCell>
                <TableCell>Hours</TableCell>
                <TableCell>PTO</TableCell>
                <TableCell>Weekdays</TableCell>
                <TableCell>Holidays</TableCell>
                <TableCell>Region</TableCell>
                {actualHours ? <TableCell>Actual hours</TableCell> : null}
                {notes ? <TableCell>Notes</TableCell> : null}
                <TableCell
                  style={size === "small" ? {} : { minWidth: "150px" }}
                >
                  {" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.totalsRow}>
                <TableCell>Total</TableCell>
                <TableCell>
                  {Math.round(totals.hours).toLocaleString()}
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                {actualHours ? (
                  <TableCell>
                    {Math.round(totals.actualHours).toLocaleString()}
                  </TableCell>
                ) : null}
                {notes ? <TableCell></TableCell> : null}
                <TableCell> </TableCell>
              </TableRow>
              {teamCapacity.getItems().map((item, index) => {
                const engineer = item.engineer;
                const periodTooltip = item.periods
                  .map((period, index) => {
                    return `${period.getWorkingDays()}  days @${
                      period.percent
                    }% from ${period.regionCapacity.from.toLocaleString(
                      "en",
                      DAY_DATE_FORMAT
                    )}: ${Math.round(period.getCapacityHours())} hours`;
                  })
                  .join(", ");

                const ptoTooltip = ` days @${Math.round(
                  item.getAverageCapacityPercent()
                )}%: ${Math.round(item.getTimeOffHours())} hours`;

                return (
                  <TableRow key={engineer.id}>
                    <TableCell>
                      <Link
                        to={{
                          pathname: `${location.pathname}${linkPrefix}/${engineer.id}`,
                        }}
                        style={{ textDecoration: "inherit", color: "inherit" }}
                      >
                        {index + 1}. {engineer.firstName} {engineer.lastName}{" "}
                        <EngineerWarningFlagsIcon id={engineer.id} />
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={periodTooltip}>
                        <span>{Math.round(item.getUtilizationHours())}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {item.timeOff > 0 ? (
                        <Tooltip title={ptoTooltip}>
                          <span>{item.timeOff}</span>
                        </Tooltip>
                      ) : (
                        " "
                      )}{" "}
                    </TableCell>
                    <TableCell>{item.getWeekdays()}</TableCell>
                    <TableCell>{item.getHolidays()} </TableCell>
                    <TableCell>{item.region?.name} </TableCell>
                    {actualHours ? (
                      <TableCell>
                        {Math.round(
                          engineer.getHarvestHours(year, month).total
                        )}{" "}
                      </TableCell>
                    ) : null}
                    {notes ? (
                      <TableCell>
                        <CommentsPanel
                          year={year}
                          month={month}
                          id={`${notesKey}${engineer.id}`}
                          hideAuthor={true}
                        />
                      </TableCell>
                    ) : null}
                    <TableCell>
                      {size === "small" ? (
                        <IconButton
                          size="small"
                          onClick={(event: any) => {
                            setMenuItem(engineer);
                            setAnchorEl(event.currentTarget);
                          }}
                        >
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      ) : (
                        <EngineerLinkBar
                          year={year}
                          month={month}
                          engineer={engineer}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem>
            <EngineerLinkBar
              year={year}
              month={month}
              engineer={menuItem as Engineer}
              notesKey={notesKey}
            />
          </MenuItem>
        </Menu>
      </Paper>
    </Box>
  );
}
