import { EngineerAggregator } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";

export function useEngineerAggregator(
  year: number,
  month: number
): EngineerAggregator {
  const [aggregator, setAggregator] = useState(
    new EngineerAggregator(year, month)
  );

  useEffect(() => {
    const aggregator = new EngineerAggregator(year, month);
    setAggregator(aggregator);
  }, [year, month]);

  return aggregator;
}
