export const SET_ALIVE = "websocket/setalive";

export interface WebSocketState {
  alive: boolean;
}

export interface SetWebSocketAliveAction {
  type: typeof SET_ALIVE;
  alive: boolean;
}

export type WebSocketAction = SetWebSocketAliveAction;
