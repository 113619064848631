import fetch, { RequestInfo, RequestInit, Response } from "node-fetch";
import { currentSession } from "../auth/Authorization";

export interface FetchServiceSubscriber {
  onError(msg: string): void;
}

export class FetchService {
  subscribers: FetchServiceSubscriber[] = [];

  async fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
    const session = await currentSession();
    const idToken = session.getIdToken().getJwtToken();
    init = init || {};
    init.headers = init.headers || {};
    const headers = init.headers as { [key: string]: string };
    headers["Authorization"] = idToken;
    return fetch(url, init)
      .then(async (response) => {
        if (response.status !== 200) {
          const text = await response.text();
          this.subscribers.forEach((s) => s.onError(text));
        }
        return response;
      })
      .catch((e) => {
        this.subscribers.forEach((s) => s.onError(e.message));
        throw e;
      });
  }

  subscribe(subscriber: FetchServiceSubscriber) {
    this.subscribers.push(subscriber);
  }
}

const service = new FetchService();

export default service;
