import Auth from '@aws-amplify/auth'

export interface FetchServiceSubscriber {
  onError(msg: string): void
}
export class FetchService {
  async fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
    const session = await Auth.currentSession()
    const idToken = session.getIdToken().getJwtToken()
    init = init || {}
    init.headers = init.headers || {}
    const headers = init.headers as { [key: string]: string }
    headers.Authorization = idToken
    const response = await fetch(url, init)
    return response
  }
}

const service = new FetchService()

export default service
