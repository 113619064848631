import { Administrator } from "msd-capacity-planning-model";
export const FETCH_ADMINISTRATORS = "administrators/fetch";
export const LOAD_ADMINISTRATORS = "administrators/load";
export const REMOVE_ADMINISTRATOR = "administrators/remove";
export const PERSIST_ADMINISTRATOR = "administrators/persist";

export interface AdministratorsState {
  items: { [id: string]: Administrator };
  synching: boolean;
}

export interface FetchAdministratorsAction {
  type: typeof FETCH_ADMINISTRATORS;
}

export interface LoadAdministratorsAction {
  type: typeof LOAD_ADMINISTRATORS;
  items: Administrator[];
}

export interface RemoveAdministratorAction {
  type: typeof REMOVE_ADMINISTRATOR;
  id: string;
}

export interface PersistAdministratorAction {
  type: typeof PERSIST_ADMINISTRATOR;
  item: Administrator;
}

export type AdministratorAction =
  | FetchAdministratorsAction
  | LoadAdministratorsAction
  | RemoveAdministratorAction
  | PersistAdministratorAction;
