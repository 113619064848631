import { CircularProgress, Container } from "@material-ui/core";
import { Chart, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LoginPanel } from "../auth/LoginPanel";
import { AppState } from "../redux/AppTypes";
import { UnauthorizedApp } from "../UnauthorizedApp";
import "./App.css";
import { ErrorBoundary } from "./AppErrorBoundary";
import { AuthorizedApp } from "./AuthorizedApp";
Chart.register(...registerables);
Chart.register(annotationPlugin);

enum AuthStatus {
  SYNCHING,
  UNAUTHENTICATED,
  AUTHORIZED,
  UNAUTHORIZED,
}

function App() {
  const authorization = useSelector((state: AppState) => state.authorization);
  const [status, setStatus] = useState(AuthStatus.SYNCHING);

  useEffect(() => {
    if (authorization.synching) {
      setStatus(AuthStatus.SYNCHING);
    } else if (
      authorization.email.length > 0 &&
      authorization.myself.roles.length > 0
    ) {
      setStatus(AuthStatus.AUTHORIZED);
    } else if (authorization.email.length > 0) {
      setStatus(AuthStatus.UNAUTHORIZED);
    } else {
      setStatus(AuthStatus.UNAUTHENTICATED);
    }
  }, [authorization]);

  return (
    <ErrorBoundary>
      <Container disableGutters={true} maxWidth={false}>
        <div className="App-messages-wrapper">
          {status === AuthStatus.SYNCHING ? <CircularProgress /> : null}
          {status === AuthStatus.UNAUTHENTICATED ? <LoginPanel /> : null}
        </div>
        {status === AuthStatus.UNAUTHORIZED ? <UnauthorizedApp /> : null}
        {status === AuthStatus.AUTHORIZED ? <AuthorizedApp /> : null}
      </Container>
    </ErrorBoundary>
  );
}

export default App;
