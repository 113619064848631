import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { useEngineerCapacityByAggregator } from "capacity/useEngineerCapacityByAggregator";
import { NoStyleLink } from "components/NoStyleLink";
import { useBudgetByAggregator } from "customers/useBudgetByAggregator";
import useCustomers from "customers/useCustomers";
import useCustomersByAggregator from "customers/useCustomersByAggregator";
import { RegionLinkBar } from "dashboards/region/RegionLinkBar";
import useEngineersByAggregator from "engineers/useEngineerByAggregator";
import { useEngineers } from "engineers/useEngineers";
import {
  CustomerAggregatorType,
  EngineerAggregatorType
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useSortedRegions from "regions/useSortedRegions";

export function RegionsTable({ year, month }: { year: number; month: number }) {
  const location = useLocation();
  const regions = useSortedRegions();
  const allEngineers = useEngineers(year, month);
  const allCustomers = useCustomers(year, month);
  const customers = useCustomersByAggregator(
    year,
    month,
    CustomerAggregatorType.REGION
  );
  const budgets = useBudgetByAggregator(
    year,
    month,
    CustomerAggregatorType.REGION
  );
  const engineers = useEngineersByAggregator(
    year,
    month,
    EngineerAggregatorType.REGION
  );
  const capacities = useEngineerCapacityByAggregator(
    year,
    month,
    EngineerAggregatorType.REGION
  );

  const [totalBudget, setTotalBudget] = useState(0);
  const [totalCapacity, setTotalCapacity] = useState(0);

  useEffect(() => {
    setTotalBudget(
      Object.values(budgets).reduce(
        (total, budget) => total + budget.teamHours + budget.opsHours,
        0
      )
    );
  }, [budgets]);

  useEffect(() => {
    setTotalCapacity(
      Object.values(capacities).reduce(
        (total, capacity) => total + capacity.allocatedHours,
        0
      )
    );
  }, [capacities]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Delta</TableCell>
          <TableCell>Customers</TableCell>
          <TableCell>Budget</TableCell>
          <TableCell>Engineers</TableCell>
          <TableCell>Capacity</TableCell>
          <TableCell style={{ width: 100 }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {regions.map((region, index) => {
          const customerCount = Math.round(customers[region.id]?.length || 0);
          const customerPercent = Math.round(
            (customerCount / allCustomers.size()) * 100
          ).toLocaleString();

          const budgetSum = Math.round(
            budgets[region.id]?.teamHours + budgets[region.id]?.opsHours || 0
          );
          const budgetPercent = Math.round(
            (budgetSum / totalBudget) * 100
          ).toLocaleString();

          const engineerCount = Math.round(engineers[region.id]?.length || 0);
          const engineerPercent = Math.round(
            (engineerCount / Object.keys(allEngineers).length) * 100
          ).toLocaleString();

          const engineerCapacitySum = Math.round(
            capacities[region.id]?.allocatedHours || 0
          );
          const engineerCapacitySumPercent = Math.round(
            (engineerCapacitySum / totalCapacity) * 100
          ).toLocaleString();

          return (
            <TableRow key={`region-${index}}`}>
              <TableCell>
                <NoStyleLink
                  pathname={`${location.pathname}/${region.id}`}
                  text={region.name}
                />
              </TableCell>
              <TableCell>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {Math.round(engineerCapacitySum - budgetSum).toLocaleString()}
                  {budgetSum > engineerCapacitySum ? (
                    <WarningIcon color="secondary" style={{ marginLeft: 8 }} />
                  ) : null}
                </Box>
              </TableCell>
              <TableCell>
                {customerCount.toLocaleString()} ({customerPercent}%)
              </TableCell>
              <TableCell>
                {budgetSum.toLocaleString()} ({budgetPercent}%)
              </TableCell>
              <TableCell>
                {engineerCount.toLocaleString()} ({engineerPercent}%)
              </TableCell>
              <TableCell>
                {engineerCapacitySum.toLocaleString()} (
                {engineerCapacitySumPercent}%)
              </TableCell>
              <TableCell>
                <RegionLinkBar year={year} month={month} region={region} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
