import {
  Box,
  IconButton,
  TableContainer,
} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import { NoStyleLink } from "components/NoStyleLink";
import MUIDataTable from "mui-datatables";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { CommentsPanel } from "comments/CommentsPanel";
import useComments from "comments/useComments";
import useCustomersInAggregator from "customers/useCustomersInAggregator";
import { CustomerLinkBar } from "dashboards/customer/CustomerLinkBar";
import {
  Customer,
  CustomerAggregatorType,
  CustomerBurnedBudgetCombinationKey,
  serializeCustomerBurnedBudgetCombinationKey
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useTeam from "teams/useTeam";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

class BudgetTotals {
  teamHours = 0;
  opsHours = 0;
  emHours = 0;
  actualHours = {} as { [key: string]: number };
}
interface CustomerTable {
  headers: any;
  rows: any;
}

export function TeamRetrospectiveCustomersHourstTable({
                                                        year,
                                                        month,
                                                        teamId,
                                                      }: {
  year: number;
  month: number;
  teamId: string;
}) {
  const location = useLocation();
  const customers = useCustomersInAggregator(
    year,
    month,
    CustomerAggregatorType.TEAM,
    teamId
  );
  const comments = useComments(year, month);
  const [anchorEl, setAnchorEl] = useState(null as any);

  const [menuItem, setMenuItem] = useState(null as Customer | null);

  const [customerTable, setCustomerTable] = useState({
    headers: [],
    rows: [[]],
  } as CustomerTable);

  const theme = createTheme({
    overrides: {
      MuiTableHead: {
        root: {
          '& th': {
            verticalAlign: 'bottom',
          },
        },
      },
    },
  });

  useEffect(() => {
    const newTable: CustomerTable = { headers: [], rows: [] };
    const newKeys = Object.values(customers).reduce((keys, c) => {
      c.getBurnedBudgetCombinationKeys(year, month).forEach((key) =>
        keys.set(key.getLabel(), key)
      );
      return keys;
    }, new Map<string, CustomerBurnedBudgetCombinationKey>());

    const totals = Object.values(customers).reduce((totals, c) => {
      const budget = c.getBudget(year, month);
      totals.teamHours += budget.teamHours || 0;
      totals.opsHours += budget.opsHours || 0;
      totals.emHours += budget.emHours || 0;
      const burnedBudget = c.getBurnedBudget(year, month);
      newKeys.forEach((key) => {
        const str = serializeCustomerBurnedBudgetCombinationKey(key);
        totals.actualHours[str] = totals.actualHours[str] || 0;
        totals.actualHours[str] += burnedBudget.getCombination(key);
      });
      return totals;
    }, new BudgetTotals());


    //setup headers / columns
    newTable.headers.push({
        "name":"Customer",
        options: {
          viewColumns: false,

          setCellProps: () => ({ style: { 
            whiteSpace: "nowrap",
            position: "sticky",
            left:"0",
            background:"white",
            zIndex:100
          }}),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              left: 0,
              background: "white",
              zIndex: 101
            }
          }),
          customHeadLabelRender: () => (
            <table><thead><tr>
              <th style={{ verticalAlign: "bottom", textAlign:"left" }}>Customer<br/><br/>Total</th>
            </tr></thead></table>
          ),
          customBodyRender: (_customer: any, _tableMeta: any, _updateValue: (arg0: any) => any) => {
            if (_customer.value === 'Total'){
              return _customer.value
            }
            return <NoStyleLink
              pathname={`${location.pathname}/customers/${_customer.id}`}
              text={_customer.value}
            />
          }
        }
      }
    );
    newTable.headers.push({
      "name":"Team hours",
      options: {
        customHeadLabelRender: () => (
          <table><thead>
          <tr><th style={{ verticalAlign: "bottom", textAlign:"left" }}>Team<br/>hours<br/><br />{(totals.teamHours || 0).toFixed(2)}</th></tr>
          </thead></table>
        ),
        customBodyRender: (_customer: any, _tableMeta: any, _updateValue: (arg0: any) => any) => {
          return _customer.value
        }
      }
    });
    newTable.headers.push({
      "name":"Ops Hours",

      options: {
        customHeadLabelRender: () => (<table><thead><tr><th style={{ verticalAlign: "bottom", textAlign:"left" }}>Ops<br/>hours<br/><br />{(totals.opsHours || 0).toFixed(2)}</th></tr></thead></table>),
        customBodyRender: (_customer: any, _tableMeta: any, _updateValue: (arg0: any) => any) => {
          return _customer.value
        }
      }
    });
    newTable.headers.push({
      "name":"EM hours",
      options: {
        customHeadLabelRender: () => <table><thead><tr><th style={{ verticalAlign: "bottom" , textAlign:"left"}}>EM<br/>hours<br/><br />{(totals.emHours || 0).toFixed(2)}</th></tr></thead></table>,
        customBodyRender: (_customer: any, _tableMeta: any, _updateValue: (arg0: any) => any) => {
          return _customer.value
        }
      }
    });
    Array.from(newKeys.values()).forEach((key) => {
      const str = serializeCustomerBurnedBudgetCombinationKey(key);
      return newTable.headers.push({
        "name":`Actual ${key.getLabel()} hours`,
        options: {
          setCellProps: () => ({ style: { minWidth: "50px", maxWidth: "50px", backgroundColor:'#e8ffe8' }}),
          customHeadLabelRender: () => {
            return <table><thead><tr><th style={{ verticalAlign: "bottom", textAlign:"left"}}>
              Actual
              <br/>
              {key.getLabel()}
              <br/>
              hours
              <br/>
              <br/>
              {(totals.actualHours[str] || 0).toFixed(2)}
            </th></tr></thead></table>
          },
          customBodyRender: (_customer: any, _tableMeta: any, _updateValue: (arg0: any) => any) => {
            return _customer.value
          }
        }
      })}
    );

    newTable.headers.push({
      "name":"Notes",
      options: {
        sort: false,
        customHeadLabelRender: () => {
          return <table><thead><tr><th style={{ verticalAlign: "bottom", textAlign:"left"}}>
            Notes
            <br/>
            <br/>
            <br/>
          </th></tr></thead></table>
        },

        customBodyRender: (_customer: any, _tableMeta: any, _updateValue: (arg0: any) => any) => {
          return _customer.value
        }
      }
    });
    newTable.headers.push({
      "name":"",
      options: {
        sort: false,
        viewColumns: false,
        customBodyRender: (_customer: any, _tableMeta: any, _updateValue: (arg0: any) => any) => {
          return _customer.value
        }
      }
    });

    // customers
    Object.values(customers).forEach((c, index) => {
      const rowIdx = index;
      const budget = c.getBudget(year, month);
      newTable.rows.push(
        [
          {
            value: `${c.name}`,
            id: `${c.id}`,
            link: `${location.pathname}/customers/${c.id}`,
          },
          {value: (budget.teamHours || 0).toFixed(2)},
          {value: (budget.opsHours || 0).toFixed(2)},
          {value: (budget.emHours || 0).toFixed(2)}
        ],
      );

      newKeys.forEach((key) => {
        const value = c
          .getBurnedBudget(year, month)
          .getCombination(key)
          .toFixed(2);
        newTable.rows[rowIdx].push({value: value});
      });

      const commentsCell = (
        <Box>
          <CommentsPanel
            year={year}
            month={month}
            id={c.id}
            hideAuthor={true}
          />
        </Box>
      );
      newTable.rows[rowIdx].push({ value: commentsCell });
      newTable.rows[rowIdx].push({
        value: (
          <IconButton
            size="small"
            onClick={(event: any) => {
              setMenuItem(c);
              setAnchorEl(event.currentTarget);
            }}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
        ),
      });
    });

    setCustomerTable(newTable);
  }, [year, month, location.pathname, customers, comments]);


  const options = {
    download: true,
    filter: false,
    print: false,
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRowsHeader:false,
    selectableRows: "none" as const,
    customSearch: (searchQuery: any, currentRow: any, columns: any)=>{
      for (let i = 0; i < columns.length; i++) {
        const cellValue = currentRow[i].value;
        if (
          typeof(cellValue) == 'string' &&
          cellValue.toLowerCase().includes(searchQuery.toLowerCase())
        )
        {return true}
      }
      return false

    },
    onDownload: (buildHead: (arg0: any) => string, buildBody: (arg0: any) => string, columns: any, data: { index: any; data: any; }[]) => {
      const headers = columns.map((column: { customHeadLabelRender: (arg0: any, arg1: number) => any; label: any; }) => {
        if (typeof column.customHeadLabelRender === 'function') {
          return column.customHeadLabelRender(column, -1);
        }
        return column.label;
      });
      const lastItems = headers.map((header: any) => {
        if (typeof header != 'string'){
          const nestedChildren = header?.props?.children?.props?.children?.props?.children?.props?.children;
          const lastChild = nestedChildren[nestedChildren?.length - 1];
          return lastChild;
        }
      });
      const totalRow = {index: 0, data: lastItems}
      data.forEach(function(obj){obj.index++})
      data.unshift(totalRow)
      data.map(obj => obj.data.splice(-2));
      columns.splice(-2)
      return "\uFEFF" + buildHead(columns) + buildBody(
        data.map((it: { index: any; data: any; }) => {
          const returnData = ({ index: it.index,
            data: it.data.map((obj: { value: any; }) => {
              if (typeof obj === 'string'){
                return obj
              } else {
                return obj.value
              }
            })
          })

          return returnData}
        )
      );
    },
    customSort: (data: any[], colIndex: string | number, order: string) => {
      function stringToFloat(str: any) {
        const num = parseFloat(str);
        return isNaN(num) ? str : num;
      }

      return data.sort((a,b) => {
        const valueA = stringToFloat(a.data[colIndex].value);
        const valueB = stringToFloat(b.data[colIndex].value);
        let comparison = 0;

        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }

        return order === 'asc' ? comparison : -comparison;

      })

    }
  }


  return (
    <Box>

      <ThemeProvider theme={theme}>
        <MUIDataTable
          title={'Customers'}
          columns={customerTable.headers}
          data={customerTable.rows}
          options={options}>
        </MUIDataTable>
      </ThemeProvider>

      <TableContainer>
      </TableContainer>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem>
          <CustomerLinkBar
            year={year}
            month={month}
            customer={menuItem as Customer}
          />
        </MenuItem>
      </Menu>
    </Box>
  );
}
