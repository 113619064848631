import { Grid, Paper, Typography } from "@material-ui/core";
import useTeamCapacityInAggregator from "capacity/useTeamCapacityInAggregator";
import { CountCard } from "components/CountCard";
import { useBudgetInAggregator } from "customers/useBudgetInAggregator";
import useCustomersInAggregator from "customers/useCustomersInAggregator";
import {
  CustomerAggregatorType,
  TeamCapacityAggregatorType
} from "msd-capacity-planning-model";
import useTeam from "teams/useTeam";
import { TeamCapacityTable } from "../TeamCapacityTable";
import { TeamCompositionTable as TeamRetrospectiveEngineersRolesTable } from "../TeamCompositionTable";
import { TeamHolidaysCard } from "../TeamHolidaysCard";
import { TeamOverviewCard } from "../TeamOverviewCard";
import { TeamRetrospectiveCustomersHourstTable } from "./TeamRetrospectiveCustomersHoursTable";

export function TeamRetrospectiveDashboard({
  year,
  month,
  teamId,
}: {
  year: number;
  month: number;
  teamId: string;
}) {
  const team = useTeam(teamId);
  const customers = useCustomersInAggregator(
    year,
    month,
    CustomerAggregatorType.TEAM,
    teamId
  );
  const capacity = useTeamCapacityInAggregator(
    year,
    month,
    TeamCapacityAggregatorType.TEAM,
    teamId
  );
  const budget = useBudgetInAggregator(
    year,
    month,
    CustomerAggregatorType.TEAM,
    teamId
  );

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      spacing={2}
      style={{ marginTop: 16 }}
    >
      <Grid item xs={12}>
        <Typography variant="h5">{team?.name} retrospective</Typography>
        <TeamOverviewCard year={year} month={month} teamId={teamId} />
      </Grid>
      <Grid item xs={2}>
        <CountCard title="Customers" count={Object.keys(customers).length} />
      </Grid>
      <Grid item xs={2}>
        <CountCard
          title="Budget"
          count={Math.round(budget.opsHours + budget.teamHours)}
        />
      </Grid>
      <Grid item xs={2}>
        <CountCard
          title="Engineers"
          count={Object.keys(capacity.items).length}
        />
      </Grid>
      <Grid item xs={2}>
        <CountCard
          title="Capacity"
          count={Math.round(capacity.allocatedHours)}
        />
      </Grid>
      <Grid item xs={2}>
        <CountCard
          title="Delta"
          count={Math.round(
            capacity.allocatedHours - budget.opsHours - budget.teamHours
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <TeamRetrospectiveCustomersHourstTable
            teamId={teamId}
            year={year}
            month={month}
          />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <TeamCapacityTable
            teamId={teamId}
            year={year}
            month={month}
            linkPrefix="/engineers"
            size="small"
            notes={true}
          />
        </Paper>
      </Grid>

      <Grid item xs={6}>
        <Paper>
          <TeamRetrospectiveEngineersRolesTable
            teamId={teamId}
            year={year}
            month={month}
          />
        </Paper>
      </Grid>

      <Grid item xs={6}>
        <Paper>
          <TeamHolidaysCard teamId={teamId} year={year} month={month} />
        </Paper>
      </Grid>
    </Grid>
  );
}
