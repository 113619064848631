import {
  AppBar,
  Box,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Tab,
  Tabs
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import DehazeIcon from "@material-ui/icons/Dehaze";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { DataExportMenuItem } from "data/DataExportMenuItem";
import { docsService } from "docs/DocsService";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";
import { WebSocketStatusPanel } from "websocket/WebSocketStatusPanel";
import { AdministrationPage } from "../administration/AdministrationPage";
import { AUTH_SIGNOUT } from "../auth/AuthorizationTypes";
import { useEmail } from "../auth/useEmail";
import { DashboardsRoutePage } from "../dashboards/DashboardsRoutePage";
import {
  AppState,
  REMOVE_APP_FEEDBACK,
  ReportFeedbackAction,
  REPORT_APP_FEEDBACK
} from "../redux/AppTypes";
import fetchService from "../utils/FetchService";
import { useSynching } from "../utils/useSynching";
import "./App.css";

const DEFAULT_TAB = "dashboards";

export function AuthorizedApp() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const email = useEmail();
  const [tab, setTab] = useState(DEFAULT_TAB);
  const [moreOptionsMenuEl, setMoreOptionsMenuEl] = React.useState(null as any);
  const synching = useSynching();
  const feedback = useSelector((state: AppState) => state.feedback);

  useEffect(() => {
    fetchService.subscribe({
      onError: (message) => {
        const action: ReportFeedbackAction = {
          type: REPORT_APP_FEEDBACK,
          feedback: message,
        };
        dispatch(action);
      },
    });
  }, [dispatch]);

  useEffect(() => {
    const l = location.pathname.split("/");
    const tab = l.length > 1 && l[1].length > 0 ? l[1] : DEFAULT_TAB;
    setTab(tab);
  }, [location]);

  const removeFeedback = () => {
    dispatch({ type: REMOVE_APP_FEEDBACK });
  };

  return (
    <Box style={{ marginBottom: 32 }}>
      <AppBar position="fixed">
        <Grid justifyContent="space-between" alignItems="center" container>
          <Grid item>
            <Tabs
              id="main-navigation"
              onChange={(e, value) => history.push(`/${value}`)}
              value={tab}
            >
              <Tab label="Dashboards" value="dashboards" />
              <Tab label="Administration" value="administration" />
              <Tab
                label="Help"
                value="help"
                onClick={() => docsService.getDocs("/index.html")}
              />
            </Tabs>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="more"
              onClick={(e) => setMoreOptionsMenuEl(e.target)}
            >
              <DehazeIcon style={{ color: "white" }} />
            </IconButton>
            <Menu
              anchorEl={moreOptionsMenuEl}
              keepMounted
              open={Boolean(moreOptionsMenuEl)}
              onClose={() => setMoreOptionsMenuEl(null)}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <DataExportMenuItem />
              <MenuItem onClick={() => dispatch({ type: AUTH_SIGNOUT })}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                Logout ({email})
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
        <LinearProgress
          style={{
            visibility: synching.any ? "visible" : "hidden",
          }}
        />
      </AppBar>
      <Box style={{ marginTop: 64 }}>
        <WebSocketStatusPanel />
        <Switch>
          <Route path="/dashboards">
            <Container maxWidth="xl">
              <DashboardsRoutePage />
            </Container>
          </Route>
          <Route path="/administration">
            <AdministrationPage />
          </Route>
          <Route path="/docs">Not found</Route>
          <Route path="/">
            <Redirect to={`/${DEFAULT_TAB}`} />
          </Route>
        </Switch>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={feedback.length > 0}
        autoHideDuration={6000}
        message={feedback[0]}
        onClose={removeFeedback}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={removeFeedback}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </Box>
  );
}
