import { Box, Grid } from "@material-ui/core";
import useEngineersCapacity from "capacity/useEngineersCapacity";
import { CountCard } from "components/CountCard";
import { useBudgetInAggregator } from "customers/useBudgetInAggregator";
import useCustomersInAggregator from "customers/useCustomersInAggregator";
import { DashboardBreadcrumbs } from "dashboards/DashboardBreadcrumbs";
import { SkillMatchingTable } from "dashboards/skills/SkillMatchingTable";
import { TopSkillsBarChart } from "dashboards/skills/TopSkillsBarChart";
import { useDashboardPeriod } from "dashboards/useDashboardPeriod";
import useEngineersInAggregator from "engineers/useEngineersInAggregator";
import {
  CustomerAggregatorType,
  EngineerAggregatorType
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { EngineersByRoleCard } from "./EngineersByRoleCard";

const AGGREGATOR = CustomerAggregatorType.REGION;
const ENGINEER_AGGREGATOR = EngineerAggregatorType.REGION;

export function RegionDashboardPage() {
  const { year, month } = useDashboardPeriod();
  const params: { [key: string]: string } = useParams();
  const location = useLocation();
  const [regionId, setRegionId] = useState("");
  const customers = useCustomersInAggregator(year, month, AGGREGATOR, regionId);
  const engineers = useEngineersInAggregator(
    year,
    month,
    ENGINEER_AGGREGATOR,
    regionId
  );
  const budgets = useBudgetInAggregator(year, month, AGGREGATOR, regionId);
  const capacities = useEngineersCapacity(year, month, engineers);

  useEffect(() => {
    setRegionId(params.region || "");
  }, [params]);

  const budget = budgets.teamHours + budgets.opsHours || 0;
  const capacity = capacities.allocatedHours;
  return (
    <Box>
      <DashboardBreadcrumbs />
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={2}>
          <CountCard
            title="Customers"
            count={Object.values(customers).length}
            path={`${location.pathname}/customers`}
          />
        </Grid>
        <Grid item xs={2}>
          <CountCard title="Budget" count={Math.round(budget)} />
        </Grid>
        <Grid item xs={2}>
          <CountCard
            title="Engineers"
            count={Object.values(engineers).length}
            path={`${location.pathname}/engineers`}
          />
        </Grid>
        <Grid item xs={2}>
          <CountCard title="Capacity" count={Math.round(capacity)} />
        </Grid>
        <Grid item xs={2}>
          <CountCard title="Delta" count={Math.round(capacity - budget)} />
        </Grid>

        <Grid item xs={6}>
          <EngineersByRoleCard
            year={year}
            month={month}
            engineers={engineers}
          />
        </Grid>

        <Grid item xs={6}>
          <TopSkillsBarChart
            year={year}
            month={month}
            engineers={engineers}
            customers={customers}
          />
        </Grid>

        <Grid item xs={12}>
          <SkillMatchingTable
            year={year}
            month={month}
            customers={customers}
            engineers={engineers}
            link={false}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
