import { Box, Card, CardContent, Grid, Tooltip, Typography } from "@material-ui/core";
import { Chart } from "chart.js";
import { DashboardPeriod } from "dashboards/DashboardPeriod";
import { useTrendPeriods } from "dashboards/useTrendPeriods";
import { Customer, CustomerHandle } from "msd-capacity-planning-model";
import { createRef, useEffect, useState } from "react";
import useAllCustomers from "../../customers/useAllCustomers";
import {
  AggregatedHarvestBudgetTrend,
  useHarvestBudgetTrend
} from "../../harvest/useHarvestBudgets";
import getMonthName from "../../utils/getMonthName";

function HarvestBudgetTrendImpl({
  months,
  trend,
  height = 300,
}: {
  months: DashboardPeriod[];
  trend: AggregatedHarvestBudgetTrend;
  height?: number;
}) {
  const chartRef = createRef<HTMLCanvasElement>();
  const [chart, setChart] = useState(null as null | Chart);

  useEffect(() => {
    if (chart) {
      return;
    }
    const ref = chartRef?.current as HTMLCanvasElement;
    const newChart = new Chart(ref, {
      type: "line",
      data: {
        labels: [],
        datasets: [
          {
            label: "Budget",
            data: [],
            borderColor: "rgb(183, 28, 28, 0.5)",
            backgroundColor: "rgb(183, 28, 28, 0.5)",
          },
          {
            label: "Consumed",
            data: [],
            borderColor: "rgb(0, 96, 100, 0.5)",
            backgroundColor: "rgb(0, 96, 100, 0.5)",
          },
          {
            label: "Billable",
            data: [],
            borderColor: "rgb(63, 81, 181, 0.5)",
            backgroundColor: "rgb(63, 81, 181, 0.5)",
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "bottom",
          },
        },
        scales: {
          y: {
            ticks: {
              precision: 0,
            },
            beginAtZero: false,
          },
        },
      },
    });
    setChart(newChart);
  }, []);

  useEffect(() => {
    if (!chart) {
      return;
    }
    chart.data.labels = months.map((month) =>
      getMonthName(new Date(month.year, month.month))
    );
    chart.data.datasets[0].data = months.map(
      (month) => trend.get(month.year, month.month).total
    );
    chart.data.datasets[1].data = months.map(
      (month) => trend.get(month.year, month.month).consumed
    );
    chart.data.datasets[2].data = months.map(
      (month) => trend.get(month.year, month.month).billable
    );
    chart.update();
  }, [months, chart, trend]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Tooltip title="Data Source: Harvest" placement="top-start">
              <Typography variant="h5" component="h2">
                Harvest budget trend
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box style={{ height: `${height}px` }}>
              <canvas ref={chartRef} />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export function HarvestBudgetTrend({
  period,
  height = 300,
  customers,
}: {
  period: DashboardPeriod;
  height?: number;
  customers?: {[id: string]: CustomerHandle}
}) {
  const periods = useTrendPeriods(period, -5, 0);
  const trend = useHarvestBudgetTrend(periods, customers);
  return (
    <HarvestBudgetTrendImpl
      months={periods}
      trend={trend}
      height={height}
    />
  );
}

// FIXME: rationalize this file, most probably this one should be reused
export function CustomersHarvestBudgetTrend({
  period,
  customers,
  height = 300,
}: {
  period: DashboardPeriod;
  customers: { [customerId: string]: Customer };
  height?: number;
}) {
  const trendPeriods = useTrendPeriods(period, -2, 0);
  const allBudgets = useHarvestBudgetTrend(trendPeriods);
  const [budgets, setBudgets] = useState(new AggregatedHarvestBudgetTrend());

  useEffect(() => {
    const ids = new Set(
      Object.values(customers)
        .filter((c) => c.harvestProjectId)
        .map((c) => c.harvestProjectId)
    );
    const newBudgets = allBudgets.getFilteredAggregatedBudget((budget) =>
      ids.has(budget.harvestProjectId)
    );
    setBudgets(newBudgets);
  }, [customers, allBudgets]);

  return (
    <HarvestBudgetTrendImpl
      months={trendPeriods}
      trend={budgets}
      height={height}
    />
  );
}

export function CustomerHarvestBudgetTrend({
  period,
  harvestProjectId,
  height = 300,
}: {
  period: DashboardPeriod;
  harvestProjectId: string;
  height?: number;
}) {
  const months = useTrendPeriods(period, -2, 0);
  const budgets = useHarvestBudgetTrend(months);
  const [filteredBudget, setFilteredBudget] = useState(
    new AggregatedHarvestBudgetTrend()
  );
  useEffect(() => {
    const newFilteredBudget = budgets.getFilteredAggregatedBudget(
      (budget) => budget.harvestProjectId === harvestProjectId
    );
    setFilteredBudget(newFilteredBudget);
  }, [harvestProjectId, budgets]);
  return (
    <HarvestBudgetTrendImpl
      months={months}
      trend={filteredBudget}
      height={height}
    />
  );
}

export function EMHarvestBudgetTrend({
  period,
  emId,
  height = 300,
}: {
  period: DashboardPeriod;
  emId: string;
  height?: number;
}) {
  const months = useTrendPeriods(period, -2, 0);
  const customers = useAllCustomers();
  const [harvestProjectIds, setHarvestProjectIds] = useState(new Set<string>());
  const budgets = useHarvestBudgetTrend(months);
  const [filteredBudget, setFilteredBudget] = useState(
    new AggregatedHarvestBudgetTrend()
  );
  useEffect(() => {
    const newHarvestProjectIds = new Set(
      Object.values(customers)
        .filter((c) => c.engagementManager === emId)
        .map((c) => c.harvestProjectId)
    );
    setHarvestProjectIds(newHarvestProjectIds);
  }, [emId, customers]);

  useEffect(() => {
    const newFilteredBudget = budgets.getFilteredAggregatedBudget((budget) =>
      harvestProjectIds.has(budget.harvestProjectId)
    );
    setFilteredBudget(newFilteredBudget);
  }, [harvestProjectIds, budgets]);

  return (
    <HarvestBudgetTrendImpl
      months={months}
      trend={filteredBudget}
      height={height}
    />
  );
}

export function TeamHarvestBudgetTrend({
  period,
  teamId,
  height = 300,
}: {
  period: DashboardPeriod;
  teamId: string;
  height?: number;
}) {
  const customers = useAllCustomers();
  const months = useTrendPeriods(period, -5, 0);
  const [harvestProjectIds, setHarvestProjectIds] = useState(new Set<string>());
  const budgets = useHarvestBudgetTrend(months);
  const [filteredBudget, setFilteredBudget] = useState(
    new AggregatedHarvestBudgetTrend()
  );

  // FIXME??: filtered only by the current month
  // should probably be a more complex time based filter
  useEffect(() => {
    const newHarvestProjectIds = new Set(
      Object.values(customers)
        .filter((c) => c.getTeam(period.year, period.month) === teamId)
        .map((c) => c.harvestProjectId)
    );
    setHarvestProjectIds(newHarvestProjectIds);
  }, [period, teamId, customers]);

  useEffect(() => {
    const newFilteredBudget = budgets.getFilteredAggregatedBudget((budget) =>
      harvestProjectIds.has(budget.harvestProjectId)
    );
    setFilteredBudget(newFilteredBudget);
  }, [harvestProjectIds, budgets]);

  return (
    <HarvestBudgetTrendImpl
      months={months}
      trend={filteredBudget}
      height={height}
    />
  );
}
