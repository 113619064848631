import env from "../app/Environment";
import fetchService from "../utils/FetchService";
import { Myself } from "./AuthorizationTypes";

export interface AuthorizationService {
  getMyself(): Promise<Myself>;
}

export class HttpAuthorizationService implements AuthorizationService {
  constructor(private endpoint: string) {}
  getMyself(): Promise<Myself> {
    return fetchService
      .fetch(`${this.endpoint}/myself`, {})
      .then((r) => r.json());
  }
}

const service: AuthorizationService = new HttpAuthorizationService(
  env.endpoint
);

export default service;
