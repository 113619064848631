import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FETCH_REGIONS, REMOVE_REGION } from "./RegionTypes";
import useSortedRegions from "./useSortedRegions";

export function RegionsPanel() {
  const history = useHistory();
  const dispatch = useDispatch();
  const regions = useSortedRegions();
  const year = new Date().getFullYear();

  return (
    <Box>
      <Grid>
        <Tooltip title="Add">
          <Button
            onClick={() => history.push(`/administration/regions/`)}
            color="primary"
            variant="contained"
          >
            New region
          </Button>
        </Tooltip>
        <Tooltip title="Refresh">
          <IconButton onClick={() => dispatch({ type: FETCH_REGIONS })}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid container>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Code</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Holidays in {year}</TableCell>
                <TableCell>Working hours</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody data-testid="regions-table-body">
              {regions.map((item, index) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>
                      {index + 1}. {item.code}
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.countHolidaysInYear(year)}</TableCell>
                    <TableCell>{item.workingHours}</TableCell>
                    <TableCell align="right">
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() =>
                            history.push(`/administration/regions/${item.id}`)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => {
                            dispatch({ type: REMOVE_REGION, id: item.id });
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
}
