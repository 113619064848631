import DateFnsUtils from "@date-io/date-fns";
import { dateReturn } from "utils/dateReturn";
import {
  Container,
  FormControl,
  IconButton,
  Input,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell as DefaultTableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  withStyles
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import WarningIcon from "@material-ui/icons/Warning";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import "date-fns";
import {
  EngineerCapacity,
  EngineerUtilizationHandle
} from "msd-capacity-planning-model";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../redux/AppTypes";
import useSortedTeams from "../teams/useSortedTeams";
import { useAllTeams } from "../teams/useAllTeams";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(DefaultTableCell);

export function EngineerUtilizationForm(props: {
  defaultValue: EngineerCapacity[];
  onChange: (budget: EngineerCapacity[]) => void;
}) {
  const [items, setItems] = useState(
    JSON.parse(JSON.stringify(props.defaultValue || [])) as EngineerCapacity[]
  );
  //const teams = useSelector((state: AppState) => state.teams.items);
  //const sortedTeams = useSortedTeams();

  const now = new Date();
  const teams = useAllTeams();
  const sortedTeams = useSortedTeams(now.getFullYear(), now.getMonth());  
  const [historyDeleted, setHistoryDeleted] = useState(false);

  useEffect(() => {
    setItems(
      JSON.parse(JSON.stringify(props.defaultValue)) as EngineerCapacity[]
    );
  }, [props.defaultValue]);

  function onChange(newItems: EngineerCapacity[]) {
    const handle = new EngineerUtilizationHandle(newItems);
    const sortedItems = handle.withSortedUtilization().utilization;
    props.onChange(sortedItems);
    setItems(sortedItems);
  }

  function addItem(): void {
    const handle = new EngineerUtilizationHandle(items);
    const date = handle.getNewCapacityDate();
    const newItem: EngineerCapacity = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
      team: "",
      percent: 75,
    };
    const newItems = handle.withNewCapacity(newItem).utilization;
    onChange(newItems);
  }

  function updateItem(index: number, item: EngineerCapacity): void {
    const newItems = JSON.parse(JSON.stringify(items));
    newItems[index] = item;
    onChange(newItems);
  }

  function deleteItem(index: number, frozen: boolean): void {
    if (frozen) {
      setHistoryDeleted(true);
    }
    const newDemand = JSON.parse(JSON.stringify(items));
    newDemand.splice(index, 1);
    onChange(newDemand);
  }

  //const now = new Date();
  return (
    <Container>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>From</TableCell>
              <TableCell>Team*</TableCell>
              <TableCell>Percentage*</TableCell>
              <TableCell align="right">
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => {
              const date = isNaN(item.year)
                ? new Date()
                : new Date(item.year, item.month || 0, item.day || 1);
              const frozen = now.getTime() > date.getTime();
              return (
                <TableRow key={`item-${index}`}>
                  <TableCell>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        format="MM/dd/yyyy"
                        value={date}
                        disabled={frozen}
                        onChange={(newDate) => {
                          const newItem: EngineerCapacity = JSON.parse(
                            JSON.stringify(item)
                          );
                          newItem.year = dateReturn(newDate, 'year')
                          newItem.month = dateReturn(newDate, 'month')
                          newItem.day = dateReturn(newDate, 'day')
                          updateItem(index, newItem);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth margin="normal">
                      <Select
                        disabled={frozen}
                        value={item.team || ""}
                        onChange={(e) => {
                          item.team = (e.target.value as string) || "";
                          updateItem(index, item);
                        }}
                        input={<Input />}
                        renderValue={(value) => teams[`${value}`]?.name || ""}
                      >
                        {Object.values(sortedTeams).map((team) => (
                          <MenuItem key={team.id} value={team.id}>
                            <ListItemText primary={team.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField
                      disabled={frozen}
                      value={items[index].percent || 0}
                      onChange={(e) => {
                        item.percent = parseInt(e.target.value);
                        updateItem(index, item);
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Delete">
                      <IconButton onClick={() => deleteItem(index, frozen)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
            {historyDeleted ? (
              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{ textAlign: "center", backgroundColor: "#ffffcc" }}
                >
                  <WarningIcon color="error" /> A history entry has been
                  deleted.
                  <br />
                  Please discard changes if the deletion was not intentional.
                  <br />
                  Please remind that removing history entries will impact on
                  historical reports.
                </TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell colSpan={4} align="right">
                <Tooltip title="New item">
                  <IconButton onClick={addItem}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
