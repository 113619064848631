import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import synchStatusMiddleware from "../administration.synchstatus/SynchStatusMiddleware";
import synchStatusReducer from "../administration.synchstatus/SynchStatusReducer";
import administratorsMiddleware from "../administrators/AdministratorsMiddleware";
import administratorsReducer from "../administrators/AdministratorsReducer";
import authorizationMiddleware from "../auth/AuthorizationMiddleware";
import authorizationReducer from "../auth/AuthorizationReducer";
import commentsMiddleware from "../comments/CommentsMiddleware";
import commentsReducer from "../comments/CommentsReducer";
import customersMiddleware from "../customers/CustomersMiddleware";
import customersReducer from "../customers/CustomersReducer";
import managersMiddleware from "../ems/EMsMiddleware";
import emsReducer from "../ems/EMsReducer";
import engineersMiddleware from "../engineers/EngineersMiddleware";
import engineersReducer from "../engineers/EngineersReducer";
import harvestMiddleware from "../harvest/HarvestMiddleware";
import harvestReducer from "../harvest/HarvestReducer";
import jiraMiddleware from "../jira/JiraMiddleware";
import jiraReducer from "../jira/JiraReducer";
import managersReducer from "../managers/ManagersReducer";
import regionsMiddleware from "../regions/RegionsMiddleware";
import regionsReducer from "../regions/RegionsReducer";
import teamMiddlewares from "../teams/TeamsMiddleware";
import teamsReducer from "../teams/TeamsReducer";
import websocketMiddleware from "../websocket/WebSocketMiddleware";
import websocketReducer from "../websocket/WebSocketReducer";
import feedbackReducer from "./feedbackReducer";
import initReducer from "./InitReducer";

const reducers = {
  administrators: administratorsReducer,
  authorization: authorizationReducer,
  comments: commentsReducer,
  customers: customersReducer,
  engineers: engineersReducer,
  ems: emsReducer,
  feedback: feedbackReducer,
  harvest: harvestReducer,
  initiated: initReducer,
  jira: jiraReducer,
  regions: regionsReducer,
  teams: teamsReducer,
  managers: managersReducer,
  websocket: websocketReducer,
  synchStatus: synchStatusReducer,
};
const middlewares = teamMiddlewares
  .concat(administratorsMiddleware)
  .concat(authorizationMiddleware)
  .concat(commentsMiddleware)
  .concat(customersMiddleware)
  .concat(engineersMiddleware)
  .concat(harvestMiddleware)
  .concat(jiraMiddleware)
  .concat(managersMiddleware)
  .concat(regionsMiddleware)
  .concat(synchStatusMiddleware)
  .concat(websocketMiddleware);

const store = createStore(
  combineReducers(reducers),
  composeWithDevTools(applyMiddleware(...middlewares))
);

// store.subscribe(() => console.log(store.getState()));

export default store;
