import { useTeamCapacityByAggregator } from "capacity/useTeamCapacityByAggregator";
import { DashboardPeriod } from "dashboards/DashboardPeriod";
import {
  CustomerAggregatorType,
  TeamCapacityAggregatorType
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { LabeledScatterDataPoint } from "../../components/LabeledScatterDataPoint";
import { ScatterChartCard } from "../../components/ScatterChartCard";
import { useBudgetByAggregator } from "../../customers/useBudgetByAggregator";

export function ManagersUtilizationScatterChartCard({
  period,
}: {
  period: DashboardPeriod;
}) {
  const budgets = useBudgetByAggregator(
    period.year,
    period.month,
    CustomerAggregatorType.MANAGER
  );
  const capacity = useTeamCapacityByAggregator(
    period.year,
    period.month,
    TeamCapacityAggregatorType.MANAGER
  );
  const [dataPoints, setDataPoints] = useState([] as LabeledScatterDataPoint[]);

  useEffect(() => {
    const newDataPoints = Object.keys(budgets).map((managerEmail) => {
      const x =
        (budgets[managerEmail]?.opsHours || 0) +
        (budgets[managerEmail]?.teamHours || 0);
      const y = capacity[managerEmail]?.allocatedHours || 0;
      const dataPoint: LabeledScatterDataPoint = {
        label: `${managerEmail}`,
        link: `/dashboards/managers/${managerEmail}`,
        x,
        y,
      };
      return dataPoint;
    });
    setDataPoints(newDataPoints);
  }, [budgets, capacity]);

  return (
    <ScatterChartCard
      title="Managers allocation"
      xScaleTitle="Budget (hours)"
      yScaleTitle="Capacity (hours)"
      dataPoints={dataPoints}
      chartHeight={300}
    ></ScatterChartCard>
  );
}
