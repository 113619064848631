import { Box } from "@material-ui/core";
import { SystemNotificationsAccordion } from "notifications/SystemNotificationsAccordion";
import { SystemNotificationType } from "notifications/SystemNotificationTypes";
import { useFilteredNotifications } from "notifications/useFilteredNotifications";
import { useEngineersOffboardedNotifications } from "./useEngineersOffboardedNotifications";
import { useEngineersOnboardedNotifications } from "./useEngineersOnboardedNotifications";

const EMPTY: string[] = [];

export function EngineersNotificationsCard({
  year,
  month,
  engineerIds = EMPTY,
}: {
  year: number;
  month: number;
  engineerIds?: string[];
}) {
  const onboarded = useEngineersOnboardedNotifications(year, month);
  const filteredOnboarded = useFilteredNotifications(onboarded, engineerIds);
  const offboarded = useEngineersOffboardedNotifications(year, month);
  const filteredOffboarded = useFilteredNotifications(offboarded, engineerIds);

  return (
    <Box>
      <SystemNotificationsAccordion
        title={`engineers onboarded: ${filteredOnboarded.length}`}
        notifications={filteredOnboarded}
        type={SystemNotificationType.INFO}
      />
      <SystemNotificationsAccordion
        title={`engineers offboarded: ${filteredOffboarded.length}`}
        notifications={filteredOffboarded}
        type={SystemNotificationType.INFO}
      />
    </Box>
  );
}
