import { CustomerAggregatorType } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { AggregatedBurnedBudget } from "./BurnedBudgetTypes";
import useAllCustomers from "./useAllCustomers";
import { useBurnedBudget } from "./useBurnedBudget";
import { useCustomerAggregator } from "./useCustomerAggregator";

export interface BurnedBudgetByGroup {
  [groupId: string]: AggregatedBurnedBudget;
}

export function useBurnedBudgetByAggregator(
  year: number,
  month: number,
  group: CustomerAggregatorType
): BurnedBudgetByGroup {
  const customers = useAllCustomers();
  const budgets = useBurnedBudget(year, month);
  const aggregator = useCustomerAggregator(year, month);
  const [response, setResponse] = useState({} as BurnedBudgetByGroup);

  useEffect(() => {
    const burnedBudgetByGroup = Object.values(budgets.items).reduce(
      (response, burnedBudget) => {
        const customer = customers[burnedBudget.customerId];
        if (!customer) {
          return response;
        }
        const groupIds = aggregator.get(customer, group);
        groupIds.forEach((groupId) => {
          response[groupId] = response[groupId] || new AggregatedBurnedBudget(year, month);
          response[groupId].add(burnedBudget);
        });
        return response;
      },
      {} as BurnedBudgetByGroup
    );
    setResponse(burnedBudgetByGroup);
  }, [year, month, group, customers, budgets, aggregator]);

  return response;
}
