import { Chart } from "chart.js";
import { DashboardPeriod } from "dashboards/DashboardPeriod";
import { useTrendPeriods } from "dashboards/useTrendPeriods";
import { createRef, useEffect, useState } from "react";
import { useCapacities } from "../../capacity/useCapacities";
import { useTeamCapacities } from "../../capacity/useTeamCapacities";
import { DashboardCard } from "../../components/DashboardCard";
import { useBudgets, useTeamBudgets } from "../../customers/useBudgets";
import getMonthName from "../../utils/getMonthName";

function UtilizationTrendsCardImpl({
  months,
  budget,
  capacity,
  height = 300,
}: {
  months: { year: number; month: number }[];
  budget: number[];
  capacity: number[];
  height?: number;
}) {
  const chartRef = createRef<HTMLCanvasElement>();
  const [chart, setChart] = useState(null as null | Chart);

  useEffect(() => {
    if (chart) {
      return;
    }
    const ref = chartRef?.current as HTMLCanvasElement;
    const newChart = new Chart(ref, {
      type: "line",
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "bottom",
          },
        },
        scales: {
          y: {
            ticks: {
              precision: 0,
            },
            beginAtZero: false,
          },
        },
      },
    });
    setChart(newChart);
  }, []);

  useEffect(() => {
    if (!chart) {
      return;
    }
    chart.data.labels = months.map((month) =>
      getMonthName(new Date(month.year, month.month))
    );
    chart.data.datasets = [
      {
        label: "Budget",
        data: budget,
        borderColor: "rgb(183, 28, 28, 0.5)",
        backgroundColor: "rgb(183, 28, 28, 0.5)",
      },
      {
        label: "Capacity",
        data: capacity,
        borderColor: "rgb(0, 96, 100, 0.5)",
        backgroundColor: "rgb(0, 96, 100, 0.5)",
      },
    ];
    chart.update();
  }, [months, chart, capacity, budget]);

  return (
    <DashboardCard title="Utilization trend" height={height}>
      {{ body: <canvas ref={chartRef} /> }}
    </DashboardCard>
  );
}

export function UtilizationTrendsCard({
  period,
  height = 300,
}: {
  period: DashboardPeriod;
  height?: number;
}) {
  const months = useTrendPeriods(period, -3, 3);
  const capacities = useCapacities(months);
  const budgets = useBudgets(months);
  const [budgetHours, setBudgetHours] = useState([] as number[]);
  const [capacityHours, setCapacityHours] = useState([] as number[]);
  useEffect(() => {
    setBudgetHours(budgets.map((budget) => budget.teamHours + budget.opsHours));
    setCapacityHours(capacities.map((capacity) => capacity.allocatedHours));
  }, [capacities, budgets]);
  return UtilizationTrendsCardImpl({
    months,
    budget: budgetHours,
    capacity: capacityHours,
    height: height,
  });
}

export function TeamUtilizationTrendCard({
  period,
  teamId,
}: {
  period: DashboardPeriod;
  teamId: string;
}) {
  const months = useTrendPeriods(period, -3, 3);
  const capacities = useTeamCapacities(months, teamId);
  const budgets = useTeamBudgets(months, teamId);
  const [budgetHours, setBudgetHours] = useState([] as number[]);
  const [capacityHours, setCapacityHours] = useState([] as number[]);
  useEffect(() => {
    setBudgetHours(budgets.map((budget) => budget.teamHours + budget.opsHours));
    setCapacityHours(capacities.map((capacity) => capacity.allocatedHours));
  }, [capacities, budgets]);
  return UtilizationTrendsCardImpl({
    months,
    budget: budgetHours,
    capacity: capacityHours,
  });
}
