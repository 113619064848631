import { useEffect, useState } from 'react'
import { JiraProjectLeadDTO } from './JiraProjectLeadDTO'
import service from './JiraResourcesService'

export function useJiraProjectLead(
  projectKey: string,
  leadEmail: string,
  reloadCounter: number
): JiraProjectLeadDTO | undefined {
  const [lead, setLead] = useState(undefined as JiraProjectLeadDTO | undefined)

  useEffect(() => {
    setLead(undefined)
    service
      .getProjectLead(projectKey, leadEmail)
      .then((newLead) => setLead(newLead))
  }, [projectKey, leadEmail, reloadCounter])

  return lead
}
