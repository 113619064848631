import { Team } from "msd-capacity-planning-model";
import {
  FETCH_TEAMS,
  LOAD_TEAMS,
  PERSIST_TEAM,
  REMOVE_TEAM,
  TeamAction,
  TeamsState
} from "./TeamsTypes";

const initialState: TeamsState = {
  items: {},
  synching: true,
};

export default function teamsReducer(
  state = initialState,
  action: TeamAction
): TeamsState {
  switch (action.type) {
    case FETCH_TEAMS:
      return {
        ...state,
        synching: true,
      };
    case REMOVE_TEAM:
      return {
        ...state,
        synching: true,
      };
    case PERSIST_TEAM:
      return {
        ...state,
        synching: true,
      };
    case LOAD_TEAMS: {
      const items = action.items.reduce((all: { [id: string]: Team }, team) => {
        all[team.id] = team;
        return all;
      }, {});
      return {
        ...state,
        items,
        synching: false,
      };
    }
    default:
      return state;
  }
}
