import { CustomerHandle } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  FetchHarvestBudget,
  FETCH_HARVEST_BUDGET
} from "../harvest/HarvestTypes";
import { AggregatedBurnedBudget } from "./BurnedBudgetTypes";
import { useBurnedBudgets } from "./useBurnedBudgets";

export function useBurnedBudget(
  year: number,
  month: number,
  customers?: { [id: string]: CustomerHandle }
): AggregatedBurnedBudget {
  const dispatch = useDispatch();
  const budgets = useBurnedBudgets();
  const [aggregatedBudget, setAggregatedBudget] = useState(
    new AggregatedBurnedBudget(year, month)
  );

  useEffect(() => {
    const action: FetchHarvestBudget = {
      type: FETCH_HARVEST_BUDGET,
      year,
      month,
    };
    dispatch(action);
  }, [dispatch, year, month]);

  useEffect(() => {
    const budget = budgets.get(year, month);
    if (customers) {
      const ids = new Set(Object.keys(customers));
      const filtered = budget.filter((customerId) => ids.has(customerId));
      setAggregatedBudget(filtered);
    } else {
      setAggregatedBudget(budget);
    }
  }, [year, month, customers, budgets]);

  return aggregatedBudget;
}
