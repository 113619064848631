import {
  EngineerAggregatorType,
  EngineerHandle
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useEngineerAggregator } from "./useEngineerAggregator";
import { useEngineers } from "./useEngineers";
import useSortedEngineers from "./useSortedEngineers";

export interface EngineersByAggregator {
  [id: string]: EngineerHandle[];
}

export default function useEngineersByAggregator(
  year: number,
  month: number,
  type: EngineerAggregatorType
): EngineersByAggregator {
  const sorted = useSortedEngineers();
  const engineers = useEngineers(year, month);
  const [response, setResponse] = useState({} as EngineersByAggregator);
  const aggregator = useEngineerAggregator(year, month);

  useEffect(() => {
    const newResponse = sorted
      .filter((e) => !!engineers[e.id])
      .reduce((response, engineer) => {
        const ids = aggregator.get(engineer, type);
        ids.forEach((id) => {
          response[id] = response[id] || [];
          response[id].push(engineer);
        });
        return response;
      }, {} as EngineersByAggregator);
    setResponse(newResponse);
  }, [year, month, type, aggregator, sorted, engineers]);

  return response;
}
