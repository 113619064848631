import useCustomers from "./useCustomers";
import { CustomerHandle } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";

export function useSortedCustomers(
  year: number,
  month: number
): CustomerHandle[] {
  const customers = useCustomers(year, month);
  const [sortedCustomers, setSortedCustomer] = useState([] as CustomerHandle[]);

  useEffect(() => {
    const newSortedItems = customers.getAll().concat([]);
    newSortedItems.sort((i1, i2) =>
      i1.name.toLowerCase().localeCompare(i2.name.toLowerCase())
    );
    setSortedCustomer(newSortedItems);
  }, [year, month, customers]);

  return sortedCustomers;
}
