import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { useHarvestBudgetSelector } from "../harvest/useHarvestBudgets";
import { AppState } from "../redux/AppTypes";
import {
  AggregatedBurnedBudget,
  AggregatedBurnedBudgets,
  BurnedBudget
} from "./BurnedBudgetTypes";
import { useAllCustomersSelector } from "./useAllCustomers";

export const useBurnedBudgetsSelector = createSelector(
  [useAllCustomersSelector, useHarvestBudgetSelector],
  (customers, harvestBudgets) => {
    // calculate the burned budget for each period
    return harvestBudgets.months.reduce((aggregatedBurnedBudgets, period) => {
      const year = period.year;
      const month = period.month;
      // calculate burned budget for customers in the given period
      const newAggregatedBurnedBudget = Object.values(customers).reduce(
        (aggregatedBurendBudget, customer) => {
          // include only customer with budget for the given period
          if (customer.hasBudget(period.year, period.month)) {
            const burnedBudget = new BurnedBudget(customer.id, year, month);

            // add capacity planning app budget
            burnedBudget.customerId = customer.id;
            const budget = customer.getBudget(year, month);
            burnedBudget.teamId = budget.team;
            burnedBudget.emHours = budget.emHours || 0;
            burnedBudget.opsHours = budget.opsHours || 0;
            burnedBudget.teamHours = budget.teamHours || 0;
            burnedBudget.total = burnedBudget.teamHours + burnedBudget.opsHours;

            // add harvest budget
            const harvestBudget = harvestBudgets
              .get(year, month)
              .get(customer.harvestProjectId);
            burnedBudget.harvestBudget = harvestBudget?.total || 0;
            burnedBudget.harvestConsumedBudget = harvestBudget?.consumed || 0;
            aggregatedBurendBudget.add(burnedBudget);
          }
          return aggregatedBurendBudget;
        },
        new AggregatedBurnedBudget(year, month)
      );
      aggregatedBurnedBudgets.add(year, month, newAggregatedBurnedBudget);
      return aggregatedBurnedBudgets;
    }, new AggregatedBurnedBudgets());
  }
);

export function useBurnedBudgets(): AggregatedBurnedBudgets {
  const state = useSelector((state: AppState) => state);
  const burnedBudget = useBurnedBudgetsSelector(state);
  return burnedBudget;
}
