import { IconButton } from "@material-ui/core";
import DahboardIcon from "@material-ui/icons/Dashboard";
import EditIcon from "@material-ui/icons/Edit";
import { Region } from "msd-capacity-planning-model";
import { useHistory } from "react-router-dom";

export function RegionLinkBar({
  year,
  month,
  region,
}: {
  year: number;
  month: number;
  region: Region;
}) {
  const history = useHistory();

  function navigate(e: any) {
    const path = e?.currentTarget?.dataset?.path;
    if (path) {
      history.push(path);
    }
  }

  return (
    <span>
      <IconButton
        data-path={`/dashboards/regions/${region?.id}`}
        onClick={navigate}
      >
        <DahboardIcon />
      </IconButton>
      <IconButton
        data-path={`/administration/regions/${region?.id}`}
        onClick={navigate}
      >
        <EditIcon />
      </IconButton>
    </span>
  );
}
