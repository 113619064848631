import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Tooltip,
  useTheme,
  Select,
  MenuItem,
} from "@material-ui/core";
import { NoStyleLink } from "components/NoStyleLink";
import MUIDataTable from "mui-datatables";
import { DocsDrawer } from "docs/DocsDrawer";
import { DashboardBreadcrumbs } from "dashboards/DashboardBreadcrumbs";
import { useDashboardPeriod } from "dashboards/useDashboardPeriod";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import useRegions from "regions/useRegions";
import { mapValueToColor } from "utils/colorGenerator";
import { useAllEngineers } from "engineers/useAllEngineers";
import useSortedTeams from "teams/useSortedTeams";
import { EngineerAggregatorType, Team, TeamHandle} from "msd-capacity-planning-model";
import { Capacity } from "msd-capacity-planning-model";
import { RegionHandle } from "msd-capacity-planning-model";
import { useManagers } from "managers/useManagers";
import { set } from "date-fns";

function createDates(year: number, month: number): Date[] {
  return [
    new Date(year, month),
    new Date(year, month + 1),
    new Date(year, month + 2),
    new Date(year, month + 3),
    new Date(year, month + 4),
    new Date(year, month + 5),
    new Date(year, month + 6)
  ];
}

function makeManagerTeamMap(managers: string[], teams: TeamHandle[]) {
  const managerTeamMap = new Map();
  managers.forEach(manager => {
    managerTeamMap.set(manager, []);
  });
  teams.forEach(team => {
    managerTeamMap.get(team.manager)?.push(team.id);
  });
  return managerTeamMap;

}

const DEFAULT_REGION = new RegionHandle({ id: "", name: "NONE", holidays: [] });

export function EngineerDelta1850DashboardPage({
  linkPrefix,
  routelinkPrefix
}: {
  linkPrefix?: string;
  routelinkPrefix?: string
}
) {

  const { year, month } = useDashboardPeriod();
  const [nowDate] = useState(new Date(year, month));
  const location = useLocation();
  const teams = useSortedTeams(year, month);
  const managers = useManagers();
  const managerTeamMap = makeManagerTeamMap(Object.values(managers), Object.values(teams));
  const engineers = useAllEngineers();
  const regions = useRegions();
  const [showTimeOff, setShowTimeOff] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState('Pod A');
  const [selectedManager, setSelectedManager] = useState('all');
  const [dates, setDates] = useState([] as Date[]);
  let data: any = []
  let tmpDataValues: any = [];

// individual date columns are instanciated here with options
  const dateCols: any = dates.map((date, index) => ({
    name: date.toISOString().substring(0, 7),
    options: {
    setCellProps: () => ({ style: { padding: 0 } }),
    setCellHeaderProps: () => ({ style: { padding: 0 } }),
    filter: false,
    sort: false
  }
}))

useEffect(() => {
  const dates = createDates(year, month);
  setDates(dates);
}, [year, month]);


const capableHours = (engineer: any, year: any, month: any) => {

  const region = regions[engineer.region] || DEFAULT_REGION;
  const capacity = new Capacity(year, month, engineer, region);
  return Math.round(capacity.getUtilizationHours1850()) || 0

}

const getPTO = (engineer: any, year: any, month: any) => {

  const region = regions[engineer.region] || DEFAULT_REGION;
  const capacity = new Capacity(year, month, engineer, region);
  return  Math.round(capacity.getDaysOff()) || 0

}


const regionNames = useMemo(
  () => {
      return Array.from(new Set(Object.values(regions || []).map(region => region.name))).filter(n => n !== "")
  }, []
);
let columns = [
  {
    name: "email",
    options:
    {
      download: false,
      filter: false,
      display: false
    }
  },
  {
    name: "Engineer",
    options: {
      setCellProps: () => ({ style: { "padding-left": 5, "padding-top": 0, "padding-bottom": 0, "padding-right": 0 } }),
      setCellHeaderProps: () => ({ style: { "padding-left": 5, "padding-top": 0, "padding-bottom": 0, "padding-right": 0 } }),
      filter: false,
      sort: false,
      sortCompare: (order: string) => {
        return (obj1: { data: { props: { text: string; }; }; }, obj2: { data: { props: { text: string; }; }; }) => {
          return obj1.data.props.text.localeCompare(obj2.data.props.text) * (order === 'asc' ? 1 : -1);
        };
      },
    }
  },
  {
    name: "Region",
    options: {
      setCellProps: () => ({ style: { padding: 0 } }),
      sort: false,
      filterOptions: {
        names: regionNames,
        logic(region: any, filters: any) {
          let textString = region.props.text;
          return !filters.includes(textString);
        }

      },
      setCellHeaderProps: () => ({ style: { padding: 0 } }),
      sortCompare: (order: string) => {
        return (obj1: { data: { props: { text: string; }; }; }, obj2: { data: { props: { text: string; }; }; }) => {
          return obj1.data.props.text.localeCompare(obj2.data.props.text) * (order === 'asc' ? 1 : -1);
        };
      },
    }
  }
]

useMemo(() => {
  columns.push(...dateCols)
}, [dates, columns])

useMemo(() => {

  const selectedTeamId = Object.values(teams).find(team => team.name === selectedTeam)?.id
  console.log(selectedTeam,selectedTeamId,selectedManager)
  
  for (let [key, value] of Object.entries(engineers || [])) {

      //get a list of team ids the engineer is in
      const EngineerTeamIds = value.getTeamIds(year, month)

      //process filters
      if (selectedTeam !== 'all' && !value.isInTeam(selectedTeamId, year, month)) continue
      if (selectedManager !== 'all' && !managerTeamMap.get(selectedManager).some((teamId: string) => EngineerTeamIds.includes(teamId))) continue
      if (value.isOffboarded()) continue

      key = value['id']
      tmpDataValues = [];
      let engineer_region =  (value?.region === '') ?
      DEFAULT_REGION.name:
      <NoStyleLink
      pathname={`${routelinkPrefix}/${regions[value.region]?.id}`}
      text={regions[value.region]?.name}
      />

      data.push(
        [
          value.id,
          value.firstName + " " + value.lastName,
          engineer_region
        ],

      )
      let indexOfEngineer: any = data.findIndex((object: string[]) => {
        return object[0] === key;
      })
      // for each month and year we do, get values for the Engineer we are working on.
      dateCols.map((date: any, index: any) => {
        let year = Number(dateCols[index]['name'].split("-")[0])
        let month = Number(dateCols[index]['name'].split("-")[1])
        let tmpCapableHours = capableHours(value, year, month - 1) || 0
        let tmpassignedHours = Math.round(value.getHarvestHours(year,month - 1).total) || 0
        let tmpAvailable = tmpCapableHours - tmpassignedHours || 0
        let tmpPTOHours = getPTO(value, year, month - 1)
        // set my values in advance so I can filter out all 0 value entries
        tmpDataValues.push(...[tmpAvailable, tmpPTOHours])
   
        if (tmpAvailable != undefined  || (showTimeOff && tmpPTOHours != 0)) {
          data[indexOfEngineer] = (data[indexOfEngineer] || []).concat([
            <Tooltip title={tmpCapableHours + " Capable minus " + tmpassignedHours + " Assigned "}>
              <Box sx={{ bgcolor: mapValueToColor(tmpAvailable) }}>
                <Box sx={{ textAlign: 'center', padding: 10 }}>{tmpAvailable || 0}</Box>
                {showTimeOff ? (<Box sx={{ textAlign: 'right' }}>{tmpPTOHours} PTO Days</Box>) : null}
              </Box>
            </Tooltip>
          ]
          )
        }
        
      })

    }

},[engineers, selectedTeam, selectedManager, dateCols])

  return (
    <Box>
      <DashboardBreadcrumbs />
      <Typography variant="h4" style={{ marginTop: 8 }}>
        Delta trend heatmap <DocsDrawer path="/dashboards/engineerdelta.html" />
      </Typography>
      <Box
      style={{
        display: "flex",
        justifyContent: "right",
      }}
    >
      <FormGroup row={true}>

      <FormControlLabel
            control={
              <Select
                value={selectedTeam}
                onChange={(e) => setSelectedTeam((e.target.value as string ))}
                defaultValue='all'
              >
                <MenuItem value='all'>All Teams</MenuItem>
                {Object.values(teams).map((team, index) => {
                  return (
                    <MenuItem value={team.name}>
                      {team.name}
                    </MenuItem>
                  )
                })}
              </Select>}
            label="Team"
          />
      <FormControlLabel
            control={
              <Select
                value={selectedManager}
                onChange={(e) => setSelectedManager((e.target.value as string ))}
                defaultValue='all'
              >
                <MenuItem value='all'>All Managers</MenuItem>
                {Object.values(managers).map((manager, index) => {
                  return (
                    <MenuItem value={manager}>
                      {manager}
                    </MenuItem>
                  )
                })}
              </Select>}
            label="Manager"
          />  
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              value={showTimeOff}
              onChange={() => setShowTimeOff(!showTimeOff)}
            />
          }
          label="PTO"
        />
      </FormGroup>
    </Box>
    <MUIDataTable
      title=""
      columns={columns}
      data={data}
      options={
        {
          pagination: false,
          print: false,
          onDownload: (buildHead, buildBody, columns, data) => {
            return "\uFEFF" + buildHead(columns) + buildBody(
              data.map((it: { index: any; data: any; }) => {
                const response = {
                  index: it.index,
                  data: [
                    it.data[0],
                    it.data[1].props.text,   //set Engineer
                    it.data[2].props.text,   //set Region
                  ]
                }

                const dates = it.data.slice(3, it.data.length)
                dates.forEach((date: any, indexDates: number) => {
                  const cellValues = date?.props?.children?.props?.children;
                  let cellValueString: string = "";
                  cellValues && cellValues.forEach((cellValue: any, index: number) => {
                    let cellValueStringPartial = "";

                    if (cellValue && (cellValue.props?.children !== null && typeof cellValue.props?.children != undefined)) {
                      const cellData = cellValue.props.children
                      if (cellData || cellData === 0) {
                        switch (index) {
                          case 0:
                            // convert negative numbers to (number)
                            cellValueStringPartial = JSON.stringify(cellData) || "0";
                            if (parseInt(cellValueStringPartial) < 0){
                              cellValueStringPartial = `(${Math.abs(parseInt(cellValueStringPartial))})`
                            }
                            break;
                          case 1:
                            //PTO Days
                            cellValueStringPartial = cellData[1] + " " + cellData[0];
                            break;
                        }
                        if (cellValueStringPartial.length > 0) {
                          if (index == 0) {
                            cellValueString = cellValueStringPartial
                          } else {
                            cellValueString = cellValueString + ", " + cellValueStringPartial
                          }
                        }
                      }
                    }
                  })
                  response.data.push(cellValueString)
                })
                return (response)
              })
            )
          },
          search: false,
          selectableRows: "none",
          viewColumns: false,
        }}
    />


    <Typography variant="caption" style={{ color: "gray" }}>
          Before {nowDate.toISOString().substring(0, 10)}: planned hours vs.
          logged hours in Harvest
          </Typography>
          <br />
          <Typography variant="caption" style={{ color: "gray" }}>
          From {nowDate.toISOString().substring(0, 10)}: planned hours vs. planned
          capacity
      </Typography>
   </Box>






)

};