import {
  AggregatedBudget,
  CustomerAggregatorType
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useBudgetByAggregator } from "./useBudgetByAggregator";

export function useBudgetInAggregator(
  year: number,
  month: number,
  group: CustomerAggregatorType,
  id: string
): AggregatedBudget {
  const budgets = useBudgetByAggregator(year, month, group);
  const [response, setResponse] = useState(new AggregatedBudget());

  useEffect(() => {
    const newResponse = budgets[id] || new AggregatedBudget();
    setResponse(newResponse);
  }, [year, month, group, id, budgets]);

  return response;
}
