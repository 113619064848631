import { TeamRetrospectiveDashboardPage } from "dashboards/team/retrospective/TeamRetrospectiveDashboardPage";
import { Route, Switch } from "react-router-dom";
import { CustomerDashboardPage } from "../customer/CustomerDashboardPage";
import { EngineerDashboardPage } from "../engineer/EngineerDashboardPage";
import { TeamCustomersDashboardPage } from "../team/TeamCustomersDashboardPage";
import { TeamDashboardPage } from "../team/TeamDashboardPage";
import { TeamEngineersDashboardPage } from "../team/TeamEngineersDashboardPage";
import { TeamPlanningDashboardPage } from "./planning/TeamPlanningDashboardPage";
import { TeamSkillsDashboardPage } from "./TeamSkillsDashboardPage";

export function TeamDashboardRoutePage({ baseUrl }: { baseUrl: string }) {
  return (
    <Switch>
      <Route path={`${baseUrl}/:team/customers/:customer`}>
        <CustomerDashboardPage />
      </Route>
      <Route path={`${baseUrl}/:team/customers`}>
        <TeamCustomersDashboardPage />
      </Route>

      <Route path={`${baseUrl}/:team/engineers/:engineer`}>
        <EngineerDashboardPage />
      </Route>
      <Route path={`${baseUrl}/:team/engineers`}>
        <TeamEngineersDashboardPage />
      </Route>

      <Route path={`${baseUrl}/:team/retrospective/customers/:customer`}>
        <CustomerDashboardPage />
      </Route>
      <Route path={`${baseUrl}/:team/retrospective/customers`}>
        <TeamCustomersDashboardPage />
      </Route>
      <Route path={`${baseUrl}/:team/retrospective/engineers/:engineer`}>
        <EngineerDashboardPage />
      </Route>
      <Route path={`${baseUrl}/:team/retrospective/engineers`}>
        <TeamEngineersDashboardPage />
      </Route>
      <Route path={`${baseUrl}/:team/retrospective`}>
        <TeamRetrospectiveDashboardPage />
      </Route>
      <Route path={`${baseUrl}/:team/planning`}>
        <TeamPlanningDashboardPage />
      </Route>
      <Route path={`${baseUrl}/:team/skills`}>
        <TeamSkillsDashboardPage />
      </Route>
      <Route path={`${baseUrl}/:team`}>
        <TeamDashboardPage />
      </Route>
    </Switch>
  );
}
