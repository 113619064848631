import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import useCapacity from "capacity/useCapacity";
import { CountCard } from "components/CountCard";
import { DashboardPeriod } from "dashboards/DashboardPeriod";
import { UtilizationTrendsCard } from "dashboards/utilization/UtilizationTrendCard";
import { useEngineers } from "engineers/useEngineers";
import { useHistory, useLocation } from "react-router-dom";
import { useOffboardedEngineers } from "engineers/useOffboardedEngineers";
import { useOnboardedEngineers } from "engineers/useOnboardedEngineers";
import {
  EngineerHandle,
  ENGINEER_ROLES,
  toIdentifiableMap
} from "msd-capacity-planning-model";
import { ChangeEvent, useEffect, useState } from "react";
import useSortedRegions from "regions/useSortedRegions";
import useSortedTeams from "teams/useSortedTeams";
import { EngineersTable } from "./EngineersTable";
import { EngineersTrendsCard } from "./EngineersTrendCard";

export function EngineersDashboard({ period }: { period: DashboardPeriod }) {
  const year = period.year;
  const month = period.month;
  const engineers = useEngineers(year, month);
  const capacity = useCapacity(year, month);
  const onboarded = useOnboardedEngineers(year, month);
  const offboarded = useOffboardedEngineers(year, month);
  const location = useLocation();
  const history = useHistory();
  const regions = useSortedRegions();
  const teams = useSortedTeams(year, month);
  const roles = ENGINEER_ROLES;
  const [filtered, setFiltered] = useState(
    {} as { [id: string]: EngineerHandle }
  );

  useEffect(() => {
    const newFiltered = Object.values(engineers).sort((a, b) => a.compare(b));
    setFiltered(toIdentifiableMap(newFiltered));
  }, [year, month, engineers]);



  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      spacing={2}
    >
      <Grid item xs={3}>
        <CountCard title="Engineers" count={Object.keys(engineers).length} />
      </Grid>
      <Grid item xs={3}>
        <CountCard title="Capacity" count={capacity.allocatedHours || 0} />
      </Grid>
      <Grid item xs={3}>
        <CountCard title="Onboarded" count={onboarded.length} />
      </Grid>
      <Grid item xs={3}>
        <CountCard title="Offboarded" count={offboarded.length} />
      </Grid>
      <Grid item xs={5}>
        <EngineersTrendsCard period={period} height={200} />
      </Grid>
      <Grid item xs={5}>
        <UtilizationTrendsCard period={period} height={200} />
      </Grid>
      <Grid item xs={2}>
              <List>
                <ListItem
                  button
                  onClick={() => history.push(`${location.pathname}/delta`)}
                >
                  <ListItemIcon>
                    <ArrowForwardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Delta trend" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => history.push(`${location.pathname}/delta1850`)}
                >
                  <ListItemIcon>
                    <ArrowForwardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Delta trend (1850 Utilization)" />
                </ListItem>
              </List>
      </Grid>    
      <Grid item xs={12}>
        <EngineersTable
          year={year}
          month={month}
          engineers={filtered}
          size={"small"}
          showRegion={true}
          showCapacity={true}
        />
      </Grid>
    </Grid>
  );
}
