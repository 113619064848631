import { AggregatedTeamUtilization, Team } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useTeamUtilizationByTeam } from "./useTeamUtilizationByTeam";

export function useTeamUtilization(
  year: number,
  month: number,
  team: Team
): AggregatedTeamUtilization {
  const [teams, setTeams] = useState({} as { [teamId: string]: Team });
  const utilizations = useTeamUtilizationByTeam(year, month, teams);
  const [utilization, setUtilization] = useState(
    new AggregatedTeamUtilization()
  );

  useEffect(() => {
    if (team) {
      setTeams({ [team.id]: team });
    } else {
      setTeams({});
    }
  }, [team]);

  useEffect(() => {
    const newUtilization =
      utilizations[team?.id] || new AggregatedTeamUtilization();
    setUtilization(newUtilization);
  }, [year, month, team, utilizations]);

  return utilization;
}
