import { Box, Tab, Tabs } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import { useEngineerCapacityByAggregator } from "capacity/useEngineerCapacityByAggregator";
import { useBudgetByAggregator } from "customers/useBudgetByAggregator";
import { CustomersTable } from "dashboards/customers/CustomersTable";
import { EngineerCapacityTable } from "dashboards/engineer/EngineerCapacityTable";
import {
  CustomerAggregatorType,
  CustomerHandle,
  EngineerAggregatorType,
  EngineerHandle
} from "msd-capacity-planning-model";
import React, { useState } from "react";

export function UtilizationTable({
  year,
  month,
  customers,
  engineers,
  linkPrefix,
}: {
  year: number;
  month: number;
  customers: { [customerId: string]: CustomerHandle };
  engineers: { [engineerId: string]: EngineerHandle };
  linkPrefix: string;
}) {
  const [tab, setTab] = useState("customers");
  const capacities = useEngineerCapacityByAggregator(
    year,
    month,
    EngineerAggregatorType.ALL,
    engineers
  );
  const budgets = useBudgetByAggregator(
    year,
    month,
    CustomerAggregatorType.ALL,
    customers
  );

  return (
    <Box style={{ minHeight: "400px" }}>
      <Box style={{ marginBottom: "16px" }}>
        <TabContext value={tab}>
          <Tabs
            value={tab}
            onChange={(event, newValue) => setTab(`${newValue}`)}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label={`Budget (${Math.round(
                Object.values(budgets)?.[0]?.getTeamPlusOps() || 0
              ).toLocaleString()} hours)`}
              value="customers"
            />
            <Tab
              label={`Engineers (${Math.round(
                Object.values(capacities)?.[0]?.allocatedHours || 0
              ).toLocaleString()} hours)`}
              value="engineers"
            />
          </Tabs>

          <TabPanel value={"customers"}>
            <CustomersTable
              year={year}
              month={month}
              customers={customers}
              linkPrefix="/customers"
              consumedBudgetPercentColumn={false}
            />
          </TabPanel>
          <TabPanel value={"engineers"}>
            <EngineerCapacityTable
              year={year}
              month={month}
              engineers={engineers}
              linkPrefix="/engineers"
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}
