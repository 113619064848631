import { CustomerHandle } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AppState } from "../redux/AppTypes";
import {
  AggregatedHarvestBudget,
  FetchHarvestBudget,
  FETCH_HARVEST_BUDGET,
  HarvestBudget,
  HarvestProjectBudget
} from "./HarvestTypes";
export class AggregatedHarvestBudgetTrend {
  months: { year: number; month: number }[] = [];
  items: { [key: string]: AggregatedHarvestBudget } = {};
  private getKey(year: number, month: number) {
    return `${year}-${month}`;
  }
  private parseKey(key: string): { year: number; month: number } {
    const [year, month] = key.split("-", 2);
    return { year: parseInt(year, 10), month: parseInt(month, 10) };
  }
  add(year: number, month: number, budget: AggregatedHarvestBudget) {
    this.months.push({ year, month });
    this.items[this.getKey(year, month)] = budget;
  }
  get(year: number, month: number) {
    return (
      this.items[this.getKey(year, month)] || new AggregatedHarvestBudget()
    );
  }

  filter(
    callback: (budget: HarvestProjectBudget) => boolean
  ): AggregatedHarvestBudgetTrend {
    const filtered = new AggregatedHarvestBudgetTrend();
    Object.keys(this.items)
      .map((key) => {
        const period = this.parseKey(key);
        const budget = this.items[key];
        const filtered = budget.filter(callback);
        return { year: period.year, month: period.month, budget: filtered };
      })
      .forEach(({ year, month, budget }) => {
        filtered.add(year, month, budget);
      });
    return filtered;
  }

  getFilteredAggregatedBudget(
    filter: (budget: HarvestProjectBudget) => boolean
  ): AggregatedHarvestBudgetTrend {
    const budgets = new AggregatedHarvestBudgetTrend();
    this.months.forEach((period) => {
      const year = period.year;
      const month = period.month;
      const budget = this.get(year, month);
      const filteredBudget = budget.filter(filter);
      budgets.add(year, month, filteredBudget);
    });
    return budgets;
  }
}

const budgetsSelector = (state: AppState) => state.harvest.budgets;

export const useHarvestBudgetSelector = createSelector(
  budgetsSelector,
  (budgets) => {
    // get all available periods
    const periods = Object.keys(budgets).reduce((all, year) => {
      const months = Object.keys(budgets[year]);
      months.forEach((month) =>
        all.push({ year: parseInt(year), month: parseInt(month) })
      );
      return all;
    }, [] as { year: number; month: number }[]);
    // gather all budgets in a single object
    const aggregatedBudgets = periods.reduce((all, period) => {
      const budget =
        budgets[period.year]?.[period.month] || new HarvestBudget();
      const aggregatedBudget = Object.keys(budget.items).reduce(
        (aggregated, key) => {
          const projectBudget = budget.items[key];
          aggregated.add(projectBudget);
          return aggregated;
        },
        new AggregatedHarvestBudget()
      );
      all.add(period.year, period.month, aggregatedBudget);
      return all;
    }, new AggregatedHarvestBudgetTrend());
    return aggregatedBudgets;
  }
);

export function useHarvestBudgetTrend(
  months: { year: number; month: number }[],
  customers?: { [id: string]: CustomerHandle }
): AggregatedHarvestBudgetTrend {
  const dispatch = useDispatch();
  const state = useSelector((state: AppState) => state);
  const trend = useHarvestBudgetSelector(state);
  const [filteredTrend, setFilteredTrend] = useState(
    new AggregatedHarvestBudgetTrend()
  );

  useEffect(() => {
    months.forEach(({ year, month }) => {
      const action: FetchHarvestBudget = {
        type: FETCH_HARVEST_BUDGET,
        year,
        month,
      };
      dispatch(action);
    });
  }, [months, dispatch]);

  useEffect(() => {
    if (customers) {
      const harvestProjectIds = Object.values(customers).reduce(
        (harvestIds: Set<string>, customer: CustomerHandle) => {
          if (customer.harvestProjectId) {
            harvestIds.add(customer.harvestProjectId);
          }
          return harvestIds;
        },
        new Set<string>()
      );
      const newTrend = trend.filter((budget) =>
        harvestProjectIds.has(budget.harvestProjectId)
      );
      setFilteredTrend(newTrend);
    } else {
      setFilteredTrend(trend);
    }
  }, [customers, trend]);

  return filteredTrend;
}
