export function getHarvestProjectURL(harvestId: string): string {
  return `https://sturdycloud.harvestapp.com/projects/${harvestId}`;
}

export function getHarvestProjectReportURL(
  harvestId: string,
  year: number,
  month: number
): string {
  const paddedMonth = String(month + 1).padStart(2, "0");
  return `https://sturdycloud.harvestapp.com/reports/projects/${harvestId}?from=${year}-${paddedMonth}-01&period=month`;
}

export function getHarvestUserReportURL(
  harvestId: string,
  year: number,
  month: number
): string {
  const paddedMonth = String(month + 1).padStart(2, "0");
  return `https://sturdycloud.harvestapp.com/reports/users/${harvestId}?from=${year}-${paddedMonth}-01&kind=month#projects`;
}

export function getHarvestInactiveUserURL(): string {
  return "https://sturdycloud.harvestapp.com/people/inactive";
}
