import { Box } from "@material-ui/core";
import { Customer } from "msd-capacity-planning-model";
import { CommentsPanel } from "./CommentsPanel";

export const PLANNING_PREFIX = "planning-";

export function CustomerPlannigCommentsPanel({
  year,
  month,
  customer,
  hideAuthor = false,
}: {
  year: number;
  month: number;
  customer: Customer;
  hideAuthor?: boolean;
}) {
  return (
    <Box>
      <CommentsPanel
        year={year}
        month={month}
        id={`${PLANNING_PREFIX}${customer.id}`}
        hideAuthor={hideAuthor}
      />
    </Box>
  );
}
