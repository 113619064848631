import { Administrator } from "msd-capacity-planning-model";
import env from "../app/Environment";
import fetchService from "../utils/FetchService";

export class AdministratorService {
  constructor(private endpoint: string) {}

  async fetch(): Promise<Administrator[]> {
    return fetchService
      .fetch(`${this.endpoint}/administrators`, {})
      .then((r) => r.json())
      .catch(() => []);
  }

  async delete(email: string): Promise<Administrator[]> {
    return fetchService
      .fetch(`${this.endpoint}/administrators/${email}`, {
        method: "DELETE",
      })
      .then((r) => r.json());
  }

  async persist(item: Administrator): Promise<Administrator[]> {
    return fetchService
      .fetch(`${this.endpoint}/administrators`, {
        method: "POST",
        body: JSON.stringify(item),
      })
      .then((r) => r.json());
  }
}

const service = new AdministratorService(env.endpoint);

export default service;
