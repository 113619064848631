import React, { useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import RefreshIcon from '@material-ui/icons/Refresh'
import service from './JiraResourcesService'
import { useJiraOnboardingTasks } from './useJiraOnboardingTasks'

export function JiraOnboardingTasks({
  projectKey,
  emEmail,
  emLabel
}: {
  projectKey: string
  emEmail: string
  emLabel: string
}) {
  const [reloadCounter, setReloadCounter] = useState(0)
  const exists = useJiraOnboardingTasks(projectKey, reloadCounter)
  const [updateClicked, setUpdateClicked] = useState(false)
  const [updating, setUpdating] = useState(false)

  function onUpdate(): void {
    setUpdateClicked(true)
    setUpdating(true)
    service.putOnboardingTasks(projectKey, emEmail, emLabel).finally(() => {
      setUpdating(false)
      setReloadCounter(reloadCounter + 1)
    })
  }

  const status = (
    <span style={{ marginRight: 8, verticalAlign: 'middle' }}>
      {typeof exists === 'undefined' ? (
        <CircularProgress size={16} style={{ marginLeft: 8 }} />
      ) : exists === true ? (
        <Tooltip title='Tasks found'>
          <CheckIcon style={{ color: 'green' }} />
        </Tooltip>
      ) : (
        <Tooltip title='Tasks not found'>
          <ClearIcon style={{ color: 'red' }} />
        </Tooltip>
      )}
    </span>
  )

  return (
    <Box>
      {status}
      Onboarding tasks:
      <IconButton
        disabled={updating || typeof exists === 'undefined'}
        onClick={() => setReloadCounter(reloadCounter + 1)}
        style={{ marginLeft: 8 }}
      >
        <RefreshIcon />
      </IconButton>
      {typeof exists === 'boolean' ? (
        <span>
          {exists === true ? 'exists' : 'missing'}{' '}
          {exists === true ? null : (
            <Button
              variant='text'
              color='primary'
              disabled={updateClicked}
              onClick={() => onUpdate()}
            >
              Update
              {updating ? (
                <CircularProgress size={16} style={{ marginLeft: 8 }} />
              ) : null}
            </Button>
          )}
        </span>
      ) : (
        ''
      )}
    </Box>
  )
}
