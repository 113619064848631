import { Box, Grid, Paper } from "@material-ui/core";
import { DashboardBreadcrumbs } from "dashboards/DashboardBreadcrumbs";
import { useLeads } from "leads/useLeads";
import { CountCard } from "../../components/CountCard";
import useBudget from "../../customers/useBudget";
import useCustomers from "../../customers/useCustomers";
import { CustomersNotificationsCard } from "../../notifications/customer/CustomersNotificationCard";
import { TeamsNotificationsCard } from "../../notifications/team/TeamsNotificationsCard";
import { useTeams } from "../../teams/useTeams";
import { useDashboardPeriod } from "../useDashboardPeriod";
import { LeadsTable } from "./LeadsTable";
import { LeadUtilizationScatterChartCard } from "./LeadUtilizationScatterChartCard";

export function LeadsDashboardPage() {
  const period = useDashboardPeriod();
  const leads = useLeads();
  const teams = useTeams(period.year, period.month);
  const customers = useCustomers(period.year, period.month);
  const budgets = useBudget(period.year, period.month);

  return (
    <Box>
      <DashboardBreadcrumbs />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs={8}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item xs={12}>
              <LeadUtilizationScatterChartCard period={period} />
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <LeadsTable period={period} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CountCard title="Leads" count={leads.length} />
            </Grid>
            <Grid item xs={6}>
              <CountCard title="Teams" count={Object.values(teams).length} />
            </Grid>
            <Grid item xs={6}>
              <CountCard title="Customers" count={customers.getAll().length} />
            </Grid>
            <Grid item xs={6}>
              <CountCard
                title="Budget"
                count={budgets.opsHours + budgets.teamHours}
              />
            </Grid>
            <Grid item xs={12}>
              <TeamsNotificationsCard
                year={period.year}
                month={period.month}
                teams={teams}
              />
              <CustomersNotificationsCard
                year={period.year}
                month={period.month}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
