export interface Item {
  name: string;
  count: number;
}

export class StringCounter {
  private items = new Map<string, number>();

  private cache = null as null | Item[];

  count(name: string) {
    const value = this.items.get(name) || 0;
    this.items.set(name, value + 1);
    this.cache = null;
  }

  getNames(): string[] {
    return Array.from(this.items.keys());
  }

  getCount(name: string): number {
    return this.items.get(name) || 0;
  }

  getItems(): Item[] {
    if (!this.cache) {
      this.cache = Array.from(this.items.keys())
        .sort((i1, i2) => {
          const value1 = this.items.get(i1) || 0;
          const value2 = this.items.get(i2) || 0;
          return value1 > value2 ? -1 : 1;
        })
        .map((key) => ({
          name: key,
          count: this.items.get(key) as number,
        }));
    }
    return this.cache as Item[];
  }

  getSlice(count: number): Item[] {
    return this.getItems().slice(0, count);
  }
}
