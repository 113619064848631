import { useEffect, useState } from 'react'
import service, { JiraProjectSchemeType } from './JiraResourcesService'

export interface JiraProjectSchemeLoader {
  loading: boolean
  scheme?: any
}

export function useJiraProjectScheme(
  projectKey: string,
  scheme: JiraProjectSchemeType,
  count?: number
): JiraProjectSchemeLoader {
  const [value, setValue] = useState({
    loading: true
  } as JiraProjectSchemeLoader)

  useEffect(() => {
    setValue({ loading: true })
    service
      .getScheme(projectKey, scheme)
      .then((scheme) => setValue({ loading: false, scheme }))
  }, [projectKey, scheme, count])

  return value
}
