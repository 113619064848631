import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { Link } from "react-router-dom";
import { SystemNotification } from "./SystemNotificationTypes";

export function SystemNotificationList({
  notifications,
  dense = true,
  warningIcon = false,
}: {
  notifications: SystemNotification[];
  dense?: boolean;
  warningIcon?: boolean;
}) {
  return notifications.length > 0 ? (
    <List dense={dense}>
      {notifications.map((item, index) => (
        <Link
          key={`${index}`}
          to={item.link || "#"}
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <ListItem alignItems="flex-start">
            {warningIcon ? (
              <ListItemAvatar>
                <WarningIcon color="error" />
              </ListItemAvatar>
            ) : null}
            <ListItemText primary={item.title} secondary={item.description} />
          </ListItem>
        </Link>
      ))}
    </List>
  ) : null;
}
