import {
  AggregatedBudget,
  CustomerAggregatorType,
  CustomerHandle
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import useAllCustomers from "./useAllCustomers";
import { useCustomerAggregator } from "./useCustomerAggregator";

export interface BudgetByGroup {
  [groupId: string]: AggregatedBudget;
}

export function useBudgetByAggregator(
  year: number,
  month: number,
  group: CustomerAggregatorType,
  filter?: { [customerId: string]: CustomerHandle }
): BudgetByGroup {
  // FIXME: use only customers with budget, check dependencies
  const customers = useAllCustomers();
  const aggregator = useCustomerAggregator(year, month);
  const [response, setResponse] = useState({} as BudgetByGroup);

  useEffect(() => {
    const budgetByGroup = Object.values(customers).reduce(
      (response, customer) => {
        if (!filter || filter[customer.id]) {
          const budget = customer.getBudget(year, month);
          const groupIds = aggregator.get(customer, group);
          groupIds.forEach((groupId) => {
            response[groupId] = response[groupId] || new AggregatedBudget();
            response[groupId].add(customer.id, budget);
          });
        }
        return response;
      },
      {} as BudgetByGroup
    );
    setResponse(budgetByGroup);
  }, [year, month, group, customers, aggregator, filter]);

  return response;
}
