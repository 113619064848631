import { IconButton, Tooltip } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { useHistory } from "react-router-dom";
import { useEngineersWarningFlags } from "./useEngineersWarningFlags";

const EMPTY_SET = new Set();

export function EngineerWarningFlagsIcon({ id }: { id: string }) {
  const history = useHistory();
  const flags = useEngineersWarningFlags();
  
  return (flags[id]?.size || 0) > 0 ? (
    <Tooltip
      title={
        <ul style={{ paddingLeft: 0 }}>
          {Array.from(flags[id] || EMPTY_SET).map((warning, index) => (
            <li key={`item-${index}`}>{warning}</li>
          ))}
        </ul>
      }
    >
      <IconButton
        color="secondary"
        onClick={() => history.push(`/administration/engineers/${id}`)}
      >
        <WarningIcon />
      </IconButton>
    </Tooltip>
  ) : (
    <span />
  );
}
