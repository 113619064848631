import { CustomerAggregatorType } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { LabeledScatterDataPoint } from "../../components/LabeledScatterDataPoint";
import { ScatterChartCard } from "../../components/ScatterChartCard";
import { useBudgetByAggregator } from "../../customers/useBudgetByAggregator";
import { useEMs } from "../../ems/useEMs";

export function EMUtilizationScatterChartCard({
  year,
  month,
}: {
  year: number;
  month: number;
}) {
  const ems = useEMs(year, month);
  const budgets = useBudgetByAggregator(year, month, CustomerAggregatorType.EM);
  const [dataPoints, setDataPoints] = useState([] as LabeledScatterDataPoint[]);

  useEffect(() => {
    const newDataPoints = Object.keys(budgets)
      .filter((emId) => !!ems[emId])
      .map((emId) => {
        const em = ems[emId];
        const dataPoint: LabeledScatterDataPoint = {
          label: `${em.firstName} ${em.lastName}`,
          link: `/dashboards/ems/${em.id}`,
          x: budgets[emId].opsHours + budgets[emId].teamHours,
          y: Object.values(budgets[emId].items).length,
        };
        return dataPoint;
      });
    setDataPoints(newDataPoints);
  }, [ems, budgets]);

  return (
    <ScatterChartCard
      title="EM Budget Allocation"
      xScaleTitle="Budget (Hours)"
      yScaleTitle="Customers"
      dataPoints={dataPoints}
      chartHeight={300}
    ></ScatterChartCard>
  );
}
