import { Grid } from "@material-ui/core";
import { Chart, ChartConfiguration } from "chart.js";
import { createRef, ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DashboardCard } from "./DashboardCard";
import { LabeledScatterDataPoint } from "./LabeledScatterDataPoint";

export function ScatterChartCard({
  title,
  dataPoints,
  xAnnotation,
  xScaleTitle,
  yScaleTitle,
  tooltipSuffix = "",
  children,
  chartHeight,
  xMin = 0,
  yMin = 0,
}: {
  title?: string;
  dataPoints: LabeledScatterDataPoint[];
  xAnnotation?: number;
  xScaleTitle?: string;
  yScaleTitle?: string;
  tooltipSuffix?: string;
  children?: {
    footer?: ReactNode;
  };
  chartHeight?: number;
  xMin?: number;
  yMin?: number;
}) {
  const history = useHistory();
  const chartRef = createRef<HTMLCanvasElement>();
  const [chart, setChart] = useState(null as null | Chart<"scatter">);

  useEffect(() => {
    if (chart) {
      return;
    }
    const ref = chartRef?.current as HTMLCanvasElement;
    const configuration: ChartConfiguration<"scatter"> = {
      type: "scatter",
      data: {
        datasets: [
          {
            data: [],
            borderColor: "rgb(63, 81, 181, 0.5)",
            backgroundColor: "rgb(63, 81, 181, 0.5)",
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {},
          },
          legend: {
            display: false,
          },
        },
      },
    };
    const newChart = new Chart(ref, configuration);
    setChart(newChart);
  }, []);

  useEffect(() => {
    if (!chart) {
      return;
    }
    chart.options.scales = {
      x: {
        display: true,
        title: { display: true, text: xScaleTitle },
        min: xMin,
      },
      y: {
        display: true,
        title: { display: true, text: yScaleTitle },
        min: yMin,
        ticks: {
          precision: 0,
        },
      },
    };
    if (typeof xAnnotation === "number") {
      chart.options!.plugins!.annotation = {
        annotations: {
          expected: {
            type: "line",
            yMin: xAnnotation,
            yMax: xAnnotation,
            borderColor: "rgb(255, 99, 132)",
            borderWidth: 2,
          },
        },
      };
    } else {
      chart.options!.plugins!.annotation = {};
    }
    chart.options.plugins!.tooltip!.callbacks!.label = (item) => {
      const c = item.dataset.data[item.dataIndex] as LabeledScatterDataPoint;
      return `${c.label} ${Math.round(c.y).toLocaleString()}${tooltipSuffix}`;
    };
    chart.options.onClick = function (event, target) {
      const index = target && target?.length > 0 ? target[0].index : undefined;
      if (typeof index !== "undefined") {
        const link = dataPoints[index]?.link;
        if (link) {
          history.push(link);
        }
      }
    };
    chart.data.datasets[0].data = dataPoints;
    chart.update();
  }, [
    tooltipSuffix,
    history,
    xAnnotation,
    chart,
    dataPoints,
    xScaleTitle,
    yScaleTitle,
    xMin,
    yMin,
  ]);

  const canvas = <canvas ref={chartRef}></canvas>;
  return (
    <DashboardCard title={title} height={chartHeight}>
      {{
        body: canvas,
        footer: children?.footer ? (
          <Grid item xs={12}>
            {children?.footer}
          </Grid>
        ) : undefined,
      }}
    </DashboardCard>
  );
}
