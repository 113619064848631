import env from "../app/Environment";
import fetchService from "../utils/FetchService";
import { Comment, MonthlyComments } from "./CommentTypes";

interface CommentEntry {
  yearMonth: string;
  id: string;
  items: Comment[];
}

class CommentService {
  constructor(private endpoint: string) {}

  async fetch(
    year: number,
    month: number
  ): Promise<{ [id: string]: Comment[] }> {
    return fetchService
      .fetch(`${this.endpoint}/comments/${year}/${month}`, {})
      .then((r) => r.json())
      .then((entries: CommentEntry[]) =>
        entries.reduce((all, comment) => {
          all[comment.id] = comment.items;
          return all;
        }, {} as MonthlyComments)
      );
  }

  async delete(
    year: number,
    month: number,
    id: string,
    index: number
  ): Promise<{ [id: string]: Comment[] }> {
    return fetchService
      .fetch(`${this.endpoint}/comments/${year}/${month}/${id}/${index}`, {
        method: "DELETE",
      })
      .then((r) => this.fetch(year, month));
  }

  async persist(
    year: number,
    month: number,
    id: string,
    item: Comment
  ): Promise<{ [id: string]: Comment[] }> {
    return fetchService
      .fetch(`${this.endpoint}/comments/${year}/${month}/${id}`, {
        method: "POST",
        body: JSON.stringify(item),
      })
      .then((r) => this.fetch(year, month));
  }
}

const service = new CommentService(env.endpoint);

export default service;
