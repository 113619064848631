import { EMHandle } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AppState } from "../redux/AppTypes";
import { useAllEMsSelector } from "./useAllEMs";
import { useEMs }  from "./useEMs";

export const useSortedEMsSelector = createSelector(
  useAllEMsSelector,
  (items) => {
    const sortedItems = Object.values(items).concat([]);
    sortedItems.sort((i1, i2) => i1.compare(i2));
    return sortedItems;
  }
);

export function useSortedEMs(year: number, month: number): EMHandle[] {
  const state = useSelector((state: AppState) => state);
  const ems = useEMs(year, month);
  const sorted = useSortedEMsSelector(state);
  const [filtered, setFiltered] = useState([] as EMHandle[]);

  useEffect(() => {
    const active = new Set(Object.keys(ems));
    setFiltered(sorted.filter((item) => active.has(item.id)));
  }, [ems, sorted]);

  return filtered;
}
