import { AggregatedBudget, CustomerHandle } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useBudgets } from "./useBudgets";

export default function useCustomersBudget(
  year: number,
  month: number,
  customers: { [customerId: string]: CustomerHandle }
): AggregatedBudget {
  const [months, setMonths] = useState([{ year, month }]);
  const budgets = useBudgets(months);
  const [budget, setBudget] = useState(new AggregatedBudget());

  useEffect(() => {
    setMonths([{ year, month }]);
  }, [year, month]);

  useEffect(() => {
    const ids = new Set(Object.keys(customers));
    const items = budgets.find((x) => true)?.items || {};
    const newBudget = Object.keys(items)
      .filter((customerId) => ids.has(customerId))
      .reduce((all, customerId) => {
        all.add(customerId, items[customerId]);
        return all;
      }, new AggregatedBudget());
    setBudget(newBudget);
  }, [months, budgets, customers]);

  return budget;
}
