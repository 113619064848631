import { useEffect, useState } from 'react'
import service from './JiraResourcesService'

export interface ActorRole {
  name: string
  description: string
  incomplete: boolean
}

export enum ActorType {
  USER = 'atlassian-user-role-actor',
  GROUP = 'atlassian-group-role-actor'
}

export interface Actor {
  displayName: string
  type: ActorType
  forbidden: boolean
  roles: Map<string, ActorRole>
}

export function useJiraProjectPeople(
  projectKey: string,
  reloadCounter = 0
): {
  roles: ActorRole[]
  actors: Actor[]
} {
  const [actors, setActors] = useState({ roles: [], actors: [] } as {
    roles: ActorRole[]
    actors: Actor[]
  })

  useEffect(() => {
    async function run() {
      // FIXME: we should use a unique ID the accountid.
      // duplicate names will cause wrong aggregations
      const newPeople = await service.getProjectPeople(projectKey)
      const newActors = newPeople.reduce((map, role) => {
        const actorsInRole = (role.actors || []) as Actor[]
        actorsInRole.forEach((actor: Actor) => {
          actor.roles = new Map()
          map.set(actor.displayName, actor)
        })
        return map
      }, new Map<string, Actor>())

      newPeople.forEach((role: any) => {
        const actorsInRole = (role.actors || []) as Actor[]
        actorsInRole.forEach((actor) => actor.roles.set(`${role.id}`, role))
      })

      setActors({
        roles: newPeople,
        actors: Array.from(newActors.values())
      })
    }
    run()
  }, [projectKey, reloadCounter])

  return actors
}
