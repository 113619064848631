import { Customer } from "msd-capacity-planning-model";
import env from "../app/Environment";
import fetchService from "../utils/FetchService";

export interface CustomerService {
  fetch(): Promise<Customer[]>;
  delete(id: string): Promise<Customer[]>;
  persist(item: Customer): Promise<Customer[]>;
}

class HTTPCustomerService implements CustomerService {
  constructor(private endpoint: string) {}

  async fetch(): Promise<Customer[]> {
    return fetchService
      .fetch(`${this.endpoint}/customers`, {})
      .then((r) => r.json())
      .catch(() => []);
  }

  async delete(id: string): Promise<Customer[]> {
    return fetchService
      .fetch(`${this.endpoint}/customers/${id}`, {
        method: "DELETE",
      })
      .then(() => this.fetch());
  }

  async persist(item: Customer): Promise<Customer[]> {
    return fetchService
      .fetch(`${this.endpoint}/customers`, {
        method: "POST",
        body: JSON.stringify(item),
      })
      .then(() => this.fetch());
  }
}

const service: CustomerService = new HTTPCustomerService(env.endpoint);

export default service;
