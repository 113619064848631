import { Box } from "@material-ui/core";
import { Team } from "msd-capacity-planning-model";
import { SystemNotificationsAccordion } from "notifications/SystemNotificationsAccordion";
import React from "react";
import { useOverallocatedTeamNotifications } from "./useOverallocatedTeamNotifications";

export function TeamsNotificationsCard({
  year,
  month,
  teams,
}: {
  year: number;
  month: number;
  teams: { [teamId: string]: Team };
}) {
  const overallocated = useOverallocatedTeamNotifications(year, month, teams);
  return (
    <Box>
      <SystemNotificationsAccordion
        title={`overallocated teams: ${overallocated.length}`}
        notifications={overallocated}
      />
    </Box>
  );
}
