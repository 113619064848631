import { yupResolver } from "@hookform/resolvers/yup";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell as DefaultTableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CustomDatePicker } from "components/CustomDatePicker";
import { CustomTextField } from "components/CustomTextField";
import { EngineerSelect } from "components/EngineerSelect";
import { ProductTypeChips } from "components/ProductTypeChips";
import { RegionSelect } from "components/RegionSelect";
import { SkillsChips } from "components/SkillsChips";
import { DocsDrawer } from "docs/DocsDrawer";
import { useAllEMs } from "ems/useAllEMs";
import { useSortedEMs } from "ems/useSortedEMs";
import useEngineersInAggregator from "engineers/useEngineersInAggregator";
import { HistoryPanel } from "history/HistoryPanel";
import {
  Customer,
  CustomerFlag,
  CustomerHandle,
  DEFAULT_CONTRACT_HOURS,
  EMHandle,
  EngineerAggregatorType,
  MementoType
} from "msd-capacity-planning-model";
import { JiraResources } from "msd-customer-resources-frontend";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getConfluenceSpaceURL } from "utils/confluenceURLService";
import { getHarvestProjectURL } from "utils/harvestURLService";
import { getJiraProjectURL } from "utils/jiraURLService";
import * as yup from "yup";
import { useIsJiraAdmin } from "../auth/useIsJiraAdmin";
import {
  AppState,
  ReportFeedbackAction,
  REPORT_APP_FEEDBACK
} from "../redux/AppTypes";
import { CustomerBudgetForm } from "./CustomerBudgetForm";
import { CustomerContractHourTypesForm } from "./CustomerContractHourTypesForm";
import { PERSIST_CUSTOMER } from "./CustomerTypes";
import useAllCustomers from "./useAllCustomers";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(DefaultTableCell);

const schema = yup.object().shape({
  backupEngineer: yup.string().optional(),
  constractHours: yup.array().of(yup.string()),
  constractSignDate: yup.date().optional(),
  csa: yup.string().optional(),
  engagementManager: yup.string().optional(),
  flags: yup.array().of(yup.string()),
  girMonitoring: yup.string().optional(),
  girTicketSystems: yup.string().optional(),
  girTollFreeNumber: yup.string().optional(),
  id: yup.string().optional(),
  jiraProjectKey: yup.string().optional(),
  jiraCustomerCommunication: yup.boolean().optional(),
  jiraCustomerQBR: yup.boolean().optional(),
  name: yup.string().required(),
  primaryEngineer: yup.string().optional(),
  productTypes: yup.array().of(yup.string()),
  region: yup.string().optional(),
  salesContact: yup.string().optional(),
  skills: yup.array().of(yup.string()),
  team: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
    },
  },
}));

const now = new Date();

export function CustomerForm() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const synching = useSelector((state: AppState) => state.customers.synching);
  const items = useAllCustomers();
  const ems = useAllEMs();
  const sortedEms = useSortedEMs(now.getFullYear(), now.getMonth());
  const params: { [key: string]: string } = useParams();
  const [customer, setCustomer] = useState(new CustomerHandle({} as any));
  const [loading, setLoading] = useState(true);
  const [teamId, setTeamId] = useState(undefined as string | undefined);
  const engineers = useEngineersInAggregator(
    now.getFullYear(),
    now.getMonth(),
    EngineerAggregatorType.TEAM,
    teamId
  );
  const [jiraResourcesExpanded, setJiraResourcesExpanded] = useState(false);
  const [em, setEM] = useState(undefined as undefined | EMHandle);
  const jiraAdmin = useIsJiraAdmin();

  const form = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    const handle = new CustomerHandle(customer);
    const team = handle.getTeam(now.getFullYear(), now.getMonth());
    setTeamId(team);
  }, [customer]);

  useEffect(() => {
    const id = params.id;
    let initialItem = items[id];
    if (!id) {
      setLoading(false);
    } else if (initialItem) {
      setLoading(false);
      setCustomer(initialItem);
    }
  }, [params.id, items]);

  useEffect(() => {
    const em = ems[customer.engagementManager || ""];
    setEM(em);
  }, [ems, customer]);

  function close(): void {
    if (history.length > 0) {
      history.goBack();
    } else {
      history.push("/customers");
    }
  }

  function createCustomerFromForm(data: any): Customer {
    const newCustomer: Customer = {
      backupEngineer: data.backupEngineer,
      budget: data.budget,
      burnedBudget: customer.burnedBudget || {},
      confluenceKey: data.confluenceKey,
      contractHourTypes: data.contractHourTypes || DEFAULT_CONTRACT_HOURS,
      contractSignDate: data.contractSignDate
        ? {
          year: data.contractSignDate?.getFullYear(),
          month: data.contractSignDate?.getMonth(),
          day: data.contractSignDate?.getDate(),
        }
        : undefined,
      csa: data.csa,
      encoreAccountId: data.encoreAccountId,
      engagementManager: data.engagementManager,
      flags: data.flags,
      gir: {
        tollFreeNumber: data.girTollFreeNumber,
        ticketSystems: data.girTicketSystems,
        monitoring: data.girMonitoring,
      },
      harvestHours: customer.harvestHours || {},
      harvestProjectId: data.harvestProjectId || "",
      id: data.id,
      jira: {
        customerCommunication:
          typeof data.jiraCustomerCommunication === "boolean"
            ? data.jiraCustomerCommunication
            : customer?.jira?.customerCommunication || false,
        qbr:
          typeof data.jiraCustomerQBR === "boolean"
            ? data.jiraCustomerQBR
            : customer?.jira?.qbr || false,
        updatedOutOfCP:
          typeof data.updatedOutOfCP === "boolean" ?
            customer?.jira?.updatedOutOfCP || false : false,
      },
      jiraProjectKey: data.jiraProjectKey || "",
      kickoffDate: data.kickoffDate
        ? {
          year: data.kickoffDate?.getFullYear(),
          month: data.kickoffDate?.getMonth(),
          day: data.kickoffDate?.getDate(),
        }
        : undefined,
      name: data.name,
      offboardingDate: data.offboardingDate
        ? {
          year: data.offboardingDate?.getFullYear(),
          month: data.offboardingDate?.getMonth(),
          day: data.offboardingDate?.getDate(),
        }
        : undefined,
      primaryEngineer: data.primaryEngineer,
      productTypes: data.productTypes || [],
      qbrStartDate: customer.qbrStartDate,
      quoteManagerLink: data.quoteManagerLink,
      raptorLink: data.raptorLink,
      region: data.region,
      salesContact: data.salesContact,
      skills: data.skills || [],
    };
    // take default values from the persisted customer
    return newCustomer;
  }

  function onSubmit(data: any): void {
    const item = createCustomerFromForm(data);
    const response = dispatch({
      type: PERSIST_CUSTOMER,
      item: item,
    }) as any;
    response
      .then((msg: string) => close())
      .catch((error: any) => {
        const action: ReportFeedbackAction = {
          type: REPORT_APP_FEEDBACK,
          feedback: error.message || "error",
        };
        dispatch(action);
      });
  }

  return (
    <Container maxWidth="md">
      {loading ? (
        <CircularProgress />
      ) : (
        <form className={classes.root} onSubmit={form.handleSubmit(onSubmit)}>
          <input
            type="hidden"
            name="id"
            value={params.id}
            ref={form.register}
          />
          <FormControl fullWidth>
            <Typography variant="h4">
              Customer form
              {customer.id ? (
                <Tooltip title="History">
                  <span>
                    <HistoryPanel
                      type={MementoType.CUSTOMER}
                      target={customer}
                    />
                  </span>
                </Tooltip>
              ) : null}
              <DocsDrawer path="/admin/customers.html" />
            </Typography>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              name="name"
              variant="filled"
              label="Name"
              autoFocus
              defaultValue={customer.name}
              error={!!form.errors.name}
              inputRef={form.register}
              inputProps={{
                form: {
                  autocomplete: "off",
                },
              }}
              helperText={form.errors.name?.message}
            />
          </FormControl>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Controller
                name="engagementManager"
                control={form.control}
                defaultValue={customer.engagementManager || ""}
                render={(field) => (
                  <FormControl fullWidth>
                    <InputLabel id="em-label">Engagement manager</InputLabel>
                    <Select
                      labelId="em-label"
                      defaultValue={field.value}
                      onChange={(e) => {
                        const emId = e.target.value as string | undefined;
                        form.setValue("engagementManager", emId);
                        const em = ems[emId || ""];
                        setEM(em);
                      }}
                      error={!!form.errors.engagementManager}
                      renderValue={(value) => ems[`${value}`]?.getDisplayName()}
                    >
                      {(sortedEms || []).map((em) => (
                        <MenuItem key={em.id} value={em.id}>
                          <ListItemText primary={em.getDisplayName()} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {form.errors.engagementManager?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                form={form}
                name="jiraProjectKey"
                label="Jira Project Key"
                defaultValue={customer.jiraProjectKey}
                linkBaseUrl={getJiraProjectURL("")}
                disabled={true}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                form={form}
                name="harvestProjectId"
                label="Harvest Project ID"
                defaultValue={customer.harvestProjectId || ""}
                linkBaseUrl={getHarvestProjectURL("")}
                disabled={!!customer.harvestProjectId}
              />
            </Grid>
            <Grid item xs={12}>
              <Box style={{ marginLeft: 8, marginRight: 8 }}>
                <Typography variant="caption" style={{ color: "gray" }}>
                  All fields above are required to configure the Jira project.
                  Please do not update the Jira project key or the Harvest ID
                  once the Jira project is created.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <EngineerSelect
                name="primaryEngineer"
                label="Primary engineer"
                defaultValue={customer.primaryEngineer || ""}
                form={form}
                engineers={engineers}
              />
            </Grid>
            <Grid item xs={4}>
              <EngineerSelect
                name="backupEngineer"
                label="Backup engineer"
                defaultValue={customer.backupEngineer || ""}
                form={form}
                engineers={engineers}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                form={form}
                name="csa"
                label="CSA"
                defaultValue={customer.csa || ""}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  name="salesContact"
                  label="Sales Contact"
                  defaultValue={customer.salesContact}
                  error={!!form.errors.salesContact}
                  inputRef={form.register}
                  helperText={form.errors.salesContact?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="flags"
                control={form.control}
                defaultValue={customer.flags || []}
                render={(field) => (
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="flags-label">Flags</InputLabel>
                    <Select
                      name="flags"
                      multiple
                      value={field.value}
                      onChange={(e) => form.setValue("flags", e.target.value)}
                      labelId="flags-label"
                      error={!!form.errors.flags}
                      renderValue={(selected) => {
                        const values = Array.isArray(selected) ? selected : [];
                        return values.join(", ");
                      }}
                    >
                      {Object.values(CustomerFlag).map((name) => {
                        return (
                          <MenuItem key={name} value={name}>
                            <ListItemText primary={name} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {form.errors.flags?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <CustomTextField
                form={form}
                name="confluenceKey"
                label="Confluence Key"
                defaultValue={customer.confluenceKey || ""}
                linkBaseUrl={getConfluenceSpaceURL("")}
                disabled={!!customer.confluenceKey}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomDatePicker
                year={customer.contractSignDate?.year}
                month={customer.contractSignDate?.month}
                day={customer.contractSignDate?.day}
                control={form.control}
                record="contractSignDate"
                label="Contract sign date"
              />
            </Grid>
            <Grid item xs={4}>
              <CustomDatePicker
                year={customer.kickoffDate?.year}
                month={customer.kickoffDate?.month}
                day={customer.kickoffDate?.day}
                control={form.control}
                record="kickoffDate"
                label="Kickoff date"
              />
            </Grid>
            <Grid item xs={4}>
              <CustomDatePicker
                year={customer.offboardingDate?.year}
                month={customer.offboardingDate?.month}
                day={customer.offboardingDate?.day}
                control={form.control}
                record="offboardingDate"
                label="Offboarding date"
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                form={form}
                name="quoteManagerLink"
                label="Quote Manager Link"
                defaultValue={customer.quoteManagerLink || ""}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                form={form}
                name="raptorLink"
                label="Raptor Link"
                defaultValue={customer.raptorLink || ""}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                form={form}
                name="encoreAccountId"
                label="Encore Account Id"
                defaultValue={customer.encoreAccountId || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <RegionSelect
                name="region"
                defaultValue={customer.region}
                form={form}
              />
            </Grid>
            <Grid item xs={12}>
              <ProductTypeChips
                form={form}
                name="productTypes"
                defaultValue={customer.productTypes || []}
              />
            </Grid>

            <Grid item xs={12}>
              <SkillsChips
                name="skills"
                form={form}
                defaultValue={customer.skills || []}
              />
            </Grid>

            <Grid item xs={12}>
              <Paper variant="outlined" style={{ marginLeft: 8 }}>
                <Typography
                  variant="h6"
                  style={{ marginTop: 16, marginLeft: 16 }}
                >
                  Budget
                </Typography>
                <Typography
                  variant="caption"
                  style={{
                    display: "block",
                    color: "gray",
                    paddingLeft: 16,
                    paddingRight: 16,
                  }}
                >
                  The budget is valid from the given date until the next budget
                  entry or until the customer is offboarded. Thus, there's no
                  need to create a new entry for each month if the values remain
                  the same. The latest entry will be used to calculate the
                  budget for subsequent months.
                </Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>From</TableCell>
                        <TableCell>Team</TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          Engineer Hours
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          Ops hours
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          Bank hours
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          EM hours
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          Contract hours
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          Planned hours
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableBody>
                    <Controller
                      name="contractHourTypes"
                      control={form.control}
                      defaultValue={
                        customer.contractHourTypes || DEFAULT_CONTRACT_HOURS
                      }
                      render={(field) => (
                        <CustomerContractHourTypesForm
                          contractHourTypes={field.value}
                          onChange={(contractHourTypes) => {
                            form.setValue(
                              "contractHourTypes",
                              contractHourTypes
                            );
                            // update budget
                            form.setValue(
                              "budget",
                              Array.from(
                                form.getValues().budget || customer.budget || []
                              )
                            );
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="budget"
                      control={form.control}
                      defaultValue={customer.budget || []}
                      render={(field) => (
                        <CustomerBudgetForm
                          currentValue={
                            Array.isArray(field.value) && field.value.length > 0
                              ? field.value
                              : []
                          }
                          contractHourTypes={
                            form.getValues().contractHourTypes ||
                            DEFAULT_CONTRACT_HOURS
                          }
                          skills={customer.skills}
                          onChange={(budget) => {
                            form.setValue("budget", budget);
                            const newCustomer = createCustomerFromForm(
                              form.getValues()
                            );
                            const customer = new CustomerHandle(newCustomer);
                            const teamId = customer.getTeam(
                              now.getFullYear(),
                              now.getMonth()
                            );
                            setTeamId(teamId);
                          }}
                        />
                      )}
                    />
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>

            {jiraAdmin ? (
              <Grid item xs={12} style={{ paddingLeft: 16, paddingRight: 8 }}>
                <Paper variant="outlined" style={{ padding: 16 }}>
                  <Box>
                    <Typography variant="h6">Ticket automation</Typography>
                  </Box>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Controller
                      name="jiraCustomerCommunication"
                      control={form.control}
                      defaultValue={
                        customer?.jira?.customerCommunication || false
                      }
                      render={(field) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={field.value}
                              onChange={(e) => {
                                form.setValue(
                                  "jiraCustomerCommunication",
                                  e?.target?.checked || false
                                );
                              }}
                            />
                          }
                          label="Customer communication and administration"
                        />
                      )}
                    />
                    <Divider
                      orientation="vertical"
                      style={{ marginRight: 16, height: 24 }}
                    />
                    <Controller
                      name="jiraCustomerQBR"
                      control={form.control}
                      defaultValue={customer?.jira?.qbr || false}
                      render={(field) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={field.value}
                              onChange={(e) => {
                                form.setValue(
                                  "jiraCustomerQBR",
                                  e?.target?.checked || false
                                );
                              }}
                            />
                          }
                          label="QBR"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Typography variant="caption" style={{ color: "gray" }}>
                      - Customer communication and administration tickets will
                      be created weekly
                      <br />- QBR tickets will be every 90 days from the
                      customer onboarding, i.e. the date the first ticket was
                      created.
                      <br />
                      - Projects onboarded from the capacity planner don't have
                      automation rules. They rely on these settings to create
                      tickets automatically.
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            ) : null}

            {jiraAdmin && customer.id ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Accordion
                    onChange={(_, expanded) =>
                      setJiraResourcesExpanded(expanded)
                    }
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Jira Resources</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box style={{ display: "block", width: "100%" }}>
                        <Box style={{ display: "block", textAlign: "right" }}>
                          <Typography
                            variant="caption"
                            style={{ color: "gray" }}
                          >
                            This component creates or updates a Jira project
                            with standard EE settings for this customer
                          </Typography>
                          <DocsDrawer path="/admin/customer-onboarding.html" />
                        </Box>
                        <Box style={{ display: "block" }}>
                          {jiraResourcesExpanded ? (
                            <JiraResources
                              projectKey={customer.jiraProjectKey || ""}
                              projectName={customer.name || ""}
                              emEmail={em?.getRackspaceEmail() || ""}
                              emLabel={em?.getJiraLabel() || ""}
                              harvestId={customer?.harvestProjectId || ""}
                            />
                          ) : null}
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </FormControl>
              </Grid>
            ) : null}
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <FormControl margin="normal">
              <Button onClick={() => close()} disabled={synching}>
                Discard
              </Button>
            </FormControl>
            <FormControl margin="normal">
              <Button
                disabled={synching}
                variant="contained"
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </FormControl>
          </Grid>
        </form>
      )}
    </Container>
  );
}
