import React from "react";
import { Switch, Route } from "react-router-dom";
import { RegionsPanel } from "./RegionsPanel";
import { RegionForm } from "./RegionForm";

export function RegionsPage() {
  return (
    <Switch>
      <Route path="/administration/regions/:id" strict={true}>
        <RegionForm />
      </Route>
      <Route path="/administration/regions/" strict={true}>
        <RegionForm />
      </Route>
      <Route path="/administration/regions">
        <RegionsPanel />
      </Route>
    </Switch>
  );
}
