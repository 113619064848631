import { IconButton, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";

export function EMEditButton({ id }: { id: string }) {
  return (
    <Tooltip title="Edit">
      <Link
        to={`/administration/ems/${id}`}
        style={{
          textDecoration: "none",
          color: "inherit",
          cursor: "inherit",
        }}
      >
        <IconButton>
          <EditIcon />
        </IconButton>
      </Link>
    </Tooltip>
  );
}
