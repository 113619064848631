import { Middleware } from "redux";
import { AppState, InitAppAction, INIT_APP } from "../redux/AppTypes";
import {
  CustomerAction as BaseAction,
  FETCH_CUSTOMERS as FETCH,
  LOAD_CUSTOMERS as LOAD,
  LoadCustomersAction as LoadAction,
  REMOVE_CUSTOMER as REMOVE,
  PERSIST_CUSTOMER as PERSIST,
} from "./CustomerTypes";
import service from "./CustomerService";

const initMiddleware: Middleware<{}, AppState> = (store) => (next) => (
  action: InitAppAction
) => {
  const result = next(action);
  if (action.type === INIT_APP) {
    store.dispatch({ type: FETCH });
  }
  return result;
};

const fetchMiddleware: Middleware<{}, AppState> = (store) => (next) => async (
  action: BaseAction
) => {
  const result = next(action);
  if (action.type === FETCH) {
    const items = await service.fetch();

    items.sort((i1, i2) =>
      i1.name.toLowerCase().localeCompare(i2.name.toLowerCase())
    );

    const load: LoadAction = {
      type: LOAD,
      items,
    };
    store.dispatch(load);
    return items;
  } else {
    return result;
  }
};

const deleteMiddleware: Middleware<{}, AppState> = (store) => (next) => async (
  action: BaseAction
) => {
  const result = next(action);
  if (action.type === REMOVE) {
    const items = await service.delete(action.id);
    const load: LoadAction = {
      type: LOAD,
      items: Object.values(items),
    };
    store.dispatch(load);
    return items;
  } else {
    return result;
  }
};

const persistMiddleware: Middleware<{}, AppState> = (store) => (next) => async (
  action: BaseAction
) => {
  const result = next(action);
  if (action.type === PERSIST) {
    const item = action.item;
    const items = await service.persist(item);
    const load: LoadAction = {
      type: LOAD,
      items: Object.values(items),
    };
    store.dispatch(load);
    return items;
  } else {
    return result;
  }
};

const middlewares = [
  initMiddleware,
  fetchMiddleware,
  deleteMiddleware,
  persistMiddleware,
];
export default middlewares;
