import { useEffect, useState } from "react";
import { useArray } from "utils/useArray";
import { useMonthDTO } from "utils/useMonthDTO";
import { AggregatedHarvestBudget } from "./HarvestTypes";
import { useHarvestBudgetTrend } from "./useHarvestBudgets";

const EMPTY_BUDGET = new AggregatedHarvestBudget();

export function useHarvestBudget(
  year: number,
  month: number
): AggregatedHarvestBudget {
  const monthDTO = useMonthDTO(year, month);
  const months = useArray(monthDTO);
  const budgets = useHarvestBudgetTrend(months);
  const [selectedBudgets, setSelectedBudgets] = useState(EMPTY_BUDGET);
  useEffect(() => {
    if (months.length < 1) {
      setSelectedBudgets(EMPTY_BUDGET);
    } else {
      const { year, month } = months[0];
      const newSelectedBudgets = budgets.get(year, month);
      setSelectedBudgets(newSelectedBudgets);
    }
  }, [months, budgets]);

  return selectedBudgets;
}
