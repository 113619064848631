import { Box } from "@material-ui/core";
import useCustomers from "customers/useCustomers";
import { DashboardBreadcrumbs } from "dashboards/DashboardBreadcrumbs";
import { useDashboardPeriod } from "dashboards/useDashboardPeriod";
import { useEngineers } from "engineers/useEngineers";
import { useParams } from "react-router-dom";
import { SkillDashboard } from "./SkillDashboard";

export function SkillDashboardPage({ baseUrl }: { baseUrl: string }) {
  const { year, month } = useDashboardPeriod();
  const customers = useCustomers(year, month);
  const engineers = useEngineers(year, month);
  const params: { [key: string]: string } = useParams();
  return (
    <Box>
      <DashboardBreadcrumbs />
      <SkillDashboard
        year={year}
        month={month}
        skill={params.skill}
        customers={customers.items}
        engineers={engineers}
        baseUrl={baseUrl}
      />
    </Box>
  );
}
