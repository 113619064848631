import {
  LOAD_ENGINEERS,
  EngineersState,
  EngineerAction,
  FETCH_ENGINEERS,
  REMOVE_ENGINEER,
  PERSIST_ENGINEER,
} from "./EngineersTypes";
import { Engineer } from "msd-capacity-planning-model";

const initialState: EngineersState = {
  items: {},
  synching: true,
};

export default function teamsReducer(
  state = initialState,
  action: EngineerAction
): EngineersState {
  switch (action.type) {
    case FETCH_ENGINEERS:
      return {
        ...state,
        synching: true,
      };
    case REMOVE_ENGINEER:
      return {
        ...state,
        synching: true,
      };
    case PERSIST_ENGINEER:
      return {
        ...state,
        synching: true,
      };
    case LOAD_ENGINEERS: {
      const items = action.items.reduce(
        (all: { [id: string]: Engineer }, e) => {
          e.skills = e.skills || [];
          e.utilization = e.utilization || [];
          e.timeOff = e.timeOff || [];
          all[e.id] = e;
          return all;
        },
        {}
      );
      return {
        ...state,
        items,
        synching: false,
      };
    }
    default:
      return state;
  }
}
