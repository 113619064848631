import { JiraBoard } from './JiraBoardDTO'
import { JiraFilter } from './JiraBoardFilterDTO'
import env from '../Environment'
import fetchService from '../FetchService'
import { JiraBoardAdministratorDTO } from './JiraBoardAdministratorDTO'
import { JiraProjectLeadDTO } from './JiraProjectLeadDTO'
import { JiraBoardColumnsDTO } from './JiraBoardColumnsDTO'

const ASYNC_FETCH_PERIOD = 5000
const ASYNC_FETCH_MAX_RETRIES = 90

export enum JiraProjectSchemeType {
  ISSUE_TYPE = 'issuetypescheme',
  ISSUE_SCREEN_TYPE = 'issuetypescreenscheme',
  NOTIFICATION = 'notificationscheme',
  PERMISSION = 'permissionscheme',
  WORKFLOW = 'workflowscheme'
}

export class JiraResourcesService {
  host: Promise<string> | undefined

  async getHost(): Promise<string> {
    if (!this.host) {
      this.host = fetchService
        .fetch(`${env.endpoint}/jira/host`)
        .then((r) => r.json())
        .then((json) => json.host as string)
    }
    const host = await this.host
    if (!host) {
      this.host = undefined
    }
    return host || ''
  }

  createProject(request: {
    projectKey: string
    projectName: string
    harvestId: string
  }): Promise<any> {
    return (this.host = fetchService
      .fetch(`${env.endpoint}/jira/project/${request.projectKey}`, {
        method: 'PUT',
        body: JSON.stringify({
          name: request.projectName,
          harvestId: request.harvestId
        })
      })
      .then((r) => r.json())
      .then((json) => json.project))
  }

  async getProject(projectKey: string): Promise<any> {
    return (this.host = fetchService
      .fetch(`${env.endpoint}/jira/project/${projectKey}`)
      .then((r) => r.json())
      .then((json) => json.project))
  }

  async getProjectLead(
    projectKey: string,
    leadEmail: string
  ): Promise<JiraProjectLeadDTO> {
    const url = new URL(`${env.endpoint}/jira/project/${projectKey}/lead`)
    url.searchParams.append('expectedLeadEmail', leadEmail)
    return fetchService
      .fetch(url.toString())
      .then((r) => r.json())
      .then((r) => r.lead)
  }

  async putProjectLead(projectKey: string, leadEmail: string): Promise<void> {
    return fetchService
      .fetch(`${env.endpoint}/jira/project/${projectKey}/lead`, {
        method: 'PUT',
        body: JSON.stringify({ email: leadEmail })
      })
      .then(() => {})
  }

  async getProjectPeople(projectKey: string): Promise<any[]> {
    return fetchService
      .fetch(`${env.endpoint}/jira/project/${projectKey}/people`)
      .then((r) => r.json())
  }

  async getScheme(
    projectKey: string,
    scheme: JiraProjectSchemeType
  ): Promise<any> {
    return fetchService
      .fetch(`${env.endpoint}/jira/project/${projectKey}/scheme/${scheme}`)
      .then((r) => {
        if (r.status === 404) {
          return {}
        } else {
          return r.json().then((r) => r.scheme)
        }
      })
  }

  async updateScheme(
    projectKey: string,
    scheme: JiraProjectSchemeType
  ): Promise<any> {
    return fetchService.fetch(
      `${env.endpoint}/jira/project/${projectKey}/scheme/${scheme}`,
      {
        method: 'PUT'
      }
    )
  }

  async updatePeople(projectKey: string): Promise<any> {
    return fetchService.fetch(
      `${env.endpoint}/jira/project/${projectKey}/people`,
      {
        method: 'PUT'
      }
    )
  }

  async getProjectFilter(projectKey: string): Promise<JiraFilter | undefined> {
    return fetchService
      .fetch(`${env.endpoint}/jira/project/${projectKey}/filter`)
      .then((r) => {
        if (r.status === 404) {
          return undefined
        } else {
          return r.json()
        }
      })
  }

  async putProjectFilter(projectKey: string): Promise<any> {
    return fetchService
      .fetch(`${env.endpoint}/jira/project/${projectKey}/filter`, {
        method: 'PUT'
      })
      .then((r) => r.json())
  }

  async getProjectBoard(projectKey: string): Promise<JiraBoard | null> {
    return fetchService
      .fetch(`${env.endpoint}/jira/project/${projectKey}/board`)
      .then((r) => {
        if (r.status === 404) {
          return null
        } else {
          return r.json()
        }
      })
  }

  async putProjectBoard(projectKey: string): Promise<any> {
    return fetchService
      .fetch(`${env.endpoint}/jira/project/${projectKey}/board`, {
        method: 'PUT'
      })
      .then((r) => r.json())
  }

  async getBoardAdministrator(
    projectKey: string
  ): Promise<JiraBoardAdministratorDTO | null> {
    const response = await this.asyncFetch(
      `${env.endpoint}/jira/project/${projectKey}/board/administrator`
    )
    if (response.status === 404) {
      return null
    } else if (response.status !== 200) {
      await response.text().then((text) => {
        console.log(`${response.status}: ${text}`)
      })
      throw new Error(`${response.status}`)
    }
    return response.json()
  }

  async putBoardAdministrator(projectKey: string): Promise<any> {
    const response = await this.asyncFetch(
      `${env.endpoint}/jira/project/${projectKey}/board/administrator`,
      {
        method: 'PUT'
      }
    )
    if (response.status !== 200) {
      await response.text().then((text) => {
        console.log(`${response.status}: ${text}`)
      })
      throw new Error(`${response.status}`)
    }
    return response.json()
  }

  async getBoardColumns(
    projectKey: string
  ): Promise<JiraBoardColumnsDTO | null> {
    return fetchService
      .fetch(`${env.endpoint}/jira/project/${projectKey}/board/columns`)
      .then((r) => {
        if (r.status === 404) {
          return null
        } else {
          return r.json()
        }
      })
  }

  async putBoardColumns(projectKey: string): Promise<any> {
    const response = await this.asyncFetch(
      `${env.endpoint}/jira/project/${projectKey}/board/columns`,
      {
        method: 'PUT'
      }
    )
    if (response.status !== 200) {
      await response.text().then((text) => {
        console.log(`${response.status}: ${text}`)
      })
      throw new Error(`${response.status}`)
    }
    return response.json()
  }

  async getBoardSwimlane(projectKey: string): Promise<boolean | null> {
    const response = await this.asyncFetch(
      `${env.endpoint}/jira/project/${projectKey}/board/swimlane`
    )
    if (response.status === 404) {
      return null
    } else if (response.status !== 200) {
      await response.text().then((text) => {
        console.log(`${response.status}: ${text}`)
      })
      throw new Error(`${response.status}`)
    }
    return response.json()
  }

  async putBoardSwimlane(projectKey: string): Promise<any> {
    const response = await this.asyncFetch(
      `${env.endpoint}/jira/project/${projectKey}/board/swimlane`,
      {
        method: 'PUT'
      }
    )
    if (response.status !== 200) {
      await response.text().then((text) => {
        console.log(`${response.status}: ${text}`)
      })
      throw new Error(`${response.status}`)
    }
    return response.json()
  }

  async getOnboardingTasks(projectKey: string): Promise<boolean | null> {
    return fetchService
      .fetch(`${env.endpoint}/jira/project/${projectKey}/onboarding/tasks`)
      .then((r) => {
        if (r.status === 404) {
          return null
        } else {
          return r.json().then((r) => r.onboarded)
        }
      })
  }

  async putOnboardingTasks(
    projectKey: string,
    emEmail: string,
    emLabel: string
  ): Promise<any> {
    await fetchService.fetch(
      `${env.endpoint}/jira/project/${projectKey}/onboarding/tasks`,
      {
        method: 'PUT',
        body: JSON.stringify({ emLabel, emEmail })
      }
    )
  }

  async asyncFetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
    let retries = 0
    let response: Response | null = null

    do {
      const r = await fetchService.fetch(url, init)

      if (r.status !== 202) {
        return r.text().then((text) => {
          throw new Error(`${r.status}: ${text}`)
        })
      }
      // get the id from the response
      const id = await r.json().then((json) => json.id as string)

      // wait until processing is done
      do {
        response = await fetchService.fetch(`${env.endpoint}/async/${id}`)
        if (response?.status === 202) {
          await new Promise((resolve) =>
            setTimeout(resolve, ASYNC_FETCH_PERIOD)
          )
        }
        // retry on 202 - processing
      } while (response?.status === 202 && retries++ < ASYNC_FETCH_MAX_RETRIES)

      // retry on 503 - temporarily unavailable
      if (response?.status === 503) {
        await new Promise((resolve) => setTimeout(resolve, ASYNC_FETCH_PERIOD))
      }
    } while (response?.status === 503 && retries++ < ASYNC_FETCH_MAX_RETRIES)
    return response
  }

  async getAutomationComm(projectKey: string): Promise<boolean | null> {
    const response = await this.asyncFetch(
      `${env.endpoint}/jira/project/${projectKey}/automation/comm`
    )
    if (response.status !== 200) {
      await response.text().then((text) => {
        console.log(`${response.status}: ${text}`)
      })
      throw new Error(`${response.status}`)
    }
    return response.json().then((r) => r.comm)
  }

  async putAutomationComm(projectKey: string, emLabel: string): Promise<void> {
    const response = await this.asyncFetch(
      `${env.endpoint}/jira/project/${projectKey}/automation/comm`,
      {
        method: 'PUT',
        body: JSON.stringify({ emLabel })
      }
    )
    if (response.status !== 200) {
      await response.text().then((text) => {
        console.log(`${response.status}: ${text}`)
      })
    }
  }

  async getAutomationQBR(projectKey: string): Promise<boolean | null> {
    const response = await this.asyncFetch(
      `${env.endpoint}/jira/project/${projectKey}/automation/qbr`
    )
    if (response.status !== 200) {
      await response.text().then((text) => {
        console.log(`${response.status}: ${text}`)
      })
      throw new Error(`${response.status}`)
    }
    return response.json().then((r) => r.qbr)
  }

  async putAutomationQBR(projectKey: string, emLabel: string): Promise<void> {
    const response = await this.asyncFetch(
      `${env.endpoint}/jira/project/${projectKey}/automation/qbr`,
      {
        method: 'PUT',
        body: JSON.stringify({ emLabel })
      }
    )
    if (response.status !== 200) {
      await response.text().then((text) => {
        console.log(`${response.status}: ${text}`)
      })
    }
  }

  async getHarvestCode(
    projectKey: string,
    harvestId: string
  ): Promise<boolean | null> {
    const response = await fetchService.fetch(
      `${env.endpoint}/harvest/project/${projectKey}/${harvestId}`
    )
    if (response.status !== 200) {
      await response.text().then((text) => {
        console.log(`${response.status}: ${text}`)
      })
      throw new Error(`${response.status}`)
    }
    return response.json().then((r) => r.code)
  }

  async putHarvestCode(projectKey: string, harvestId: string): Promise<void> {
    const response = await fetchService.fetch(
      `${env.endpoint}/harvest/project/${projectKey}/${harvestId}`,
      {
        method: 'PUT'
      }
    )
    if (response.status !== 200) {
      await response.text().then((text) => {
        console.log(`${response.status}: ${text}`)
      })
    }
  }
}

const service = new JiraResourcesService()
export default service
