import Auth from "@aws-amplify/auth";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import env from "../app/Environment";

const configuration = {
  cookieStorage: {
    domain: window.location.hostname,
    secure: window.location.hostname !== "localhost",
  },
  region: env.region,
  userPoolId: env.userPoolId,
  userPoolWebClientId: env.userPoolClientId,
  clientId: env.userPoolClientId,
  oauth: {
    domain:
      `${env.userPoolDomain}.auth.${env.region}.amazoncognito.com`,
    scope: ["openid"],
    redirectSignIn: env.redirectSignIn,
    redirectSignOut: env.redirectSignOut,
    responseType: "code",
  },
};
Auth.configure(configuration);

export function currentSession(): Promise<CognitoUserSession> {
  return Auth.currentSession();
}

export function login() {
  return Auth.federatedSignIn();
}

export function signOut() {
  return Auth.signOut();
}