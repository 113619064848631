import { Container } from "@material-ui/core";
import { EngineerDashboardRoutePage } from "dashboards/engineer/EngineerDashboardRoutePage";
import { Route, Switch } from "react-router-dom";
import { EngineersDashboardPage } from "./EngineersDashboardPage";
import { EngineerDeltaDashboardPage } from "./EngineersDeltaDashboardPage";
import { EngineerDelta1850DashboardPage } from "./EngineersDelta1850DashboardPage";
import { useDashboardPeriod } from "dashboards/useDashboardPeriod";
import { useEngineers } from "engineers/useEngineers";

export function EngineersDashboardRoutePage({ baseUrl }: { baseUrl: string }) {

  return (
    <Container maxWidth="xl">
      <Switch>
      <Route path={`${baseUrl}/delta`} exact={true}>
          <EngineerDeltaDashboardPage
            linkPrefix="/dashboards/engineers"
            routelinkPrefix="/dashboards/regions"
          />
        </Route>
        <Route path={`${baseUrl}/delta1850`} exact={true}>
          <EngineerDelta1850DashboardPage
            linkPrefix="/dashboards/engineers"
            routelinkPrefix="/dashboards/regions"
          />
        </Route>
        <Route path={`${baseUrl}/:engineer`}>
          <EngineerDashboardRoutePage baseUrl={baseUrl} />
        </Route>
        <Route path={`${baseUrl}`}>
          <EngineersDashboardPage />
        </Route>

      </Switch>
    </Container>
  );
}
