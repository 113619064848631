import { TeamHandle } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { TeamAggregatorType, useTeamAggregator } from "./TeamAggregator";
import { useTeams } from "./useTeams";
import { useDashboardPeriod } from "dashboards/useDashboardPeriod";
export interface TeamsByAggregator {
  [id: string]: TeamHandle[];
}

export function useTeamsByAggregator(
  type: TeamAggregatorType
): TeamsByAggregator {
  const now =  new Date();
  const period =  useDashboardPeriod();
  const teams = useTeams(period.year, period.month);
  const aggregator = useTeamAggregator();
  const [teamsByGroup, setTeamsByGroup] = useState({} as TeamsByAggregator);

  useEffect(() => {
    const newTeamsByGroup = Object.values(teams).reduce((all, team) => {
      const id = aggregator.get(team, type);
      if (!all[id]) {
        all[id] = [];
      }
      all[id].push(team);
      return all;
    }, {} as TeamsByAggregator);
    setTeamsByGroup(newTeamsByGroup);
  }, [aggregator, teams, type]);

  return teamsByGroup;
}
