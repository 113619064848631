import { useTheme } from "@material-ui/core";
import { useEngineerCapacityByAggregator } from "capacity/useEngineerCapacityByAggregator";
import { PieChartCard } from "components/PieChartCard";
import useEngineersInAggregator from "engineers/useEngineersInAggregator";
import { EngineerAggregatorType } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useTeams } from "teams/useTeams";
import { getColors } from "utils/colorGenerator";

export function SkillCapacityByTeamPieChart({
  year,
  month,
  skill,
}: {
  year: number;
  month: number;
  skill: string;
}) {
  const theme = useTheme();
  const teams = useTeams(year, month);
  const engineers = useEngineersInAggregator(
    year,
    month,
    EngineerAggregatorType.SKILL,
    skill
  );
  const capacities = useEngineerCapacityByAggregator(
    year,
    month,
    EngineerAggregatorType.TEAM,
    engineers
  );
  const [data, setData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    const teamIds = Object.keys(capacities);
    const labels = teamIds.map((teamId) => teams[teamId]?.name || "");
    const data = teamIds.map(
      (teamId) => capacities[teamId]?.allocatedHours || 0
    );
    const backgroundColor = getColors(theme, data.length);
    setData({ labels, datasets: [{ data, backgroundColor }] });
  }, [theme, teams, capacities]);

  return <PieChartCard title="Skill capacity by team" data={data} />;
}
