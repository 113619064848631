import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import useTeamCapacityInAggregator from "capacity/useTeamCapacityInAggregator";
import { useBudgetInAggregator } from "customers/useBudgetInAggregator";
import useCustomersIdsInAggregator from "customers/useCustomerIdsInAggregator";
import useCustomersInAggregator from "customers/useCustomersInAggregator";
import { DashboardPeriod } from "dashboards/DashboardPeriod";
import useEngineersInAggregator from "engineers/useEngineersInAggregator";
import {
  CustomerAggregatorType,
  EngineerAggregatorType,
  TeamCapacityAggregatorType
} from "msd-capacity-planning-model";
import { CustomersNotificationsCard } from "notifications/customer/CustomersNotificationCard";
import { SystemNotificationList } from "notifications/SystemNotificationsList";
import { useTeamNotifications } from "notifications/team/useTeamNotifications";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useTeam from "teams/useTeam";
import { CommentsPanelEditor } from "../../comments/CommentsPanelEditor";
import { CountCard } from "../../components/CountCard";
import { TeamCapacityByRegionCard } from "../regions/CapacityByRegionCard";
import { CustomersBurnedBudgetScatterChartCard } from "../utilization/BurnedBudgetByCustomerScatterChartCard";
import { TeamBurnedBudgetCard } from "../utilization/BurnedBudgetCard";
import { TeamCapacityByRoleCard } from "../utilization/CapacityByRoleCard";
import { TeamHarvestBudgetTrend } from "../utilization/HarvestBudgetTrend";
import { HarvestEngineerHoursBarChartCard } from "../utilization/HarvestEngineerHoursBarChartCard";
import { TeamUtilizationRatioCard } from "../utilization/UtilizationRatioCard";
import { TeamUtilizationTrendCard } from "../utilization/UtilizationTrendCard";
import { TeamBudgetDetailsCard } from "./TeamBudgetDetailsCard";
import { TeamMissingSkillsCard } from "./TeamMissingSkillsCard";
import { TeamOverviewCard } from "./TeamOverviewCard";
import { TeamTimeOffForecastCard } from "./TeamTimeOffForecastCard";
import { TeamTopSkillsCard } from "./TeamTopSkillsCard";
import { TeamUtilizationTable } from "./TeamUtilizationTable";

export function TeamDashboard({
  period,
  teamId,
}: {
  period: DashboardPeriod;
  teamId: string;
}) {
  const location = useLocation();
  const { year, month } = period;
  const previousPeriod = { year, month: month - 1 };
  const customers = useCustomersInAggregator(
    year,
    month,
    CustomerAggregatorType.TEAM,
    teamId
  );
  const customerIds = useCustomersIdsInAggregator(
    year,
    month,
    CustomerAggregatorType.TEAM,
    teamId
  );
  const capacity = useTeamCapacityInAggregator(
    year,
    month,
    TeamCapacityAggregatorType.TEAM,
    teamId
  );
  const budget = useBudgetInAggregator(
    year,
    month,
    CustomerAggregatorType.TEAM,
    teamId
  );
  const engineers = useEngineersInAggregator(
    year,
    month,
    EngineerAggregatorType.TEAM,
    teamId
  );
  const history = useHistory();
  const team = useTeam(teamId);
  const notifications = useTeamNotifications(year, month, team);

  return (
    <Box style={{ marginTop: 8 }}>
      <TeamOverviewCard year={year} month={month} teamId={teamId} />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs={9}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={3}>
              <CountCard
                title="Customers"
                count={Object.keys(customers).length}
                path={`${location.pathname}/customers`}
              />
            </Grid>

            <Grid item xs={3}>
              <CountCard
                title="Budget"
                count={Math.round(budget.opsHours + budget.teamHours)}
              />
            </Grid>

            <Grid item xs={3}>
              <CountCard
                title="Engineers"
                count={Object.keys(capacity.items).length}
                path={`${location.pathname}/engineers`}
              />
            </Grid>

            <Grid item xs={3}>
              <CountCard
                title="Capacity"
                count={Math.round(capacity.allocatedHours)}
              />
            </Grid>

            <Grid item xs={6}>
              <TeamBurnedBudgetCard year={year} month={month} teamId={teamId} />
            </Grid>

            <Grid item xs={6}>
              <TeamUtilizationRatioCard year={year} month={month} team={team} />
            </Grid>

            <Grid item xs={6}>
              <HarvestEngineerHoursBarChartCard
                year={year}
                month={month}
                engineers={engineers}
              />
            </Grid>

            <Grid item xs={6}>
              <CustomersBurnedBudgetScatterChartCard
                year={year}
                month={month}
                customerIds={customerIds}
                linkPrefix={`${location.pathname}/customers`}
              />
            </Grid>

            <Grid item xs={6}>
              <TeamHarvestBudgetTrend period={previousPeriod} teamId={teamId} />
            </Grid>

            <Grid item xs={6}>
              <TeamUtilizationTrendCard period={period} teamId={teamId} />
            </Grid>

            <Grid item xs={6}>
              <TeamBudgetDetailsCard budget={budget} />
            </Grid>

            <Grid item xs={6}>
              <TeamTimeOffForecastCard period={period} teamId={teamId} />
            </Grid>

            <Grid item xs={6}>
              <TeamCapacityByRegionCard
                year={year}
                month={month}
                teamId={teamId}
              />
            </Grid>

            <Grid item xs={6}>
              <TeamCapacityByRoleCard
                year={year}
                month={month}
                teamId={teamId}
              />
            </Grid>

            <Grid item xs={6}>
              <TeamTopSkillsCard year={year} month={month} teamId={teamId} />
            </Grid>

            <Grid item xs={6}>
              <TeamMissingSkillsCard
                year={year}
                month={month}
                teamId={teamId}
              />
            </Grid>

            <Grid item xs={12}>
              <TeamUtilizationTable
                year={year}
                month={month}
                teamId={teamId}
                linkPrefix={`/customers`}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <List>
                <ListItem
                  button
                  onClick={() => history.push(`${teamId}/retrospective`)}
                >
                  <ListItemIcon>
                    <ArrowForwardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Retrospective dashboard" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => history.push(`${teamId}/planning`)}
                >
                  <ListItemIcon>
                    <ArrowForwardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Planning dashboard" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => history.push(`${teamId}/skills`)}
                >
                  <ListItemIcon>
                    <ArrowForwardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Skills dashboard" />
                </ListItem>
              </List>
            </Grid>
            <Grid item>
              <SystemNotificationList
                notifications={notifications}
                warningIcon={true}
              />
              <CustomersNotificationsCard
                year={year}
                month={month}
                customerIds={customerIds}
              />
            </Grid>
            <Grid item>
              <Paper>
                <CommentsPanelEditor year={year} month={month} id={teamId} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
