import {
  Box,
  Chip,
  createStyles,
  makeStyles,
  Theme,
  Tooltip
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { Link } from "react-router-dom";
import { DashboardCard } from "../../components/DashboardCard";
import useCustomer from "../../customers/useCustomer";
import useEM from "../../ems/useEM";
import { useTeamNotifications } from "../../notifications/team/useTeamNotifications";
import useTeam from "../../teams/useTeam";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      marginBottom: theme.spacing(2),
    },
    link: {
      textDecoration: "inherit",
      color: "inherit",
    },
  })
);

export function CustomerOverviewCard({
  year,
  month,
  customerId,
}: {
  year: number;
  month: number;
  customerId: string;
}) {
  const classes = useStyles();
  const customer = useCustomer(customerId);
  const em = useEM(customer?.engagementManager || "");
  const team = useTeam(customer?.getTeam(year, month) || "");
  const teamNotifications = useTeamNotifications(year, month, team);

  return (
    <DashboardCard>
      {{
        body: (
          <Box>
            <Box className={classes.item}>
              Engagement Manager:{" "}
              <Link
                to={`/dashboards/ems/${em?.id || ""}`}
                className={classes.link}
              >
                {em?.firstName} {em?.lastName}
              </Link>
            </Box>
            <Box className={classes.item}>
              Manager:{" "}
              <Link
                to={`/dashboards/managers/${team?.manager || ""}`}
                className={classes.link}
              >
                {team?.manager}
              </Link>
            </Box>
            <Box className={classes.item}>
              Team:{" "}
              <Link
                to={`/dashboards/teams/${team?.id || ""}`}
                className={classes.link}
              >
                {team?.name}
                {(teamNotifications?.length || 0) > 0 ? (
                  <Tooltip
                    title={teamNotifications
                      .map((n) => n.description)
                      .join(" - ")}
                  >
                    <span>
                      {" "}
                      <WarningIcon
                        color="error"
                        style={{ verticalAlign: "bottom" }}
                      />
                    </span>
                  </Tooltip>
                ) : null}
              </Link>
            </Box>
            <Box className={classes.item}>
              Skills:
              {customer?.skills.map((skill, index) => (
                <Chip
                  key={`${index}`}
                  label={skill}
                  style={{ marginLeft: 8 }}
                />
              ))}
            </Box>
          </Box>
        ),
      }}
    </DashboardCard>
  );
}
