import { useTheme } from "@material-ui/core";
import { Chart } from "chart.js";
import { createRef, useEffect, useState } from "react";
import { DashboardCard } from "../../components/DashboardCard";
import { useCustomerSkillsInTeam } from "../../customers/useCustomerSkillsInTeam";
import { useEngineerSkillsInTeam } from "../../engineers/useEngineerSkillsInTeam";
import { getColors } from "../../utils/colorGenerator";

export function TeamMissingSkillsCard({
  year,
  month,
  teamId,
}: {
  year: number;
  month: number;
  teamId: string;
}) {
  const chartRef = createRef<HTMLCanvasElement>();
  const [chart, setChart] = useState(null as null | Chart);
  const theme = useTheme();
  const customerSkills = useCustomerSkillsInTeam(year, month, teamId);
  const engineerSkills = useEngineerSkillsInTeam(year, month, teamId);

  useEffect(() => {
    if (chart) {
      return;
    }
    const ref = chartRef?.current as HTMLCanvasElement;
    const newChart = new Chart(ref, {
      type: "bar",
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            display: true,
            title: { display: true, text: "Customers" },
            min: 0,
          },
        },
      },
    });
    setChart(newChart);
  }, []);

  useEffect(() => {
    if (!chart) {
      return;
    }
    const customerSkillNames = customerSkills.getNames();
    const engineerSkillNames = new Set(engineerSkills.getNames());
    const missingSkills = customerSkillNames.filter(
      (n) => !engineerSkillNames.has(n)
    );
    chart.data.labels = missingSkills;
    const backgroundColours = getColors(theme, 1);
    chart.data.datasets = [
      {
        data: missingSkills.map((i) => customerSkills.getCount(i)),
        borderColor: backgroundColours[0],
        backgroundColor: backgroundColours[0],
      },
    ];
    chart.update();
  }, [year, month, theme, chart, customerSkills, engineerSkills]);

  return (
    <DashboardCard title="Missing skills" height={300}>
      {{ body: <canvas ref={chartRef} /> }}
    </DashboardCard>
  );
}
