import React, { useState } from 'react'
import { Box, Button, CircularProgress, Tooltip } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import { useJiraProjectBoard } from './useJiraProjectBoard'

import service from './JiraResourcesService'

export function JiraBoard({ projectKey }: { projectKey: string }) {
  const [reloadCounter, setReloadCounter] = useState(0)
  const board = useJiraProjectBoard(projectKey, reloadCounter)
  const [updateClicked, setUpdateClicked] = useState(false)
  const [updating, setUpdating] = useState(false)

  function onUpdate(): void {
    setUpdateClicked(true)
    setUpdating(true)
    service.putProjectBoard(projectKey).finally(() => {
      setUpdating(false)
      setReloadCounter(reloadCounter + 1)
    })
  }

  const status = (
    <span style={{ marginRight: 8, verticalAlign: 'middle' }}>
      {typeof board === 'undefined' ? (
        <CircularProgress size={16} style={{ marginLeft: 8 }} />
      ) : board === null ? (
        <Tooltip title='Missing board'>
          <ClearIcon style={{ color: 'red' }} />
        </Tooltip>
      ) : (
        <Tooltip title='Found'>
          <CheckIcon style={{ color: 'green' }} />
        </Tooltip>
      )}
    </span>
  )

  return (
    <Box>
      {status}
      Board:{' '}
      {board === null ? (
        <Button
          variant='text'
          color='primary'
          disabled={updateClicked}
          onClick={() => onUpdate()}
        >
          Update
          {updating ? (
            <CircularProgress size={16} style={{ marginLeft: 8 }} />
          ) : null}
        </Button>
      ) : (
        board?.name || ''
      )}
    </Box>
  )
}
