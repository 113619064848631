import { MonthDTO } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";

export function useMonthDTO(year: number, month: number): MonthDTO {
  const [monthDTO, setMonthDTO] = useState({ year, month } as MonthDTO);
  useEffect(() => {
    setMonthDTO({ year, month });
  }, [year, month]);
  return monthDTO;
}
