import {
  Checkbox,
  TableBody,
  TableCell as DefaultTableCell,
  TableRow,
  Tooltip,
  withStyles
} from "@material-ui/core";
import { ContractHourType } from "msd-capacity-planning-model";
import React, { useEffect, useState } from "react";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: 0,
  },
})(DefaultTableCell);

export function CustomerContractHourTypesForm({
  contractHourTypes,
  onChange,
}: {
  contractHourTypes: ContractHourType[];
  onChange?: (contractHours: ContractHourType[]) => void;
}) {
  const [types, seTypes] = useState(new Set(contractHourTypes));

  useEffect(() => {
    seTypes(new Set(contractHourTypes));
  }, [contractHourTypes]);

  function updateContractHours(
    newHour: ContractHourType,
    checked: boolean
  ): void {
    const newHours = new Set(Array.from(types));
    if (checked) {
      newHours.add(newHour);
    } else {
      newHours.delete(newHour);
    }
    seTypes(newHours);
    if (onChange) {
      onChange(Array.from(newHours));
    }
  }

  return (
    <TableBody>
      <TableRow style={{ backgroundColor: "#f5f5f5" }}>
        <TableCell colSpan={2} style={{ paddingLeft: 16 }}>
          Contract hours
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          <Tooltip title="Contract hours">
            <Checkbox
              color="primary"
              checked={types.has(ContractHourType.TEAM_HOURS)}
              onChange={(e) => {
                updateContractHours(
                  ContractHourType.TEAM_HOURS,
                  e?.target?.checked === true
                );
              }}
            />
          </Tooltip>
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          <Tooltip title="Contract hours">
            <Checkbox
              color="primary"
              checked={types.has(ContractHourType.OPS_HOURS)}
              onChange={(e) => {
                updateContractHours(
                  ContractHourType.OPS_HOURS,
                  e?.target?.checked === true
                );
              }}
            />
          </Tooltip>
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          <Tooltip title="Contract hours">
            <Checkbox
              color="primary"
              checked={types.has(ContractHourType.BANK_HOURS)}
              onChange={(e) => {
                updateContractHours(
                  ContractHourType.BANK_HOURS,
                  e?.target?.checked === true
                );
              }}
            />
          </Tooltip>
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          <Tooltip title="Contract hours">
            <Checkbox
              color="primary"
              checked={types.has(ContractHourType.EM_HOURS)}
              onChange={(e) => {
                updateContractHours(
                  ContractHourType.EM_HOURS,
                  e?.target?.checked === true
                );
              }}
            />
          </Tooltip>
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableBody>
  );
}
