import { useHistory } from "react-router-dom";
import { MonthNavigator } from "utils/MonthNavigator";
import { useDashboardPeriod } from "./useDashboardPeriod";

export function DashboardPeriodNavigator() {
  const history = useHistory();
  const { year, month } = useDashboardPeriod();

  return (
    <MonthNavigator
      year={year}
      month={month}
      onChange={(newYear, newMonth) => {
        history.push({ search: `year=${newYear}&month=${newMonth + 1}` });
      }}
    />
  );
}
