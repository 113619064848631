export function getLastFridayCompletedMonthRatio(
  year: number,
  month: number,
  current = new Date()
): number {
  const saturday = new Date(
    current.getFullYear(),
    current.getMonth(),
    current.getDate() + (6 - current.getDay() - 1) - 6
  );
  const from = new Date(year, month);
  const to = new Date(year, month + 1, 1);
  const max = to.getTime() - from.getTime();
  let elapsed = saturday.getTime() - from.getTime();
  elapsed = Math.max(elapsed, 0);
  elapsed = Math.min(elapsed, max);
  return elapsed / max;
}

export default function getCompletedMonthRatio(
  year: number,
  month: number,
  current = new Date()
): number {
  const from = new Date(year, month);
  const to = new Date(year, month + 1, 1);
  const max = to.getTime() - from.getTime();
  let elapsed = current.getTime() - from.getTime();
  elapsed = Math.max(elapsed, 0);
  elapsed = Math.min(elapsed, max);
  return elapsed / max;
}
