import { TeamCapacityAggregator } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useTeams } from "teams/useTeams";

export function useTeamCapacityAggregator(
  year: number,
  month: number
): TeamCapacityAggregator {
  const teams = useTeams(year, month);
  const [aggregator, setAggregator] = useState(
    new TeamCapacityAggregator(year, month, {})
  );
  useEffect(() => {
    const aggregator = new TeamCapacityAggregator(year, month, teams);
    setAggregator(aggregator);
  }, [year, month, teams]);
  return aggregator;
}
