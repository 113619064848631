import { EngineerHandle } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useAllEngineers } from "./useAllEngineers";

export function useEngineers(
  year: number,
  month: number
): { [engineerId: string]: EngineerHandle } {
  const engineers = useAllEngineers();
  const [filteredEngineers, setFilteredEngineers] = useState(
    {} as { [engineerId: string]: EngineerHandle }
  );
  useEffect(() => {
    const newFilteredEngineers = Object.values(engineers)
      .filter((e) => e.getCapacities(year, month).length > 0)
      .reduce((all, e) => {
        all[e.id] = e;
        return all;
      }, {} as { [engineerId: string]: EngineerHandle });
    setFilteredEngineers(newFilteredEngineers);
  }, [year, month, engineers]);
  return filteredEngineers;
}
