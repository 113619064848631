import { Box, Grid } from "@material-ui/core";
import useCustomersInAggregator from "customers/useCustomersInAggregator";
import { CustomersTable } from "dashboards/customers/CustomersTable";
import { DashboardBreadcrumbs } from "dashboards/DashboardBreadcrumbs";
import { useDashboardPeriod } from "dashboards/useDashboardPeriod";
import { CustomerAggregatorType } from "msd-capacity-planning-model";
import { useParams } from "react-router-dom";

export function RegionCustomersPage() {
  const { year, month } = useDashboardPeriod();
  const params: { [key: string]: string } = useParams();
  const customers = useCustomersInAggregator(
    year,
    month,
    CustomerAggregatorType.REGION,
    params.region || ""
  );

  return (
    <Box>
      <DashboardBreadcrumbs />
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12}>
          <CustomersTable
            year={year}
            month={month}
            customers={customers}
            consumedBudgetPercentColumn={false}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
