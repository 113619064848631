import { Badge, Box,debounce,Drawer, IconButton, Tooltip } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { Team, TeamHandle } from "msd-capacity-planning-model";
import EditIcon from "@material-ui/icons/Edit";
import CommentIcon from "@material-ui/icons/Comment";
import QuestionIcon from "@material-ui/icons/Help"
import { NoStyleLink } from "components/NoStyleLink";
import { useEffect, useState,useMemo,Fragment} from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { CommentsPanelEditor } from "../../comments/CommentsPanelEditor";
import {
  FetchCommentsAction,
  FETCH_COMMENTS
} from "../../comments/CommentTypes";
import useComments from "../../comments/useComments";
import useSortedTeams from "../../teams/useSortedTeams";
import {
  TeamUtilizations,
  useTeamUtilizationByTeam
} from "../../utilization/useTeamUtilizationByTeam";
import { red } from "@material-ui/core/colors";
import { integer } from "aws-sdk/clients/cloudfront";
import { inherits } from "util";
import internal from "stream";


  function TeamsTableImpl({
    year,
    month,
    utilizations,
  }: {
    year: number;
    month: number;
    utilizations: TeamUtilizations;
  }) {
    
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const now = new Date();
    const sortedTeams =  useSortedTeams(now.getFullYear(), now.getMonth());
    const filteredTeams = sortedTeams.filter(
      (team) => team.isOffboarded(now) === false
    );
    const teams = useMemo(() => filteredTeams.filter(obj => {return obj.id in utilizations}), [utilizations]);
    const [commentId, setCommentId] = useState(undefined as string | undefined);
    const comments = useComments(year, month);


    const utilizationFilterNames = useMemo(() => Array.from(new Set(Object.values(teams).map(team => utilizations[team.id]?.getSummary().utilization ? utilizations[team.id]?.getSummary().utilization : "NaN"))), [utilizations, teams])
    const deltaFilterNames = useMemo(() => Array.from(new Set(Object.values(teams).map(team => utilizations[team.id]?.getSummary().delta ? utilizations[team.id]?.getSummary().delta : 0))), [utilizations, teams])
    const teamNames = useMemo(() => Object.values(teams).map(team => team.name), [teams]);
    
    // tried to add a filter to this data to only show teams that belong to EM but does not seem to be working... need to troubleshoot futher
    //    const data = useMemo(() => Object.values(teams).sort((e1, e2) => e1.compare(e2)).filter((team) => !!utilizations[team.id]).map(e => [e, e, e, e, e, e, e, e, e]), [teams]);

   // const data = useMemo(() => Object.values(teams).sort((e1, e2) => e1.compare(e2)).map(e => [e, e, e, e, e, e, e, e, e]), [teams]);

    const columns: any = [
      {
        name: "Team",
        options: {
        filter: true,
        setCellProps: () => ({ style: { padding: 5 }}),
        setCellHeaderProps: () => ({ style: { padding: 5 }}),
        customBodyRender: (teams: TeamHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
          return <NoStyleLink
                pathname={`${location.pathname}/${teams.id}${location.search}`}
                text={teams.name}   
                />
        },      
        sortCompare: (order: string) => {
          return (obj1: { data: TeamHandle }, obj2: { data: TeamHandle } ) => {
            return obj1.data.compare(obj2.data) * (order === 'asc' ? 1 : -1);
          };
        },
        filterOptions: {
            names: teamNames,
            logic(name: { name: string; }, filters: string | string[]) {
              let textString = name.name
              return !filters.includes(textString);}
      }
      }
      },
      {
        name: "Utilization",
        label: "",
        options: {
          filter: true,
          filterOptions:{
            names: utilizationFilterNames,
            logic(customer: { id: string | number; }, filters: any) {
              let textString = utilizations[customer.id]?.getSummary().utilization || "NaN";
              return !filters.includes(textString);} 
          },
          setCellProps: () => ({ style: { padding: 5 }}),
          setCellHeaderProps: () => ({ style: { padding: 5 }}),
          customBodyRender: (teams: TeamHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
            return utilizations[teams.id]?.getSummary().utilization ? utilizations[teams.id]?.getSummary().utilization : "NaN";
          }, 
          sortCompare: (order: string) => {
            return (obj1: any, obj2: any ) => {
              let val1 = utilizations[obj1.data.id]?.getSummary().utilization || 0
              let val2 = utilizations[obj2.data.id]?.getSummary().utilization || 0
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
        }
      }
      },
      {
        name: "Delta",
          options: {
          filter: true,
          filterOptions:{
            names: deltaFilterNames,
            logic(customer: { id: string | number; }, filters: any) {
              let textString = utilizations[customer.id]?.getSummary().delta || 0;
              return !filters.includes(textString);} 
          },
          setCellProps: (cellValue:string, dataIndex:integer, rowIndex:integer) => {
            return {
              style: Number(cellValue) < 0  ? {color: "red", padding: 5} : {color: "black", padding: 5}
            };},
          setCellHeaderProps: () => ({ style: { padding: 5 }}),
          customBodyRender: (teams: TeamHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
            return utilizations[teams.id]?.getSummary().delta;
          }, 
          sortCompare: (order: string) => {
            return (obj1: any, obj2: any ) => {
              let val1 = utilizations[obj1.data.id]?.getSummary().delta || 0
              let val2 = utilizations[obj2.data.id]?.getSummary().delta || 0
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
        }
      }
      },
      {
        name: "Customers",
        options: {
        filter: true,
        setCellProps: () => ({ style: { padding: 5 }}),
        setCellHeaderProps: () => ({ style: { padding: 5 }}),
        customBodyRender: (teams: TeamHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
          return utilizations[teams.id]?.getSummary().customers.toLocaleString();
        }, 
        sortCompare: (order: string) => {
          return (obj1: any, obj2: any ) => {
            let val1 = utilizations[obj1.data.id]?.getSummary().customers || 0
            let val2 = utilizations[obj2.data.id]?.getSummary().customers || 0
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
      }
        }
      },
      {
        name: "Budget",
        options: {
          filter: true,
          setCellProps: () => ({ style: { padding: 5 }}),
          setCellHeaderProps: () => ({ style: { padding: 5 }}),
          customBodyRender: (teams: TeamHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
            return Math.round(utilizations[teams.id]?.getSummary().budget)?.toLocaleString();

          }, 
          sortCompare: (order: string) => {
            return (obj1: any, obj2: any ) => {
              let val1 = utilizations[obj1.data.id]?.getSummary().budget || 0
              let val2 = utilizations[obj2.data.id]?.getSummary().budget || 0
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
        }
        }
      },
      {
        name: "Engineers",
        options: {
          filter: true,
          setCellProps: () => ({ style: { padding: 5 }}),
          setCellHeaderProps: () => ({ style: { padding: 5 }}),
          customBodyRender: (teams: TeamHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
            return utilizations[teams.id]?.getSummary().engineers?.toLocaleString();

          }, 
          sortCompare: (order: string) => {
            return (obj1: any, obj2: any ) => {
              let val1 = utilizations[obj1.data.id]?.getSummary().engineers || 0
              let val2 = utilizations[obj2.data.id]?.getSummary().engineers || 0
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
        }
        }
      },
      {
        name: "Capacity",
        options: {
          filter: true,
          setCellProps: () => ({ style: { padding: 5 }}),
          setCellHeaderProps: () => ({ style: { padding: 5 }}),
          customBodyRender: (teams: TeamHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
            return Math.round(utilizations[teams.id]?.getSummary().capacity).toLocaleString();
          }, 
          sortCompare: (order: string) => {
            return (obj1: { data: { id: string | number; }; }, obj2: { data: { id: string | number; }; } ) => {
              let val1 = utilizations[obj1.data.id]?.getSummary().capacity || 0
              let val2 = utilizations[obj2.data.id]?.getSummary().capacity || 0
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
        }
        }
      },
      {
        name: "",              // dummy column to house icon
        options: {
          filter: false,
          sort: false,
          download: false,      
          viewColumns: false,
          setCellProps: () => ({ style: { padding: 5 }}),
          setCellHeaderProps: () => ({ style: { padding: 5 }}),
          customBodyRender: (teams: TeamHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
            return <IconButton onClick={() =>
              history.push(`/administration/teams/${teams.id}`)}>
              <EditIcon />
            </IconButton>;
          }, 
          }
        
      },
      {
        name: "",             // dummy column to house icon
        options: {
          filter: false,
          sort: false,
          download: false,
          viewColumns: false,
          setCellProps: () => ({ style: { padding: 5 }}),
          setCellHeaderProps: () => ({ style: { padding: 5 }}),
          customBodyRender: (teams: TeamHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
            return <IconButton onClick={() => setCommentId(teams.id)}>
                     <Badge
                         color="primary"
                         badgeContent={(comments[teams.id] || []).length}
                     >
                       <CommentIcon />
                     </Badge>
                   </IconButton>;
          }, 
        }
      },
  ];


  useEffect(() => {
    const action: FetchCommentsAction = { type: FETCH_COMMENTS, year, month };
    dispatch(action);
  }, []);

  
  const data = useMemo(() => Object.values(teams).sort((c1, c2) => c1.compare(c2)).map(c => Array(columns.length).fill(c)), [teams]);


    // documentation for the mui-datatables version we are using.
    // https://github.com/gregnb/mui-datatables/tree/d55775971265eaf8b7a9bfdbc8dce6dc93d28d2e              
    return (
      <Box>
        <MUIDataTable
          title={'Teams'}
          columns={columns}
          // data is breaking and not sure why...
          data={data}
          options={
                    {
                      filterType: 'multiselect',
                      "draggableColumns": {"enabled": true},
                      "pagination":false,
                      "selectableRows":"none",
                      onDownload: (buildHead, buildBody, columns, data) => {
                        return "\uFEFF" + buildHead(columns) + buildBody(
                         data.map((it: { index: integer; data: string | any[]; }) => 
                         (
                          { index: it.index, 
                           data: [
                             it.data[0].name,
                             utilizations[it.data[0].id]?.getSummary().utilization,
                             utilizations[it.data[0].id]?.getSummary().delta,
                             utilizations[it.data[0].id]?.getSummary().customers,
                             utilizations[it.data[0].id]?.getSummary().budget,
                             utilizations[it.data[0].id]?.getSummary().engineers,
                             utilizations[it.data[0].id]?.getSummary().capacity
                           ] 
                         })
                         )
                         );
                        },
                      textLabels: {
                        "body": {
                          columnHeaderTooltip: column => {
                            switch(column.label){
                              case "Utilization":
                                return "Calculation: Region working hours * Team utilization ratio - PTO - Holidays";
                            default:
                                return "";
                            }
                          }
                        }
                      }
                    }
                  }
        />  
        
        <Drawer
          anchor="right"
          open={!!commentId}
          onClose={() => setCommentId(undefined)}
          style={{ width: 400 }}
        >
          <CommentsPanelEditor
            year={year}
            month={month}
            id={commentId || ""}
          ></CommentsPanelEditor>
        </Drawer>
      </Box>
    );
  }

export function TeamsTable({
  year,
  month,
  teams,
}: {
  year: number;
  month: number;
  teams: { [teamId: string]: Team };
}) {
  const utilizations = useTeamUtilizationByTeam(year, month, teams);
  return (
    <TeamsTableImpl year={year} month={month} utilizations={utilizations} />
  );
}