import { Grid, Paper } from "@material-ui/core";
import useTeamCapacityInAggregator from "capacity/useTeamCapacityInAggregator";
import { TeamCapacityAggregatorType } from "msd-capacity-planning-model";
import { CommentsPanelEditor } from "../../comments/CommentsPanelEditor";
import { CountCard } from "../../components/CountCard";
import { TeamCapacityTable } from "./TeamCapacityTable";

export function TeamEngineersDashboard({
  year,
  month,
  teamId,
}: {
  year: number;
  month: number;
  teamId: string;
}) {
  const capacity = useTeamCapacityInAggregator(
    year,
    month,
    TeamCapacityAggregatorType.TEAM,
    teamId
  );
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      spacing={5}
      style={{ marginTop: 16 }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs={9}>
          <TeamCapacityTable year={year} month={month} teamId={teamId} />
        </Grid>
        <Grid item xs={3}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            spacing={1}
          >
            <Grid item>
              <CountCard
                title="Engineers"
                count={Math.round(capacity.allocatedEngineers)}
              />
            </Grid>

            <Grid item>
              <CountCard
                title="Capacity"
                count={Math.round(capacity.allocatedHours)}
              />
            </Grid>

            <Grid item>
              <Paper style={{ marginBottom: 32 }}>
                <CommentsPanelEditor
                  year={year}
                  month={month}
                  id={`engineers@${teamId}`}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
