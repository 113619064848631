import useBudget from "customers/useBudget";
import { useEffect, useState } from "react";
import { DAY_DATE_FORMAT } from "utils/dateFormats";
import useAllCustomers from "../../customers/useAllCustomers";
import {
  SystemNotification,
  SystemNotificationSource
} from "../SystemNotificationTypes";

export function useCustomersOffboardedNotifications(
  year: number,
  month: number
): SystemNotification[] {
  const customers = useAllCustomers();
  const current = useBudget(year, month);
  const next = useBudget(year, month + 1);

  const [notifications, setNotifications] = useState(
    [] as SystemNotification[]
  );

  useEffect(() => {
    const newNotifications = Object.keys(current.items)
      .filter((customerId) => !next.items[customerId])
      .map((customerId) => {
        const title = customers[customerId]?.name || "";
        return {
          source: SystemNotificationSource.CUSTOMER,
          id: customerId,
          title,
          link: `/administration/customers/${customerId}`,
          description:
            "on " +
            customers[customerId]
              ?.getOffboardingDate()
              ?.toLocaleString("en", DAY_DATE_FORMAT),
        };
      });
    setNotifications(newNotifications);
  }, [customers, next, current]);

  return notifications;
}
