import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Typography,
  useTheme
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/AppTypes";
import { AddCommentInput } from "./AddCommentInput";
import { RemoveCommentAction, REMOVE_COMMENT } from "./CommentTypes";
import useComments from "./useComments";

const useStyles = makeStyles(() => ({
  panelParent: {
    "&:hover": {
      "& $addButton": {
        visibility: "visible",
      },
    },
  },
  commentParent: {
    "&:hover": {
      "& $hiddenChild": {
        visibility: "visible",
      },
    },
  },
  hiddenChild: {
    visibility: "hidden",
  },
  addButton: {
    visibility: "hidden",
  },
}));

export function CommentsPanel({
  year,
  month,
  id,
  hideAuthor = false,
}: {
  year: number;
  month: number;
  id: string;
  hideAuthor?: boolean;
}) {
  const theme = useTheme();
  const styles = useStyles();
  const dispatch = useDispatch();
  const author = useSelector((state: AppState) => state.authorization.email);
  const comments = useComments(year, month);
  const [addable, setAddable] = useState(false);

  const synching = useSelector(
    (state: AppState) => state.comments.synching[`${year}`]?.[`${month}`]
  );

  function deleteComment(index: number): void {
    const action: RemoveCommentAction = {
      type: REMOVE_COMMENT,
      year,
      month,
      id,
      index,
    };
    dispatch(action);
  }

  return (
    <List className={styles.panelParent}>
      {(comments[id] || []).map((comment, index) => {
        return (
          <ListItem>
            <Box
              style={{ display: "flex", justifyContent: "space-between" }}
              className={styles.commentParent}
              key={`comment-${index}`}
            >
              <Box style={{ minWidth: 100 }}>
                <Typography variant="body2">{comment.message}</Typography>
                <Typography
                  variant="caption"
                  style={{
                    color: "gray",
                    marginLeft: theme.spacing(),
                    display: "block",
                  }}
                >
                  <span>
                    {comment.author}{" "}
                    {comment?.timestamp
                      ? new Date(comment?.timestamp).toLocaleString()
                      : null}
                  </span>
                </Typography>
              </Box>
              <Box style={{ minWidth: 32 }} className={styles.hiddenChild}>
                {comment.author === author ? (
                  <IconButton
                    size="small"
                    onClick={() => deleteComment(index)}
                    disabled={synching === true}
                    style={{ marginLeft: 8 }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                ) : null}
              </Box>
            </Box>
          </ListItem>
        );
      })}
      {addable ? (
        <AddCommentInput
          year={year}
          month={month}
          id={`${id}`}
          onSubmit={() => setAddable(false)}
        />
      ) : null}
      {!addable ? (
        <Box className={styles.addButton}>
          <Button
            onClick={() => setAddable(true)}
            size="small"
            startIcon={<AddIcon />}
          >
            Add comment
          </Button>
        </Box>
      ) : null}
    </List>
  );
}
