import { useSelector } from "react-redux";
import { AppState } from "redux/AppTypes";
import { createSelector } from "reselect";
import { useAllTeamsSelector } from "./useAllTeams";

export const useProductTypesSelector = createSelector(
  useAllTeamsSelector,
  (teams) => {
    const productTypes = new Set<string>();
    Object.values(teams).forEach((team) =>
      (team.productTypes || []).forEach((productType) =>
        productTypes.add(productType)
      )
    );
    return Array.from(productTypes).sort();
  }
);

export default function useProductTypes(): string[] {
  const state = useSelector((state: AppState) => state);
  return useProductTypesSelector(state);
}
