import {
  CustomerAggregatorType,
  CustomerHandle
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useCustomerAggregator } from "./useCustomerAggregator";
import useCustomersByAggregator from "./useCustomersByAggregator";

export interface CustomersInAggregator {
  [customerId: string]: CustomerHandle;
}

export default function useCustomersInAggregator(
  year: number,
  month: number,
  type: CustomerAggregatorType,
  ids: string | string[]
): CustomersInAggregator {
  const customers = useCustomersByAggregator(year, month, type);
  const [response, setResponse] = useState({} as CustomersInAggregator);
  const aggregator = useCustomerAggregator(year, month);

  useEffect(() => {
    const newResponse = {} as CustomersInAggregator;
    const idsArray = Array.isArray(ids) ? ids : [ids];
    idsArray.forEach((id) => {
      (customers[id] || []).forEach((customer) => {
        newResponse[customer.id] = customer;
      });
    });
    setResponse(newResponse);
  }, [year, month, type, ids, aggregator, customers]);

  return response;
}
