import { Capacity } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useCapacities } from "./useCapacities";

export function useEngineerCapacities(
  months: { year: number; month: number; }[],
  engineerId: string
): (Capacity | undefined)[] {
  const monthlyCapacities = useCapacities(months);
  const [engineerCapacities, setEngineerCapacities] = useState(
    [] as (Capacity | undefined)[]
  );
  useEffect(() => {
    const newEngineerCapacities = monthlyCapacities.map((capacities) => {
      return capacities
        .getItems()
        .find((capacity) => capacity.engineer.id === engineerId);
    });
    setEngineerCapacities(newEngineerCapacities);
  }, [months, engineerId, monthlyCapacities]);
  return engineerCapacities;
}
