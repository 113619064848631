import { Chart } from "chart.js";
import { DashboardPeriod } from "dashboards/DashboardPeriod";
import { useTrendPeriods } from "dashboards/useTrendPeriods";
import React, { createRef, useEffect, useState } from "react";
import { useEngineerCapacities } from "../../capacity/useEngineerCapacities";
import { DashboardCard } from "../../components/DashboardCard";

export function EngineerCapacityTrendCard({
  period,
  engineerId,
}: {
  period: DashboardPeriod;
  engineerId: string;
}) {
  const periods = useTrendPeriods(period);
  const capacities = useEngineerCapacities(periods, engineerId);
  const chartRef = createRef<HTMLCanvasElement>();
  const [chart, setChart] = useState(null as null | Chart);

  useEffect(() => {
    if (chart) {
      return;
    }
    const ref = chartRef?.current as HTMLCanvasElement;
    const newChart = new Chart(ref, {
      type: "line",
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            ticks: {
              precision: 0,
            },
            beginAtZero: true,
          },
        },
      },
    });
    setChart(newChart);
  }, []);

  useEffect(() => {
    if (!chart) {
      return;
    }
    const dates = periods.map((period) => new Date(period.year, period.month));
    chart.data.labels = dates.map((date) =>
      date.toLocaleString("default", { month: "long" })
    );
    chart.data.datasets = [
      {
        data: capacities.map(
          (capacity) => capacity?.getUtilizationHours() || 0
        ),
        borderColor: "rgb(0, 96, 100, 0.5)",
        backgroundColor: "rgb(0, 96, 100, 0.5)",
        borderWidth: 2,
      },
    ];
    chart.update();
  }, [periods, chart, capacities]);

  return (
    <DashboardCard title="Capacity trend (hours)" height={200}>
      {{ body: <canvas ref={chartRef} /> }}
    </DashboardCard>
  );
}
