import { useSelector } from "react-redux";
import { AppState } from "redux/AppTypes";
import { createSelector } from "reselect";
import {
  SystemNotification,
  SystemNotificationSource,
  SystemNotificationType
} from "../SystemNotificationTypes";

const stateSelector = (state: AppState) => state.synchStatus.statuses;

export const useSynchNotificationsSelector = createSelector(
  stateSelector,
  (statuses) => {
    return statuses
      .filter((status) => status.success === false)
      .map((status) => ({
        source: SystemNotificationSource.SYNCH,
        type: SystemNotificationType.ERROR,
        id: status.id,
        title: status.id,
        description: `synch error`,
        link: "/administration/synchstatus",
      }));
  }
);

export default function useSynchNotifications(): SystemNotification[] {
  const state = useSelector((state: AppState) => state);
  return useSynchNotificationsSelector(state);
}
