import { CustomerAggregatorType } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { AggregatedBurnedBudget } from "./BurnedBudgetTypes";
import { useBurnedBudgetByAggregator } from "./useBurnedBudgetByAggregator";

export function useBurnedBudgetInAggregator(
  year: number,
  month: number,
  group: CustomerAggregatorType,
  ids: string | string[]
): AggregatedBurnedBudget {
  const budgets = useBurnedBudgetByAggregator(year, month, group);
  const [response, setResponse] = useState(
    new AggregatedBurnedBudget(year, month)
  );

  useEffect(() => {
    const idArray = Array.isArray(ids) ? ids : [ids];
    const newResponse = idArray.reduce((all, id) => {
      const aggregatedBudget =
        budgets[id] || new AggregatedBurnedBudget(year, month);
      Object.values(aggregatedBudget.items).forEach((budget) =>
        all.add(budget)
      );
      return all;
    }, new AggregatedBurnedBudget(year, month));
    setResponse(newResponse);
  }, [year, month, group, ids, budgets]);

  return response;
}
