import { CustomerAggregatorType } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { LabeledScatterDataPoint } from "../../components/LabeledScatterDataPoint";
import { ScatterChartCard } from "../../components/ScatterChartCard";
import { useBudgetByAggregator } from "../../customers/useBudgetByAggregator";
import { useEMs }  from "../../ems/useEMs";

export function EMCapacityScatterChartCard({
  year,
  month,
}: {
  year: number;
  month: number;
}) {
  const ems = useEMs(year, month);

  const budgets = useBudgetByAggregator(year, month, CustomerAggregatorType.EM);
  const [dataPoints, setDataPoints] = useState([] as LabeledScatterDataPoint[]);

  useEffect(() => {
    const newDataPoints = Object.keys(budgets)
      .filter((emId) => !!ems[emId])
      .map((emId) => {
        const em = ems[emId];
        const capacity = em.getCapacityHandle().getCapacity(year, month);
        const capableHours = Math.round(capacity.countWorkingHours());
        const emHours = budgets[emId].emHours || 0;
        const dataPoint: LabeledScatterDataPoint = {
          label: `${em.firstName} ${em.lastName}`,
          link: `/dashboards/ems/${em.id}`,
          x: emHours,
          y: Math.round((emHours / capableHours) * 100),
        };
        return dataPoint;
      });
    setDataPoints(newDataPoints);
  }, [year, month, ems, budgets]);

  return (
    <ScatterChartCard
      title="EM allocated capacity"
      xScaleTitle="Budget (EM Hours)"
      yScaleTitle="Allocated Capacity (%)"
      xAnnotation={100}
      dataPoints={dataPoints}
      chartHeight={300}
    ></ScatterChartCard>
  );
}
