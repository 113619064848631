import { Region } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import useRegions from "../regions/useRegions";
import { useEngineer } from "./useEngineer";

export function useEngineerRegion(id: string): Region | undefined {
  const regions = useRegions();
  const engineer = useEngineer(id);
  const [region, setRegion] = useState(undefined as Region | undefined);
  useEffect(() => {
    const regionId = engineer?.region;
    setRegion(regions[regionId || ""]);
  }, [regions, engineer]);
  return region;
}
