import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from "@material-ui/core";
import { useAllEMs } from "ems/useAllEMs";
import { useEMs } from "ems/useEMs";
import { EMHandle } from "msd-capacity-planning-model";
import React from "react";
import { UseFormMethods } from "react-hook-form";

export function EMSelect({
  name,
  defaultValue,
  form,
  label,
  emFilter,
  onChange,
}: {
  name: string;
  defaultValue?: string;
  form: UseFormMethods<Record<string, any>>;
  label?: string;
  emFilter?: (em: EMHandle) => boolean;
  onChange?: (value: string) => void;
}) {
  const now = new Date();
  const all = useAllEMs();
  const active = useEMs(now.getFullYear(), now.getMonth());

  return (
    <FormControl fullWidth>
      <InputLabel id={`${name}-label`}>{label || "EM"}</InputLabel>
      <Select
        labelId={`${name}-label`}
        name={name}
        defaultValue={defaultValue || ""}
        onChange={(e) => {
          const newEM = e.target.value as string;
          form.setValue(name, newEM);
          onChange(newEM);
        }}
        error={!!form.errors[name]}
        input={<Input />}
        renderValue={(value) => {
          const em = all[(value as string) || ""];
          return em?.getDisplayName() || "";
        }}
      >
        <MenuItem value="">
          <ListItemText primary="None" />
        </MenuItem>
        {Object.values(active)
          .filter((em) => (emFilter ? emFilter(em) : true))
          .map((em, index) => (
            <MenuItem key={`em-${index}`} value={em.id}>
              <ListItemText primary={`${em.getDisplayName()}`} />
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{form.errors[name]?.message}</FormHelperText>
    </FormControl>
  );
}
