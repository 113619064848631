class MemoizeUntil<T> {
  lastDate = 0;
  value: T | undefined;

  constructor(private fn: () => T, private maxAge: number) {}

  get(): (now?: Date) => T {
    const self = this;
    return (now = new Date()) => {
      if (
        typeof self.value === "undefined" ||
        self.lastDate + self.maxAge < now.getTime()
      ) {
        self.lastDate = now.getTime();
        self.value = this.fn();
      }
      return self.value;
    };
  }
}

export default function memoizeUntil<T>(
  fn: () => T,
  maxAge: number
): (date?: Date) => T {
  return new MemoizeUntil(fn, maxAge).get();
}
