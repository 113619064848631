import { HarvestProject, HarvestUser } from "msd-capacity-planning-model";

export const DO_FETCH_HARVEST_BUDGET = "harvest/do_fetch/budget";
export const DO_FETCH_HARVEST_USERS = "harvest/do_fetch/users";
export const FETCH_HARVEST_BUDGET = "harvest/fetch/budget";
export const FETCH_HARVEST_USERS = "harvest/fetch/users";
export const LOAD_HARVEST_BUDGET = "harvest/load/budget";
export const LOAD_HARVEST_PROJECTS = "harvest/load/projects";
export const LOAD_HARVEST_USERS = "harvest/load/users";

export interface HarvestUserHours {
  id: string;
  email: string;
  hours: number;
}

export interface HarvestTeamTimeReport {
  [email: string]: HarvestUserHours;
}
export class HarvestProjectBudget {
  harvestProjectId = "";
  consumed = 0;
  billable = 0; 
  total = 0; 
}

export class AggregatedHarvestBudget {
  items: { [project: string]: HarvestProjectBudget } = {};
  total = 0;
  consumed = 0;
  billable = 0;
  extra = 0;
  add(budget: HarvestProjectBudget) {
    this.items[budget.harvestProjectId] = budget;
    this.total += budget.total;
    this.consumed += budget.consumed;
    this.billable += budget.billable;
    this.extra += Math.max(0, budget.consumed - budget.total);
  }
  has(projectId: string) {
    return !!this.items[projectId];
  }
  get(projectId: string) {
    return this.items[projectId] || new HarvestProjectBudget();
  }
  filter(
    callback: (budget: HarvestProjectBudget) => boolean
  ): AggregatedHarvestBudget {
    const aggregated = new AggregatedHarvestBudget();
    Object.values(this.items)
      .filter(callback)
      .forEach((budget) => aggregated.add(budget));
    return aggregated;
  }
}

export class HarvestBudget {
  items: { [project: string]: HarvestProjectBudget } = {};
  get(projectId: string) {
    return this.items[projectId] || new HarvestProjectBudget();
  }
}

export interface FetchHarvestUsers {
  type: typeof FETCH_HARVEST_USERS;
}

export interface DoFetchHarvestUsers {
  type: typeof DO_FETCH_HARVEST_USERS;
}

export interface LoadHarvestUsers {
  type: typeof LOAD_HARVEST_USERS;
  items: HarvestUser[];
}

export interface FetchHarvestBudget {
  type: typeof FETCH_HARVEST_BUDGET;
  year: number;
  month: number;
}

export interface DoFetchHarvestBudget {
  type: typeof DO_FETCH_HARVEST_BUDGET;
  year: number;
  month: number;
}

export interface LoadHarvestBudget {
  type: typeof LOAD_HARVEST_BUDGET;
  year: number;
  month: number;
  budget: HarvestBudget;
}

export interface LoadHarvestProjectsAction {
  type: typeof LOAD_HARVEST_PROJECTS;
  projects: HarvestProject[];
}

export interface HarvestState {
  synching: { [key: string]: boolean };
  projects: HarvestProject[];
  users: {
    synching: boolean;
    items: HarvestUser[];
  };
  budgets: {
    [year: string]: {
      [month: string]: HarvestBudget;
    };
  };
}

export type HarvestAction =
  | DoFetchHarvestBudget
  | DoFetchHarvestUsers
  | FetchHarvestBudget
  | FetchHarvestUsers
  | LoadHarvestBudget
  | LoadHarvestProjectsAction
  | LoadHarvestUsers;
