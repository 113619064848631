import {
  AggregatedBudget,
  AggregatedTeamBudget,
  CustomerHandle
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import useAllCustomers from "./useAllCustomers";

export function useBudgets(
  months: { year: number; month: number }[]
): AggregatedBudget[] {
  const customers = useAllCustomers();
  const [budgets, setBudgets] = useState([] as AggregatedBudget[]);

  useEffect(() => {
    setTimeout(() => {
      const newBudgets = months.map((date) => {
        const monthlyBudget = Object.values(customers).reduce(
          (all, customer) => {
            const newDemand = customer.getBudget(date.year, date.month);
            if (
              newDemand.emHours > 0 ||
              newDemand.opsHours > 0 ||
              newDemand.teamHours > 0
            ) {
              all.emHours += newDemand.emHours;
              all.opsHours += newDemand.opsHours;
              all.teamHours += newDemand.teamHours;
              all.items[customer.id] = newDemand;
            }
            return all;
          },
          new AggregatedBudget()
        );
        monthlyBudget.emHours = Math.round(monthlyBudget.emHours);
        monthlyBudget.teamHours = Math.round(monthlyBudget.teamHours);
        monthlyBudget.opsHours = Math.round(monthlyBudget.opsHours);
        return monthlyBudget;
      });
      setBudgets(newBudgets);
    });
  }, [months, customers]);

  return budgets;
}

export function useBudgetsByTeam(
  months: { year: number; month: number }[]
): { [teamId: string]: AggregatedTeamBudget }[] {
  const customers = useAllCustomers();
  const budgets = useBudgets(months);
  const [budgetsByTeam, setBudgetsByTeam] = useState(
    [] as { [teamId: string]: AggregatedTeamBudget }[]
  );

  useEffect(() => {
    const newBudgetByTeam = budgets.map((budget, index) => {
      const { year, month } = months[index];
      return Object.values(customers).reduce((response, customer) => {
        const handle = new CustomerHandle(customer);
        const teamId = handle.getTeam(year, month);
        const budget = handle.getBudget(year, month);
        if (!response[teamId]) {
          response[teamId] = new AggregatedTeamBudget(teamId);
        }
        response[teamId].teamHours += budget.teamHours;
        response[teamId].opsHours += budget.opsHours;
        response[teamId].emHours += budget.emHours;
        response[teamId].items[customer.id] = budget;
        return response;
      }, {} as { [teamId: string]: AggregatedTeamBudget });
    });
    setBudgetsByTeam(newBudgetByTeam);
  }, [months, customers, budgets]);

  return budgetsByTeam;
}

export function useTeamBudgets(
  months: { year: number; month: number }[],
  teamId: string
): AggregatedTeamBudget[] {
  const budgetsByTeam = useBudgetsByTeam(months);
  const [teamBudgets, setTeamBudgets] = useState([] as AggregatedTeamBudget[]);

  useEffect(() => {
    const newTeamBudgets = budgetsByTeam.map(
      (budgetByTeam) => budgetByTeam[teamId] || new AggregatedTeamBudget(teamId)
    );
    setTeamBudgets(newTeamBudgets);
  }, [teamId, budgetsByTeam]);

  return teamBudgets;
}
