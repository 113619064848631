import {
  BURNED_BUDGET_OK_RANGE,
  BURNED_BUDGET_WARNING_RANGE, CustomerBurnedBudgetRatioStatus
} from "msd-capacity-planning-model";

export class BurnedBudget {
  constructor(
    public customerId: string,
    public year: number,
    public month: number
  ) {}
  teamId = ""; // the team at the time of the budget consumption
  emHours = 0;
  opsHours = 0;
  teamHours = 0;
  total = 0;
  harvestBudget = 0;
  harvestConsumedBudget = 0;
  getConsumedBudget() {
    return Math.round(Math.max(0, this.harvestConsumedBudget));
  }
  getConsumedBudgetPercent() {
    return Math.round((this.harvestConsumedBudget / this.total) * 100);
  }
  getConsumedInBudget() {
    return Math.min(this.total, this.harvestConsumedBudget);
  }
  getConsumedOverBudget() {
    return Math.round(Math.max(0, this.harvestConsumedBudget - this.total));
  }
  getConsumedOverBudgetPercent() {
    return Math.round((this.getConsumedOverBudget() / this.total) * 100);
  }
}

export class AggregatedBurnedBudgetRatio {
  constructor(
    public total: number,
    public burnedInBudget: number,
    public burnedOverBudget: number,
    private now = new Date()
  ) {}

  getExpectedHoursRatio(): number {
    const now = this.now;
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    return now.getDate() / lastDay.getDate();
  }

  getExpectedHoursPercent(): number {
    return Math.round(this.getExpectedHoursRatio() * 100);
  }

  getExpectedHours(): number {
    return Math.round(this.total * this.getExpectedHoursRatio());
  }

  getBurnedHoursTotal(): number {
    return Math.round(this.burnedInBudget + this.burnedOverBudget);
  }

  getBurnedHoursRatio(): number {
    return this.getBurnedHoursTotal() / this.total;
  }

  getBurnedHoursInBudget(): number {
    return Math.round(this.burnedInBudget);
  }

  getBurnedHoursInBudgetRatio(): number {
    return this.getBurnedHoursInBudget() / this.total;
  }

  getBurnedHoursInBudgetPercent(): number {
    return Math.round(this.getBurnedHoursInBudgetRatio() * 100);
  }

  getBurnedHoursOverBudget(): number {
    return Math.round(this.burnedOverBudget);
  }

  getBurnedHoursOverBudgetRatio(): number {
    return this.getBurnedHoursOverBudget() / this.total;
  }

  getBurnedHoursOverBudgetPercent(): number {
    return Math.round(this.getBurnedHoursOverBudgetRatio() * 100);
  }

  getStatus(): CustomerBurnedBudgetRatioStatus {
    const expectedRatio = this.getExpectedHoursRatio();
    const actualRatio = this.getBurnedHoursRatio();
    const okLowerLimit = Math.max(0, expectedRatio - BURNED_BUDGET_OK_RANGE);
    const okUpperLimit = Math.min(1, expectedRatio + BURNED_BUDGET_OK_RANGE);
    if (actualRatio >= okLowerLimit && actualRatio <= okUpperLimit) {
      return CustomerBurnedBudgetRatioStatus.OK;
    }
    const warningLowerLimit = Math.max(
      0,
      expectedRatio - BURNED_BUDGET_WARNING_RANGE
    );
    if (actualRatio >= warningLowerLimit && actualRatio <= 1) {
      return CustomerBurnedBudgetRatioStatus.WARNING;
    }
    return CustomerBurnedBudgetRatioStatus.ALARM;
  }

  isOK(): boolean {
    return this.getStatus() === CustomerBurnedBudgetRatioStatus.OK;
  }
}

export class AggregatedBurnedBudget {
  emHours = 0;
  opsHours = 0;
  teamHours = 0;
  total = 0;
  harvestTotal = 0;
  consumedInBudget = 0;
  consumedOverBudget = 0;
  items: { [customerId: string]: BurnedBudget } = {};

  constructor(public year: number, public month: number) {}

  get(customerId: string): BurnedBudget {
    return (
      this.items[customerId] ||
      new BurnedBudget(customerId, this.year, this.month)
    );
  }
  add(budget: BurnedBudget): void {
    this.emHours += budget.emHours;
    this.opsHours += budget.opsHours;
    this.teamHours += budget.teamHours;
    this.total += budget.opsHours + budget.teamHours;
    this.harvestTotal += budget.harvestBudget;
    this.consumedInBudget += budget.getConsumedInBudget();
    this.consumedOverBudget += budget.getConsumedOverBudget();
    this.items[budget.customerId] = budget;
  }

  filter(callback: (customerId: string) => boolean): AggregatedBurnedBudget {
    const filtered = new AggregatedBurnedBudget(this.year, this.month);
    Object.keys(this.items)
      .filter((customerId) => callback(customerId))
      .forEach((customerId) => {
        filtered.add(this.items[customerId]);
      });
    return filtered;
  }

  // if the current date is after the budget date then
  // the date to calculate the ratio is the last day of the month
  getRatio(now = new Date()): AggregatedBurnedBudgetRatio {
    let date = now;
    if (now.getTime() >= new Date(this.year, this.month + 1).getTime()) {
      date = new Date(this.year, this.month + 1, 0);
    }
    return new AggregatedBurnedBudgetRatio(
      this.total,
      this.consumedInBudget,
      this.consumedOverBudget,
      date
    );
  }
}

export class AggregatedBurnedBudgets {
  months: { year: number; month: number }[] = [];
  items: { [key: string]: AggregatedBurnedBudget } = {};
  private getKey(year: number, month: number) {
    return `${year}-${month}`;
  }
  add(year: number, month: number, budget: AggregatedBurnedBudget) {
    this.months.push({ year, month });
    this.items[this.getKey(year, month)] = budget;
  }
  get(year: number, month: number) {
    return (
      this.items[this.getKey(year, month)] ||
      new AggregatedBurnedBudget(year, month)
    );
  }
}
