import { RegionHoliday } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../redux/AppTypes";

export default function useHolidays(
  year: number,
  month: number
): { [regionId: string]: RegionHoliday[] } {
  const regions = useSelector((state: AppState) => state.regions.items);
  const [holidays, setHolidays] = useState(
    {} as { [regionId: string]: RegionHoliday[] }
  );

  useEffect(() => {
    const newHolidays = Object.values(regions).reduce((all, region) => {
      const days = (region.holidays || []).filter(
        (h) => h.year === year && h.month === month
      );
      all[region.id] = days;
      return all;
    }, {} as { [regionId: string]: RegionHoliday[] });
    setHolidays(newHolidays);
  }, [year, month, regions]);

  return holidays;
}
