import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography
} from "@material-ui/core";
import useTeamCapacityInAggregator from "capacity/useTeamCapacityInAggregator";
import {
  Region,
  RegionHoliday,
  TeamCapacityAggregatorType
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import useHolidays from "regions/useHolidays";
import useRegions from "regions/useRegions";
import { DAY_DATE_FORMAT } from "utils/dateFormats";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

interface Item {
  region: Region;
  holidays: RegionHoliday[];
}

export function TeamHolidaysCard({
  year,
  month,
  teamId,
}: {
  year: number;
  month: number;
  teamId: string;
}) {
  const classes = useStyles();
  const regions = useRegions();
  const holidays = useHolidays(year, month);
  const [items, setItems] = useState([] as Item[]);
  const capacity = useTeamCapacityInAggregator(
    year,
    month,
    TeamCapacityAggregatorType.TEAM,
    teamId
  );

  useEffect(() => {
    const filter = Object.values(capacity.items).reduce((all, e) => {
      all.add(e.region.id);
      return all;
    }, new Set<string>());
    const newItems = Object.keys(holidays)
      .filter((regionId) => filter.has(regionId))
      .map((regionId) => {
        return {
          region: regions[regionId],
          holidays: holidays[regionId],
        };
      });
    setItems(newItems);
  }, [year, month, regions, holidays, capacity]);

  return (
    <Box>
      <List dense={true}>
        <ListItem>
          <Typography variant="h6">Holidays</Typography>
        </ListItem>
        {items.map((item, index) => {
          return (
            <span key={`region${index}`}>
              <ListItem>
                <ListItemText primary={item.region.name} />
              </ListItem>
              <Collapse in={true} timeout="auto" unmountOnExit>
                <List component="div" disablePadding dense={true}>
                  {item.holidays.map((h, index) => {
                    const date = new Date(h.year, h.month, h.day);
                    return (
                      <ListItem
                        className={classes.nested}
                        key={`holiday${index}`}
                      >
                        <ListItemText
                          primary={`${h.name}: ${date.toLocaleString(
                            "en",
                            DAY_DATE_FORMAT
                          )}`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            </span>
          );
        })}
      </List>
    </Box>
  );
}
