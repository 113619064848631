import { useTeamCapacityByAggregator } from "capacity/useTeamCapacityByAggregator";
import { CustomerAggregatorType, Team, TeamCapacityAggregatorType } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { LabeledScatterDataPoint } from "../../components/LabeledScatterDataPoint";
import { ScatterChartCard } from "../../components/ScatterChartCard";
import { useBudgetByAggregator } from "../../customers/useBudgetByAggregator";

export function UtilizationByTeamScatterChartCard({
  year,
  month,
  teams,
  linkPrefix,
}: {
  year: number;
  month: number;
  teams: { [teamId: string]: Team };
  linkPrefix?: string;
}) {
  const budgets = useBudgetByAggregator(
    year,
    month,
    CustomerAggregatorType.TEAM
  );
  const capacities = useTeamCapacityByAggregator(
    year,
    month,
    TeamCapacityAggregatorType.TEAM
  );
  const [coordinates, setCoordinates] = useState(
    [] as LabeledScatterDataPoint[]
  );

  useEffect(() => {
    const newCoordinates = Object.values(teams).map((team) => {
      const label = team.name;
      const budget = budgets?.[team.id];
      const capacity = capacities[team.id];
      const budgetHours = (budget?.opsHours || 0) + (budget?.teamHours || 0);
      const capacityHours = capacity?.allocatedHours || 0;
      return {
        label,
        x: capacityHours,
        y: Math.round((budgetHours / capacityHours) * 100),
        link: linkPrefix ? `${linkPrefix}/${team.id}` : undefined,
      };
    });
    setCoordinates(newCoordinates);
  }, [linkPrefix, teams, budgets, capacities]);

  return (
    <ScatterChartCard
      title="Team utilization"
      xScaleTitle="Capacity (hours)"
      yScaleTitle="Utilization (%)"
      xAnnotation={100}
      tooltipSuffix="%"
      dataPoints={coordinates}
      chartHeight={300}
    ></ScatterChartCard>
  );
}
