import { useEffect, useState } from 'react'
import service from './JiraResourcesService'

export function useJiraBoardSwimlane(
  projectKey: string,
  reloadCounter: number
): boolean | null | undefined {
  const [swimlane, setSwimlane] = useState(
    undefined as boolean | null | undefined
  )

  useEffect(() => {
    setSwimlane(undefined)
    service
      .getBoardSwimlane(projectKey)
      .then((response) => setSwimlane(response))
      .catch(() => setSwimlane(null))
  }, [projectKey, reloadCounter])

  return swimlane
}
