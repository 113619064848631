import { useEffect, useState } from 'react'
import service from './JiraResourcesService'

export function useJiraOnboardingTasks(
  projectKey: string,
  reloadCounter: number
): boolean | null | undefined {
  const [exists, setExists] = useState(undefined as boolean | null | undefined)

  useEffect(() => {
    setExists(undefined)
    service.getOnboardingTasks(projectKey).then((r) => setExists(r))
  }, [projectKey, reloadCounter])

  return exists
}
