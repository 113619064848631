import { SET_ALIVE, WebSocketAction, WebSocketState } from "./WebSocketTypes";

const initialState: WebSocketState = {
  alive: true,
};

export default function websocketReducer(
  state = initialState,
  action: WebSocketAction
): WebSocketState {
  switch (action.type) {
    case SET_ALIVE:
      return {
        ...state,
        alive: action.alive,
      };
    default:
      return state;
  }
}
