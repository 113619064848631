import { Container } from "@material-ui/core";
import { CustomerDashboardPage } from "dashboards/customer/CustomerDashboardPage";
import { EMDashboardRoutePage } from "dashboards/em/EMDashboardRoutePage";
import { EMLeadCustomersDashboardPage } from "dashboards/emlead/EMLeadCustomersDashboardPage";
import { EMLeadDashboardPage } from "dashboards/emlead/EMLeadDashboardPage";
import { EMLeadEMsDashboardPage } from "dashboards/emlead/EMLeadEMsDashboardPage";
import { Route, Switch } from "react-router-dom";
import { EMLeadsDashboardPage } from "./EMLeadsDashboardPage";

export function EMLeadsDashboardRoutePage() {
  const baseUrl = "/dashboards/emleads/:emlead";
  return (
    <Switch>
      <Route path={`${baseUrl}/ems/:em`}>
        <Container maxWidth="xl">
          <EMDashboardRoutePage baseUrl={`${baseUrl}/ems`} />
        </Container>
      </Route>
      <Route path={`${baseUrl}/ems`}>
        <Container maxWidth="xl">
          <EMLeadEMsDashboardPage />
        </Container>
      </Route>
      <Route path={`${baseUrl}/customers/:customer`}>
        <Container maxWidth="xl">
          <CustomerDashboardPage />
        </Container>
      </Route>
      <Route path={`${baseUrl}/customers`}>
        <Container maxWidth="xl">
          <EMLeadCustomersDashboardPage />
        </Container>
      </Route>
      <Route path={baseUrl}>
        <EMLeadDashboardPage />
      </Route>
      <Route path="/dashboards/emleads">
        <Container maxWidth="xl">
          <EMLeadsDashboardPage />
        </Container>
      </Route>
    </Switch>
  );
}
