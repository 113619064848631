import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@material-ui/core";
import {
  AggregatedTeamUtilization,
  EngineerHandle,
  TeamHandle
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useRegions from "../regions/useRegions";
import { useSkillsByTeam } from "../skills/useSkillsByTeam";
import { useTeams } from "../teams/useTeams";
import { useTeamUtilizationByTeam } from "../utilization/useTeamUtilizationByTeam";
import { MonthNavigator } from "../utils/MonthNavigator";

interface RankedTeam {
  team: TeamHandle;
  utilization: AggregatedTeamUtilization;
  ranking: number;
  engineersByRegion: Map<string, EngineerHandle[]>;
  regionValue: number;
  regionIndex: number;
  engineersBySkill: Map<string, EngineerHandle[]>;
  skillValue: number;
  skillIndex: number;
  missingSkills: Set<string>;
  deltaValue: number;
  deltaIndex: number;
}

function CustomerAllocationTable({
  year,
  month,
  skills,
  region,
}: {
  year: number;
  month: number;
  skills: string[];
  region: string;
}) {
  const teams = useTeams(year, month);
  const [rankedTeams, setRankedTeams] = useState([] as RankedTeam[]);
  const skillsByTeam = useSkillsByTeam(year, month);
  const utilizationByTeam = useTeamUtilizationByTeam(year, month, teams);

  useEffect(() => {
    const newRankedTeams = Object.values(teams).map((team) => {
      const utilization =
        utilizationByTeam[team.id] || new AggregatedTeamUtilization();
      // region
      const engineersByRegion = utilization.getEngineersByRegion();
      const regionValue = !region
        ? utilization.capacity.allocatedEngineers
        : engineersByRegion.get(region)?.length || 0;

      // skills
      const engineersBySkill = utilization.getEngineersBySkills();
      const skillValue = skills.reduce((total, skill) => {
        return total + (engineersBySkill.get(skill)?.length || 0);
      }, 0);
      const missingSkills = skills.reduce((total, skill) => {
        if ((engineersBySkill.get(skill)?.length || 0) < 1) {
          total.add(skill);
        }
        return total;
      }, new Set<string>());

      // delta
      const deltaValue = utilization.getDelta();

      return {
        team,
        utilization,
        ranking: 0,
        engineersByRegion,
        regionValue,
        engineersBySkill,
        skillValue,
        missingSkills,
        deltaValue,
      } as RankedTeam;
    });

    // calculate weighted indexes
    const maxValues = newRankedTeams.reduce(
      (max, item) => {
        max.region =
          item.regionValue > max.region ? item.regionValue : max.region;
        max.skill = item.skillValue > max.skill ? item.skillValue : max.skill;
        max.delta = item.deltaValue > max.delta ? item.deltaValue : max.delta;
        return max;
      },
      { region: 0, skill: 0, delta: 0 }
    );
    newRankedTeams.forEach((item) => {
      item.regionIndex = Math.round(
        (item.regionValue / maxValues.region) * 10 || 0
      );
      item.skillIndex = Math.round(
        (item.skillValue / maxValues.skill) * 10 || 0
      );
      item.deltaIndex = Math.round(
        (item.deltaValue / maxValues.delta) * 10 || 0
      );
      const base =
        item.regionValue > 0 &&
        item.missingSkills.size <= 0 &&
        item.deltaValue >= 0
          ? 0
          : -30;
      item.ranking =
        base + item.regionIndex + item.skillIndex + item.deltaIndex;
    });
    newRankedTeams.sort((i1, i2) => i2.ranking - i1.ranking);
    setRankedTeams(newRankedTeams);
  }, [year, month, region, skills, teams, utilizationByTeam, skillsByTeam]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Team</TableCell>
            <TableCell>Ranking</TableCell>
            <TableCell>Region</TableCell>
            <TableCell>Skills</TableCell>
            <TableCell>Delta</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rankedTeams.map((item, index) => (
            <TableRow key={`${index}`}>
              <TableCell>
                <Link
                  to={`/dashboards/teams/${item.team.id}`}
                  style={{ textDecoration: "inherit", color: "inherit" }}
                >
                  {item.team.name}
                </Link>
              </TableCell>
              <TableCell>{item.ranking}</TableCell>
              <TableCell>
                <Tooltip
                  title={"Index: " + Math.round(item.regionIndex)}
                  style={{
                    color: item.regionValue < 1 ? "red" : "inherit",
                  }}
                >
                  <span>{item.regionValue.toLocaleString()}</span>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={
                    "Index: " +
                    Math.round(item.skillIndex) +
                    (item.missingSkills.size > 0
                      ? " - Missing: " +
                        Array.from(item.missingSkills).join(", ")
                      : "")
                  }
                  style={{
                    color: item.missingSkills.size > 0 ? "red" : "inherit",
                  }}
                >
                  <span>{item.skillValue.toLocaleString()}</span>
                </Tooltip>
              </TableCell>
              <TableCell
                style={{
                  color: item.deltaValue < 0 ? "red" : "inherit",
                }}
              >
                <Tooltip title={"Index: " + Math.round(item.deltaIndex)}>
                  <span>{Math.round(item.deltaValue).toLocaleString()}</span>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function CustomerAllocationAssistant({ skills }: { skills: string[] }) {
  const regions = useRegions();
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [region, setRegion] = useState("");

  return (
    <Box style={{ width: 500, padding: 16 }}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          fontSize: 12,
          marginBottom: "8px",
        }}
      >
        <FormControl>
          <Select
            value={region}
            onChange={(e) => setRegion((e?.target?.value as string) || "")}
            displayEmpty
            style={{ minWidth: 100, fontSize: 12 }}
          >
            <MenuItem value="">Any region</MenuItem>
            {Object.values(regions).map((region) => (
              <MenuItem value={region.id}>{region.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <MonthNavigator
            year={year}
            month={month}
            onChange={(newYear, newMonth) => {
              setYear(newYear);
              setMonth(newMonth);
            }}
          ></MonthNavigator>
        </FormControl>
      </Box>
      <CustomerAllocationTable
        year={year}
        month={month}
        skills={skills}
        region={region}
      />
    </Box>
  );
}
