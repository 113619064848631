import { useTheme } from "@material-ui/core";
import { useEngineerCapacityByAggregator } from "capacity/useEngineerCapacityByAggregator";
import { BarChartCard } from "components/BarChartCard";
import { useBudgetByAggregator } from "customers/useBudgetByAggregator";
import {
  CustomerAggregatorType,
  CustomerHandle,
  EngineerAggregatorType,
  EngineerHandle
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";

export function TopSkillsBarChart({
  year,
  month,
  customers,
  engineers,
  max = 5,
  title = "Top required skills",
}: {
  year: number;
  month: number;
  customers: { [id: string]: CustomerHandle };
  engineers: { [id: string]: EngineerHandle };
  max?: number;
  title?: string;
}) {
  const theme = useTheme();
  const budgets = useBudgetByAggregator(
    year,
    month,
    CustomerAggregatorType.SKILL,
    customers
  );
  const capacities = useEngineerCapacityByAggregator(
    year,
    month,
    EngineerAggregatorType.SKILL,
    engineers
  );
  const [data, setData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    const skills = Object.keys(budgets);
    skills.sort(
      (s1, s2) =>
        (budgets[s2]?.getTeamPlusOps() || 0) -
        (budgets[s1]?.getTeamPlusOps() || 0)
    );

    const selected = skills.slice(0, Math.min(max, skills.length));

    const newData = {
      labels: selected,
      datasets: [
        {
          label: "Budget",
          data: selected.map(
            (s) => Math.round(budgets[s]?.getTeamPlusOps()) || 0
          ),
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
        },
        {
          label: "Capacity",
          data: selected.map(
            (s) => Math.round(capacities[s]?.allocatedHours) || 0
          ),
          borderColor: theme.palette.secondary.light,
          backgroundColor: theme.palette.secondary.light,
        },
      ],
    };
    setData(newData);
  }, [theme, max, budgets, capacities]);

  return <BarChartCard title={title} data={data} />;
}
