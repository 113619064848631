import useCustomers from "customers/useCustomers";
import { useHarvestBudget } from "harvest/useHarvestBudget";
import { useEffect, useState } from "react";
import {
  SystemNotification,
  SystemNotificationSource
} from "../SystemNotificationTypes";

export function useCustomerBudgetOutOfSynchNotifications(
  year: number,
  month: number
): SystemNotification[] {
  const customers = useCustomers(year, month);
  const budgets = useHarvestBudget(year, month);
  const [notifications, setNotifications] = useState(
    [] as SystemNotification[]
  );

  useEffect(() => {
    const newNotifications = Object.values(customers.items)
      .filter((customer) => !!customer.harvestProjectId)
      .filter((customer) => {
        const harvestBudget = budgets.get(customer.harvestProjectId);
        const budget = customer.getBudget(year, month);
        const total = (budget.teamHours || 0) + (budget.opsHours || 0);
        return harvestBudget.total > 0 && harvestBudget.total !== total;
      })
      .map((customer) => {
        const budget = customer.getBudget(year, month);
        const harvestBudget = budgets.get(customer.harvestProjectId);
        return {
          source: SystemNotificationSource.CUSTOMER,
          id: customer.id,
          title: `${customer.name}`,
          link: `/administration/customers/${customer.id}`,
          description: `team hours: ${(
            budget.teamHours || 0
          ).toLocaleString()} - ops hours: ${(
            budget.opsHours || 0
          ).toLocaleString()} - harvest budget: ${harvestBudget.total}`,
        };
      });
    setNotifications(newNotifications);
  }, [year, month, customers, budgets]);

  return notifications;
}
