import { Engineer } from "msd-capacity-planning-model";
import env from "../app/Environment";
import fetchService from "../utils/FetchService";

export interface EngineerService {
  fetch(): Promise<Engineer[]>;
  delete(id: string): Promise<Engineer[]>;
  persist(item: Engineer): Promise<Engineer[]>;
  setJiraAccess(id: string): Promise<void>;
  setConfluenceAccess(id: string): Promise<void>;
}

class HTTPEngineerService implements EngineerService {
  constructor(private endpoint: string) {}

  async fetch(): Promise<Engineer[]> {
    return fetchService
      .fetch(`${this.endpoint}/engineers`)
      .then((r) => r.json())
      .catch(() => []);
  }

  async delete(id: string): Promise<Engineer[]> {
    return fetchService
      .fetch(`${this.endpoint}/engineers/${id}`, {
        method: "DELETE",
      })
      .then(() => this.fetch());
  }

  async persist(item: Engineer): Promise<Engineer[]> {
    return fetchService
      .fetch(`${this.endpoint}/engineers`, {
        method: "POST",
        body: JSON.stringify(item),
      })
      .then(() => this.fetch());
  }

  async setJiraAccess(id: string): Promise<void> {
    return fetchService
      .fetch(`${this.endpoint}/engineers/${id}/jira/access`, {
        method: "POST",
      })
      .then(async (response) => {
        if (response.status && response.status !== 200) {
          throw new Error(`failed to grant Jira access`);
        }
      });
  }

  async setConfluenceAccess(id: string): Promise<void> {
    return fetchService
      .fetch(`${this.endpoint}/engineers/${id}/confluence/access`, {
        method: "POST",
      })
      .then(async (response) => {
        if (response.status && response.status !== 200) {
          throw new Error(`failed to grant Confluence access`);
        }
      });
  }
}

export function sortEngineers(e1: Engineer, e2: Engineer): number {
  const str1 = `${e1.lastName} ${e1.firstName}`;
  const str2 = `${e2.lastName} ${e2.firstName}`;
  return str1.localeCompare(str2);
}

export function isEngineerInTeam(e: Engineer, teamId: string): boolean {
  return new Set(e.utilization.map((u) => u.team)).has(teamId);
}

const service: EngineerService = new HTTPEngineerService(env.endpoint);

export default service;
