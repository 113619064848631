import { EM } from "msd-capacity-planning-model";
import env from "../app/Environment";
import fetchService from "../utils/FetchService";

export class EMService {
  constructor(private endpoint: string) {}

  async fetch(): Promise<EM[]> {
    return fetchService
      .fetch(`${this.endpoint}/ems`, {})
      .then((r) => r.json())
      .catch(() => []);
  }

  async delete(id: string): Promise<EM[]> {
    return fetchService
      .fetch(`${this.endpoint}/ems/${id}`, {
        method: "DELETE",
      })
      .then(() => this.fetch());
  }

  async persist(item: EM): Promise<EM[]> {
    return fetchService
      .fetch(`${this.endpoint}/ems`, {
        method: "POST",
        body: JSON.stringify(item),
      })
      .then(() => this.fetch());
  }

  async setJiraAccess(id: string): Promise<void> {
    return fetchService
      .fetch(`${this.endpoint}/ems/${id}/jira/access`, {
        method: "POST",
      })
      .then(async (response) => {
        if (response.status && response.status !== 200) {
          throw new Error(`failed to grant Jira access`);
        }
      });
  }

  async setConfluenceAccess(id: string): Promise<void> {
    return fetchService
      .fetch(`${this.endpoint}/ems/${id}/confluence/access`, {
        method: "POST",
      })
      .then(async (response) => {
        if (response.status && response.status !== 200) {
          throw new Error(`failed to grant Confluence access`);
        }
      });
  }
}

const service: EMService = new EMService(env.endpoint);

export default service;
