import { CustomerHandle } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import useAllCustomers from "./useAllCustomers";

export class AggregatedCustomers {
  public items: { [id: string]: CustomerHandle } = {};

  add(customer: CustomerHandle): void {
    this.items[customer.id] = customer;
  }

  addAll(customers: CustomerHandle[]): void {
    customers.forEach((customer) => this.add(customer));
  }

  get(customerId: string): CustomerHandle {
    return this.items[customerId];
  }

  getAll(): CustomerHandle[] {
    return Object.values(this.items);
  }

  size(): number {
    return Object.keys(this.items).length;
  }
}

export default function useCustomers(
  year: number,
  month: number
): AggregatedCustomers {
  const customers = useAllCustomers();
  const [customersWithBudget, setCustomerWithBudget] = useState(
    new AggregatedCustomers()
  );

  useEffect(() => {
    const newCustomersWithBudget = Object.values(customers).reduce(
      (all, customer) => {
        const budget = customer.getBudget(year, month);
        if (budget.emHours + budget.teamHours + budget.opsHours > 0) {
          all.add(customer);
        }
        return all;
      },
      new AggregatedCustomers()
    );
    setCustomerWithBudget(newCustomersWithBudget);
  }, [year, month, customers]);

  return customersWithBudget;
}
