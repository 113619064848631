import { AggregatedCapacity, Capacity } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import useSortedEngineers from "../engineers/useSortedEngineers";
import useRegions from "../regions/useRegions";

export function useCapacities(
  months: { year: number; month: number }[]
): AggregatedCapacity<Capacity>[] {
  const engineers = useSortedEngineers();
  const regions = useRegions();

  const [capacities, setCapacities] = useState(
    [] as AggregatedCapacity<Capacity>[]
  );

  useEffect(() => {
    const newCapacities = months.map((date) => {
      const monthCapacities = engineers
        .filter((engineer) => !!regions[engineer.region || ""])
        .map((engineer) => {
          return new Capacity(
            date.year,
            date.month,
            engineer,
            regions[engineer.region || ""]
          );
        })
        .reduce((total, capacity) => {
          if (!capacity.isEmpty()) {
            total.add(capacity);
          }
          return total;
        }, new AggregatedCapacity<Capacity>());
      monthCapacities.allocatedHours = Math.round(
        monthCapacities.allocatedHours
      );
      return monthCapacities;
    });
    setCapacities(newCapacities);
  }, [months, engineers, regions]);

  return capacities;
}
