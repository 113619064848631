import {
  Box,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";

import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import { useEngineerCapacityByAggregator } from "capacity/useEngineerCapacityByAggregator";
import { NoStyleLink } from "components/NoStyleLink";
import { useBudgetByAggregator } from "customers/useBudgetByAggregator";
import {
  CustomerAggregatorType,
  CustomerHandle,
  EngineerAggregatorType,
  EngineerHandle
} from "msd-capacity-planning-model";
import { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";

export function SkillMatchingTable({
  year,
  month,
  customers,
  engineers,
  linkPrefix = "",
  link = true,
}: {
  year: number;
  month: number;
  customers: { [customerId: string]: CustomerHandle };
  engineers: { [engineerId: string]: EngineerHandle };
  linkPrefix?: string;
  link?: boolean;
}) {
  const location = useLocation();

  const budgets = useBudgetByAggregator(
    year,
    month,
    CustomerAggregatorType.SKILL,
    customers
  );
  const capacities = useEngineerCapacityByAggregator(
    year,
    month,
    EngineerAggregatorType.SKILL,
    engineers
  );
  const [skills, setSkills] = useState([] as string[]);
  const [filter, setFilter] = useState("");
  const [filtered, setFiltered] = useState([] as string[]);

  useEffect(() => {
    const newSkills = new Set(
      Object.keys(budgets).concat(Object.keys(capacities))
    );
    const sortedSkills = Array.from(newSkills).sort((s1, s2) => {
      const value =
        budgets[s2]?.getTeamPlusOps() - budgets[s1]?.getTeamPlusOps();
      return value === 0 ? s1.localeCompare(s2) : value;
    });
    setSkills(sortedSkills);
  }, [budgets, capacities]);

  useEffect(() => {
    if (filter) {
      setFiltered(
        skills.filter((s) => s.toLowerCase().indexOf(filter.toLowerCase()) > -1)
      );
    } else {
      setFiltered(skills);
    }
  }, [skills, filter]);


  const columns: any = [ 
    {
      name: "Skill",
      options: {
        sortCompare: (order: string) => {
          return (obj1: { data: { props: { text: string; }; }; }, obj2: { data: { props: { text: string; }; }; }) => {
            return obj1.data.props.text.localeCompare(obj2.data.props.text) * (order === 'asc' ? 1 : -1);
          };
        },
      }
    },
    {
      name: "Delta",
      options: {
        sortCompare: (order: string) => {
          return (obj1: any, obj2: any) => {
            const val1 =  typeof(obj1.data) == 'object' ? obj1?.data?.props?.children[0]: obj1.data;
            const val2 =  typeof(obj2.data) == 'object' ? obj2?.data?.props?.children[0]: obj2.data;
            return (val1 - val2) * (order === 'desc' ? 1 : -1);
          };
        },
      }
    },
    {
      name: "Customers"
    },
    {
      name: "Budget",
    },
    {
      name: "Engineers",
    },
    {
      name: "Capacity",
    }
  ]

  const data =  useMemo(() => filtered.map((skill,index) =>  {
    const budget = (budgets[skill]?.teamHours || 0) + (budgets[skill]?.opsHours || 0);
    const capacity = capacities[skill]?.allocatedHours || 0;
    const delta = capacity - budget;
    return (
     [
      link ? (<NoStyleLink pathname={`${location.pathname}${linkPrefix}/${skill}`} text={skill}/>) : (skill),
      delta < 0 ?  <Box>{Math.round(delta)} <WarningIcon color="secondary" style={{ marginLeft: 8 }}/></Box>  : Math.round(delta || 0),
      Math.round(budgets[skill]?.getTeamPlusOps() || 0),
      Math.round(budget || 0),
      Math.round(capacities[skill]?.allocatedEngineers || 0),
      Math.round(capacity || 0)
     ]
    )}
    ),[filtered])

  return (
    <Box>
      <Box style={{ textAlign: "center" }}>
        <TextField
          label="Skill filter"
          value={filter}
          onChange={(event) => {
            setFilter(event?.target?.value || "");
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setFilter("")}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <MUIDataTable
       title=""
       columns={columns}
       data={data}
       options={
        {
          filterType: 'multiselect',

          "draggableColumns":{"enabled":false},
          "pagination":false,
          "selectableRows":"none",
          "download": false,
          "search":false,
          "print":false,
          "viewColumns":false,
          "filter":false
        }
      }
      />
    </Box>
  );
}
