import { Container } from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import { EngineerDashboardPage } from "./EngineerDashboardPage";

export function EngineerDashboardRoutePage({ baseUrl }: { baseUrl: string }) {
  return (
    <Container maxWidth="xl">
      <Switch>
        <Route path={`${baseUrl}`}>
          <EngineerDashboardPage />
        </Route>
      </Switch>
    </Container>
  );
}
