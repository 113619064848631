import { useEffect, useState } from 'react'
import service from './JiraResourcesService'

export enum HarvestCodeState {
  fetching = 'fetching',
  failed = 'failed',
  correct = 'exists',
  incorrect = 'notExists'
}

// sets the jira project id in harvest as the project code
export function useHarvestCode(
  projectKey: string,
  harvestId: string,
  reloadCounter: number
): HarvestCodeState {
  const [rule, setRule] = useState(HarvestCodeState.fetching)

  useEffect(() => {
    setRule(HarvestCodeState.fetching)
    service
      .getHarvestCode(projectKey, harvestId)
      .then((r) => {
        setRule(
          r === true ? HarvestCodeState.correct : HarvestCodeState.incorrect
        )
      })
      .catch(() => {
        setRule(HarvestCodeState.failed)
      })
  }, [projectKey, harvestId, reloadCounter])

  return rule
}
