import { Box, Button, Dialog, DialogTitle } from "@material-ui/core";
import { useCallback } from "react";
import { DataExportPanel } from "./DataExportPanel";

export function DataExportDialog({ open, onClose }: { open: boolean, onClose: () => void }) {
  const close = useCallback((e) => {
    try {
      e.stopPropagation()
    } catch (_) { }
    onClose();
  }, [onClose]);
  return <Dialog maxWidth="sm" fullWidth onClose={close} open={open} >
    <DialogTitle>Export Data</DialogTitle>
    <Box style={{ height: 200, marginLeft: 24 }}>
      <DataExportPanel />
    </Box>
    <Box style={{ margin: 24 }}><Button onClick={close}>Close</Button></Box>
  </Dialog>
}