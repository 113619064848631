import { JiraProject } from "msd-capacity-planning-model";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/AppTypes";
import { FETCH_JIRA } from "../jira/JiraTypes";

export function useJiraProjects(): JiraProject[] {
  const dispatch = useDispatch();
  const projects = useSelector((state: AppState) => state.jira.projects);

  useEffect(() => {
    dispatch({ type: FETCH_JIRA });
  }, [dispatch]);

  return projects;
}
