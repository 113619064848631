import {
  Box,
  IconButton,

} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  CustomerPlannigCommentsPanel,
  PLANNING_PREFIX
} from "comments/CustomerPlanningCommentsPanel";
import useAllCustomers from "customers/useAllCustomers";
import { CustomerLinkBar } from "dashboards/customer/CustomerLinkBar";
import { useBudgetInAggregator } from "customers/useBudgetInAggregator";
import { Customer, CustomerAggregatorType } from "msd-capacity-planning-model";
import { useState, useMemo } from "react";
import MUIDataTable from "mui-datatables";

const NOTES_KEY = PLANNING_PREFIX;

export function TeamPlanningCustomersTable({
  year,
  month,
  teamId,
}: {
  year: number;
  month: number;
  teamId: string;
}) {
  const customers = useAllCustomers();
  const budgets = useBudgetInAggregator(
    year,
    month,
    CustomerAggregatorType.TEAM,
    teamId
  );
  const [menuItem, setMenuItem] = useState(null as Customer | null);
  const [anchorEl, setAnchorEl] = useState(null as any);





  const columns: any = [
    {
      name: "Customer",
      label: "Customer",
      options: {
      sortCompare: (order: any) => {
        return (obj1: any , obj2: any  ) => {
          return obj1.data[0].localeCompare(obj2.data[0]) * (order === 'asc' ? 1 : -1)
        };
      }
    }

    },
    {
      name: "Team hours",
      label: "Team hours",
      options: {
        sortCompare: (order: string) => {
          return (obj1: any, obj2: any ) => {
            let val1 = obj1.data[0]
            let val2 = obj2.data[0]
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        }
      }   
    },
    {
      name: "Ops hours",
      label: "Ops hours",
      options: {
        sortCompare: (order: string) => {
          return (obj1: any, obj2: any ) => {
            let val1 = obj1.data[0]
            let val2 = obj2.data[0]
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        }
      }   
    },
    {
      name: "EM hours",
      label: "EM hours",
      options: {
        sortCompare: (order: string) => {
          return (obj1: any, obj2: any ) => {
            let val1 = obj1.data[0]
            let val2 = obj2.data[0]
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        }
      }   

    },
    {
      name: "Bank hours",
      label: "Bank hours",
      options: {
        sortCompare: (order: string) => {
          return (obj1: any, obj2: any ) => {
            let val1 = obj1.data[0]
            let val2 = obj2.data[0]
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        }
      }   

    },
    {
      name: "Notes",
      label: "Notes"

    },
    {
      name: "",
      label: ""

    }
  ]

  const data= useMemo(() => { 
    return  Object.keys(budgets.items).map((customerId, idx) => {
      const customer = customers[customerId];
      const budget = budgets.items[customerId];
      return (
        [
        [customer?.name],
        [budget?.teamHours || 0],
          [budget?.opsHours || 0],
          [budget?.emHours || 0],
          [budget?.bankHours || 0],
          [
            <CustomerPlannigCommentsPanel
              year={year}
              month={month}
              customer={customer}
              hideAuthor={true}
            />
          ],
          [<IconButton
              size="small"
              onClick={(event: any) => {
                setMenuItem(customer);
                setAnchorEl(event.currentTarget);
              }}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
          ]
        ]
      );
    })
  }, [budgets, customers]);

  const options = {
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    selectableRowsHeader:false,
    selectableRows: "none" as const
   };

  return (
    <Box>
      <MUIDataTable
          title={'Customers'}
          columns={columns}
          data={data}
          options={options}>
          
            
      </MUIDataTable>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem>
          <CustomerLinkBar
            year={year}
            month={month}
            customer={menuItem as Customer}
            notesKey={NOTES_KEY}
          />
        </MenuItem>
      </Menu>
      </Box>

  );
}
