import { useTheme } from "@material-ui/core/styles";
import useTeamCapacityInAggregator from "capacity/useTeamCapacityInAggregator";
import { Chart } from "chart.js";
import {
  AggregatedCapacity,
  AggregatedCapacityItem,
  TeamCapacityAggregatorType
} from "msd-capacity-planning-model";
import { createRef, useEffect, useState } from "react";
import useCapacity from "../../capacity/useCapacity";
import { DashboardCard } from "../../components/DashboardCard";
import useRegions from "../../regions/useRegions";
import { getColors } from "../../utils/colorGenerator";

function CapacityByRegionCardImpl({
  capacities,
}: {
  capacities: AggregatedCapacity<AggregatedCapacityItem>;
}) {
  const chartRef = createRef<HTMLCanvasElement>();
  const [chart, setChart] = useState(null as null | Chart);
  const theme = useTheme();
  const regions = useRegions();

  useEffect(() => {
    if (chart) {
      return;
    }
    const ref = chartRef?.current as HTMLCanvasElement;
    const newChart = new Chart(ref, {
      type: "doughnut",
      data: {
        datasets: [],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "bottom",
          },
        },
      },
    });
    setChart(newChart);
  }, []);

  useEffect(() => {
    if (!chart) {
      return;
    }
    const capacityByRegion = capacities.getItems().reduce((map, item) => {
      const name = regions[item.engineer.region]?.name || "";
      if (!map.has(name)) {
        map.set(name, new AggregatedCapacity<AggregatedCapacityItem>());
      }
      map.get(name)!.add(item);
      return map;
    }, new Map<string, AggregatedCapacity<AggregatedCapacityItem>>());
    const data = Array.from(capacityByRegion.values()).map(
      (c) => c.allocatedHours
    );
    const backgroundColor = getColors(theme, data.length);
    chart.data = {
      labels: Array.from(capacityByRegion.keys()).map(
        (region) =>
          region +
          " (" +
          capacityByRegion.get(region)?.allocatedHours.toLocaleString() +
          ")"
      ),
      datasets: [{ data, backgroundColor }],
    };
    chart.update();
  }, [theme, chart, capacities, regions]);

  return (
    <DashboardCard title="Capacity by region (hours)" height={300}>
      {{ body: <canvas ref={chartRef} /> }}
    </DashboardCard>
  );
}

export function TeamCapacityByRegionCard({
  year,
  month,
  teamId,
}: {
  year: number;
  month: number;
  teamId: string;
}) {
  const capacities = useTeamCapacityInAggregator(
    year,
    month,
    TeamCapacityAggregatorType.TEAM,
    teamId
  );
  return <CapacityByRegionCardImpl capacities={capacities} />;
}

export function CapacityByRegionCard({
  year,
  month,
}: {
  year: number;
  month: number;
}) {
  const capacities = useCapacity(year, month);
  return <CapacityByRegionCardImpl capacities={capacities} />;
}
