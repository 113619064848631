import React from "react";
import { Route, Switch } from "react-router-dom";
import { AdministratorsForm } from "./AdministratorsForm";
import { AdministratorsPanel } from "./AdministratorsPanel";

export function AdministratorsPage() {
  return (
    <Switch>
      <Route path="/administration/administrators/:id" strict={true}>
        <AdministratorsForm />
      </Route>
      <Route path="/administration/administrators/" strict={true}>
        <AdministratorsForm />
      </Route>
      <Route path="/administration/administrators">
        <AdministratorsPanel />
      </Route>
    </Switch>
  );
}
