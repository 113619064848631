import { LabeledScatterDataPoint } from "components/LabeledScatterDataPoint";
import { ScatterChartCard } from "components/ScatterChartCard";
import { useBudgetByAggregator } from "customers/useBudgetByAggregator";
import useEngineersByAggregator from "engineers/useEngineerByAggregator";
import { CustomerAggregatorType, EngineerAggregatorType } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import useRegions from "regions/useRegions";

export function UtilizationByRegionScatterChartCard({
  year,
  month,
  baseUrl,
}: {
  year: number;
  month: number;
  baseUrl: string;
}) {
  const regions = useRegions();
  const budgets = useBudgetByAggregator(
    year,
    month,
    CustomerAggregatorType.REGION
  );
  const engineers = useEngineersByAggregator(
    year,
    month,
    EngineerAggregatorType.REGION
  );

  const [dataPoints, setDataPoints] = useState([] as LabeledScatterDataPoint[]);

  useEffect(() => {
    const newDataPoints = Object.values(regions)
      .filter((region) => budgets[region.id] && engineers[region.id])
      .map((region) => {
        const dataPoint: LabeledScatterDataPoint = {
          label: `${region.name}`,
          link: baseUrl ? `${baseUrl}/${region.id}` : undefined,
          x: engineers[region.id].length,
          y: budgets[region.id].opsHours + budgets[region.id].teamHours,
        };
        return dataPoint;
      });
    setDataPoints(newDataPoints);
  }, [baseUrl, regions, budgets, engineers]);

  return (
    <ScatterChartCard
      title="Utilization by region"
      xScaleTitle="Engineers"
      yScaleTitle="Budget (hours)"
      dataPoints={dataPoints}
      chartHeight={300}
    ></ScatterChartCard>
  );
}
