import {
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  makeStyles
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { useState } from "react";
import { docsService } from "./DocsService";

const useStyles = makeStyles({
  paper: {
    width: 450,
  },
});

export function DocsDrawer({ path }: { path: string }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <Box style={{ display: "inline" }}>
      <IconButton
        onClick={async () => {
          setLoading(true);
          setOpen(true);
          try {
            await docsService.setCookies();
          } finally {
            setLoading(false);
          }
        }}
      >
        <HelpIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        classes={{ paper: classes.paper }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <iframe
            src={`https://${window.location.hostname}/docs${path}`}
            title="docs"
            frameBorder="0"
            style={{ width: "100%", height: "100%" }}
          ></iframe>
        )}
      </Drawer>
    </Box>
  );
}
