import { FETCH_CUSTOMERS } from "customers/CustomerTypes";
import { FETCH_EMS } from "ems/EMsTypes";
import { FETCH_ENGINEERS } from "engineers/EngineersTypes";
import { FETCH_REGIONS } from "regions/RegionTypes";
import { bufferTime, Subject } from "rxjs";
import { FETCH_TEAMS } from "teams/TeamsTypes";
import env from "../app/Environment";
import store from "../redux/store";
import fetchService from "../utils/FetchService";

const TWO_MINUTES = 1000 * 60 * 2;
const TEN_MINUTES = 1000 * 60 * 10;

const ACTIONS = new Map();
ACTIONS.set("customer", FETCH_CUSTOMERS);
ACTIONS.set("em", FETCH_EMS);
ACTIONS.set("engineer", FETCH_ENGINEERS);
ACTIONS.set("region", FETCH_REGIONS);
ACTIONS.set("team", FETCH_TEAMS);

interface FetchMessageBody {
  tables: string[];
}

enum WebSocketMessageAction {
  PING = "PING",
  FETCH = "FETCH",
}
interface WebSocketMessage {
  action: WebSocketMessageAction;
  body: any;
}

export class WebSocketService {
  webSocket: WebSocket | undefined;
  lastMessage = new Date();
  lastReconnect = 0; // minute
  fetchActionSubject = new Subject<string>();

  constructor(private restEndpoint: string, private websocketEndpoint: string) {
    this.fetchActionSubject.pipe(bufferTime(2000)).subscribe((tables) => {
      const distintTables = new Set(tables);
      distintTables.forEach((table) => {
        const actionType = ACTIONS.get(table);
        store.dispatch({ type: actionType });
      });
    });
  }

  processMessage(message: WebSocketMessage) {
    if (message.action === WebSocketMessageAction.FETCH) {
      const body = message.body as FetchMessageBody;
      body.tables.forEach((table) => {
        this.fetchActionSubject.next(table);
      });
    } else if (message.action !== WebSocketMessageAction.PING) {
      console.log(message);
    }
  }

  async reconnect(now = new Date()) {
    const newReconnect = Math.floor(now.getTime() / 1000 / 60);
    if (newReconnect > this.lastReconnect) {
      this.lastReconnect = newReconnect;
      if (this.webSocket) {
        try {
          this.webSocket.close();
          this.webSocket = undefined;
        } catch (error) {
          console.log(error);
        }
      }
      await this.connect();
    }
  }

  async connect(): Promise<void> {
    // get a user specific websocket URL
    const uuid = await fetchService
      .fetch(`${this.restEndpoint}/websocket/init`, {
        method: "POST",
      })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return { uuid: "" };
        }
      })
      .then((json) => json.uuid);

    if (!uuid) {
      return;
    }

    // connect to the websocket
    this.webSocket = new WebSocket(`${this.websocketEndpoint}?uuid=${uuid}`);
    this.lastMessage = new Date();

    // listen to messages
    this.webSocket.onmessage = ({ data }: { data: string }) => {
      this.lastMessage = new Date();
      try {
        this.processMessage(JSON.parse(data));
      } catch (error) {
        console.log(error);
      }
    };
  }

  isAlive(now = new Date()): boolean {
    return this.lastMessage.getTime() + TWO_MINUTES > now.getTime();
  }

  isReconnectable(now = new Date()): boolean {
    return this.lastMessage.getTime() + TEN_MINUTES > now.getTime();
  }
}

const service = new WebSocketService(env.endpoint, env.websocketEndpoint);
export default service;
