import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  withStyles
} from "@material-ui/core";
import { DashboardBreadcrumbs } from "dashboards/DashboardBreadcrumbs";
import { useDashboardPeriod } from "dashboards/useDashboardPeriod";
import { useTrendPeriods } from "dashboards/useTrendPeriods";
import { TeamHandle } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useRegions from "regions/useRegions";
import useTeamSnapshots from "teams/useTeamSnapshots";
import useLinkStyles from "../../utils/useLinkStyle";

const NumberCell = withStyles((theme) => ({
  root: {
    textAlign: "right",
  },
}))((props) => <TableCell {...props} />);

export function TeamsHiringNeeds({
  teams = {},
}: {
  teams: { [teamId: string]: TeamHandle };
}) {
  const period = useDashboardPeriod();
  const regions = useRegions();
  const linkStyles = useLinkStyles();
  const periods = useTrendPeriods(period, 0, 3);
  const snapshots = useTeamSnapshots(periods);
  const [filteredTeams, setFilteredTeams] = useState([] as TeamHandle[]);

  useEffect(() => {
    const newFilteredTeams = Object.values(teams || []).filter((team) => {
      return Object.values(snapshots).some((periodSnapshots) => {
        const snapshot = periodSnapshots[team.id];
        return snapshot?.isOverCapacity() || false;
      });
    });
    setFilteredTeams(newFilteredTeams);
  }, [teams, snapshots]);

  return (
    <Box>
      <DashboardBreadcrumbs />
      <Typography variant="h4" style={{ marginTop: 8 }}>
        Team hiring needs
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Team</TableCell>
            <TableCell>Region</TableCell>
            {periods.map((period, index) => {
              const date = new Date(period.year, period.month);
              return (
                <NumberCell key={`date${index}`}>
                  {date.toISOString().substring(0, 7)}
                </NumberCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredTeams.map((team, index) => {
            return (
              <TableRow key={`team-${index}`}>
                <TableCell>
                  <Link to={`${team.id}`} className={linkStyles.link}>
                    {team.name}{" "}
                    {(team.type || []).map((type: string, typeIdx: number) => (
                      <Chip
                        key={`type${typeIdx}`}
                        label={type}
                        style={{ marginRight: 2 }}
                      />
                    ))}
                  </Link>
                </TableCell>
                <TableCell>
                  {team.region ? (
                    <Link
                      to={`/dashboards/regions/${team.region}`}
                      className={linkStyles.link}
                    >
                      {regions[team.region]?.name}
                    </Link>
                  ) : null}
                </TableCell>
                {periods.map((period, index) => {
                  const date = new Date(period.year, period.month);
                  const key = date.toISOString().substring(0, 7);
                  const snapshot = snapshots[key]?.[team.id];
                  const capacity = snapshot?.getGrossUtilizationHours() || 0;
                  const budget = snapshot?.getBudget().getPlannedHours() || 0;
                  const delta = Math.round(snapshot?.getGrossDelta() || 0);
                  const hires = Math.ceil(Math.abs(Math.min(0, delta)) / 120);
                  const tooltip =
                    `gross capacity: ${Math.round(capacity)} - ` +
                    `planned hours: ${Math.round(budget)}`;
                  return (
                    <NumberCell key={`date${index}`}>
                      <Tooltip title={tooltip}>
                        <Link
                          to={`${team.id}?year=${date.getFullYear()}&month=${
                            date.getMonth() + 1
                          }`}
                          className={linkStyles.link}
                        >
                          {hires}
                        </Link>
                      </Tooltip>
                    </NumberCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Typography
        variant="caption"
        style={{ display: "block", color: "gray", marginTop: 8 }}
      >
        The required number of engineers is the smallest integral value that is
        greater than or equal to the specified delta divided by 120.
        <br />
        The delta is calculated ignoring the PTO.
      </Typography>
    </Box>
  );
}
