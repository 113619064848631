import {
  AggregatedCapacity,
  EngineerHandle,
  TeamCapacity,
  TeamCapacityAggregatorType
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import useCapacity from "./useCapacity";
import { useTeamCapacityAggregator } from "./useTeamCapacityAggregator";

export interface CapacityByGroup {
  [groupId: string]: AggregatedCapacity<TeamCapacity>;
}

export function useTeamCapacityByAggregator(
  year: number,
  month: number,
  group: TeamCapacityAggregatorType,
  filter?: { [engineerId: string]: EngineerHandle }
): CapacityByGroup {
  const capacities = useCapacity(year, month);
  const aggregator = useTeamCapacityAggregator(year, month);
  const [response, setResponse] = useState({} as CapacityByGroup);

  useEffect(() => {
    const newResponse = Object.values(capacities.items).reduce(
      (all, capacity) => {
        if (!filter || filter[capacity.engineer.id]) {
          capacity.getTeamUtilizations().forEach((teamCapacity) => {
            const id = aggregator.get(teamCapacity, group);
            all[id] = all[id] || new AggregatedCapacity<TeamCapacity>();
            all[id].add(teamCapacity);
          });
        }
        return all;
      },
      {} as CapacityByGroup
    );
    setResponse(newResponse);
  }, [year, month, group, aggregator, capacities, filter]);

  return response;
}
