import { useTheme } from "@material-ui/core/styles";
import { Chart } from "chart.js";
import { DashboardCard } from "components/DashboardCard";
import { createRef, useEffect, useState } from "react";

export function LineChartCard({
  title,
  height = 300,
  data,
}: {
  title: string;
  height?: number;
  data: any;
}) {
  const chartRef = createRef<HTMLCanvasElement>();
  const [chart, setChart] = useState(null as null | Chart);
  const theme = useTheme();

  useEffect(() => {
    if (chart) {
      return;
    }
    const ref = chartRef?.current as HTMLCanvasElement;
    const newChart = new Chart(ref, {
      type: "line",
      data: {
        datasets: [],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
          },
        },
      },
    });
    setChart(newChart);
  }, []);

  useEffect(() => {
    if (!chart) {
      return;
    }
    chart.data = data;
    chart.update();
  }, [theme, chart, data]);

  return (
    <DashboardCard title={title} height={height}>
      {{ body: <canvas ref={chartRef} /> }}
    </DashboardCard>
  );
}
