import { EM } from "msd-capacity-planning-model";
import {
  EMAction,
  EMsState,
  FETCH_EMS,
  LOAD_EMS,
  PERSIST_EM,
  REMOVE_EM
} from "./EMsTypes";

const initialState: EMsState = {
  items: {},
  synching: true,
};

export default function emsReducer(
  state = initialState,
  action: EMAction
): EMsState {
  switch (action.type) {
    case FETCH_EMS:
      return {
        ...state,
        synching: true,
      };
    case REMOVE_EM:
      return {
        ...state,
        synching: true,
      };
    case PERSIST_EM:
      return {
        ...state,
        synching: true,
      };
    case LOAD_EMS:
      return {
        ...state,
        items: action.items.reduce((all: { [id: string]: EM }, item) => {
          all[item.id] = item;
          return all;
        }, {}),
        synching: false,
      };
    default:
      return state;
  }
}
