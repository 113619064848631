import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Region } from "msd-capacity-planning-model";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import {
  AppState,
  ReportFeedbackAction,
  REPORT_APP_FEEDBACK
} from "../redux/AppTypes";
import { RegionHolidaysForm } from "./RegionHolidaysForm";
import { PERSIST_REGION } from "./RegionTypes";

const schema = yup.object().shape({
  id: yup.string().optional(),
  name: yup.string().required(),
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
    },
  },
}));

export function RegionForm() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const synching = useSelector((state: AppState) => state.regions.synching);
  const items = useSelector((state: AppState) => state.regions.items);

  const params: { [key: string]: string } = useParams();
  const [item, setItem] = useState({} as Region);
  const [loading, setLoading] = useState(true);
  const form = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    const id = params.id;
    let initialItem = items[id];
    if (!id) {
      setLoading(false);
    } else if (initialItem) {
      setLoading(false);
      setItem(initialItem);
    }
  }, [params.id, items]);

  useEffect(() => {
    form.register("id");
    form.setValue("id", item.id);
    form.register("holidays");
    form.setValue("holidays", item.holidays);
  }, [form, item]);

  function onSubmit(data: any): Promise<void> {
    const item: Region = {
      id: data.id,
      code: data.code,
      name: data.name,
      holidays: data.holidays,
      workingHours: data.workingHours || 8,
    };
    const response = dispatch({
      type: PERSIST_REGION,
      item: item,
    }) as any;
    return response
      .then(() => onClose())
      .catch((error: any) => {
        const action: ReportFeedbackAction = {
          type: REPORT_APP_FEEDBACK,
          feedback: error.message || "",
        };

        dispatch(action);
      });
  }

  function onClose(): void {
    if (history.length > 0) {
      history.goBack();
    } else {
      history.push("/administration/regions");
    }
  }

  return (
    <Container maxWidth="md">
      {loading ? (
        <CircularProgress />
      ) : (
        <form className={classes.root} onSubmit={form.handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <Typography variant="h4">Region form</Typography>
          </FormControl>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  name="name"
                  variant="filled"
                  label="Name"
                  autoFocus
                  defaultValue={item.name}
                  error={!!form.errors.name}
                  inputRef={form.register}
                  helperText={form.errors.name?.message}
                  inputProps={{
                    "data-testid": "region-name-input",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  name="code"
                  label="Code"
                  defaultValue={item.code}
                  error={!!form.errors.code}
                  inputRef={form.register}
                  helperText={form.errors.code?.message}
                  inputProps={{
                    "data-testid": "region-code-input",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  name="workingHours"
                  label="Working Hours"
                  defaultValue={item.workingHours || 8}
                  error={!!form.errors.workingHours}
                  inputRef={form.register}
                  helperText={form.errors.workingHours?.message}
                  inputProps={{
                    "data-testid": "region-workingHours-input",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography variant="h6" style={{ marginTop: 16 }}>
                  Holidays
                </Typography>
              </FormControl>
              <FormControl fullWidth>
                <Paper variant="outlined">
                  <RegionHolidaysForm
                    defaultValue={
                      Array.isArray(item.holidays) ? item.holidays : []
                    }
                    onChange={(holidays) => form.setValue("holidays", holidays)}
                  />
                </Paper>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <FormControl margin="normal">
              <Button onClick={onClose} disabled={synching}>
                Discard
              </Button>
            </FormControl>
            <FormControl margin="normal">
              <Button
                disabled={synching}
                variant="contained"
                color="primary"
                type="submit"
                data-testid="save-button-input"
              >
                Save
              </Button>
            </FormControl>
          </Grid>
        </form>
      )}
    </Container>
  );
}
