import { Box, Container, Grid, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { CountCard } from "components/CountCard";
import useCustomersIdsInAggregator from "customers/useCustomerIdsInAggregator";
import useCustomersInAggregator from "customers/useCustomersInAggregator";
import { DashboardBreadcrumbs } from "dashboards/DashboardBreadcrumbs";
import { CustomersBurnedBudgetScatterChartCard } from "dashboards/utilization/BurnedBudgetByCustomerScatterChartCard";
import { CustomersHarvestBudgetTrend } from "dashboards/utilization/HarvestBudgetTrend";
import useEM from "ems/useEM";
import { useEMsByLead } from "ems/useEMsByLead";
import { CustomerAggregatorType } from "msd-capacity-planning-model";
import { useParams } from "react-router-dom";
import { useDashboardPeriod } from "../useDashboardPeriod";
import { EMLeadCustomersTable } from "./EMLeadCustomersTable";
import { EMLeadEMsTable } from "./EMLeadEMsTable";

export function EMLeadDashboardPage() {
  const theme = useTheme();
  const period = useDashboardPeriod();
  const params: { [key: string]: string } = useParams();
  const em = useEM(params.emlead);
  const ems = useEMsByLead();
  const recursiveEMs = useEMsByLead(true);

  const customers = useCustomersInAggregator(
    period.year,
    period.month,
    CustomerAggregatorType.EM_LEAD,
    params.emlead
  );
  const customerIds = useCustomersIdsInAggregator(
    period.year,
    period.month,
    CustomerAggregatorType.EM_LEAD,
    params.emlead
  );

  return (
    <Box>
      <Container maxWidth="xl">
        <DashboardBreadcrumbs />
        <Box
          style={{
            marginTop: theme.spacing(),
            marginBottom: theme.spacing() * 2,
          }}
        >
          <Typography variant="h5">{em?.getDisplayName()}</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <CountCard
              title="Team"
              count={Object.keys(ems[params.emlead] || {}).length}
              path={`/dashboards/emleads/${params.emlead}/ems`}
            />
          </Grid>
          <Grid item xs={4}>
            <CountCard
              title="EMs"
              count={Object.keys(recursiveEMs[params.emlead] || {}).length}
              path={`/dashboards/emleads/${params.emlead}/ems`}
            />
          </Grid>
          <Grid item xs={4}>
            <CountCard
              title="Customers"
              count={Object.keys(customers).length}
              path={`/dashboards/emleads/${params.emlead}/customers`}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomersBurnedBudgetScatterChartCard
              year={period.year}
              month={period.month}
              customerIds={customerIds}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomersHarvestBudgetTrend
              period={period}
              customers={customers}
            />
          </Grid>
        </Grid>
      </Container>
      <EMLeadEMsTable
        year={period.year}
        month={period.month}
        emLeadId={params.emlead || ""}
      />
      <EMLeadCustomersTable
        year={period.year}
        month={period.month}
        emLeadId={params.emlead || ""}
      />
    </Box>
  );
}
