import { Middleware } from "redux";
import { AppState } from "../redux/AppTypes";
import {
  DO_FETCH_COMMENTS,
  FETCH_COMMENTS,
  LOAD_COMMENTS,
  REMOVE_COMMENT,
  PERSIST_COMMENT,
  DoFetchCommentsAction,
  FetchCommentsAction,
  LoadCommentsAction,
  CommentAction,
} from "./CommentTypes";
import service from "./CommentService";

const commentsCache = new Set<string>();

const doFetchMiddleware: Middleware<{}, AppState> = (store) => (next) => async (
  action: DoFetchCommentsAction
) => {
  next(action);
  if (action.type === DO_FETCH_COMMENTS) {
    const items = await service.fetch(action.year, action.month);
    const load: LoadCommentsAction = {
      type: LOAD_COMMENTS,
      year: action.year,
      month: action.month,
      items,
    };
    store.dispatch(load);
  }
};

const fetchMiddleware: Middleware<{}, AppState> = (store) => (next) => async (
  action: FetchCommentsAction
) => {
  // TODO add force reload
  next(action);
  if (action.type === FETCH_COMMENTS) {
    const key = `${action.year}-${action.month}`;
    if (!commentsCache.has(key)) {
      commentsCache.add(key);
      const doFetch: DoFetchCommentsAction = {
        type: DO_FETCH_COMMENTS,
        year: action.year,
        month: action.month,
      };
      store.dispatch(doFetch);
    }
  }
};

const deleteMiddleware: Middleware<{}, AppState> = (store) => (next) => async (
  action: CommentAction
) => {
  const result = next(action);
  if (action.type === REMOVE_COMMENT) {
    await service.delete(action.year, action.month, action.id, action.index);
    store.dispatch({
      type: DO_FETCH_COMMENTS,
      year: action.year,
      month: action.month,
    });
  }
  return result;
};

const persistMiddleware: Middleware<{}, AppState> = (store) => (next) => async (
  action: CommentAction
) => {
  next(action);
  if (action.type === PERSIST_COMMENT) {
    await service.persist(action.year, action.month, action.id, action.item);
    store.dispatch({
      type: DO_FETCH_COMMENTS,
      year: action.year,
      month: action.month,
    });
  }
};

const middlewares = [
  fetchMiddleware,
  doFetchMiddleware,
  deleteMiddleware,
  persistMiddleware,
];
export default middlewares;
