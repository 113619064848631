import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  TextField,
  Typography
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { CustomTextField } from "components/CustomTextField";
import { Administrator, AdministratorRole } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  AppState,
  ReportFeedbackAction,
  REPORT_APP_FEEDBACK
} from "redux/AppTypes";
import { useSynching } from "utils/useSynching";
import * as yup from "yup";
import {
  FETCH_ADMINISTRATORS,
  PERSIST_ADMINISTRATOR
} from "./AdministratorTypes";

const ROLES = [AdministratorRole.JIRA];

const schema = yup.object().shape({
  id: yup.string().required(),
  roles: yup.array().of(yup.string()),
});

export function AdministratorsForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params: { [key: string]: string } = useParams();
  const [loading, setLoading] = useState(true);
  const synching = useSynching();
  const form = useForm({ resolver: yupResolver(schema) });
  const admins = useSelector((state: AppState) => state.administrators.items);
  const [admin, setAdmin] = useState({} as Administrator);

  useEffect(() => {
    const id = params.id;
    let initialItem = admins[id];
    if (!id) {
      setLoading(false);
    }
    if (initialItem) {
      setAdmin(initialItem);
      setLoading(false);
    }
  }, [params.id, admins]);

  function close(): void {
    history.push("/administration/administrators");
  }

  function onSubmit(data: any): void {
    const item = {
      id: (data.id as string || "").toLowerCase(),
      roles: data.roles,
    };
    const response = dispatch({
      type: PERSIST_ADMINISTRATOR,
      item: item,
    }) as any;
    response
      .then(() => close())
      .catch((error: any) => {
        const action: ReportFeedbackAction = {
          type: REPORT_APP_FEEDBACK,
          feedback: error.message || "error",
        };
        dispatch(action);
      });
  }

  useEffect(() => {
    dispatch({ type: FETCH_ADMINISTRATORS });
  }, [dispatch]);

  return (
    <Box>
      <Typography variant="h4">Administrator form</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Box
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <CustomTextField
                name="id"
                label="email"
                form={form}
                disabled={false}
                defaultValue={admin?.id || ""}
              />

              <Controller
                name="roles"
                control={form.control}
                defaultValue={Array.isArray(admin?.roles) ? admin?.roles : []}
                render={(field) => (
                  <Autocomplete
                    multiple
                    value={field.value}
                    onChange={(event, newValue) => {
                      form.setValue("roles", newValue);
                    }}
                    options={ROLES}
                    getOptionLabel={(option) => option}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip label={option} {...getTagProps({ index })} />
                      ))
                    }
                    style={{ marginTop: 8, width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} label="Roles" />
                    )}
                  />
                )}
              />
              <Button
                disabled={synching.any}
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginTop: 16 }}
              >
                Save
              </Button>
            </Box>
          </form>
        </Box>
      )}
    </Box>
  );
}
