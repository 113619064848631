import {
  AUTH_SET_SESSION,
  AUTH_SIGNOUT,
  SetSessionAction,
  SignoutAction
} from "auth/AuthorizationTypes";
import { Middleware } from "redux";
import { AppState } from "../redux/AppTypes";
import websocketService from "./WebSocketService";
import { SetWebSocketAliveAction, SET_ALIVE } from "./WebSocketTypes";

let interval: any = 0;

function stopCheckAlive(): void {
  if (interval) {
    clearInterval(interval);
    interval = 0;
  }
}

const initMiddleware: Middleware<{}, AppState> =
  (store) => (next) => (action: SetSessionAction) => {
    if (action.type === AUTH_SET_SESSION && action.session) {
      stopCheckAlive();
      websocketService.connect().then(() => {
        // updates the websocket app state
        function updateAlive() {
          const alive = websocketService.isAlive();
          if (store.getState().websocket.alive !== alive) {
            const action: SetWebSocketAliveAction = {
              type: SET_ALIVE,
              alive,
            };
            store.dispatch(action);
          }
        }

        interval = setInterval(() => {
          updateAlive();
          if (!websocketService.isAlive()) {
            websocketService
              .reconnect()
              .catch((error) => {
                console.log(error);
              })
              .then(() => {
                updateAlive();
              });
          }
          if (!websocketService.isReconnectable()) {
            stopCheckAlive();
          }
        }, 30000);
      });
    }
    return next(action);
  };

const signOutMiddleware: Middleware<{}, AppState> =
  (store) => (next) => (action: SignoutAction) => {
    if (action.type === AUTH_SIGNOUT) {
      stopCheckAlive();
    }
    return next(action);
  };

const middlewares = [initMiddleware, signOutMiddleware];
export default middlewares;
