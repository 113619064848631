import { Box, Button } from "@material-ui/core";
import { AppState } from "./redux/AppTypes";
import { useDispatch, useSelector } from "react-redux";
import { AUTH_SIGNOUT } from "./auth/AuthorizationTypes";

export function UnauthorizedApp() {
  const user = useSelector((state: AppState) => state.authorization.email);
  const dispatch = useDispatch();

  return (
    <Box>
      {user} is not authorized to use this application
      <Button onClick={() => dispatch({ type: AUTH_SIGNOUT })}>Logout</Button>
    </Box>
  );
}
