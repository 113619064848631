import {
  AggregatedCapacity,
  TeamCapacity,
  TeamCapacityAggregatorType
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useTeamCapacityByAggregator } from "./useTeamCapacityByAggregator";

export default function useTeamCapacityInAggregator(
  year: number,
  month: number,
  type: TeamCapacityAggregatorType,
  id: string
): AggregatedCapacity<TeamCapacity> {
  const aggregated = useTeamCapacityByAggregator(year, month, type);
  const [result, setResult] = useState(new AggregatedCapacity<TeamCapacity>());

  useEffect(() => {
    const newResult = aggregated[id] || new AggregatedCapacity<TeamCapacity>();
    setResult(newResult);
  }, [type, id, aggregated]);

  return result;
}
