import { Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { CustomerDashboard } from "../customer/CustomerDashboard";
import { DashboardBreadcrumbs } from "../DashboardBreadcrumbs";
import { useDashboardPeriod } from "../useDashboardPeriod";

export function EMCustomerDashboardPage() {
  const period = useDashboardPeriod();
  const params: { [key: string]: string } = useParams();
  const customerId = params.customer;

  return (
    <Box>
      <DashboardBreadcrumbs excluded={["customers"]} />
      <CustomerDashboard period={period} customerId={customerId} />
    </Box>
  );
}
