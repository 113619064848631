import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { CustomDatePicker } from "components/CustomDatePicker";
import { CustomTextField } from "components/CustomTextField";
import { RegionSelect } from "components/RegionSelect";
import { DocsDrawer } from "docs/DocsDrawer";
import { Team, TeamType } from "msd-capacity-planning-model";
import useDefaultProductTypes from "producttypes/useDefaultProductTypes";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getJiraURL } from "utils/jiraURLService";
import * as yup from "yup";
import {
  AppState,
  ReportFeedbackAction,
  REPORT_APP_FEEDBACK
} from "../redux/AppTypes";
import { PERSIST_TEAM } from "./TeamsTypes";

const schema = yup.object().shape({
  harvest: yup.string().optional(),
  id: yup.string().optional(),
  jira: yup.string().optional(),
  lead: yup.string().required(),
  manager: yup.string().required(),
  name: yup.string().required(),
  pagerDuty: yup.string().optional(),
  productTypes: yup.array().of(yup.string()),
  teamsEnabled: yup.boolean().required(),
  teamsWebhook: yup.string().optional(),
  type: yup.array().of(yup.string()),
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
    },
  },
}));

export function TeamForm() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const synching = useSelector((state: AppState) => state.teams.synching);
  const productTypes = useDefaultProductTypes();
  const teams = useSelector((state: AppState) => state.teams.items);
  const params: { [key: string]: string } = useParams();
  const [team, setTeam] = useState({} as Team);
  const [loading, setLoading] = useState(true);
  const form = useForm({ resolver: yupResolver(schema) });

  function onSubmit(data: any): void {
    const item: Team = {
      harvest: data.harvest,
      harvestHours: team.harvestHours || {},
      id: data.id,
      jira: data.jira,
      lead: data.lead,
      manager: data.manager,
      name: data.name,
      pagerDuty: data.pagerDuty,
      productTypes: data.productTypes || [],
      offboardingDate: data.offboardingDate
        ? {
          year: data.offboardingDate?.getFullYear(),
          month: data.offboardingDate?.getMonth(),
          day: data.offboardingDate?.getDate(),
        }
        : undefined,
      region: data.region || undefined,
      type: team.type,
      teams: {
        enabled: data.teamsEnabled || false,
        webhook: data.teamsWebhook || "",
      },
    };
    const response = dispatch({
      type: PERSIST_TEAM,
      item,
    }) as any;
    response
      .then((msg: string) => onClose())
      .catch((error: any) => {
        const action: ReportFeedbackAction = {
          type: REPORT_APP_FEEDBACK,
          feedback: error.message || "",
        };
        dispatch(action);
      });
  }

  useEffect(() => {
    const id = params.id;
    let initialTeam = teams[id];
    if (!id) {
      setLoading(false);
    } else if (initialTeam) {
      setLoading(false);
      setTeam(initialTeam);
    }
  }, [params.id, teams]);

  function onClose(): void {
    if (history.length > 0) {
      history.goBack();
    } else {
      history.push("/admninistration/teams");
    }
  }

  return (
    <Container maxWidth="md">
      {loading ? (
        <CircularProgress />
      ) : (
        <form
          className={classes.root}
          onSubmit={form.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <input
            type="hidden"
            name="id"
            value={params.id}
            ref={form.register}
          />
          <FormControl fullWidth>
            <Typography variant="h4">
              Team form <DocsDrawer path="/admin/teams.html" />
            </Typography>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              name="name"
              variant="filled"
              label="Name"
              autoFocus
              defaultValue={team.name}
              error={!!form.errors.name}
              inputRef={form.register}
              helperText={form.errors.name?.message}
              inputProps={{
                form: {
                  autocomplete: "off",
                },
              }}
            />
          </FormControl>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  name="lead"
                  label="Lead"
                  defaultValue={team.lead}
                  error={!!form.errors.lead}
                  inputRef={form.register}
                  helperText={form.errors.lead?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  name="manager"
                  label="Manager"
                  defaultValue={team.manager}
                  error={!!form.errors.manager}
                  inputRef={form.register}
                  helperText={form.errors.manager?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <RegionSelect
                name="region"
                defaultValue={team.region}
                form={form}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="type-label">Cloud</InputLabel>
                <Select
                  name="type"
                  multiple
                  value={team.type || []}
                  onChange={(e) =>
                    setTeam({ ...team, type: e.target.value as TeamType[] })
                  }
                  labelId="type-label"
                  error={!!form.errors.type}
                  inputRef={form.register}
                  input={<Input />}
                  renderValue={(selected) => {
                    const values = Array.isArray(selected) ? selected : [];
                    return values.join(", ");
                  }}
                >
                  {Object.values(TeamType).map((name) => {
                    return (
                      <MenuItem key={name} value={name}>
                        <Checkbox
                          checked={(team.type || []).indexOf(name) > -1}
                        />
                        <ListItemText primary={name} />
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{form.errors.type?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="productTypes"
                control={form.control}
                defaultValue={team.productTypes || []}
                render={(field) => (
                  <FormControl fullWidth>
                    <Autocomplete
                      multiple
                      options={productTypes}
                      value={field.value || ""}
                      onChange={(_, values: string[]) => {
                        form.setValue("productTypes", values);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Product Types"
                        />
                      )}
                    />
                    <FormHelperText>
                      {form.errors.productTypes?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                name="jira"
                label="JIRA Project Category id"
                defaultValue={team.jira || ""}
                form={form}
                disabled={!!team.jira}
                linkBaseUrl={
                  getJiraURL() + "/jira/projects?page=1&selectedCategory="
                }
                helperText="This is used to sync projects to the corresponding team board in Jira. IMPORTANT: DO NOT update with a URL or the team project key"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  label="Harvest team URL"
                  name="harvest"
                  defaultValue={team.harvest}
                  error={!!form.errors.harvest}
                  inputRef={form.register}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  label="Pager duty team URL"
                  name="pagerDuty"
                  defaultValue={team.pagerDuty}
                  error={!!form.errors.pagerDuty}
                  inputRef={form.register}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <CustomDatePicker
                year={team.offboardingDate?.year}
                month={team.offboardingDate?.month}
                day={team.offboardingDate?.day}
                control={form.control}
                record="offboardingDate"
                label="Offboarding date"
              />
            </Grid>
            <Grid item xs={12} style={{ display: "flex" }}>
              <FormControl>
                <FormLabel component="legend">
                  <Typography variant="caption">Teams integration</Typography>
                </FormLabel>
                <Checkbox
                  name="teamsEnabled"
                  defaultChecked={team.teams?.enabled || false}
                  inputRef={form.register}
                  color="primary"
                />
              </FormControl>
              <FormControl style={{ flexGrow: 1 }}>
                <TextField
                  label="Teams webhook url"
                  name="teamsWebhook"
                  defaultValue={team.teams?.webhook || ""}
                  error={!!form.errors.teamsWebhook}
                  inputRef={form.register}
                />
                <FormHelperText>
                  Daily messages will be sent to this incoming webhook to inform
                  the current budget consumption in Harvest
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <FormControl margin="normal">
              <Button onClick={onClose} disabled={synching}>
                Discard
              </Button>
            </FormControl>
            <FormControl margin="normal">
              <Button
                disabled={synching}
                variant="contained"
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </FormControl>
          </Grid>
        </form>
      )}
    </Container>
  );
}
