import { EMHandle } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useEMLeads } from "./useEMLeads";

export function useSortedEMLeads(year: number, month: number): EMHandle[] {
  const active = useEMLeads(year, month);
  const [sorted, setSorted] = useState([] as EMHandle[]);

  useEffect(() => {
    const newSorted = Object.values(active).sort((a, b) => a.compare(b, false));
    setSorted(newSorted);
  }, [active]);

  return sorted;
}
