import { Box } from "@material-ui/core";
import { ReactNode } from "react";
import { useParams } from "react-router-dom";
import { DashboardBreadcrumbs } from "../DashboardBreadcrumbs";
import { useDashboardPeriod } from "../useDashboardPeriod";
import { TeamDashboard } from "./TeamDashboard";

export function TeamDashboardPage({
  children,
}: {
  children?: { breadcrumbs: ReactNode };
}) {
  const period = useDashboardPeriod();
  const params: { [key: string]: string } = useParams();

  return (
    <Box>
      <DashboardBreadcrumbs docs="/dashboards/team.html"/>
      <TeamDashboard teamId={params.team} period={period} />
    </Box>
  );
}
