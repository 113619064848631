import React from "react";
import { Route, Switch } from "react-router-dom";
import { CustomerForm } from "./CustomerForm";
import { CustomersPanel } from "./CustomersPanel";

export function CustomersPage() {
  return (
    <Switch>
      <Route path="/administration/customers/:id" strict={true}>
        <CustomerForm />
      </Route>
      <Route path="/administration/customers/" strict={true}>
        <CustomerForm />
      </Route>
      <Route path="/administration/customers">
        <CustomersPanel />
      </Route>
    </Switch>
  );
}
