import {
  Box,
} from "@material-ui/core";
import {useMemo, Fragment } from "react";
import MUIDataTable from "mui-datatables";
import { integer } from "aws-sdk/clients/cloudfront";

import useEngineersCapacity from "capacity/useEngineersCapacity";
import { EngineerLinkBar } from "dashboards/engineer/EngineerLinkBar";
import { EngineerWarningFlagsIcon } from "engineers/EngineerWarningFlagsIcon";
import { EngineerHandle } from "msd-capacity-planning-model";
import useRegions from "regions/useRegions";
import { useTeams } from "teams/useTeams";

export function EngineersTable({
  year,
  month,
  engineers,
  linkPrefix = "",
  initialRowsPerPage = 10,
  size = "medium",
  showRegion = false,
  showCapacity = false,
}: {
  year: number;
  month: number;
  engineers: { [engineerId: string]: EngineerHandle };
  linkPrefix?: string;
  initialRowsPerPage?: number;
  size?: "medium" | "small";
  showRegion?: boolean;
  showCapacity?: boolean;
}) {
  const teams = useTeams(year, month);
  const regions = useRegions();
  const capacity = useEngineersCapacity(year, month, engineers);
  const engineerNames = useMemo(() => Object.values(engineers).map(engineer => engineer?.getDisplayName()), [engineers]);

  const roleNames = useMemo(() => Array.from(new Set(Object.values(engineers).map(engineer => engineer?.role ? engineer?.role : "no role assigned"))), [engineers]);

  const data = useMemo(() => Object.values(engineers).sort((e1, e2) => e1.compare(e2)).map(e => [e, e, e, e, e, e]), [engineers]);
  //array of array of EngineerHandle objects
  
  const columns: any = [
    {
      name: "Engineer",
      options: {
      filter: true,
      sort: true,
      customBodyRender: (engineer: EngineerHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
        return <Fragment>{engineer?.getDisplayName()} <EngineerWarningFlagsIcon id={engineer.id}/></Fragment>;

      },      
      setCellProps: () => ({ style: { padding: 5 }}),
      setCellHeaderProps: () => ({ style: { padding: 5 }}),
      sortCompare: (order: string) => {
        return (obj1: { data: EngineerHandle }, obj2: { data: EngineerHandle } ) => {
          return obj1.data.compare(obj2.data) * (order === 'asc' ? 1 : -1);
        };
      },
      filterOptions: {
        names: engineerNames,
        logic(name: { getDisplayName: () => any; }, filters: string | any[]) {
          let textString = name?.getDisplayName()

          return !filters.includes(textString);} 
  }}},
  {
    name: "Region",
    options: {
    filter: true,
    setCellProps: () => ({ style: { padding: 5 }}),
    setCellHeaderProps: () => ({ style: { padding: 5 }}),
    customBodyRender: (engineer: EngineerHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
      return regions[engineer.region]?.getDisplayName();

    },      
    sortCompare: (order: string) => {
      return (obj1: { data: EngineerHandle }, obj2: { data: EngineerHandle } ) => {
        return obj1.data.region.localeCompare(obj2.data.region) * (order === 'asc' ? 1 : -1);
      };
    },
    }
  },
  {
    name: "Team",
    options: {
    filter: true,
    setCellProps: () => ({ style: { padding: 5 }}),
    setCellHeaderProps: () => ({ style: { padding: 5 }}),
    customBodyRender: (engineer: EngineerHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
      return engineer?.getTeamIds(year,month).map((teamId) => teams[teamId]?.name).join(",");
    },    
    sortCompare: (order: string) => {
      return (obj1: { data: EngineerHandle }, obj2: { data: EngineerHandle } ) => {
        return obj1.data?.getTeamIds(year,month).map((teamId) => teams[teamId]?.name).join(",").localeCompare(obj2.data?.getTeamIds(year,month).map((teamId) => teams[teamId]?.name).join(",")) * (order === 'asc' ? 1 : -1);

      };
    },
    
    }
  },
  {
    name: "Role",
    options: {
    filter: true,
    filterOptions:{
      names: roleNames,
      logic(role: any, filters: any) {
        let textString = role;      
        return !filters.includes(textString);} 
    },
    setCellProps: () => ({ style: { padding: 5 }}),
    setCellHeaderProps: () => ({ style: { padding: 5 }}),
    customBodyRender: (engineer: EngineerHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
      return engineer.role ? engineer.role : "no role assigned";
    }, 
    sortCompare: (order: string) => {
      return (obj1: { data: EngineerHandle }, obj2: { data: EngineerHandle } ) => {
        let role1 = obj1.data.role ?  obj1.data.role  : ""
        let role2 = obj2.data.role ?  obj2.data.role  : ""
        return role1.localeCompare(role2) * (order === 'asc' ? 1 : -1);
      };
    },
    }
  },
  {
    name: "Capacity",
    options: {
      filter: true,
      setCellProps: () => ({ style: { padding: 5 }}),
      setCellHeaderProps: () => ({ style: { padding: 5 }}),
      customBodyRender: (engineer: EngineerHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
        return Math.round(capacity.items[engineer.id]?.getUtilizationHours() || 0)?.toLocaleString();

      },      
      sortCompare: (order: string) => {
        return (obj1: { data: string|any; }, obj2: { data: string|any; } ) => {
          let val1 = Math.round(capacity.items[obj1.data.id]?.getUtilizationHours() || 0)
          let val2 = Math.round(capacity.items[obj2.data.id]?.getUtilizationHours() || 0)
          return (val1 - val2) * (order === 'asc' ? 1 : -1);
        };
    }
  }},
  {
    name: "",              // dummy column to house icon
    options: {
      filter: false,
      sort: false,
      download: false,
      viewColumns: false,
      customBodyRender: (engineer: EngineerHandle, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {
        return <EngineerLinkBar year={year} month={month} engineer={engineer} />
      },     
      setCellProps: () => ({ style: { padding: 5 }}),
      setCellHeaderProps: () => ({ style: { padding: 5 }})
      
      }
  }
  ]


  return (
    <Box>
       <MUIDataTable
          title={'Engineers'}
          columns={columns}
          data={data}
          options={
            {
            filterType: 'multiselect',

            onDownload: (buildHead, buildBody, columns, data) => {
               return "\uFEFF" + buildHead(columns) + buildBody(
                data.map((it: { index: integer; data: string | any[]; }) => 
                ({ index: it.index, 
                  data: [
                    it.data[0]?.getDisplayName(),
                    regions[it.data[0].region]?.getDisplayName(),
                    it.data[0]?.getTeamIds(year,month).map((teamId: string | number) => teams[teamId]?.name).join(","),
                    it.data[0].role ? it.data[0].role : "no role assigned",
                    Math.round(capacity.items[it.data[0].id]?.getUtilizationHours() || 0)?.toLocaleString()

                  ] 
                })
                )
                );
            }, 
            "draggableColumns":{"enabled":true},
            "pagination":false,
            "selectableRows":"none",
            textLabels: {
              "body": {
                columnHeaderTooltip: column => {
                  switch(column.label){
                    default:
                      return "";
                  }
                }
              }
              
            }
          }
          }
            /> 
    </Box>
  );
}