import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  useTheme
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import WarningIcon from "@material-ui/icons/Warning";
import { EMHandle } from "msd-capacity-planning-model";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../redux/AppTypes";
import { FETCH_EMS, REMOVE_EM } from "./EMsTypes";
import { useAllEMs } from "./useAllEMs";

export function EMsPanel() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const ems = useAllEMs();
  const regions = useSelector((state: AppState) => state.regions.items);
  const [offboarded, setOffboarded] = useState(false);
  const [filter, setFilter] = useState("");
  const [filteredItems, setFilteredItems] = useState([] as EMHandle[]);

  useEffect(() => {
    const items = Object.values(ems);
    items.sort((a, b) => a.compare(b));
    let newFilteredItems = items;
    if (filter) {
      newFilteredItems = newFilteredItems.filter((item) => {
        const str = `${Array.from(item.getEmails()).join(
          " "
        )} ${item.getDisplayName()}`;
        return str.toLowerCase().indexOf(filter.toLowerCase()) > -1;
      });
    }
    if (!offboarded) {
      newFilteredItems = newFilteredItems.filter(
        (item) => item.isOffboarded() === false
      );
    }

    setFilteredItems(newFilteredItems);
  }, [ems, filter, offboarded]);

  return (
    <Box>
      <Grid>
        <Tooltip title="Add">
          <Button
            onClick={() => history.push("/administration/ems/")}
            color="primary"
            variant="contained"
          >
            New engagement manager
          </Button>
        </Tooltip>
        <Tooltip title="Refresh">
          <IconButton onClick={() => dispatch({ type: FETCH_EMS })}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              value={offboarded}
              onChange={() => setOffboarded(!offboarded)}
            />
          }
          label="Offboarded"
        />

        <Tooltip title="Search">
          <TextField
            onChange={(e) => setFilter(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {" "}
                  <SearchIcon />{" "}
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
      </Grid>

      <Grid container>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Lead</TableCell>
                <TableCell>Region</TableCell>
                <TableCell align="right" style={{ maxWidth: 100 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems.map((item, index) => {
                const offboarded = item.isOffboarded();
                const flags = Array.from(item.getWarningFlags());
                return (
                  <TableRow key={item.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={
                        offboarded ? { textDecorationLine: "line-through" } : {}
                      }
                    >
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        {index + 1}. {item.getDisplayName()}
                        {flags.length > 0 ? (
                          <Tooltip
                            title={
                              <ul style={{ paddingLeft: 0 }}>
                                {flags.map((warning, index) => (
                                  <li key={`item-${index}`}>{warning}</li>
                                ))}
                              </ul>
                            }
                          >
                            <WarningIcon
                              color="secondary"
                              style={{ marginLeft: 8 }}
                            />
                          </Tooltip>
                        ) : null}
                        {item.isLead() ? (
                          <Chip
                            label="Lead"
                            style={{ marginLeft: theme.spacing() }}
                          />
                        ) : null}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {ems[item.lead]?.getDisplayName() || ""}
                    </TableCell>
                    <TableCell>{regions[item.region]?.name}</TableCell>
                    <TableCell align="right">
                      <Tooltip title="Dashboard">
                        <IconButton disabled
                          onClick={() =>
                            history.push(`/dashboards/engineers/${item.id}`)
                          }
                        >
                          <DashboardIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() =>
                            history.push(`/administration/ems/${item.id}`)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() =>
                            dispatch({ type: REMOVE_EM, id: item.id })
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
}
