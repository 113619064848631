import { HarvestUserHandle } from "msd-capacity-planning-model";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/AppTypes";
import { createSelector } from "reselect";
import { FETCH_HARVEST_USERS } from "./HarvestTypes";

const harvestUsersSelector = (state: AppState) => state.harvest.users.items;

export const useHarvestUsersSelector = createSelector(
  harvestUsersSelector,
  (items) => {
    const values = Object.values(items) || [];
    return values.reduce((map, e) => {
      map[e.id] = new HarvestUserHandle(e);
      return map;
    }, {} as { [id: string]: HarvestUserHandle });
  }
);

export function useHarvestUsers(): { [id: string]: HarvestUserHandle } {
  const dispatch = useDispatch();
  const state = useSelector((state: AppState) => state);

  useEffect(() => {
    dispatch({ type: FETCH_HARVEST_USERS });
  }, [dispatch]);

  return useHarvestUsersSelector(state);
}
