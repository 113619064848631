import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from "@material-ui/core";
import { useManagers } from "managers/useManagers";
import React from "react";
import { UseFormMethods } from "react-hook-form";

export function ManagerSelect({
  name,
  defaultValue,
  form,
  onChange,
}: {
  name: string;
  defaultValue?: string;
  form: UseFormMethods<Record<string, any>>;
  onChange?: (value: string) => void;
}) {
  const all = useManagers();

  return (
    <FormControl fullWidth>
      <InputLabel id={`${name}-label`}>Manager</InputLabel>
      <Select
        labelId={`${name}-label`}
        name={name}
        defaultValue={defaultValue || ""}
        onChange={(e) => {
          const newManager = e.target.value as string;
          form.setValue(name, newManager);
          onChange(newManager);
        }}
        error={!!form.errors[name]}
        input={<Input />}
      >
        <MenuItem value="">
          <ListItemText primary="None" />
        </MenuItem>
        {all.map((manager, index) => (
          <MenuItem key={`${index}`} value={manager}>
            <ListItemText primary={`${manager}`} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{form.errors[name]?.message}</FormHelperText>
    </FormControl>
  );
}
