import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DashboardPeriod } from "./DashboardPeriod";

function getPeriodFromLocation(
  locationSearch: string | undefined
): DashboardPeriod {
  const now = new Date();
  const search = new URLSearchParams(locationSearch || "");
  const year = parseInt(search.get("year") || "") || now.getFullYear();
  const newMonth = parseInt(search.get("month") || "") - 1;
  const month = isNaN(newMonth) ? now.getMonth() : newMonth;
  return { year, month };
}

export function useDashboardPeriod(delta: number = 0): DashboardPeriod {
  const history = useHistory();
  const now = new Date();
  const [period, setPeriod] = useState({
    year: now.getFullYear(),
    month: now.getMonth() + delta,
  });

  useEffect(() => {
    const newPeriod = getPeriodFromLocation(history?.location?.search);
    setPeriod({ year: newPeriod.year, month: newPeriod.month + delta });
  }, [delta, history?.location?.search]);

  return period;
}
