import { Box } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import { DashboardBreadcrumbs } from "../DashboardBreadcrumbs";
import { useDashboardPeriod } from "../useDashboardPeriod";
import { TeamCustomersDashboard } from "./TeamCustomersDashboard";

export function TeamCustomersDashboardPage() {
  const period = useDashboardPeriod();
  const params: { [key: string]: string } = useParams();
  const teamId = params.team;
  return (
    <Box>
      <DashboardBreadcrumbs />
      <TeamCustomersDashboard
        year={period.year}
        month={period.month}
        teamId={teamId}
      />
    </Box>
  );
}
