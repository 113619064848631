import { TeamHandle } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { TeamAggregatorType } from "./TeamAggregator";
import { useTeamsByAggregator } from "./useTeamsByAggregator";

export interface TeamInAggregator {
  [teamId: string]: TeamHandle;
}

export default function useTeamsInAggregator(
  type: TeamAggregatorType,
  id: string
): TeamInAggregator {
  const teamsByAggregator = useTeamsByAggregator(type);
  const [teams, setTeams] = useState({} as TeamInAggregator);

  useEffect(() => {
    const newTeams = (teamsByAggregator[id] || []).reduce((all, team) => {
      all[team.id] = team;
      return all;
    }, {} as TeamInAggregator);
    setTeams(newTeams);
  }, [type, id, teamsByAggregator]);

  return teams;
}
