import DateFnsUtils from "@date-io/date-fns";
import { dateReturn } from "utils/dateReturn";
import {
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell as DefaultTableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  withStyles
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  KeyboardDatePicker, MuiPickersUtilsProvider
} from "@material-ui/pickers";
import "date-fns";
import { RegionHoliday } from "msd-capacity-planning-model";
import React, { useState } from "react";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(DefaultTableCell);

export function RegionHolidaysForm(props: {
  defaultValue: RegionHoliday[];
  onChange: (holidays: RegionHoliday[]) => void;
}) {
  const [items, setItems] = useState(props.defaultValue);
  const onChange = props.onChange;

  function addItem(): void {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);
    date.setDate(1);
    const newItem: RegionHoliday = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
      name: "",
    };
    const newItems = JSON.parse(JSON.stringify(items));
    newItems.push(newItem);
    setItems(newItems);
    onChange(newItems);
  }

  function updateItem(index: number, item: RegionHoliday): void {
    const newItems = JSON.parse(JSON.stringify(items));
    newItems[index] = item;
    setItems(newItems);
    onChange(newItems);
  }

  function deleteItem(index: number): void {
    const newDemand = JSON.parse(JSON.stringify(items));
    newDemand.splice(index, 1);
    setItems(newDemand);
    onChange(newDemand);
  }

  return (
    <Container>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="right">
                <Tooltip title="New item">
                  <IconButton onClick={addItem}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={`item-${index}`}>
                <TableCell>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      format="MM/dd/yyyy"
                      value={
                        item.year
                          ? new Date(
                              item.year || 0,
                              item.month || 0,
                              item.day || 0
                            )
                          : null
                      }
                      onChange={(newDate) => {
                        const newItem: RegionHoliday = JSON.parse(
                          JSON.stringify(item)
                        );
                        newItem.year = dateReturn(newDate, 'year')
                        newItem.month = dateReturn(newDate, 'month')
                        newItem.day = dateReturn(newDate, 'day')
                        newItem.name = "";
                        updateItem(index, newItem);
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </TableCell>
                <TableCell>
                  <TextField
                    value={item.name || ""}
                    onChange={(e) => {
                      item.name = `${e.target.value}`;
                      updateItem(index, item);
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => {
                        deleteItem(index);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
