import { AggregatedBudget, CustomerHandle } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useBudgets } from "./useBudgets";

export default function useBudget(
  year: number,
  month: number,
  customers?: { [id: string]: CustomerHandle }
): AggregatedBudget {
  const [months, setMonths] = useState([{ year, month }]);
  const budget = useBudgets(months);
  const [filtered, setFiltered] = useState(new AggregatedBudget());

  useEffect(() => {
    setMonths([{ year, month }]);
  }, [year, month]);

  useEffect(() => {
    const newBudget = budget.length > 0 ? budget[0] : new AggregatedBudget();
    if (customers) {
      const newFiltered = newBudget.filter(
        (customerId) => !!customers[customerId]
      );
      setFiltered(newFiltered);
    } else {
      setFiltered(newBudget);
    }
  }, [customers, budget]);

  return filtered;
}
