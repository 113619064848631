import { Box } from "@material-ui/core";
import { useBurnedBudgetInAggregator } from "customers/useBurnedBudgetInAggregator";
import { CustomerAggregatorType } from "msd-capacity-planning-model";
import { ReactNode, useEffect, useState } from "react";
import { LabeledScatterDataPoint } from "../../components/LabeledScatterDataPoint";
import { ScatterChartCard } from "../../components/ScatterChartCard";
import { AggregatedBurnedBudget } from "../../customers/BurnedBudgetTypes";
import useAllCustomers from "../../customers/useAllCustomers";
import { useBurnedBudget } from "../../customers/useBurnedBudget";
import { useTeams } from "../../teams/useTeams";
import getCompletedMonthRatio from "../../utils/getCompletedMonthRatio";

export function BurnedBudgetScatterChartCard({
  year,
  month,
  burnedBudget,
  title,
  linkPrefix = "",
  chartHeight = 300,
}: {
  year: number;
  month: number;
  burnedBudget: AggregatedBurnedBudget;
  title?: string;
  linkPrefix?: string;
  chartHeight?: number;
}) {
  const teams = useTeams(year, month);
  const customers = useAllCustomers();

  const [coordinates, setCoordinates] = useState(
    [] as LabeledScatterDataPoint[]
  );
  const [captions, setCaptions] = useState(undefined as ReactNode | undefined);
  const [xAnnotation, setXAnnotation] = useState(0);

  useEffect(() => {
    const newXAnnotation = getCompletedMonthRatio(year, month) * 100;
    setXAnnotation(newXAnnotation);
  }, [year, month]);

  useEffect(() => {
    const selectedCustomers = Object.values(burnedBudget.items)
      .filter((b) => b.total > 0)
      .map((b) => customers[b.customerId]);
    const newCaptions = [] as ReactNode[];

    if (newCaptions.length > 0) {
      setCaptions(<Box>{newCaptions}</Box>);
    }

    const newCoordinates = selectedCustomers
      .map((customer) => {
        const x = burnedBudget.get(customer.id).total;
        const y = (burnedBudget.get(customer.id).getConsumedBudget() / x) * 100;
        const team = teams[customer.getTeam(year, month)]?.name || "";
        return {
          label: `${team}:  ${customer.name}`,
          link: linkPrefix ? `${linkPrefix}/${customer.id}` : undefined,
          x,
          y,
        };
      })
      .filter((coordinate) => coordinate.x > 0);
    setCoordinates(newCoordinates);
  }, [year, month, linkPrefix, customers, teams, burnedBudget]);

  return (
    <ScatterChartCard
      dataPoints={coordinates}
      title={title || "Burned budget"}
      xScaleTitle="Budget (hours)"
      yScaleTitle="Consumed (%)"
      tooltipSuffix="%"
      xAnnotation={xAnnotation}
      chartHeight={chartHeight}
    >
      {{ footer: captions }}
    </ScatterChartCard>
  );
}

export function AllCustomersBurnedBudgetScatterChartCard({
  year,
  month,
  linkPrefix,
}: {
  year: number;
  month: number;
  linkPrefix?: string;
}) {
  const burnedBudgets = useBurnedBudget(year, month);
  return (
    <BurnedBudgetScatterChartCard
      year={year}
      month={month}
      burnedBudget={burnedBudgets}
      linkPrefix={linkPrefix}
    />
  );
}

export function CustomersBurnedBudgetScatterChartCard({
  year,
  month,
  customerIds,
  linkPrefix,
  title,
}: {
  year: number;
  month: number;
  customerIds: string[];
  linkPrefix?: string;
  title?: string;
}) {
  const burnedBudget = useBurnedBudgetInAggregator(
    year,
    month,
    CustomerAggregatorType.CUSTOMER,
    customerIds
  );
  return (
    <BurnedBudgetScatterChartCard
      year={year}
      month={month}
      burnedBudget={burnedBudget}
      linkPrefix={linkPrefix}
      title={title}
    />
  );
}
