import { Box } from "@material-ui/core";
import { DashboardBreadcrumbs } from "dashboards/DashboardBreadcrumbs";
import { EngineerCapacityTable } from "dashboards/engineer/EngineerCapacityTable";
import { useDashboardPeriod } from "dashboards/useDashboardPeriod";
import useEngineersInAggregator from "engineers/useEngineersInAggregator";
import { EngineerAggregatorType } from "msd-capacity-planning-model";
import { useParams } from "react-router-dom";

export function SkillEngineersDashboardPage({ baseUrl }: { baseUrl: string }) {
  const { year, month } = useDashboardPeriod();
  const params: { [key: string]: string } = useParams();
  const engineers = useEngineersInAggregator(
    year,
    month,
    EngineerAggregatorType.SKILL,
    params.skill || ""
  );
  return (
    <Box>
      <DashboardBreadcrumbs />
      <EngineerCapacityTable
        year={year}
        month={month}
        engineers={engineers}
        actualHours={false}
      />
    </Box>
  );
}
