import { Region } from "msd-capacity-planning-model";
import env from "../app/Environment";
import fetchService from "../utils/FetchService";

export interface RegionService {
  fetch(): Promise<Region[]>;
  delete(id: string): Promise<Region[]>;
  persist(item: Region): Promise<Region[]>;
}

class HTTPRegionService implements RegionService {
  constructor(private endpoint: string) {}

  async fetch(): Promise<Region[]> {
    return fetchService
      .fetch(`${this.endpoint}/regions`, {})
      .then((r) => r.json())
      .catch(() => []);
  }

  async delete(id: string): Promise<Region[]> {
    return fetchService
      .fetch(`${this.endpoint}/regions/${id}`, {
        method: "DELETE",
      })
      .then(() => this.fetch());
  }

  async persist(item: Region): Promise<Region[]> {
    return fetchService
      .fetch(`${this.endpoint}/regions`, {
        method: "POST",
        body: JSON.stringify(item),
      })
      .then(() => this.fetch());
  }
}

const regionService: RegionService = new HTTPRegionService(env.endpoint);

export default regionService;
