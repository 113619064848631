import { Box } from "@material-ui/core";
import useCustomersInAggregator from "customers/useCustomersInAggregator";
import { SkillMatchingTable } from "dashboards/skills/SkillMatchingTable";
import useEngineersInAggregator from "engineers/useEngineersInAggregator";
import {
  CustomerAggregatorType,
  EngineerAggregatorType
} from "msd-capacity-planning-model";
import { useParams } from "react-router-dom";
import { DashboardBreadcrumbs } from "../DashboardBreadcrumbs";
import { useDashboardPeriod } from "../useDashboardPeriod";

export function TeamSkillsDashboardPage() {
  const period = useDashboardPeriod();
  const params: { [key: string]: string } = useParams();
  const customers = useCustomersInAggregator(
    period.year,
    period.month,
    CustomerAggregatorType.TEAM,
    params.team
  );
  const engineers = useEngineersInAggregator(
    period.year,
    period.month,
    EngineerAggregatorType.TEAM,
    params.team
  );

  return (
    <Box>
      <DashboardBreadcrumbs />
      <SkillMatchingTable
        year={period.year}
        month={period.month}
        customers={customers}
        engineers={engineers}
        link={false}
      />
    </Box>
  );
}
