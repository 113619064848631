import { InitAppAction, INIT_APP } from "../redux/AppTypes";
import {
  AuthorizationState,
  AUTH_SET_SESSION,
  AUTH_SIGNOUT,
  AuthorizationAction,
  AUTH_SET_MYSELF,
} from "./AuthorizationTypes";

const initialState = {
  synching: true,
  email: "",
  idToken: "",
  refreshToken: "",
  accessToken: "",
  myself: { roles: [] },
} as AuthorizationState;

export default function authorizationReducer(
  state = initialState,
  action: AuthorizationAction | InitAppAction
): AuthorizationState {
  switch (action.type) {
    case AUTH_SET_SESSION: {
      const newState = {
        ...state,
        accessToken: "",
        idToken: "",
        refreshToken: "",
        email: "",
      };
      const session = action.session;
      if (session) {
        newState.accessToken = session.getAccessToken().getJwtToken();
        newState.idToken = session.getIdToken().getJwtToken();
        newState.refreshToken = session.getRefreshToken().getToken();
        newState.email = session.getIdToken().decodePayload().email;
      }
      return newState;
    }
    case AUTH_SET_MYSELF: {
      return {
        ...state,
        myself: action.myself,
      };
    }
    case AUTH_SIGNOUT: {
      const newState = {
        ...state,
        accessToken: "",
        idToken: "",
        refreshToken: "",
        email: "",
      };
      return newState;
    }
    case INIT_APP: {
      return {
        ...state,
        synching: false,
      };
    }
    default:
      return state;
  }
}
