import { RegionHandle } from "msd-capacity-planning-model";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AppState } from "../redux/AppTypes";

const NO_REGION = new RegionHandle({
  id: "",
  name: "",
  holidays: [],
});

const regionsSelector = (state: AppState) => state.regions.items;

export const useRegionsSelector = createSelector(regionsSelector, (items) => {
  const values = Object.values(items) || [];
  return values.reduce(
    (map, e) => {
      map[e.id] = new RegionHandle(e);
      return map;
    },
    { [NO_REGION.id]: NO_REGION } as { [id: string]: RegionHandle }
  );
});

export default function useRegions(): { [regionId: string]: RegionHandle } {
  const state = useSelector((state: AppState) => state);
  return useRegionsSelector(state);
}
