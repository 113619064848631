import {
  Box,
  Button,
  Chip,
  FormControl,
  TextField,
  Typography
} from "@material-ui/core";
import { useState } from "react";
import { Controller, UseFormMethods } from "react-hook-form";

export function EmailInput({
  form,
  name,
  defaultValue = [],
}: {
  form: UseFormMethods<Record<string, any>>;
  name: string;
  defaultValue?: string[];
}) {
  const [newEmail, setNewEmail] = useState("");

  function add(current?: string[]): void {
    if (newEmail && typeof newEmail === "string") {
      const newEmails = Array.from(
        new Set([...(current || []), newEmail.toLocaleLowerCase().trim()])
      );
      form.control.setValue(name, newEmails);
      setNewEmail("");
    }
  }

  return (
    <Controller
      name={name}
      control={form.control}
      defaultValue={defaultValue || []}
      render={(field) => (
        <FormControl fullWidth>
          <Typography variant="caption" style={{ color: "#0000008a" }}>
            Emails
          </Typography>
          <Box>
            {(field.value || []).map((email: string, idx: number) => (
              <Chip
                key={`email${idx}`}
                label={email || ""}
                onDelete={() => {
                  const newEmails = new Set<string>(field.value || []);
                  newEmails.delete(email);
                  form.control.setValue(name, Array.from(newEmails));
                }}
                style={{ marginRight: 8, verticalAlign: "bottom" }}
              />
            ))}

            <TextField
              label="New email"
              name="newEmail"
              type="email"
              autoComplete="off"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              onKeyDown={(e) => {
                if (e?.key === "Enter" && newEmail) {
                  add(field.value);
                  e.preventDefault();
                }
              }}
            />
            <Button
              onClick={() => add(field.value)}
              style={{ verticalAlign: "bottom" }}
            >
              Add
            </Button>
          </Box>
        </FormControl>
      )}
    ></Controller>
  );
}
