import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from "@material-ui/core";
import { Region } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { Controller, UseFormMethods } from "react-hook-form";
import useSortedRegions from "regions/useSortedRegions";

export function RegionSelect({
  name,
  defaultValue,
  form,
  onChange,
}: {
  name: string;
  defaultValue?: string;
  form: UseFormMethods<Record<string, any>>;
  onChange?: (value: string) => void;
}) {
  const sortedRegions = useSortedRegions();
  const [regions, setRegions] = useState({} as { [regionId: string]: Region });

  const [items, setItems] = useState([
    { v: "", l: "None" },
    { v: defaultValue, l: "" },
  ] as { v: string; l: string }[]);

  useEffect(() => {
    const newRegions = sortedRegions.reduce((map, region) => {
      map[region.id] = region;
      return map;
    }, {} as { [regionId: string]: Region });
    setRegions(newRegions);
    const newItems = [{ v: "", l: "None" }];
    if (defaultValue && !newRegions[defaultValue]) {
      newItems.push({ v: defaultValue, l: "" });
    }
    sortedRegions.forEach((region) => {
      newItems.push({ v: region.id, l: region.name });
    });
    setItems(newItems);
  }, [defaultValue, sortedRegions]);

  return (
    <Controller
      name={name}
      control={form.control}
      defaultValue={defaultValue || ""}
      render={(field) => (
        <FormControl fullWidth margin="normal">
          <InputLabel id="region-label">Region</InputLabel>
          <Select
            labelId="region-label"
            name={name}
            defaultValue={defaultValue}
            value={field.value}
            onChange={(e) => {
              const newValue = (e.target.value as string) || "";
              form.control.setValue(name, newValue);
              if (onChange) {
                onChange(newValue);
              }
            }}
            error={!!form.errors.region}
            input={<Input />}
            renderValue={(value) => regions[`${value}`]?.name || ""}
          >
            {items.map((item, index) => (
              <MenuItem key={`${item.v}-${index}`} value={item.v}>
                <ListItemText primary={item.l} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{form.errors.region?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
