import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from "@material-ui/core";
import { EngineerHandle } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { Controller, UseFormMethods } from "react-hook-form";

export function EngineerSelect({
  name,
  defaultValue,
  form,
  engineers,
  label = "Engineer",
}: {
  name: string;
  defaultValue?: string;
  form: UseFormMethods<Record<string, any>>;
  engineers: { [id: string]: EngineerHandle };
  label?: string;
}) {
  const [items, setItems] = useState([
    { v: "", l: "Undefined" },
    { v: defaultValue, l: "" },
  ] as { v: string; l: string }[]);

  const [sorted, setSorted] = useState([] as EngineerHandle[]);

  useEffect(() => {
    const newSorted = Object.values(engineers).sort((a, b) => a.compare(b));
    setSorted(newSorted);
  }, [engineers]);

  useEffect(() => {
    const newItems = [{ v: "", l: "Undefined" }];
    if (defaultValue && !engineers[defaultValue]) {
      newItems.push({ v: defaultValue, l: "" });
    }
    sorted.forEach((e) => {
      newItems.push({ v: e.id, l: e.getDisplayName() });
    });
    setItems(newItems);
  }, [defaultValue, engineers, sorted]);

  return (
    <Controller
      name={name}
      control={form.control}
      defaultValue={defaultValue || ""}
      render={(field) => (
        <FormControl fullWidth margin="normal">
          <InputLabel id={`select-${name}`}>{label}</InputLabel>
          <Select
            labelId={`select-${name}`}
            name={name}
            defaultValue={defaultValue}
            value={field.value}
            onChange={(e) => form.control.setValue(name, e.target.value || "")}
            error={!!form.errors[name]}
            input={<Input />}
            renderValue={(value) =>
              engineers[`${value}`]?.getDisplayName() || ""
            }
          >
            {items.map((item, index) => (
              <MenuItem key={`${item.v}-${index}`} value={item.v}>
                <ListItemText primary={item.l} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{form.errors[name]?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
