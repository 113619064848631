import useAllCustomers from "customers/useAllCustomers";
import { DashboardPeriod } from "dashboards/DashboardPeriod";
import { useAllEngineers } from "engineers/useAllEngineers";
import { TeamSnapshot } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import useRegions from "regions/useRegions";
import { useAllTeams } from "./useAllTeams";

function useTeamSnapshots(periods: DashboardPeriod[]): {
  [date: string]: { [teamId: string]: TeamSnapshot };
} {
  const teams = useAllTeams();
  const engineers = useAllEngineers();
  const customers = useAllCustomers();
  const regions = useRegions();
  const [snapshots, setSnapshots] = useState(
    {} as { [date: string]: { [teamId: string]: TeamSnapshot } }
  );

  useEffect(() => {
    const newSnapshots = periods.reduce((map, period) => {
      const date = new Date(period.year, period.month);
      map[date.toISOString().substring(0, 7)] = Object.values(
        teams || []
      ).reduce((teamMap, team) => {
        teamMap[team.id] = new TeamSnapshot(
          date.getFullYear(),
          date.getMonth(),
          team,
          Object.values(engineers),
          Object.values(customers),
          regions
        );
        return teamMap;
      }, {} as { [teamId: string]: TeamSnapshot });
      return map;
    }, {} as { [date: string]: { [teamId: string]: TeamSnapshot } });
    setSnapshots(newSnapshots);
  }, [periods, regions, teams, engineers, customers]);

  return snapshots;
}

export default useTeamSnapshots;
