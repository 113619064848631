import { useTeamCapacityByAggregator } from "capacity/useTeamCapacityByAggregator";
import { TeamCapacityAggregatorType } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { AggregatedSkills } from "./AggregatedSkills";

export class AggregatedSkillsByTeam {
  items: { [teamId: string]: AggregatedSkills } = {};
  get(teamId: string) {
    return this.items[teamId] || {};
  }
}

export function useSkillsByTeam(
  year: number,
  month: number
): AggregatedSkillsByTeam {
  const [skills, setSkills] = useState(new AggregatedSkillsByTeam());
  const capacities = useTeamCapacityByAggregator(
    year,
    month,
    TeamCapacityAggregatorType.TEAM
  );

  useEffect(() => {
    const newSkills = Object.keys(capacities).reduce((all, teamId) => {
      const aggregatedSkills = capacities[teamId]
        .getItems()
        .reduce((skills, teamCapacity) => {
          teamCapacity.engineer.skills.forEach((skill) => {
            skills[skill] = skills[skill] || 0;
            skills[skill]++;
          });
          return skills;
        }, {} as AggregatedSkills);
      all.items[teamId] = aggregatedSkills;
      return all;
    }, new AggregatedSkillsByTeam());
    setSkills(newSkills);
  }, [year, month, capacities]);

  return skills;
}
