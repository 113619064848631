import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../redux/AppTypes";

export default function useSkills() {
  const customers = useSelector((state: AppState) => state.customers.items);
  const engineers = useSelector((state: AppState) => state.engineers.items);
  const [skills, setSkills] = useState([] as string[]);

  useEffect(() => {
    let skills = new Set<string>();
    skills = Object.values(customers).reduce((all, customer) => {
      (customer.skills || []).forEach((skill) => all.add(skill));
      return all;
    }, skills);
    skills = Object.values(engineers).reduce((all, engineer) => {
      (engineer.skills || []).forEach((skill) => all.add(skill));
      return all;
    }, skills);
    const sortedSkills = Array.from(skills);
    sortedSkills.sort();
    setSkills(Array.from(sortedSkills));
  }, [customers, engineers]);

  return skills;
}
