import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Drawer,
  IconButton,
  Input,
  ListItemText,
  MenuItem,
  Select,
  TableBody,
  TableCell as DefaultTableCell,
  TableRow,
  TextField,
  Tooltip,
  withStyles
} from "@material-ui/core";
import AssistantIcon from "@material-ui/icons/Assistant";
import DeleteIcon from "@material-ui/icons/Delete";
import WarningIcon from "@material-ui/icons/Warning";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import "date-fns";
import {
  Budget,
  BudgetsHandle,
  ContractHourType
} from "msd-capacity-planning-model";
import React, { useEffect, useState } from "react";
import useSortedTeams  from "../teams/useSortedTeams";
import { useAllTeams } from "../teams/useAllTeams";
import { CustomerAllocationAssistant } from "./CustomerAllocationAssistant";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(DefaultTableCell);

export function CustomerBudgetForm(props: {
  currentValue: Budget[];
  contractHourTypes: ContractHourType[];
  skills: string[];
  onChange: (budget: Budget[]) => void;
}) {
  const now = new Date();
  const frozenBudgetThreshold = new Date(now.getFullYear(), now.getMonth());
  const sortedTeams = useSortedTeams(now.getFullYear(), now.getMonth());
  const teams = useAllTeams();
  const [demand, setDemand] = useState(props.currentValue);
  const [historyDeleted, setHistoryDeleted] = useState(false);
  const onChange = props.onChange;
  const [onboardingAssistant, setOnboardingAssistant] = useState(false);
  const [hoursTypes, setHoursTypes] = useState(
    new Set(props.contractHourTypes)
  );

  useEffect(() => {
    setHoursTypes(new Set(props.contractHourTypes));
  }, [props.contractHourTypes]);

  function setNewDemand(newDemand: Budget[]): void {
    let handle = new BudgetsHandle(newDemand, Array.from(hoursTypes));
    handle = handle.getSorted();
    setDemand(handle.budgets);
    onChange(handle.budgets);
  }

  function newBudgetDate(): Date {
    const handle = new BudgetsHandle(demand, Array.from(hoursTypes));
    return handle.getNewBudgetDate();
  }

  function addDemand(): void {
    const defaultDate = newBudgetDate();
    const newValue: Budget = {
      year: defaultDate.getFullYear(),
      month: defaultDate.getMonth(),
      team: "",
      bankHours: 0,
      teamHours: 0,
      opsHours: 0,
      emHours: 0,
    };
    const newDemand = JSON.parse(JSON.stringify(demand));
    newDemand.push(newValue);
    setNewDemand(newDemand);
  }

  function updateDemand(index: number, newDemand: Budget): void {
    const newDemans = JSON.parse(JSON.stringify(demand));
    newDemans[index] = newDemand;
    setNewDemand(newDemans);
  }

  function deleteDemand(index: number, history: boolean): void {
    if (index < 0 || index >= demand.length) {
      return;
    }
    if (history) {
      setHistoryDeleted(true);
    }
    const newDemand = demand.concat([]);
    newDemand.splice(index, 1);
    setNewDemand(newDemand);
  }

  return (
    <TableBody>
      {demand.map((item, index) => {
        const budgetYear = item.year || 0;
        const budgetMonth = item.month || 0;
        let budgetDate = new Date(budgetYear, budgetMonth);
        const history = budgetDate.getTime() < frozenBudgetThreshold.getTime();
        const budgetHandle = new BudgetsHandle(demand, Array.from(hoursTypes));
        const totalContractHours = budgetHandle.getContractHours(
          budgetDate.getFullYear(),
          budgetDate.getMonth()
        );
        const plannedHours = budgetHandle.getPlannedHours(
          budgetDate.getFullYear(),
          budgetDate.getMonth()
        );
        return (
          <TableRow key={`item-${index}`}>
            <TableCell style={{ minWidth: 100 }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  format="MM/yyyy"
                  value={budgetYear > 0 ? budgetDate : null}
                  disabled={history}
                  onChange={(newDate) => {
                    const newDemand = JSON.parse(JSON.stringify(demand[index]));
                    newDemand.year = newDate?.getFullYear();
                    newDemand.month = newDate?.getMonth();
                    updateDemand(index, newDemand);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </TableCell>
            <TableCell>
              <Select
                labelId="team-label"
                name="team"
                value={item.team}
                disabled={history}
                onChange={(e) => {
                  const teamId = e.target.value as string;
                  const newDemand: Budget = JSON.parse(
                    JSON.stringify(demand[index])
                  );
                  newDemand.team = teamId;
                  updateDemand(index, newDemand);
                }}
                input={<Input />}
                renderValue={(value) => teams[`${value}`]?.name || ""}
              >
                {(Object.values(sortedTeams) || []).map((team) => (
                  <MenuItem key={team.id} value={team.id}>
                    <ListItemText primary={team.name} />
                  </MenuItem>
                ))}
              </Select>
            </TableCell>
            <TableCell>
              <TextField
                type="number"
                value={demand[index].teamHours}
                disabled={history}
                onChange={(e) => {
                  const newDemand = JSON.parse(JSON.stringify(demand[index]));
                  newDemand.teamHours = parseFloat(e.target.value);
                  updateDemand(index, newDemand);
                }}
              />
            </TableCell>
            <TableCell>
              <TextField
                type="number"
                value={demand[index].opsHours || 0}
                disabled={history}
                onChange={(e) => {
                  const newDemand = JSON.parse(JSON.stringify(demand[index]));
                  newDemand.opsHours = parseFloat(e.target.value);
                  updateDemand(index, newDemand);
                }}
              />
            </TableCell>
            <TableCell>
              <TextField
                type="number"
                value={demand[index].bankHours || 0}
                disabled={history}
                onChange={(e) => {
                  const newDemand = JSON.parse(JSON.stringify(demand[index]));
                  newDemand.bankHours = parseFloat(e.target.value);
                  updateDemand(index, newDemand);
                }}
              />
            </TableCell>
            <TableCell>
              <TextField
                type="number"
                value={demand[index].emHours || 0}
                disabled={history}
                onChange={(e) => {
                  const newDemand = JSON.parse(JSON.stringify(demand[index]));
                  newDemand.emHours = parseFloat(e.target.value);
                  updateDemand(index, newDemand);
                }}
              />
            </TableCell>
            <TableCell>{totalContractHours.toFixed(1)}</TableCell>
            <TableCell>{plannedHours.toFixed(1)}</TableCell>
            <TableCell>
              <Tooltip title="Delete">
                <IconButton onClick={() => deleteDemand(index, history)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      })}
      <TableRow>
        <TableCell colSpan={8} style={{ textAlign: "center" }}>
          <Tooltip title="New allocation">
            <Button onClick={addDemand} color="primary">
              New budget
            </Button>
          </Tooltip>
          |
          <Tooltip title="Allocation assistant">
            <IconButton onClick={() => setOnboardingAssistant(true)}>
              <AssistantIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <Drawer
          anchor="right"
          open={onboardingAssistant}
          onClose={() => setOnboardingAssistant(false)}
        >
          <CustomerAllocationAssistant skills={props.skills || []} />
        </Drawer>
      </TableRow>
      {historyDeleted ? (
        <TableRow>
          <TableCell
            colSpan={8}
            style={{ textAlign: "center", backgroundColor: "#ffffcc" }}
          >
            <WarningIcon color="error" /> A history entry has been deleted.
            <br />
            Please discard changes if the deletion was not intentional.
            <br />
            Please remind that removing history entries will impact on
            historical reports.
          </TableCell>
        </TableRow>
      ) : null}
    </TableBody>
  );
}
