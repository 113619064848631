import { Team } from "msd-capacity-planning-model";
export const FETCH_TEAMS = "teams/fetch";
export const LOAD_TEAMS = "teams/load";
export const REMOVE_TEAM = "teams/remove";
export const PERSIST_TEAM = "teams/persist";

export interface TeamsState {
  items: { [id: string]: Team };
  synching: boolean;
}

export interface FetchTeamsAction {
  type: typeof FETCH_TEAMS;
}

export interface LoadTeamsAction {
  type: typeof LOAD_TEAMS;
  items: Team[];
}

export interface RemoveTeamAction {
  type: typeof REMOVE_TEAM;
  id: string;
}

export interface PersistTeamAction {
  type: typeof PERSIST_TEAM;
  item: Team;
}

export type TeamAction =
  | FetchTeamsAction
  | LoadTeamsAction
  | RemoveTeamAction
  | PersistTeamAction;
