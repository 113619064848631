import { Container } from "@material-ui/core";
import { SkillDashboardRoutePage } from "dashboards/skill/SkillDashboardRoutePage";
import { Route, Switch } from "react-router-dom";
import { SkillsDashboardPage } from "./SkillsDashboardPage";

export function SkillsDashboardRoutePage({ baseUrl }: { baseUrl: string }) {
  return (
    <Container maxWidth="xl">
      <Switch>
        <Route path={`${baseUrl}/:skill`}>
          <SkillDashboardRoutePage baseUrl={baseUrl} />
        </Route>
        <Route path={`${baseUrl}`}>
          <SkillsDashboardPage baseUrl={baseUrl} />
        </Route>
      </Switch>
    </Container>
  );
}
