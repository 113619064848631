import useCapacity from "capacity/useCapacity";
import { EngineerHandle } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useAllEngineers } from "./useAllEngineers";

export function useOnboardedEngineers(year: number, month: number) {
  const engineers = useAllEngineers();
  const previous = useCapacity(year, month - 1);
  const current = useCapacity(year, month);
  const [onboardedEngineers, setOnboardedEngineers] = useState<
    EngineerHandle[]
  >([]);

  useEffect(() => {
    const newOnboardedEngineers = Object.keys(current.items)
      .filter((id) => !previous.items[id] && engineers[id])
      .map((id) => engineers[id]);
    setOnboardedEngineers(newOnboardedEngineers);
  }, [year, month, engineers, previous, current]);

  return onboardedEngineers;
}
