import { AggregatedCapacity, TeamCapacity } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useCapacities } from "./useCapacities";

export function useTeamCapacities(
  months: { year: number; month: number }[],
  teamId: string
): AggregatedCapacity<TeamCapacity>[] {
  const totalCapacities = useCapacities(months);
  const [teamCapacities, setTeamCapacities] = useState(
    [] as AggregatedCapacity<TeamCapacity>[]
  );
  useEffect(() => {
    const newTeamCapacities = totalCapacities.map((totalCapacity) => {
      return totalCapacity.getItems().reduce((all, capacity) => {
        if (capacity.isInTeam(teamId)) {
          const engineerTeamCapacity = capacity.getTeamUtilization(teamId);
          all.add(engineerTeamCapacity);
        }
        return all;
      }, new AggregatedCapacity<TeamCapacity>());
    });
    setTeamCapacities(newTeamCapacities);
  }, [months, teamId, totalCapacities]);
  return teamCapacities;
}
