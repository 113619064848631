import { useEffect, useState } from "react";
import { SystemNotification } from "./SystemNotificationTypes";

export function useFilteredNotifications(
  notifications: SystemNotification[],
  sources: string[]
): SystemNotification[] {
  const [filteredNotifications, setFilteredNotifications] = useState(
    [] as SystemNotification[]
  );

  useEffect(() => {
    if (sources.length > 0) {
      const ids = new Set(sources);
      setFilteredNotifications(notifications.filter((n) => ids.has(n.id)));
    } else {
      setFilteredNotifications(notifications);
    }
  }, [notifications, sources]);

  return filteredNotifications;
}
