import { EngineerWarningFlag } from "msd-capacity-planning-model";
import { useSelector } from "react-redux";
import { AppState } from "redux/AppTypes";
import { createSelector } from "reselect";
import { useAllEngineersSelector } from "./useAllEngineers";

export const useEngineersWarningFlagsSelector = createSelector(
  useAllEngineersSelector,
  (items) => {
    const values = Object.values(items) || [];
    return values.reduce((map, e) => {
      map[e.id] = e.getWarningFlags();
      return map;
    }, {} as { [id: string]: Set<EngineerWarningFlag> });
  }
);

export function useEngineersWarningFlags(): {
  [id: string]: Set<EngineerWarningFlag>;
} {
  const state = useSelector((state: AppState) => state);
  return useEngineersWarningFlagsSelector(state);
}
