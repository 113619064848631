import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import WarningIcon from "@material-ui/icons/Warning";
import { DeleteButton } from "components/DeleteButton";
import { BasicModal } from "components/BasicModal";

import { CustomerHandle, Customer } from "msd-capacity-planning-model";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../redux/AppTypes";
import { FETCH_CUSTOMERS, REMOVE_CUSTOMER } from "./CustomerTypes";
import useAllCustomers from "./useAllCustomers";
import { useCustomerWarningFlags } from "./useCustomerWarningFlags";
import { PERSIST_CUSTOMER } from "./CustomerTypes";
import {
  ReportFeedbackAction,
  REPORT_APP_FEEDBACK
} from "../redux/AppTypes";

export function CustomersPanel() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const dispatch = useDispatch();
  const history = useHistory();
  const ems = useSelector((state: AppState) => state.ems.items);
  const items = useAllCustomers();
  const teams = useSelector((state: AppState) => state.teams.items);
  const [nameFilter, setNameFilter] = useState("");
  const [offboardedFilter, setOffboardedFilter] = useState(false);
  const [filteredItems, setFilteredItems] = useState([] as CustomerHandle[]);
  const warningFlags = useCustomerWarningFlags(year, month);

  const [isVisible, setIsVisible] = useState(false);

  const [selectedItem, setSelectedItem] = useState({} as CustomerHandle);


  const [modal, setModal] = useState({
    agreeLabel: "Confirm",
    onDesagree: () => { },
    onAgree: async (item: CustomerHandle) => { },
    desagreeLabel: "Cancel",
    body: null,
    title: null,
    confirmMessage: null
  });

  useEffect(() => {
    const now = new Date();
    let newFilteredItems = Object.values(items);

    if (nameFilter) {
      newFilteredItems = newFilteredItems.filter(
        (c) => c.name.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1
      );
    }
    if (!offboardedFilter) {
      newFilteredItems = newFilteredItems.filter(
        (c) => c.isOffboarded(now) === false
      );
    }
    newFilteredItems.sort((c1, c2) => {
      const offboarded1 = c1.isOffboarded(now) ? 1 : 0;
      const offboarded2 = c2.isOffboarded(now) ? 1 : 0;
      if (offboarded1 !== offboarded2) {
        return offboarded1 - offboarded2;
      }
      const flags1 = (warningFlags[c1.id] || new Set()).size;
      const flags2 = (warningFlags[c2.id] || new Set()).size;
      return flags2 - flags1 || c1.compare(c2);
    });

    const setModalMetadata = (item: CustomerHandle) => {
      setModal({
        ...modal,
        title: item?.name + " was updated outside of Capacity Planner. We suggest checking this project.",
        body: "",
        onAgree: async () => {
          console.log(item)
          dispatch({
            type: PERSIST_CUSTOMER,
            item,
          }) as any;

          setIsVisible(false);
          dispatch({ type: FETCH_CUSTOMERS })
        },
        onDesagree: () => { setIsVisible(false); },
        confirmMessage: "I confirm those updates were checked",
      });
    };

    setFilteredItems(newFilteredItems);
    if (selectedItem && Object.keys(selectedItem).length !== 0) {
      setModalMetadata(selectedItem);
    }

  }, [year, month, items, nameFilter, offboardedFilter, warningFlags, selectedItem]);

  return (
    <>
      <BasicModal modal={modal} isVisible={isVisible} setIsVisible={setIsVisible} />
      <Box maxWidth="lg">
        <Grid>
          <Tooltip title="Add">
            <Button
              onClick={() => history.push("/administration/customers/")}
              color="primary"
              variant="contained"
            >
              New customer
            </Button>
          </Tooltip>
          <Tooltip title="Refresh">
            <IconButton onClick={() => dispatch({ type: FETCH_CUSTOMERS })}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={offboardedFilter}
                onChange={() => setOffboardedFilter(!offboardedFilter)}
              />
            }
            label="Offboarded"
            style={{ marginRight: 16 }}
          />
          <Tooltip title="Search">
            <TextField
              onChange={(e) => setNameFilter(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {" "}
                    <SearchIcon />{" "}
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>
        </Grid>
        <Grid container>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Team</TableCell>
                  <TableCell>Engagement manager</TableCell>
                  <TableCell style={{ maxWidth: 100 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredItems.map((item, index) => {
                  const flags = Array.from(warningFlags[item.id] || new Set());
                  const offboarded = item.isOffboarded(now);
                  return (
                    <TableRow key={`${index}`}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={
                          offboarded ? { textDecorationLine: "line-through" } : {}
                        }
                      >
                        {index + 1}. {item.name}
                      </TableCell>
                      <TableCell style={{ width: 100 }} >{teams[item.getTeam()]?.name}</TableCell>
                      <TableCell style={{ width: 150 }}>
                        {ems[item.engagementManager]?.firstName}{" "}
                        {ems[item.engagementManager]?.lastName}
                      </TableCell>
                      <TableCell style={{ width: 250 }} align="right">

                        {item.jira.updatedOutOfCP &&
                          <Tooltip
                            title={
                              <ul style={{ paddingLeft: 0 }}>
                                <li key={`item-${index}`}>"Jira project has been updatated outside of Capacity Planner"</li>
                              </ul>
                            }
                          >
                            <IconButton
                              color="secondary"
                              onClick={() => {
                                const itemUpdated = JSON.parse(JSON.stringify(item));
                                itemUpdated.jira.updatedOutOfCP = false;
                                setSelectedItem(itemUpdated);
                                setIsVisible(true)
                              }
                              }
                            >
                              <svg fill="#f50057" version="1.1" id="Capa_1" width="20px" height="20px" viewBox="0 0 31.569 31.569" stroke="#da2f2f" transform="rotate(0)"><g id="SVGRepo_bgCarrier" stroke-width="0">
                              </g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                  <g>
                                    <g>
                                      <path d="M9.635,12.041c3.595-1.193,6.812-0.585,6.812-0.585l0.011,0.937c0.016,1.514,0.43,1.652,0.43,1.652 c0.979,0.765,1.867-0.053,1.867-0.053s6.262-4.61,6.426-4.731c0.164-0.122,1.133-0.723,1.136-2.299 c0.001-1.578-1.33-2.33-1.33-2.33s-5.17-3.52-6.212-4.229c-1.044-0.71-1.629-0.267-1.629-0.267 c-0.768,0.502-0.698,1.16-0.698,1.16v1.84c-5.81,1.193-9.875,4.722-9.875,4.722c-5.587,4.751-4.049,11.353-4.049,11.353 s0.014,0.296,0.58,0.296c0.643,0,0.65-0.396,0.65-0.396C4.02,16.863,6.04,13.234,9.635,12.041z"></path> <path d="M29.046,12.355c0,0-0.015-0.294-0.578-0.294c-0.646,0-0.65,0.396-0.65,0.396c-0.268,2.247-2.286,5.875-5.882,7.07 c-3.597,1.192-6.812,0.584-6.812,0.584l-0.011-0.937c-0.015-1.518-0.428-1.652-0.428-1.652c-0.979-0.766-1.869,0.053-1.869,0.053 s-6.261,4.611-6.425,4.732c-0.165,0.121-1.13,0.722-1.134,2.3c-0.004,1.575,1.327,2.329,1.327,2.329s5.168,3.519,6.211,4.229 c1.043,0.711,1.63,0.269,1.63,0.269c0.767-0.504,0.698-1.157,0.698-1.157v-1.843c5.811-1.191,9.875-4.724,9.875-4.724 C30.585,18.958,29.046,12.355,29.046,12.355z"></path>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </IconButton>
                          </Tooltip>}


                        {flags.length > 0 ? (
                          <Tooltip
                            title={
                              <ul style={{ paddingLeft: 0 }}>
                                {flags.map((warning, index) => (
                                  <li key={`item-${index}`}>{warning}</li>
                                ))}
                              </ul>
                            }
                          >
                            <IconButton
                              color="secondary"
                              onClick={() =>
                                history.push(
                                  `/administration/customers/${item.id}`
                                )
                              }
                            >
                              <WarningIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}

                        <IconButton
                          title="Dashboard"
                          onClick={() =>
                            history.push(`/dashboards/customers/items/${item.id}`)
                          }
                        >
                          <DashboardIcon />
                        </IconButton>
                        <IconButton
                          title="Edit"
                          onClick={() =>
                            history.push(`/administration/customers/${item.id}`)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                        <DeleteButton
                          title="Delete customer"
                          text={`Are you sure you want to delete "${item.name}"?`}
                          onClick={() =>
                            dispatch({ type: REMOVE_CUSTOMER, id: item.id })
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box >
    </>
  );
}
