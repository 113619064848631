import { EMHandle } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "redux/AppTypes";
import { createSelector } from "reselect";
import { useAllEMsSelector } from "./useAllEMs";

export const useAllEMLeadsSelector = createSelector(
  [useAllEMsSelector],
  (ems) => {
    return Object.values(ems).filter((em) => em.isLead());
  }
);

export function useEMLeads(
  year: number,
  month: number
): { [emId: string]: EMHandle } {
  const state = useSelector((state: AppState) => state);
  const all = useAllEMLeadsSelector(state);
  const [active, setActive] = useState({} as { [emId: string]: EMHandle });

  useEffect(() => {
    const date = new Date(year, month);
    const newActive = all
      .filter((em) => !em.isOffboarded(date))
      .reduce((map, em) => {
        map[em.id] = em;
        return map;
      }, {} as { [emId: string]: EMHandle });
    setActive(newActive);
  }, [year, month, all]);

  return active;
}
