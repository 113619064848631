export default function useDefaultProductTypes(): string[] {
  return [
    "DataOps",
    "DevOps-Pod",
    "DevOps-Squad",
    "O365",
    "Openstack",
    "Security",
    "VMM",
    "VMware",
  ];
}
