import { useEffect, useState } from 'react'
import { JiraBoardColumnsDTO } from './JiraBoardColumnsDTO'
import service from './JiraResourcesService'

export function useJiraBoardColumns(
  projectKey: string,
  reloadCounter: number
): JiraBoardColumnsDTO | null | undefined {
  const [columns, setColumns] = useState(
    undefined as JiraBoardColumnsDTO | null | undefined
  )

  useEffect(() => {
    setColumns(undefined)
    service.getBoardColumns(projectKey).then((response) => setColumns(response))
  }, [projectKey, reloadCounter])

  return columns
}
