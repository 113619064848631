import { EMHandle } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useAllEMs } from "./useAllEMs";

export default function useEM(emId: string): EMHandle | undefined {
  const ems = useAllEMs();
  const [em, setEM] = useState(undefined as EMHandle | undefined);
  useEffect(() => {
    setEM(ems[emId]);
  }, [emId, ems]);
  return em;
}
