import { useTheme } from "@material-ui/core";
import { useBudgetByAggregator } from "customers/useBudgetByAggregator";
import { CustomerAggregatorType } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import useRegions from "regions/useRegions";
import { getColors } from "utils/colorGenerator";
import { PieChartCard } from "../../components/PieChartCard";

export function BudgetByRegionPieChart({
  year,
  month,
}: {
  year: number;
  month: number;
}) {
  const theme = useTheme();
  const regions = useRegions();
  const budgets = useBudgetByAggregator(
    year,
    month,
    CustomerAggregatorType.REGION
  );

  const [data, setData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    const regionIds = Object.keys(regions);
    const labels = regionIds.map((id) => regions[id]?.name || "");
    const data = regionIds.map(
      (id) => budgets[id]?.teamHours + budgets[id]?.opsHours || 0
    );
    const backgroundColor = getColors(theme, data.length);
    setData({ labels, datasets: [{ data, backgroundColor }] });
  }, [theme, regions, budgets]);

  return <PieChartCard title="Budget by region" data={data} />;
}
