import { Box, IconButton, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import useTeam from "../../teams/useTeam";

export function TeamOverviewCard({
  year,
  month,
  teamId,
}: {
  year: number;
  month: number;
  teamId: string;
}) {
  const history = useHistory();
  const team = useTeam(teamId);

  return (
    <Box style={{ marginBottom: 8 }}>
      <Typography variant="body2">
        <strong>{team?.name}</strong> - <strong>Lead</strong>: {team?.lead} |{" "}
        <strong>Manager</strong>: {team?.manager} | <strong>Type</strong>:{" "}
        {team?.type}
        <IconButton
          onClick={() => history.push(`/administration/teams/${teamId}`)}
        >
          <EditIcon />
        </IconButton>
      </Typography>
    </Box>
  );
}
