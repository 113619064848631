import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import { DeleteButton } from "components/DeleteButton";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  FETCH_ADMINISTRATORS,
  REMOVE_ADMINISTRATOR
} from "./AdministratorTypes";
import { useSortedAministrators } from "./useSortedAdministrators";

export function AdministratorsPanel() {
  const history = useHistory();
  const dispatch = useDispatch();
  const admins = useSortedAministrators();

  useEffect(() => {
    dispatch({ type: FETCH_ADMINISTRATORS });
  }, [dispatch]);

  return (
    <Box maxWidth="lg">
      <Grid>
        <Tooltip title="Add">
          <Button
            onClick={() => history.push("/administration/administrators/")}
            color="primary"
            variant="contained"
          >
            New administator
          </Button>
        </Tooltip>
        <Tooltip title="Refresh">
          <IconButton onClick={() => dispatch({ type: FETCH_ADMINISTRATORS })}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Roles</TableCell>
              <TableCell style={{ maxWidth: 100 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {admins.map((admin, idx) => (
              <TableRow key={`admin-${idx}`}>
                <TableCell>{admin.id}</TableCell>
                <TableCell>{admin.roles}</TableCell>
                <TableCell style={{ maxWidth: 100 }}>
                  <IconButton
                    title="Edit"
                    onClick={() =>
                      history.push(`/administration/administrators/${admin.id}`)
                    }
                  >
                    <EditIcon />
                  </IconButton>
                  <DeleteButton
                    title="Delete customer"
                    text={`Are you sure you want to delete "${admin.id}"?`}
                    onClick={() =>
                      dispatch({ type: REMOVE_ADMINISTRATOR, id: admin.id })
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
