import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { Engineer } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";

export function EngineersRolesTable({
  engineers,
}: {
  engineers: { [engineerId: string]: Engineer };
}) {
  const [roles, setRoles] = useState({} as { [role: string]: number });

  useEffect(() => {
    const newRoles = Object.values(engineers).reduce((all, engineer) => {
      all[engineer.role] = (all[engineer.role] || 0) + 1;
      return all;
    }, {} as { [role: string]: number });
    setRoles(newRoles);
  }, [engineers]);

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Role</TableCell>
            <TableCell>Engineers</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(roles).map((role, index) => {
            return (
              <TableRow key={`role-${index}`}>
                <TableCell>{role}</TableCell>
                <TableCell>{roles[role]}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
