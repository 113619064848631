import { Button, CircularProgress, Tooltip } from '@material-ui/core'
import React, { useState } from 'react'
import service, { JiraProjectSchemeType } from './JiraResourcesService'
import { useJiraProjectScheme } from './useJiraProjectScheme'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'

const schemeNames = {
  [JiraProjectSchemeType.ISSUE_TYPE]: 'Issue type scheme',
  [JiraProjectSchemeType.ISSUE_SCREEN_TYPE]: 'Issue screen type scheme',
  [JiraProjectSchemeType.NOTIFICATION]: 'Notification scheme',
  [JiraProjectSchemeType.PERMISSION]: 'Permission scheme',
  [JiraProjectSchemeType.WORKFLOW]: 'Workflow scheme'
}

export function JiraProjectScheme({
  projectKey,
  scheme
}: {
  projectKey: string
  scheme: JiraProjectSchemeType
}) {
  const [reloadCounter, setReloadCounter] = useState(0)
  const value = useJiraProjectScheme(projectKey, scheme, reloadCounter)
  const [updateClicked, setUpdateClicked] = useState(false)
  const [updating, setUpdating] = useState(false)

  function onUpdate(): void {
    setUpdateClicked(true)
    setUpdating(true)
    service.updateScheme(projectKey, scheme).finally(() => {
      setUpdating(false)
      setReloadCounter(reloadCounter + 1)
    })
  }

  const status =
    value.scheme?.expected === true ? (
      <Tooltip title='Standard scheme'>
        <CheckIcon style={{ color: 'green' }} />
      </Tooltip>
    ) : (
      <Tooltip title='Not standard scheme'>
        <ClearIcon style={{ color: 'red' }} />
      </Tooltip>
    )

  return (
    <span>
      <span style={{ marginRight: 8, verticalAlign: 'middle' }}>
        {value.loading ? <CircularProgress size={16} /> : status}
      </span>
      {value.loading ? null : (
        <span>
          {schemeNames[scheme]} : {value.scheme?.name}{' '}
          {value.scheme?.expected === true ? null : (
            <span>
              <Button
                variant='text'
                color='primary'
                disabled={updateClicked}
                onClick={() => onUpdate()}
              >
                Update{' '}
                {updating ? (
                  <CircularProgress size={16} style={{ marginLeft: 8 }} />
                ) : null}
              </Button>
            </span>
          )}
        </span>
      )}
    </span>
  )
}
