import { createSelector } from "reselect";
import { AppState } from "../redux/AppTypes";
import { TeamHandle } from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { useAllTeamsSelector } from "./useAllTeams";
import { useSelector } from "react-redux";


export function useTeams(
  year: number,
  month: number
): { [teamId: string]: TeamHandle } {
  const state = useSelector((state: AppState) => state);
  const teams = useAllTeamsSelector(state);
  const [filtered, setFiltered] = useState({} as { [teamId: string]: TeamHandle });

  useEffect(() => {
    const date = new Date(year, month + 1, 1);
    const newTeams = Object.values(teams).reduce((acc, team) => {
      if (!team.isOffboarded(date)) {
        acc[team.id] = team;
      }
      return acc;
    }, {} as { [emId: string]: TeamHandle });
    setFiltered(newTeams);
  }, [year, month, teams]);

  return filtered;
}