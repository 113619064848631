import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "inherit",
      color: "inherit",
    },
  })
);

export default useStyles;
