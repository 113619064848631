import { Grid, Paper, Typography } from "@material-ui/core";
import useTeamCapacityInAggregator from "capacity/useTeamCapacityInAggregator";
import { PLANNING_PREFIX } from "comments/CustomerPlanningCommentsPanel";
import { CountCard } from "components/CountCard";
import { useBudgetInAggregator } from "customers/useBudgetInAggregator";
import useCustomersInAggregator from "customers/useCustomersInAggregator";
import {
  CustomerAggregatorType,
  TeamCapacityAggregatorType
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import useTeam from "teams/useTeam";
import { TeamCapacityTable } from "../TeamCapacityTable";
import { TeamCompositionTable } from "../TeamCompositionTable";
import { TeamHolidaysCard } from "../TeamHolidaysCard";
import { TeamOverviewCard } from "../TeamOverviewCard";
import { TeamPlanningCustomersTable } from "./TeamPlanningCustomerTable";

const NOTES_KEY = PLANNING_PREFIX;

export function TeamPlanningDashboard({
  year,
  month,
  teamId,
}: {
  year: number;
  month: number;
  teamId: string;
}) {
  const team = useTeam(teamId);
  const [date, setDate] = useState(new Date(year, month));
  const customers = useCustomersInAggregator(
    date.getFullYear(),
    date.getMonth(),
    CustomerAggregatorType.TEAM,
    teamId
  );
  const capacity = useTeamCapacityInAggregator(
    date.getFullYear(),
    date.getMonth(),
    TeamCapacityAggregatorType.TEAM,
    teamId
  );
  const budget = useBudgetInAggregator(
    date.getFullYear(),
    date.getMonth(),
    CustomerAggregatorType.TEAM,
    teamId
  );

  useEffect(() => {
    setDate(new Date(year, month));
  }, [year, month]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      spacing={2}
      style={{ marginTop: 16 }}
    >
      <Grid item xs={12}>
        <Typography variant="h5">
          {team?.name} planning:{" "}
          {date.toLocaleString("default", { month: "long" })}
        </Typography>
        <TeamOverviewCard
          year={date.getFullYear()}
          month={date.getMonth()}
          teamId={teamId}
        />
      </Grid>

      <Grid item xs={2}>
        <CountCard title="Customers" count={Object.keys(customers).length} />
      </Grid>
      <Grid item xs={2}>
        <CountCard
          title="Budget"
          count={Math.round(budget.opsHours + budget.teamHours)}
        />
      </Grid>
      <Grid item xs={2}>
        <CountCard
          title="Engineers"
          count={Object.keys(capacity.items).length}
        />
      </Grid>
      <Grid item xs={2}>
        <CountCard
          title="Capacity"
          count={Math.round(capacity.allocatedHours)}
        />
      </Grid>
      <Grid item xs={2}>
        <CountCard
          title="Delta"
          count={Math.round(
            capacity.allocatedHours - budget.opsHours - budget.teamHours
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <TeamPlanningCustomersTable
            teamId={teamId}
            year={date.getFullYear()}
            month={date.getMonth()}
          />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <TeamCapacityTable
            teamId={teamId}
            year={date.getFullYear()}
            month={date.getMonth()}
            linkPrefix="/engineers"
            size="small"
            notes={true}
            notesKey={NOTES_KEY}
            actualHours={false}
          />
        </Paper>
      </Grid>

      <Grid item xs={6}>
        <Paper>
          <TeamCompositionTable
            teamId={teamId}
            year={date.getFullYear()}
            month={date.getMonth()}
          />
        </Paper>
      </Grid>

      <Grid item xs={6}>
        <Paper>
          <TeamHolidaysCard
            teamId={teamId}
            year={date.getFullYear()}
            month={date.getMonth()}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
