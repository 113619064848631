import { Region } from "msd-capacity-planning-model";
import {
  FETCH_REGIONS,
  LOAD_REGIONS,
  PERSIST_REGION,
  RegionAction,
  RegionsState,
  REMOVE_REGION
} from "./RegionTypes";

const initialState: RegionsState = {
  items: {},
  synching: true,
};

export default function teamsReducer(
  state = initialState,
  action: RegionAction
): RegionsState {
  switch (action.type) {
    case FETCH_REGIONS:
      return {
        ...state,
        synching: true,
      };
    case REMOVE_REGION:
      return {
        ...state,
        synching: true,
      };
    case PERSIST_REGION:
      return {
        ...state,
        synching: true,
      };
    case LOAD_REGIONS:
      return {
        ...state,
        items: action.items.reduce((all, region) => {
          all[region.id] = region;
          return all;
        }, {} as { [id: string]: Region }),
        synching: false,
      };
    default:
      return state;
  }
}
