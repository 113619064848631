import { Customer } from "msd-capacity-planning-model";
export const FETCH_CUSTOMERS = "customers/fetch";
export const LOAD_CUSTOMERS = "customers/load";
export const REMOVE_CUSTOMER = "customers/remove";
export const PERSIST_CUSTOMER = "customers/persist";

export interface CustomersState {
  items: { [id: string]: Customer };
  synching: boolean;
}

export interface FetchCustomersAction {
  type: typeof FETCH_CUSTOMERS;
}

export interface LoadCustomersAction {
  type: typeof LOAD_CUSTOMERS;
  items: Customer[];
}

export interface RemoveCustomerAction {
  type: typeof REMOVE_CUSTOMER;
  id: string;
}

export interface PersistCustomerAction {
  type: typeof PERSIST_CUSTOMER;
  item: Customer;
}

export type CustomerAction =
  | FetchCustomersAction
  | LoadCustomersAction
  | RemoveCustomerAction
  | PersistCustomerAction;
